import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import ProductCard from "../common/ProductCard";
import SliderNavButtons from "../common/SliderNavButtons";

const FeaturedProducts = () => {
  const sliderRef = useRef(null);
  const [products, setProducts] = useState([]);

  const settings = {
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <></>,
    prevArrow: <></>,

    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 968,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const handlePrev = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const featuredproducturl =
        process.env.REACT_APP_BASE_URL + "homepage/featuredproduct/";
      try {
        const response = await fetch(`${featuredproducturl}`);
        const data = await response.json();
        console.log(data);
        const transformedData = data.map((product) => ({
          id: product.product_id.id,
          imageUrl: product.product_id.part_id.image,
          brand: product.product_id.part_id.brand,
          rating: 5,
          title: product.product_id.part_id.partName,
          price: product.product_id.normalRate,
          unit: product.product_id.units,
        }));
        setProducts(transformedData);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchData();
  }, []);

  const imageurl = process.env.REACT_APP_BASE;

  return (
    <div className="bg-gray-200 px-6 mt-5 pb-5 md:pt-10 md:px-10 lg:px-20">
      <div className="flex items-center justify-between">
        <h1 className="text-lg vsm:text-xl font-bold">Featured Products</h1>

        <SliderNavButtons onNext={handleNext} onPrev={handlePrev} />
      </div>

      <div className="relative w-full mt-2 mb-5 md:mb-10 bg-gray-300 h-[2px] flex">
        <div className="absolute top-0 left-0 flex">
          <div className="w-[160px] bg-yellow h-[10px]"></div>
          <div className="w-[50px] relative -left-4 bg-yellow h-[10px] skew-x-[-50deg]"></div>
        </div>
      </div>

      <div className="md:pt-4">
        <Slider {...settings} ref={sliderRef}>
          {products.map((product, index) => (
            <div className="px-5" key={index}>
              <ProductCard
                imageUrl={product.imageUrl}
                brand={product.brand}
                rating={product.rating}
                title={product.title}
                price={product.price}
                unit={product.unit}
                id={product.id}
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default FeaturedProducts;
