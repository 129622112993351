import axios from "axios";
import { atom, useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { FaCheck, FaTimes } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { ToastContainer, toast } from "react-toastify";
import InputWithLabel from "../../../common/InputWithLabel";
import { orderNumAtom, purchaseOrderTypeAtom } from "./PurchaseOrder";
import { useNavigate } from "react-router-dom";

export const isPurchaseOrderViewedAtom = atom(false);

const ViewOrder = () => {
  const navigate = useNavigate();
  const [productsOfOrder, setProductsOfOrder] = useState([]);
  const [apiData, setApiData] = useState(null);
  const [currentDate, setCurrentDate] = useState("");
  const [orderNum] = useAtom(orderNumAtom);
  const [purchaseOrderType] = useAtom(purchaseOrderTypeAtom);
  const [formData, setFormData] = useState({});
  const [openPopupForm, setOpenPopupForm] = useState(null);
  const [errors, setErrors] = useState({});
  const [specific_seller_quotation_id, setSpecific_seller_quotation_id] =
    useState(null);
  const [popupFormData, setPopupFormData] = useState({
    remarks: "",
  });

  const photo_url = process.env.REACT_APP_BASE;

  // const fetchData = async () => {
  //   try {
  //     const url =
  //       process.env.REACT_APP_BASE_URL + "newseller/getsellerprofile/1/";
  //     const response = await axios.get(`${url}`); // Replace with your actual API endpoint
  //     const apiDatas = response.data;
  //     setApiData(apiDatas[0]);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  useEffect(() => {
    // setData(staticData)

    const fetchData = async () => {
      const url =
        process.env.REACT_APP_BASE_URL +
        "quotation/multiple-order-details/" +
        orderNum +
        "/";
      try {
        const response = await axios.get(`${url}`);
        const apiData = response.data;

        console.log(apiData);

        // Transform the API data into the desired format
        const transformedData = apiData.map((item, index) => {
          return {
            specific_seller_quotation_id: item.id,
            partNo: item.quotation_id.part_id.partNumber || "N/A",
            partDesc: item.quotation_id.part_id.partSubCategories || "N/A",
            unit: item.quotation_id.quantity || "N/A",
            price: item.quoted_price_per_unit || "N/A",
            totalPrice: item.quoted_price_total || "N/A",
            deliveryTime: item.delivery_period || "N/A",
            status: item.status || "",
            remarks: item.remarks || "",
            checkstatus: item.quotation_id.status || "",
          };
        });
        if (transformedData.checkstatus === "") {
          transformedData.checkstatus = "Pending";
        }
        if (transformedData.checkstatus === "Accepted") {
          transformedData.checkstatus = "Rejected";
        }

        setProductsOfOrder(transformedData);

        console.log("Products of Order", productsOfOrder);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [orderNum]);

  const Accepted = async (id) => {
    formData["specific_seller_quotation_id"] = id;
    formData["status"] = "Accepted";
    try {
      const url = process.env.REACT_APP_BASE_URL + "quotation/updatestatus/";
      const response = await axios.post(`${url}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        body: JSON.stringify(formData),
      });
      console.log("Data sent successfully:", response.data);

      toast.success("Accepted Successfully!");
      navigate("/seller/business-orders/purchase-order");
    } catch (error) {
      console.error("Error sending data:", error);
      // Handle error
    }
  };

  const onDeclineSubmit = async () => {
    try {
      const url =
        process.env.REACT_APP_BASE_URL + "quotation/declinespecificquotation/";
      popupFormData["specific_seller_quotation_id"] =
        specific_seller_quotation_id;
      const response = await axios.post(`${url}`, popupFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        body: JSON.stringify(popupFormData),
      });
      console.log("Data sent successfully:", response.data);
    } catch (error) {
      console.error("Error sending data:", error);
      // Handle error
    }
  };

  // fetch data to get the products of the order and set it to productsOfOrder

  useEffect(() => {
    // fetchData();

    const today = new Date();
    const formattedDate = today.toLocaleDateString();
    setCurrentDate(formattedDate);
  }, []);

  const handleInputChange = (name, value) => {
    setPopupFormData({
      ...popupFormData,
      [name]: value,
    });
  };
  const clearError = (name) => {
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  return (
    <div class="mx-auto m-6 flex flex-col gap-4">
      <ToastContainer position="top-center" />

      <button
        className="bg-blue w-fit mx-6 px-4 py-1.5 rounded-md text-white font-medium"
        onClick={(event) => {
          event.preventDefault();
          window.history.back();
        }}
      >
        Back
      </button>

      {productsOfOrder.length > 0 ? (
          productsOfOrder.map((product, index) => (
            <div
              className="bg-white mx-6 rounded-lg shadow-lg px-8 py-10"
              key={index}
            >
              <div className="flex flex-col">
                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                    <div className="overflow-hidden">
                      <table className="min-w-full border text-center text-sm font-light">
                        <thead className="border-b font-bold text-lg">
                          <tr>
                            <th className="border-r h-[38px] px-4 py-0">Parts No.</th>
                            <th className="border-r h-[38px] px-4 py-0">Parts Description</th>
                            <th className="border-r h-[38px] px-4 py-0">Unit</th>
                            <th className="border-r h-[38px] px-4 py-0">Propsed Price (Per Unit)</th>
                            <th className="border-r h-[38px] px-4 py-0">Total Price</th>

                            {purchaseOrderType === "Ongoing" && (
                              <>
                                <th className="border-r h-[38px] px-4 py-0">Delivery Time</th>
                                <th className="border-r h-[38px] px-4 py-0">Actions</th>
                              </>
                            )}

                            {purchaseOrderType === "Previous" && (
                              <>
                                <th className="border-r h-[38px] px-4 py-0">Status</th>
                                <th className="border-r h-[38px] px-4 py-0">Remarks</th>
                              </>
                            )}
                          </tr>
                        </thead>

                        <tbody>
                          <tr className="border-b">
                            <td className="whitespace-nowrap border-r h-[38px] px-4 py-0">{product.partNo}</td>
                            <td className="whitespace-nowrap border-r h-[38px] px-4 py-0">{product.partDesc}</td>
                            <td className="whitespace-nowrap border-r h-[38px] px-4 py-0">{product.unit}</td>
                            <td className="whitespace-nowrap border-r h-[38px] px-4 py-0">{product.price}</td>
                            <td className="whitespace-nowrap border-r h-[38px] px-4 py-0">{product.totalPrice}</td>

                            {purchaseOrderType === "Ongoing" && (
                              <>
                                <td className="whitespace-nowrap border-r h-[38px] px-4 py-0">{product.deliveryTime}</td>
                                {product.checkstatus === "Pending" && (
                                  <td className="whitespace-nowrap h-[38px] px-4 py-0 flex items-center justify-center gap-2">
                                    <button
                                      className="px-1.5 border-2 border-gray-300 font-medium rounded-md h-[35px]"
                                      onClick={() => {
                                        Accepted(product.specific_seller_quotation_id);
                                      }}
                                    >
                                      <FaCheck className="w-[20px] h-[20px] text-green" />
                                    </button>

                                    <button
                                      className="px-1.5 border-2 border-gray-300 font-medium rounded-md h-[35px]"
                                      onClick={() => {
                                        console.log("CLICKED");
                                        setSpecific_seller_quotation_id(product.specific_seller_quotation_id);
                                        setOpenPopupForm("Decline");
                                        toast.success("Declined Successfully!");
                                      }}
                                    >
                                      <FaTimes className="w-[20px] h-[20px] text-red" />
                                    </button>

                                    <button className="px-1.5 border-2 border-gray-300 font-medium rounded-md h-[35px]">
                                      <AiOutlineEye className="w-[22px] h-[22px] text-gray-700" />
                                    </button>
                                  </td>
                                )}
                              </>
                            )}

                            {purchaseOrderType === "Previous" && (
                              <>
                                <td className="whitespace-nowrap border-r h-[38px] px-4 py-0">
                                  {product.status === "Accepted" ? "Processing" : product.status === "Pending" ? "Rejected" : product.status}
                                </td>
                                <td className="whitespace-nowrap border-r h-[38px] px-4 py-0">{product.remarks}</td>
                              </>
                            )}
                          </tr>

                          <tr className="border-b">
                            <td className="whitespace-nowrap border-r h-[38px] px-4 py-0" colSpan={7}></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className="mx-6">
            <table className="min-w-full border text-center text-sm font-light">
                        <thead className="border-b font-bold text-lg">
                          <tr>
                            <th className="border-r h-[38px] px-4 py-0">Parts No.</th>
                            <th className="border-r h-[38px] px-4 py-0">Parts Description</th>
                            <th className="border-r h-[38px] px-4 py-0">Unit</th>
                            </tr>
                        </thead>

                        {/* <tbody>
                          <tr className="border-b">
                            <td className="whitespace-nowrap border-r h-[38px] px-4 py-0">{productsOfOrder[0].partNo}</td>
                            <td className="whitespace-nowrap border-r h-[38px] px-4 py-0">{productsOfOrder[0].partDesc}</td>
                            <td className="whitespace-nowrap border-r h-[38px] px-4 py-0">{productsOfOrder[0].unit}</td>
                           
                          </tr>

                        </tbody> */}
                      </table>
          </p>
        )}


      {openPopupForm === "Decline" && (
        <div className="bg-[#0000007c] z-50 fixed top-0 left-0 w-screen h-screen flex items-center justify-center p-4">
          <div className="relative bg-white max-w-[800px] max-h-[95vh] overflow-y-auto sm:min-w-[600px] rounded-lg px-10 py-8">
            <div className="flex flex-col gap-4">
              <button
                className="absolute right-2 top-2 text-red-900"
                onClick={() => {
                  setOpenPopupForm(null);
                }}
              >
                <IoClose className="w-7 h-7" />
              </button>

              <div className="flex flex-col gap-1">
                <InputWithLabel
                  htmlFor="remarks"
                  label="Remarks"
                  placeholder="Enter remarks"
                  name="remarks"
                  value={popupFormData.remarks}
                  isTextArea={true}
                  rows={5}
                  onChange={(e) => {
                    handleInputChange("remarks", e.target.value);
                    clearError("remarks");
                  }}
                />

                {errors.remarks && (
                  <p className="text-red-900 text-[14px] font-medium">
                    {errors.remarks}
                  </p>
                )}
              </div>

              <button
                type="button"
                className="w-fit self-end mt-4 bg-blue px-6 py-1.5 rounded-md text-[14px] text-white font-semibold"
                onClick={() => {
                  onDeclineSubmit();
                }}
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
      )}


    </div>
  );
};

export default ViewOrder;
