import { useAtom } from "jotai";
import React, { useEffect, useRef, useState } from "react";
import { HiChevronDown, HiChevronLeft, HiChevronRight } from "react-icons/hi";
import DeclinedTable, { dataAtom, rowsPerPageAtom } from "./DeclinedTable";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify"; // Import ToastContainer and toast from react-toastify
import "react-toastify/dist/ReactToastify.css"; // Import CSS for react-toastify
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";



const staticData = [
  {
    productName: "Laptop",
    orderNo: "ORD123456",
    orderDate: "2023-11-21",
    price: 999.99,
    paymentMethod: "Credit Card",
    status: "Shipped",
  },
  {
    productName: "Smartphone",
    orderNo: "ORD789012",
    orderDate: "2023-11-22",
    price: 499.99,
    paymentMethod: "PayPal",
    status: "Delivered",
  },
];

const Declined = ({ searchQuery }) => {
  
const navigate = useNavigate();
  const [rowsPerPage, setRowsPerPage] = useAtom(rowsPerPageAtom);
  const [data, setData] = useAtom(dataAtom);
  const dataLength = data.length;

  const optionsRef = useRef(null);

  // State for managing the visibility of the rows per page options
  const [showRowsPerPageOptions, setShowRowsPerPageOptions] = useState(false);

  // Array of options for rows per page
  const rowsPerPageOptions = [10, 12, 14, 16, 18, 20];

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        // Handle the case where the token is not present (e.g., redirect to login page)
        toast.error("Authentication credentials not provided. Please log in.");
        return;
      }
  
      // Check if the authentication token has expired (example for JWT, may vary based on implementation)
      const isTokenExpired = () => {
        try {
          const decodedToken = jwtDecode(token);
          const currentTime = Date.now() / 1000; // Convert to seconds
          console.log("decodedToken", decodedToken.exp);
          console.log("currentTime", currentTime);
          console.log("isTokenExpired", decodedToken.exp < currentTime);
          return decodedToken.exp < currentTime;
        } catch (error) {
          return true; // Error decoding token or invalid token, treat as expired
        }
      };
  
      if (isTokenExpired()) {
        // Handle token expiration (e.g., refresh the token or redirect to login page)
        toast.error("Authentication token has expired. Please log in again.");
        navigate("/login");
        return;
      }

      const sendurl = process.env.REACT_APP_BASE_URL + "quotation/filtered-quotations/Declined/";
    
      try {
        const response = await axios.get( `${sendurl}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const apiData = response.data;

        console.log(apiData);


        // Transform the API data into the desired format
        const transformedData = apiData.map((item, index) => {
          return {
            productId: item.quotation_id.id,
            partNo: item.quotation_id.part_id.partNumber,
            vehicleCompany: item.quotation_id.part_id.vehicleCompany,
            subCategory: item.quotation_id.part_id.subCategory,
            availability: item.quotation_id.part_id.availability,
            brand: item.quotation_id.part_id.brand,
            model: item.quotation_id.part_id.model,
            manufactureYear: parseInt(item.quotation_id.part_id.manufactureYear),
            partCategories: item.quotation_id.part_id.partCategories,
            partSubCategories: item.quotation_id.part_id.partSubCategories,
            partName: item.quotation_id.part_id.partName,
            partNumber: item.quotation_id.part_id.partNumber,
            description: item.quotation_id.part_id.description,
            coverPhoto: item.quotation_id.part_id.image ? item.quotation_id.part_id.image : null,
            orderDate: new Date(item.quotation_id.order_id.dateandtime).toLocaleDateString(),
            quantity : item.quotation_id.quantity,
            totalprice : item.quoted_price_total,
          };
        });

        console.log("TRANSFORNED DATA",transformedData);

        setData(transformedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    // Event listener to close the popup when clicking outside of it
    const handleOutsideClick = (event) => {
      if (
        showRowsPerPageOptions &&
        optionsRef.current &&
        !optionsRef.current.contains(event.target)
      ) {
        setShowRowsPerPageOptions(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    // Cleanup the event listener when the component is unmounted
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showRowsPerPageOptions]);

  // State for managing the current page
  const [currentPage, setCurrentPage] = useState(1);

  // Number of items to display per page
  const itemsPerPage = rowsPerPage;

  // Calculate the total number of pages
  const totalPages = Math.ceil(dataLength / itemsPerPage);

  // Function to handle the click on the left arrow
  const handleLeftArrowClick = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  // Function to handle the click on the right arrow
  const handleRightArrowClick = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  // Calculate the range of items to display on the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  return (
    <>
      <DeclinedTable
        startIndex={startIndex}
        endIndex={endIndex}
        searchQuery={searchQuery}
      />

      <div className="flex items-center justify-center text-gray-500 sm:justify-between">
        <div className="hidden sm:block">
          <p className="font-medium text-[15px]">
            1-
            <span>
              {rowsPerPage > dataLength ? dataLength : rowsPerPage}
            </span>{" "}
            of <span>{dataLength}</span>
          </p>
        </div>

        <div className="text-[15px] font-medium flex items-center gap-6">
          <div className="items-center gap-2 hidden sm:flex">
            <p>Rows per page:</p>

            <div
              className="relative flex items-center gap-1 cursor-pointer"
              onClick={() => setShowRowsPerPageOptions(!showRowsPerPageOptions)}
            >
              <p>{rowsPerPage}</p>
              <HiChevronDown className="text-[20px]" />

              {showRowsPerPageOptions && (
                <div
                  ref={optionsRef}
                  className="absolute right-6 bg-white border border-gray-300 mt-2 p-2 rounded-md"
                >
                  {rowsPerPageOptions.map((option) => (
                    <div
                      key={option}
                      className="cursor-pointer hover:bg-gray-200 p-1"
                      onClick={() => {
                        setRowsPerPage(option);
                        setShowRowsPerPageOptions(false);
                      }}
                    >
                      {option}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className="flex items-center gap-3">
            <button
              className="border-2 border-gray-300 text-gray-700 disabled:text-[#A1A9B8] rounded-xl p-1"
              onClick={handleLeftArrowClick}
              disabled={currentPage === 1}
            >
              <HiChevronLeft className="text-[24px]" />
            </button>

            <div>
              {currentPage}/{totalPages}
            </div>

            <button
              className="border-2 border-gray-300 text-gray-700 disabled:text-[#A1A9B8] rounded-xl p-1"
              onClick={handleRightArrowClick}
              disabled={currentPage === totalPages}
            >
              <HiChevronRight className="text-[24px]" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Declined;
