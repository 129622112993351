import { useAtom } from "jotai";
import React from "react";
import { orderDataForViewAtom } from "../../../SellerPage/OrderReviewManagement/OrderManagement/Pending/PendingTableRow";
import { useState,useEffect } from "react";

const ViewOrderFromOM = () => {
  const [orderDataForView] = useAtom(orderDataForViewAtom);
  const [returnreason,setreturnreason] = useState(false);
  const apiData = orderDataForView;
  console.log("apiData", apiData);
  useEffect(() => {
  if (apiData.ReturnReason && apiData.ReturnReason.length > 0) {
    setreturnreason(true);
  }
  }, [apiData]);

  return (
    <div className="mx-auto m-6 flex flex-col gap-4">
      <button
        className="bg-blue w-fit mx-6 px-4 py-1.5 rounded-md text-white font-medium"
        onClick={(event) => {
          event.preventDefault();
          window.history.back();
        }}
      >
        Back
      </button>

      <div className="bg-white mx-6 rounded-lg shadow-lg px-8 py-10">

      <div className="flex items-center justify-between mb-8">

          <div className="text-gray-700 font-bold">
            <div className="text-sm">
              Order Date:<span className="font-medium"> {orderDataForView.Order_date}</span>
            </div>
          </div>
        </div> 
        <div className="flex flex-row justify-between">
  <div className="pb-8 mb-4">
    <h2 className="text-2xl font-bold mb-4">Bill To:</h2>
    {orderDataForView.name && (
      <div className="text-gray-700 mb-2 uppercase">
        {orderDataForView.name}
      </div>
    )}
    {orderDataForView.phone && (
      <div className="text-gray-700 mb-2 uppercase">
        {orderDataForView.phone}
      </div>
    )}
    {orderDataForView.address && (
      <div className="text-gray-700 mb-2 uppercase">
        {orderDataForView.address}
      </div>
    )}
  </div>
  <div className="pb-8 mb-4">
    <h2 className="text-2xl font-bold mb-4">Bill From:</h2>
    {orderDataForView.SellerName && (
      <div className="text-gray-700 mb-2 uppercase">
        {orderDataForView.SellerName}
      </div>
    )}
    {orderDataForView.SellerPhone && (
      <div className="text-gray-700 mb-2 uppercase">
        {orderDataForView.SellerPhone}
      </div>
    )}
    {orderDataForView.SellerAddress && (
      <div className="text-gray-700 mb-2 uppercase">
        {orderDataForView.SellerAddress}
      </div>
    )}
  </div>
</div>
        <div className="flex flex-col">
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
              <div className="overflow-hidden">

                <table className="min-w-full border text-center text-sm font-light">
                  
                  <thead className="border-b font-bold text-lg">
                    <tr>
                      <th scope="col" className="border-r h-[34px] px-4 py-0">
                        SN
                      </th>
                      <th scope="col" className="border-r h-[34px] px-4 py-0">
                        Product Name
                      </th>
                      <th scope="col" className="border-r h-[34px] px-4 py-0">
                        Quantity
                      </th>
                      <th scope="col" className="border-r h-[34px] px-4 py-0">
                        Price
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {orderDataForView.products.map((product, index) => (
                      <tr className="border-b" key={index}>
                        <td className="whitespace-nowrap border-r h-[34px] px-4 py-0 font-medium">
                          {index + 1}
                        </td>
                        <td className="whitespace-nowrap border-r h-[34px] px-4 py-0">
                          {product.product_name}
                        </td>
                        <td className="whitespace-nowrap border-r h-[34px] px-4 py-0">
                          {product.quantity}
                        </td>
                        <td className="whitespace-nowrap border-r h-[34px] px-4 py-0">
                          {product.price}
                        </td>
                      </tr>
                      
                    ))}

                    <tr className="border-b">
                      <td
                        className="whitespace-nowrap border-r h-[34px] px-4 py-0 text-start font-bold"
                        colSpan={3}
                      >
                        Total Price:{" "}
                        <span className="">{orderDataForView.total_price}</span>
                      </td>
                    </tr>
                    <tr className="border-b">
                      <td
                        className="whitespace-nowrap border-r h-[34px] px-4 py-0 text-start font-bold"
                        colSpan={3}
                      >
                       
                      </td>
                      </tr>
                      {returnreason && (
                      <tr className="border-b">
                        <td
                          className="whitespace-nowrap border-r h-[34px] px-4 py-0 text-start font-bold"
                          colSpan={3}
                        >
                          Return Reason:{""}
                          <span className="">{orderDataForView.ReturnReason}</span>
                        </td>
                      </tr>
                        
                      )}

                      
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewOrderFromOM;
