import React, { useState, useEffect } from "react";
import "react-circular-progressbar/dist/styles.css";
import { useParams } from "react-router-dom";
import UserReview from "../common/UserReview";

const RatingsReviews = () => {
  const reviewsPerPage = 3;
  const { "*": searchInput } = useParams();
  const [currentPage, setCurrentPage] = useState(1);


  const [reviews, setReviews] = useState([]);

  
  useEffect(() => {
    const fetchData = async () => {
      const ratingproducturl =
        process.env.REACT_APP_BASE_URL + `users/productreview/${searchInput}`;
      try {
        const response = await fetch(`${ratingproducturl}`);
        const data = await response.json();
        console.log(data.data);
        const transformedData = data.data.map((item) => ({
          name: item.user.name,
          rating: item.rating,
          content: item.comment,
        }));
        setReviews(transformedData);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchData();
  }, []);



  const indexOfLastReview = currentPage * reviewsPerPage;
  const indexOfFirstReview = indexOfLastReview - reviewsPerPage;
  const currentReviews = reviews.slice(indexOfFirstReview, indexOfLastReview);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(reviews.length / reviewsPerPage);

  const renderPaginationItem = (pageNumber, label) => (
    <button
      key={pageNumber}
      onClick={() => paginate(pageNumber)}
      className={`mx-2 w-[45px] py-1.5 rounded-md border-2 font-medium text-[15px] flex items-center justify-center ${
        currentPage === pageNumber
          ? "bg-gray-300 text-blue border-blue"
          : "bg-gray-300 text-gray-900 border-gray-500"
      }`}
    >
      {label ? label : pageNumber}
    </button>
  );

  return (
    <div className="bg-white">
      <div className="w-full px-4 md:px-10 py-3 md:py-5 flex flex-col gap-4">
        <h1 className="text-blue text-lg font-bold">Rating & Reviews</h1>

      </div>

      <div className="mt-4 md:mt-6">


        {currentReviews.map((review, index) => (
          <UserReview
            key={index}
            name={review.name}
            rating={review.rating}
            content={review.content}
          />
        ))}

        <div className="px-4 md:px-10 py-3 md:py-6 flex items-center justify-center">
          <button
            onClick={() => paginate(currentPage - 1)}
            className={`mx-2 w-[45px] py-1.5 rounded-md font-bold bg-white text-black border-2 border-gray-500 ${
              currentPage === 1
                ? "cursor-not-allowed opacity-50"
                : "hover:bg-gray-200"
            }`}
            disabled={currentPage === 1}
          >
            {"<"}
          </button>

          {Array.from({ length: totalPages }, (_, index) => {
            const showItem =
              index < 3 ||
              index > totalPages - 3 ||
              Math.abs(index - currentPage + 1) < 3;

            if (!showItem) return null;

            const pageNumber = index + 1;
            return renderPaginationItem(pageNumber);
          })}

          <button
            onClick={() => paginate(currentPage + 1)}
            className={`mx-2 w-[45px] py-1.5 rounded-md font-bold bg-white text-black border-2 border-gray-500 ${
              currentPage === totalPages
                ? "cursor-not-allowed opacity-50"
                : "hover:bg-gray-200"
            }`}
            disabled={currentPage === totalPages}
          >
            {">"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default RatingsReviews;
