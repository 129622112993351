import { useAtom } from "jotai";
import React, { useEffect } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import AddAd from "../../components/Admin/Ad/AddAd";
import AddCredit from "../../components/Admin/AddCredit/AddCredit";
import { AdminDashboard } from "../../components/Admin/AdminDashboard/AdminDashboard";
import AgentManagement from "../../components/Admin/AgentManagement/AgentManagement";
import ChatList from "../../components/Admin/Chat/ChatList";
import IndividualChat from "../../components/Admin/Chat/IndiviudalChat";
import ManageDictionary from "../../components/Admin/Dictionary/ManageDictionary";
import InventoryManagement from "../../components/Admin/Inventorymanagement/inventoryManagement";
import OrderManagement from "../../components/Admin/OrderManagement/OrderManagement/OrderManagement";
import ViewOrderFromOM from "../../components/Admin/OrderManagement/OrderManagement/ViewOrderFromOM";
import AddPart, {
  isAddPartSuccessAtom,
} from "../../components/Admin/ProductManagement/AddPart/AddPart";
import AddPartSummary, {
  isAddPartFormSubmittedAtom,
} from "../../components/Admin/ProductManagement/AddPart/AddPartSummary";
import SuccessPage from "../../components/Admin/ProductManagement/AddPart/SuccessPage";
import ImportPart from "../../components/Admin/ProductManagement/ImportPart/ImportPart";
import ManageProducts, {
  isManageActiveAtom,
  isProductViewedAtom,
  viewedProductAtom,
} from "../../components/Admin/ProductManagement/ManageProduct/ManageProducts";
import UploadPhotos from "../../components/Admin/ProductManagement/UploadPhotos/UploadPhotos";
import Quotation from "../../components/Admin/Quotation/Quotation";
import StaffManagement from "../../components/Admin/StaffManagement/StaffManagement";
import ManageUnidentifiedProducts from "../../components/Admin/UnidentifiedProducts/ManageUnidentifiedProducts";
import ManageUsers from "../../components/Admin/UserManagement/ManageUsers";
import ViewUser from "../../components/Admin/UserManagement/ViewUser";
import ManageVendors from "../../components/Admin/VendorsManagement/ManageVendors";
import ViewVendor from "../../components/Admin/VendorsManagement/ViewVendor";
import AdminPageHeader from "../../components/Admin/common/AdminPageHeader";
import Sidebar, {
  activeNavItemAtom,
  activeNavNameAtom,
  activeSubNavItemAtom,
  activeSubNavNameAtom,
} from "../../components/Admin/common/Sidebar";
import ViewProduct from "../../components/Admin/common/ViewProduct";
import SellerSignupPage from "../../pages/SellerPage/SellerSignupPage";
import ManageAskForRatePart from "../../components/Admin/AskForRate/ManageAskForRatePart";

const AdminMainPage = () => {
  // const [activeNavName] = useAtom(activeNavNameAtom);
  // const [activeSubNavName] = useAtom(activeSubNavNameAtom);
  const [isAddPartSuccess] = useAtom(isAddPartSuccessAtom);
  const [isManageActive] = useAtom(isManageActiveAtom);
  const [isProductViewed] = useAtom(isProductViewedAtom);
  const [viewedProduct] = useAtom(viewedProductAtom);
  // const [isAdminPartEditActive] = useAtom(isAdminPartEditActiveAtom);
  const [isAddPartFormSubmitted] = useAtom(isAddPartFormSubmittedAtom);
  const [, setActiveNavName] = useAtom(activeNavNameAtom);
  const [, setActiveNavItem] = useAtom(activeNavItemAtom);
  const [, setActiveSubNavName] = useAtom(activeSubNavNameAtom);
  const [, setActiveSubNavItem] = useAtom(activeSubNavItemAtom);

  useEffect(() => {
    // check url and set the state
    const url = window.location.href;
    console.log(url.includes("product-management"));

    if (url.includes("product-management")) {
      setActiveNavItem(1);
      setActiveNavName("Product Management");
      setActiveSubNavItem(null);
      setActiveSubNavName(null);
    } else if (url.includes("vendors-management")) {
      setActiveNavItem(2);
      setActiveNavName("Vendors Management");
      setActiveSubNavItem(null);
      setActiveSubNavName(null);
    } else if (url.includes("quotation")) {
      setActiveNavName("Quotation");
      setActiveSubNavItem(null);
      setActiveSubNavName(null);
    } else if (url.includes("staff-management")) {
      setActiveNavItem(8);
      setActiveNavName("All Staff");
      setActiveSubNavItem(null);
      setActiveSubNavName(null);
    } else if (url.includes("agent-management")) {
      setActiveNavItem(6);
      setActiveNavName("Agent Management");
      setActiveSubNavItem(null);
      setActiveSubNavName(null);
    } else if (url.includes("order-management")) {
      setActiveNavItem(2);
      setActiveNavName("Order Management");
      setActiveSubNavItem(null);
      setActiveSubNavName(null);
    }
  }, []);

  return (
    <div className="overflow-x-hidden bg-gray-200 w-full min-h-screen flex">
      <Sidebar />
      <div className="relative w-full overflow-x-hidden">
        <AdminPageHeader />

        <Routes>
          <Route path="/" element={<Outlet />}>
            <Route index element={<AdminDashboard />} />
          </Route>

          <Route path="product-management" element={<Outlet />}>
            <Route
              path="add"
              element={
                isAddPartFormSubmitted && isAddPartSuccess ? (
                  <SuccessPage />
                ) : isAddPartSuccess ? (
                  <AddPartSummary />
                ) : (
                  <AddPart />
                )
              }
            />
            <Route
              path="manage"
              element={
                isManageActive ? (
                  isProductViewed ? (
                    <ViewProduct formData={viewedProduct} />
                  ) : (
                    <ManageProducts />
                  )
                ) : (
                  <AddPart />
                )
              }
            />
            <Route path="import" element={<ImportPart />} />
            <Route path="upload-photos" element={<UploadPhotos />} />
            <Route path="edit" element={<AddPart />} />
          </Route>

          <Route path="vendors-management" element={<Outlet />}>
            <Route index element={<ManageVendors />} />
            <Route path="edit" element={<SellerSignupPage />} />
            <Route path="view" element={<ViewVendor />} />
          </Route>

          <Route path="quotation" element={<Outlet />}>
            <Route index element={<Quotation />} />
          </Route>

          <Route path="order-management" element={<Outlet />}>
            <Route index element={<OrderManagement />} />
            <Route path="view/:Order_id" element={<ViewOrderFromOM />} />
          </Route>

          <Route path="unidentified-products" element={<Outlet />}>
            <Route index element={<ManageUnidentifiedProducts />} />
          </Route>

          <Route path="staff-management" element={<Outlet />}>
            <Route index element={<StaffManagement />} />
          </Route>

          <Route path="user-management" element={<Outlet />}>
            <Route index element={<ManageUsers />} />
            <Route path=":id" element={<ViewUser />} />
          </Route>

          <Route path="dictionary" element={<Outlet />}>
            <Route index element={<ManageDictionary />} />
          </Route>

          <Route path="agent-management" element={<Outlet />}>
            <Route index element={<AgentManagement />} />
          </Route>

          <Route path="chat" element={<Outlet />}>
            <Route index element={<ChatList />} />
          </Route>

          <Route path="chat/:vendorId" element={<IndividualChat />} />

          <Route path="order-management" element={<Outlet />}>
            <Route index element={<Quotation />} />
          </Route>

          <Route path="inventory-management" element={<Outlet />}>
            <Route index element={<InventoryManagement />} />
          </Route>

          <Route path="add-credit" element={<Outlet />}>
            <Route index element={<AddCredit />} />
          </Route>

          <Route path="ad" element={<Outlet />}>
            <Route path="add" element={<AddAd />} />
          </Route>

          <Route path = "ask-for-rate" element = {<Outlet />}>
            <Route index element = {<ManageAskForRatePart />} />
          </Route>

          {/* <Route path="unfound-products" element={<Outlet />}>
            <Route index element={<ManageUnfoundProducts />} />
          </Route> */}
        </Routes>
      </div>
    </div>
  );
};

export default AdminMainPage;
