import { atom, useAtom } from "jotai";
import React, { useState } from "react";
import "../../../../../../styles/table.css"
import CancelledTableRow from "./CancelledTableRow";

export const rowsPerPageAtom = atom(10);
export const isProductEditModeAtom = atom(false);
export const productIdAtom = atom(null);
export const dataAtom = atom([]);

const CancelledTable = ({ startIndex, endIndex }) => {
  const [data] = useAtom(dataAtom);

  return (
    <div className="relative bg-white border-2 border-gray-400 overflow-x-auto whitespace-nowrap table-container">
      <table className="w-full text-white text-start border-spacing-4">
        {/* <div className="w-full  border-gray-400 bg-gray-300 text-gray-900 text-[14px] font-bold px-5 py-3 uppercase sm:hidden">
          <h1>All Product Detail</h1>
        </div> */}

        <thead className="text-gray-900 border-y-2 border-gray-400 uppercase">
        <tr>
            <th className="text-center">Order No</th>
            <th className="text-center">Order Date</th>
            <th className="text-center">Payment Method</th>
            <th className="text-center">Total Price</th>
            <th className="text-center">Total Quantity</th>
            <th className="text-center">Status</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>

        <tbody className="text-gray-700">
          {data.slice(startIndex, endIndex).map((item, index) => (
            <CancelledTableRow
              key={index}
              formData={item}
              onEditClick={() => {
                console.log("Edit button clicked");
              }}
              onDeleteClick={() => {
                console.log("Delete button clicked");

                // Delete product from the database with productId
              }}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CancelledTable;
