import { atom, useAtom } from "jotai";
import React, { useEffect } from "react";
import "../../../../../styles/table.css";
import PreviousRequestTableRow from "./PreviousRequestsTableRow";

export const rowsPerPageAtom = atom(10);
export const isProductEditModeAtom = atom(false);
export const productIdAtom = atom(null);
export const dataAtom = atom([]);

const PreviousRequestTable = ({ startIndex, endIndex, searchQuery }) => {
  const [data, setData] = useAtom(dataAtom);
  const [, setIsEditButtonClicked] = useAtom(isProductEditModeAtom);
  const [, setProductId] = useAtom(productIdAtom);



  return (
    <div className="relative bg-white border-2 border-gray-400 overflow-x-auto whitespace-nowrap table-container">
      <table className="w-full text-white text-start border-spacing-4">
        <div className="w-full  border-gray-400 bg-gray-300 text-gray-900 text-[14px] font-bold px-5 py-3 uppercase sm:hidden">
          <h1>All Product Detail</h1>
        </div>

        <thead className="text-gray-900 border-y-2 border-gray-400 uppercase hidden sm:table-header-group">
          <tr>
            <th className="text-center">Order No.</th>
            <th className="text-center">Date/Time</th>
            <th className="text-center">No. of Products</th>
            <th className="text-center">Status</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>

        {data.length !== 0 && (
          <tbody className="text-gray-700">
            {data
              .filter((item) =>
                Object.values(item).some((value) =>
                  value
                    .toString()
                    .toLowerCase()
                    .includes(searchQuery.toLowerCase())
                )
              )
              .slice(startIndex, endIndex)
              .map((item, index) => (
                <PreviousRequestTableRow
                  key={index}
                  formData={item}
                  onEditClick={() => {
                    setIsEditButtonClicked(true);
                    setProductId(index);
                  }}
                  onDeleteClick={() => {
                    // Delete product from the database with productId
                  }}
                />
              ))}
          </tbody>
        )}
      </table>

      {data.length === 0 && (
        <div className="w-full">
          <div className="border-b-2 border-gray-300 text-center h-[65px] flex items-center justify-center text-gray-700 relative hover:bg-gray-100">
            <p className="text-gray-700 font-semibold">No data available</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default PreviousRequestTable;
