// import axios from "axios";
// import { useAtom } from "jotai";
// import React, { useEffect, useState, useRef } from "react";
// import { HiChevronDown, HiChevronLeft, HiChevronRight } from "react-icons/hi";
// import AllProductsTable, { dataAtom, rowsPerPageAtom } from "./AllProductsTable";

// const AllProducts = ({
//   searchQuery,
//   isAnyRowSelected,
//   setIsAnyRowSelected,
//   selectedRows,
//   setSelectedRows,
// }) => {
//   const [rowsPerPage, setRowsPerPage] = useAtom(rowsPerPageAtom);
//   const [data, setData] = useAtom(dataAtom);
//   const [totalPages, setTotalPages] = useState(1);
//   const [currentPage, setCurrentPage] = useState(1);

//   const url = process.env.REACT_APP_BASE_URL + "admin/approved-partinfo/";

//   const fetchData = async () => {
//     try {
//       const response = await axios.get(url);
//       const apiData = response.data;

//       // Transform the API data into the desired format
//       const transformedData = apiData.map((item) => ({
//         productId: item.id,
//         vehicleCompany: item.vehicleCompany,
//         subCategory: item.subCategory,
//         availability: item.availability,
//         brand: item.brand,
//         model: item.model,
//         manufactureYear: parseInt(item.manufactureYear),
//         partCategories: item.partCategories,
//         partSubCategories: item.partSubCategories,
//         partName: item.partName,
//         partNumber: Array.isArray(item.partNumber)
//           ? item.partNumber.map((part) => part.partNumber).join(", ")
//           : item.partNumber,
//         description: item.description,
//         status: item.status,
//         coverPhoto: item.image || null,
//         otherAttributes: Array.isArray(item.attributes)
//           ? item.attributes.map((attribute) => ({
//             attributeName: attribute.attributeName,
//             attributeValue: attribute.attributeValue,
//           }))
//           : [],
//       }));

//       setData(transformedData);
//       setTotalPages(Math.ceil(transformedData.length / rowsPerPage));
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };

//   useEffect(() => {
//     fetchData();
//   }, []);

//   const optionsRef = useRef(null);
//   const [showRowsPerPageOptions, setShowRowsPerPageOptions] = useState(false);

//   useEffect(() => {
//     const handleOutsideClick = (event) => {
//       if (
//         showRowsPerPageOptions &&
//         optionsRef.current &&
//         !optionsRef.current.contains(event.target)
//       ) {
//         setShowRowsPerPageOptions(false);
//       }
//     };

//     document.addEventListener("mousedown", handleOutsideClick);

//     return () => {
//       document.removeEventListener("mousedown", handleOutsideClick);
//     };
//   }, [showRowsPerPageOptions]);

//   const handleLeftArrowClick = () => {
//     setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
//   };

//   const handleRightArrowClick = () => {
//     setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
//   };

//   const startIndex = (currentPage - 1) * rowsPerPage;
//   const endIndex = startIndex + rowsPerPage;

//   return (
//     <>
//       <AllProductsTable
//         startIndex={startIndex}
//         endIndex={endIndex}
//         searchQuery={searchQuery}
//         fetchData={fetchData}
//         isAnyRowSelected={isAnyRowSelected}
//         setIsAnyRowSelected={setIsAnyRowSelected}
//         selectedRows={selectedRows}
//         setSelectedRows={setSelectedRows}
//       />

//       <div className="flex items-center justify-center text-gray-500 sm:justify-between">
//         <div className="hidden sm:block">
//           <p className="font-medium text-[15px]">
//             {startIndex + 1}-
//             <span>{endIndex > data.length ? data.length : endIndex}</span> of{" "}
//             <span>{data.length}</span>
//           </p>
//         </div>

//         <div className="text-[15px] font-medium flex items-center gap-6">
//           <div className="items-center gap-2 hidden sm:flex">
//             <p>Rows per page:</p>

//             <div
//               className="relative flex items-center gap-1 cursor-pointer"
//               onClick={() => setShowRowsPerPageOptions(!showRowsPerPageOptions)}
//             >
//               <p>{rowsPerPage}</p>
//               <HiChevronDown className="text-[20px]" />

//               {showRowsPerPageOptions && (
//                 <div
//                   ref={optionsRef}
//                   className="absolute right-6 bg-white border border-gray-300 mt-2 p-2 rounded-md"
//                 >
//                   {[10, 20, 30, 50].map((option) => (
//                     <div
//                       key={option}
//                       className="cursor-pointer hover:bg-gray-200 p-1"
//                       onClick={() => {
//                         setRowsPerPage(option);
//                         setTotalPages(Math.ceil(data.length / option));
//                         setShowRowsPerPageOptions(false);
//                       }}
//                     >
//                       {option}
//                     </div>
//                   ))}
//                 </div>
//               )}
//             </div>
//           </div>

//           <div className="flex items-center gap-3">
//             <button
//               className="border-2 border-gray-300 text-gray-700 disabled:text-[#A1A9B8] rounded-xl p-1"
//               onClick={handleLeftArrowClick}
//               disabled={currentPage === 1}
//             >
//               <HiChevronLeft className="text-[24px]" />
//             </button>

//             <div>
//               {currentPage}/{totalPages}
//             </div>

//             <button
//               className="border-2 border-gray-300 text-gray-700 disabled:text-[#A1A9B8] rounded-xl p-1"
//               onClick={handleRightArrowClick}
//               disabled={currentPage === totalPages}
//             >
//               <HiChevronRight className="text-[24px]" />
//             </button>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default AllProducts;






import axios from "axios";
import { useAtom } from "jotai";
import React, { useEffect, useRef, useState } from "react";
import { HiChevronDown, HiChevronLeft, HiChevronRight } from "react-icons/hi";
import AllProductsTable, {
  dataAtom,
  rowsPerPageAtom,
} from "./AllProductsTable";

const AllProducts = ({
  searchQuery,
  isAnyRowSelected,
  setIsAnyRowSelected,
  selectedRows,
  setSelectedRows,
}) => {
  const [rowsPerPage, setRowsPerPage] = useAtom(rowsPerPageAtom);
  const [data, setData] = useAtom(dataAtom);
  const dataLength = data.length;

  const optionsRef = useRef(null);

  // State for managing the visibility of the rows per page options
  const [showRowsPerPageOptions, setShowRowsPerPageOptions] = useState(false);

  // Array of options for rows per page
  const rowsPerPageOptions = [10, 12, 14, 16, 18, 20];

  const url = process.env.REACT_APP_BASE_URL + "admin/approved-partinfo/";

  const fetchData = async () => {
    try {
      const response = await axios.get(`${url}`);
      const apiData = response.data;

      console.log(apiData);

      // Transform the API data into the desired format
      const transformedData = apiData.map((item, index) => {
        return {
          productId: item.id,

          vehicleCompany: item.vehicleCompany,
          subCategory: item.subCategory,
          availability: item.availability,
          brand: item.brand,
          model: item.model,
          manufactureYear: parseInt(item.manufactureYear),
          partCategories: item.partCategories,
          partSubCategories: item.partSubCategories,
          partName: item.partName,
          partNumber: Array.isArray(item.partNumber)
            ? item.partNumber.map((part) => part.partNumber).join(", ")
            : item.partNumber,
          description: item.description,
          status: item.status,
          coverPhoto: item.image ? item.image : null,
          otherAttributes: Array.isArray(item.attributes)
            ? item.attributes.map((attribute) => ({
                attributeName: attribute.attributeName,
                attributeValue: attribute.attributeValue,
              }))
            : [],
        };
      });

      setData(transformedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    // Event listener to close the popup when clicking outside of it
    const handleOutsideClick = (event) => {
      if (
        showRowsPerPageOptions &&
        optionsRef.current &&
        !optionsRef.current.contains(event.target)
      ) {
        setShowRowsPerPageOptions(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    // Cleanup the event listener when the component is unmounted
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showRowsPerPageOptions]);

  // State for managing the current page
  const [currentPage, setCurrentPage] = useState(1);

  // Number of items to display per page
  const itemsPerPage = rowsPerPage;

  // Calculate the total number of pages
  const totalPages = Math.ceil(dataLength / itemsPerPage);

  // Function to handle the click on the left arrow
  const handleLeftArrowClick = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  // Function to handle the click on the right arrow
  const handleRightArrowClick = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  // Calculate the range of items to display on the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  return (
    <>
      <AllProductsTable
        startIndex={startIndex}
        endIndex={endIndex}
        searchQuery={searchQuery}
        fetchData={fetchData}
        isAnyRowSelected={isAnyRowSelected}
        setIsAnyRowSelected={setIsAnyRowSelected}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
      />

      <div className="flex items-center justify-center text-gray-500 sm:justify-between">
        <div className="hidden sm:block">
          <p className="font-medium text-[15px]">
            1-
            <span>
              {rowsPerPage > dataLength ? dataLength : rowsPerPage}
            </span>{" "}
            of <span>{dataLength}</span>
          </p>
        </div>

        <div className="text-[15px] font-medium flex items-center gap-6">
          <div className="items-center gap-2 hidden sm:flex">
            <p>Rows per page:</p>

            <div
              className="relative flex items-center gap-1 cursor-pointer"
              onClick={() => setShowRowsPerPageOptions(!showRowsPerPageOptions)}
            >
              <p>{rowsPerPage}</p>
              <HiChevronDown className="text-[20px]" />

              {showRowsPerPageOptions && (
                <div
                  ref={optionsRef}
                  className="absolute right-6 bg-white border border-gray-300 mt-2 p-2 rounded-md"
                >
                  {rowsPerPageOptions.map((option) => (
                    <div
                      key={option}
                      className="cursor-pointer hover:bg-gray-200 p-1"
                      onClick={() => {
                        setRowsPerPage(option);
                        setShowRowsPerPageOptions(false);
                      }}
                    >
                      {option}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className="flex items-center gap-3">
            <button
              className="border-2 border-gray-300 text-gray-700 disabled:text-[#A1A9B8] rounded-xl p-1"
              onClick={handleLeftArrowClick}
              disabled={currentPage === 1}
            >
              <HiChevronLeft className="text-[24px]" />
            </button>

            <div>
              {currentPage}/{totalPages}
            </div>

            <button
              className="border-2 border-gray-300 text-gray-700 disabled:text-[#A1A9B8] rounded-xl p-1"
              onClick={handleRightArrowClick}
              disabled={currentPage === totalPages}
            >
              <HiChevronRight className="text-[24px]" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllProducts;
