import axios from "axios";
import { atom, useAtom } from "jotai";
import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { HiMinus, HiOutlineSearch } from "react-icons/hi";
import { IoClose } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify"; // Import ToastContainer and toast from react-toastify
import "react-toastify/dist/ReactToastify.css"; // Import CSS for react-toastify
import NotFoundImage from "../../../assets/images/not_found.jpg";
import "../../../styles/table.css";
import InputWithLabel from "../../common/InputWithLabel";
import {
  isSellerProductViewedAtom,
  viewedSellerProductAtom,
} from "../ProductManagement/ManageProduct/ManageProduct";

export const searchResultsAtom = atom([]);
export const selectedProductsFromSearchAtom = atom([]);

const SearchSection = () => {
  const navigate = useNavigate();
  const [productsData, setProductsData] = useState(null);
  const [, setIsProductViewed] = useAtom(isSellerProductViewedAtom);
  const [, setViewedProduct] = useAtom(viewedSellerProductAtom);

  const isAgent = localStorage.getItem("agenttoken") ? true : false;

  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useAtom(searchResultsAtom);
  const [error, setError] = useState("");
  const [selectedProductsFromSearch, setSelectedProductsFromSearch] = useAtom(
    selectedProductsFromSearchAtom
  );
  const [popupOpen, setPopupOpen] = useState(false);
  const [popupFormOpen, setPopupFormOpen] = useState(false);

  const [formData, setFormData] = useState({
    vehicleCompany: "",
    subCategory: "",
    availability: "In Stock",
    brand: "",
    model: "",
    manufactureYear: "",
    partCategories: "",
    partSubCategories: "",
    partNumber: "",
    partName: "",
    description: "",
    image: null,
    otherAttributes: Array.from({ length: 5 }, () => ({ attributeValue: "" })),
    quantity: "",
  });

  const [errors, setErrors] = useState({
    partNumber: "",
    partName: "",
    partCategories: "",
    vehicleCompany: "",
    brand: "",
    model: "",
    quantity: "",
  });

  const handleInputChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const clearError = (name) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const validateForm = () => {
    const validationRules = {
      partNumber: "Please enter the part number.",
      partName: "Please enter the part name.",
      partCategories: "Please enter a description.",
      vehicleCompany: "Please enter the vehicle company.",
      brand: "Please enter the brand.",
      model: "Please enter the model.",
      quantity: "Please enter the quantity.",
    };

    let isValid = true;

    Object.keys(validationRules).forEach((fieldName) => {
      const errorMessage = validationRules[fieldName];
      const fieldValue = formData[fieldName].trim();

      if (!fieldValue) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [fieldName]: errorMessage,
        }));
        isValid = false;
      } else if (fieldName === "quantity" && isNaN(fieldValue)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          quantity: "Quantity must be a number.",
        }));
        isValid = false;
      }
    });

    return isValid;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      const existingProductIndex = selectedProductsFromSearch.findIndex(
        (product) => product.productId === formData.productId
      );

      const selectedProduct = {
        ...formData,
      };

      if (existingProductIndex !== -1) {
        // Product already exists, update quantity and other properties
        const updatedProducts = [...selectedProductsFromSearch];
        updatedProducts[existingProductIndex] = selectedProduct;
        setSelectedProductsFromSearch(updatedProducts);
      } else {
        // Product does not exist, add as new
        setSelectedProductsFromSearch((prev) => [...prev, selectedProduct]);
      }

      // Reset form data and close the form
      setFormData({
        partNumber: "",
        partCategories: "",
        quantity: "",
        productId: "",
      });
      setPopupOpen(false);
      setPopupFormOpen(false);
    }
  };

  useEffect(() => {
    // setProductsData(staticData);

    const fetchData = async () => {
      const token =
        localStorage.getItem("token") || localStorage.getItem("agenttoken");

      if (!token) {
        // Handle the case where the token is not present (e.g., redirect to login page)
        toast.error("Authentication credentials not provided. Please log in.");
        return;
      }

      // Check if the authentication token has expired (example for JWT, may vary based on implementation)
      const isTokenExpired = () => {
        try {
          const decodedToken = jwtDecode(token);
          const currentTime = Date.now() / 1000; // Convert to seconds
          console.log("decodedToken", decodedToken.exp);
          console.log("currentTime", currentTime);
          console.log("isTokenExpired", decodedToken.exp < currentTime);
          return decodedToken.exp < currentTime;
        } catch (error) {
          return true; // Error decoding token or invalid token, treat as expired
        }
      };

      if (isTokenExpired()) {
        // Handle token expiration (e.g., refresh the token or redirect to login page)
        toast.error("Authentication token has expired. Please log in again.");
        navigate("/login");
        return;
      }
      try {
        const url = isAgent
          ? process.env.REACT_APP_BASE_URL +
            "sellerdashboard/agentsearchproduct/"
          : process.env.REACT_APP_BASE_URL + "sellerdashboard/searchproduct/";

        const response = await axios.get(`${url}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }); // Replace with your actual API endpoint
        console.log("response", response);
        const apiData = response.data;

        console.log("API DATA", apiData);

        // Transform the API data into the desired format
        const transformedData = apiData.map((item, index) => {
          return {
            productId: item.id,
            partId: item.part_id.id,
            vehicleCompany: item.part_id.vehicleCompany,
            subCategory: item.part_id.subCategory,
            availability: item.part_id.availability,
            brand: item.part_id.brand,
            model: item.part_id.model,
            manufactureYear: parseInt(item.part_id.manufactureYear),
            partCategories: item.part_id.partCategories,
            partSubCategories: item.part_id.partSubCategories,
            partName: item.part_id.partName,
            partNumber: Array.isArray(item.partnumbers)
            ? item.partnumbers.map((part) => part.partNumber).join(", ")
            : item.partNumber,
            description: item.part_id.description,
            coverPhoto: item.part_id.image ? item.part_id.image : null,
            stockQuantity: item.stockQuantity ? item.stockQuantity : null,
            sellingPrice: item.sellingPrice ? item.sellingPrice : null,
            units: item.units ? item.units : null,
            otherAttributes: item.attributes.map((attr) => ({
              attributeName: attr.attributeName,
              attributeValue: attr.attributeValue,
            })),
            otherPhotos:
              item.images.length > 0
                ? item.images.map((images) => images.image)
                : [null, null],
            tags:
              item.tags.length > 0
                ? item.tags.map((tag) => tag.tagname)
                : [null, null],
          };
        });

        setProductsData(transformedData);
      } catch (error) {
        console.log("erorrrr");
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleSearch = () => {
    if (!searchQuery.trim()) {
      setError("Please enter a search query.");
      setSearchResults([]);
      return;
    }

    const filteredResults = productsData
      ? productsData.filter((product) =>
          Object.values(product).some((value) =>
            String(value).toLowerCase().includes(searchQuery.toLowerCase())
          )
        )
      : [];

    if (filteredResults.length > 0) {
      setSearchResults(filteredResults);
      setError("");
    } else {
      setSearchResults([]);
      setError("No matching products found.");
      setPopupOpen(true);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  console.log("searchResults", searchResults);

  return (
    <div className="w-full flex flex-col gap-8">
      <div className="w-full sm:self-end flex flex-col sm:flex-row sm:items-center gap-3 sm:gap-6 sm:w-fit">
        <div className="px-4 py-2 flex items-center gap-3 bg-white border border-gray-300 rounded-md flex-1 sm:flex-none">
          <HiOutlineSearch className="text-gray-500 text-[20px]" />

          <input
            type="text"
            placeholder="Search Product by Product Id"
            className="flex-1 bg-white outline-none font-medium text-[14px] placeholder:text-[13px] sm:min-w-[300px] placeholder:text-gray-400"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyPress={handleKeyPress}
          />
        </div>

        <button
          type="button"
          className="bg-blue px-8 py-1.5 rounded-md text-[14px] text-white font-semibold"
          onClick={handleSearch}
        >
          Find
        </button>
      </div>

      <div className="relative w-full bg-white border-2 max-h-[195px] overflow-y-auto border-gray-400 overflow-x-auto whitespace-nowrap table-container">
        <table className="relative w-full  text-white text-center border-spacing-4">
          <div className="w-full  border-gray-400 bg-gray-300 text-gray-900 text-[14px] font-bold px-5 py-3 uppercase sm:hidden">
            <h1>Product Detail</h1>
          </div>

          <thead className="text-gray-900 border-y-2 border-gray-400 uppercase hidden sm:table-header-group">
            <tr>
              <th className="text-center">Part Number</th>
              <th className="text-center">Part Name</th>
              <th className="text-center">Part Category</th>
              <th className="text-center">Vehicle Company</th>
              <th className="text-center">Brand</th>
              <th className="text-center">Model</th>
              <th className="text-center">Action</th>
            </tr>
          </thead>

          {searchResults.length > 0 ? (
            <tbody className="text-gray-700">
              {searchResults.map((foundProduct, index) => (
                <>
                  <div className="border-b-2 border-gray-400 px-5 py-3 sm:hidden">
                    <div className="flex items-center gap-4">
                      <div
                        className={`w-6 h-6 flex justify-center items-center bg-blue text-white rounded-[4px]`}
                      >
                        <button
                          onClick={(event) => {
                            event.preventDefault();
                          }}
                        >
                          <HiMinus className="w-5 h-5" />
                        </button>
                      </div>

                      <div>
                        <h1 className="text-gray-900 font-bold text-[15px] uppercase">
                          Vehicle Company
                        </h1>
                        <p className="text-gray-800 font-medium text-[15px]">
                          {foundProduct.vehicleCompany}
                        </p>
                      </div>
                    </div>

                    <div className="flex flex-col gap-2 mt-4 ml-[2.4rem]">
                      <div className="flex flex-row gap-2 items-center">
                        <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                          Part Number:
                        </h2>
                        <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                          {foundProduct.partNumber}
                        </p>
                      </div>

                      <div className="flex flex-row gap-2 items-center">
                        <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                          Part Name:
                        </h2>
                        <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                          {foundProduct.partName}
                        </p>
                      </div>

                      <div className="flex flex-row gap-2 items-center">
                        <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                          Part Category:
                        </h2>
                        <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                          {foundProduct.partCategories}
                        </p>
                      </div>

                      <div className="flex flex-row gap-2 items-center">
                        <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                          Brand:
                        </h2>
                        <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                          {foundProduct.brand}
                        </p>
                      </div>

                      <div className="flex flex-row gap-2 items-center">
                        <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                          Model:
                        </h2>
                        <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                          {foundProduct.model}
                        </p>
                      </div>

                      <button
                        type="submit"
                        className="w-fit my-2 bg-blue px-6 py-1.5 rounded-md text-[14px] text-white font-semibold"
                      >
                        Select
                      </button>
                    </div>
                  </div>

                  <tr className="border-b-2 border-gray-300 text-gray-700 relative hidden sm:table-row hover:bg-gray-100">
                    <td className="text-center">{foundProduct.partNumber}</td>

                    <td className="text-center">{foundProduct.partName}</td>

                    <td className="text-center">
                      {" "}
                      {foundProduct.partCategories}
                    </td>

                    <td className="text-center">
                      {foundProduct.vehicleCompany}
                    </td>

                    <td className="text-center">{foundProduct.brand}</td>

                    <td className="text-center">{foundProduct.model}</td>

                    <td className="flex items-center gap-2">
                      <button
                        className="px-1.5 border-2 border-gray-300 font-medium rounded-md h-[35px]"
                        onClick={(e) => {
                          e.preventDefault();
                          setIsProductViewed(true);
                          setViewedProduct(foundProduct);
                        }}
                      >
                        <AiOutlineEye className="w-[22px] h-[22px] text-gray-700" />
                      </button>

                      <button
                        type="submit"
                        className="bg-blue px-4 py-1.5 rounded-md text-[14px] text-white font-semibold"
                        onClick={() => {
                          const existingProductIndex =
                            selectedProductsFromSearch.findIndex(
                              (product) =>
                                product.productId === foundProduct.productId
                            );

                          if (existingProductIndex !== -1) {
                            // Product already exists, increase quantity
                            const updatedProducts = [
                              ...selectedProductsFromSearch,
                            ];
                            updatedProducts[existingProductIndex].quantity += 1;
                            setSelectedProductsFromSearch(updatedProducts);
                          } else {
                            // Product does not exist, add as new with quantity 1
                            setSelectedProductsFromSearch((prev) => [
                              ...prev,
                              { ...foundProduct, quantity: 1 },
                            ]);
                          }
                        }}
                      >
                        Select
                      </button>
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          ) : (
            <tbody>
              <tr className="border-b-2 border-gray-300 h-[65px] text-gray-700 relative hidden sm:table-row hover:bg-gray-100">
                <td colSpan="7" className="text-center">
                  <p className="text-gray-700 font-semibold">
                    Your search results will appear here.
                  </p>
                </td>
              </tr>
            </tbody>
          )}
        </table>

        {searchResults.length === 0 && (
          <div className="sm:hidden">
            <div className="w-full">
              <div className="border-b-2 border-gray-300 text-center h-[65px] flex items-center justify-center text-gray-700 relative hover:bg-gray-100">
                <p className="text-gray-700 font-semibold">
                  Your search results will appear here.
                </p>
              </div>
            </div>
          </div>
        )}
      </div>

      {popupOpen && (
        <div className="bg-[#0000007c] z-50 fixed top-0 left-0 w-screen h-screen flex items-center justify-center p-4">
          <div className="relative bg-white max-w-[800px] max-h-[95vh] overflow-y-auto w-full mx-4 sm:min-w-[600px] rounded-lg px-6 sm:px-8 py-8">
            {!popupFormOpen ? (
              <div>
                <button
                  className="absolute right-2 top-2 text-red-900"
                  onClick={() => {
                    setError("");
                    setPopupOpen(false);
                  }}
                >
                  <IoClose className="w-7 h-7" />
                </button>

                <div className="w-full flex flex-col items-center gap-6">
                  <div className="flex flex-col gap-4 items-center">
                    <img src={NotFoundImage} alt="" className="max-w-[400px]" />

                    <p className="font-medium text-gray-700">{error}</p>
                  </div>

                  <button
                    className="bg-blue px-6 py-1.5 rounded-md font-medium text-white"
                    onClick={(event) => {
                      event.preventDefault();
                      setError("");
                      setPopupFormOpen(true);
                    }}
                  >
                    Request
                  </button>
                </div>
              </div>
            ) : (
              <div className="w-full flex flex-col gap-4">
                <button
                  className="absolute right-2 top-2 text-red-900"
                  onClick={() => {
                    setError("");
                    setPopupOpen(false);
                    setPopupFormOpen(false);
                  }}
                >
                  <IoClose className="w-7 h-7" />
                </button>

                <div className="flex flex-col gap-1">
                  <InputWithLabel
                    htmlFor="partNumber"
                    label="Part Number"
                    type="text"
                    placeholder="Enter Part Number"
                    name="partNumber"
                    value={formData.partNumber}
                    onChange={(e) => {
                      handleInputChange("partNumber", e.target.value);
                      clearError("partNumber");
                    }}
                  />

                  {errors.partNumber && (
                    <p className="text-red-900 text-[14px] font-medium">
                      {errors.partNumber}
                    </p>
                  )}
                </div>

                <div className="flex flex-col gap-1">
                  <InputWithLabel
                    htmlFor="partName"
                    label="Part Name"
                    type="text"
                    placeholder="Enter Part Name"
                    name="partName"
                    value={formData.partName}
                    onChange={(e) => {
                      handleInputChange("partName", e.target.value);
                      clearError("partName");
                    }}
                  />

                  {errors.partName && (
                    <p className="text-red-900 text-[14px] font-medium">
                      {errors.partName}
                    </p>
                  )}
                </div>

                <div className="flex flex-col gap-1">
                  <InputWithLabel
                    htmlFor="partCategories"
                    label="Category"
                    placeholder="Say something about your vehicle..."
                    name="partCategories"
                    value={formData.partCategories}
                    onChange={(e) => {
                      handleInputChange("partCategories", e.target.value);
                      clearError("partCategories");
                    }}
                  />

                  {errors.partCategories && (
                    <p className="text-red-900 text-[14px] font-medium">
                      {errors.partCategories}
                    </p>
                  )}
                </div>

                <div className="flex flex-col gap-1">
                  <InputWithLabel
                    htmlFor="vehicleCompany"
                    label="Vehicle Company"
                    placeholder="Enter vehicle company"
                    name="vehicleCompany"
                    value={formData.vehicleCompany}
                    onChange={(e) => {
                      handleInputChange("vehicleCompany", e.target.value);
                      clearError("vehicleCompany");
                    }}
                  />

                  {errors.vehicleCompany && (
                    <p className="text-red-900 text-[14px] font-medium">
                      {errors.vehicleCompany}
                    </p>
                  )}
                </div>

                <div className="flex flex-col gap-1">
                  <InputWithLabel
                    htmlFor="brand"
                    label="Brand"
                    placeholder="Enter brand name"
                    name="brand"
                    value={formData.brand}
                    onChange={(e) => {
                      handleInputChange("brand", e.target.value);
                      clearError("brand");
                    }}
                  />

                  {errors.brand && (
                    <p className="text-red-900 text-[14px] font-medium">
                      {errors.brand}
                    </p>
                  )}
                </div>

                <div className="flex flex-col gap-1">
                  <InputWithLabel
                    htmlFor="model"
                    label="Model"
                    placeholder="Enter model name"
                    name="model"
                    value={formData.model}
                    onChange={(e) => {
                      handleInputChange("model", e.target.value);
                      clearError("model");
                    }}
                  />

                  {errors.model && (
                    <p className="text-red-900 text-[14px] font-medium">
                      {errors.model}
                    </p>
                  )}
                </div>

                <div className="flex flex-col gap-1">
                  <InputWithLabel
                    htmlFor="quantity"
                    label="Quantity"
                    type="text"
                    placeholder="Enter quantity"
                    name="quantity"
                    value={formData.quantity}
                    onChange={(e) => {
                      handleInputChange("quantity", e.target.value);
                      clearError("quantity");
                    }}
                  />

                  {errors.quantity && (
                    <p className="text-red-900 text-[14px] font-medium">
                      {errors.quantity}
                    </p>
                  )}
                </div>

                <button
                  type="button"
                  className="w-fit self-end mt-4 bg-blue px-6 py-1.5 rounded-md text-[14px] text-white font-semibold"
                  onClick={isAgent ? () => {} : () => handleSubmit()}
                >
                  Proceed
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchSection;
