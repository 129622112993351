import React from "react";
import { CiDeliveryTruck } from "react-icons/ci";
import CarImage from "../../../assets/images/car.jpg";

const HeroSection = () => {
  return (
    <div>
      <div
        className={`relative min-h-[400px] w-full flex flex-col items-center justify-center bg-cover bg-no-repeat bg-center`}
        style={{ backgroundImage: `url(${CarImage})` }}
      >
        <div className="bg-[#201f3786] w-full h-full absolute top-0 left-0"></div>

        <div className="relative px-6 z-10 h-full flex flex-col self-center gap-6 md:px-20">
          <div className="flex flex-col items-center gap-2">
            <h1 className="uppercase text-center text-[22px] font-bold text-white md:text-3xl">
              Explore Components Based On Your Car.
            </h1>

            <p className="text-white text-center text-sm md:text-base">
              Find Excellent Quality And Service With Our Products
            </p>
          </div>

          {/* <div className="bg-white p-3 pb-5 mx-4 rounded-lg md:rounded-full md:mx-0 md:p-1.5">
            <div className="flex gap-2 flex-col md:gap-4 md:flex-row">
              <div className="w-full md:min-w-[636px] min-h-[50px] py-1 relative flex items-center gap-2 group flex-col md:flex-row">
                <>
                  <div className="w-full">
                    <DropdownWithFilter
                      buttonLabel="Select Car"
                      items={[
                        "Uppercase",
                        "Lowercase",
                        "Camel Case",
                        "Kebab Case",
                      ]}
                      icon={FaCar}
                    />
                  </div>

                  <div className="w-[2px] h-[25px] bg-gray-300 hidden md:block"></div>

                  <div className="w-full">
                    <DropdownWithFilter
                      buttonLabel="Select Model"
                      items={[
                        "Uppercase",
                        "Lowercase",
                        "Camel Case",
                        "Kebab Case",
                      ]}
                      icon={IoLogoModelS}
                    />
                  </div>

                  <div className="w-[2px] h-[25px] bg-gray-300 hidden md:block"></div>

                  <div className="w-full">
                    <DropdownWithFilter
                      buttonLabel="Select Year"
                      items={[
                        "Uppercase",
                        "Lowercase",
                        "Camel Case",
                        "Kebab Case",
                      ]}
                      icon={FaRegCalendar}
                    />
                  </div>
                </>
              </div>

              <button className="mx-3 bg-blue rounded-full text-white font-bold px-10 py-2 md:mx-0">
                Search
              </button>
            </div>
          </div> */}
        </div>
      </div>

      <div className="bg-[#444bd3] px-6 py-6 grid vsm:grid-cols-2 gap-4 sm:gap-5 md:py-4 md:grid-cols-3 md:px-10 lg:px-20 xl:grid-cols-5">
        <div className="flex gap-2 items-center text-white">
          <div>
            <CiDeliveryTruck className="text-3xl" />
          </div>

          <div className="flex flex-col">
            <h1 className="font-semibold text-base vsm:text-sm md:text-base">
              Free Shipping
            </h1>
            <p className="text-sm vsm:text-sm vsm:text-[13px] md:text-sm">
              For Orders from Rs 500
            </p>
          </div>
        </div>

        <div className="flex gap-2 items-center text-white">
          <div>
            <CiDeliveryTruck className="text-3xl" />
          </div>

          <div className="flex flex-col">
            <h1 className="font-semibold text-base vsm:text-sm md:text-base">
              Original Products
            </h1>
            <p className="text-sm vsm:text-[13px] md:text-sm">
              Trusted Aftermarket brands
            </p>
          </div>
        </div>

        <div className="flex gap-2 items-center text-white">
          <div>
            <CiDeliveryTruck className="text-3xl" />
          </div>

          <div className="flex flex-col">
            <h1 className="font-semibold text-base vsm:text-sm md:text-base">
              Support 24/7
            </h1>
            <p className="text-sm vsm:text-[13px] md:text-sm">
              Call us anytime for our service
            </p>
          </div>
        </div>

        <div className="flex gap-2 items-center text-white">
          <div>
            <CiDeliveryTruck className="text-3xl" />
          </div>

          <div className="flex flex-col">
            <h1 className="font-semibold text-base vsm:text-sm md:text-base">
              100% Safety
            </h1>
            <p className="text-sm vsm:text-[13px] md:text-sm">
              Only secure payments
            </p>
          </div>
        </div>

        <div className="flex gap-2 items-center text-white">
          <div>
            <CiDeliveryTruck className="text-3xl" />
          </div>

          <div className="flex flex-col">
            <h1 className="font-semibold text-base vsm:text-sm md:text-base">
              Heavy Discount
            </h1>
            <p className="text-sm vsm:text-[13px] md:text-sm">
              Get discounts on our products
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
