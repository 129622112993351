import { useAtom } from "jotai";
import React, { useState } from "react";
import { BiChevronLeft } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import InfoSvg from "../../assets/svg/info.svg";
import {
  isVendorEditAtom,
  vendorToEditAtom,
} from "../../components/Admin/VendorsManagement/AcceptedVendors/AcceptedVendorsTableRow";
import AddAddressForm from "../../components/Forms/SellerPage/AddAddressForm";
import AddProfileForm from "../../components/Forms/SellerPage/AddProfileForm";
import VerifyIdAndBank from "../../components/Forms/SellerPage/VerifyIdAndBankForm";
import SignupHeader from "../../components/SellerPage/Signup/SignupHeader";
import StepsIndicator, {
  currentStepAtom,
} from "../../components/SellerPage/Signup/StepsIndicator";
import DocumentSampleModal from "../../components/modals/DocumentSampleModal";
import EditAddProfileForm from "../../components/Forms/SellerPage/SignupAddProfileForm";
import StepsIndicator2 from "../../components/User/PlaceOrder/StepIndicator2";

const SellerSignupPage = () => {
  const [currentStep, setCurrentStep] = useAtom(currentStepAtom);

  const [isSampleModalOpen, setSampleModalOpen] = useState(false);

  const [, setVendorToEdit] = useAtom(vendorToEditAtom);
  const [isVendorEdit, setIsVendorEdit] = useAtom(isVendorEditAtom);

  const openSampleModal = (e) => {
    e.preventDefault();
    setSampleModalOpen(true);
  };

  const closeSampleModal = () => {
    setSampleModalOpen(false);
  };

  const renderForm = () => {
    switch (currentStep) {
      case 1:
        return <AddProfileForm />;
      case 2:
        return <AddAddressForm />;
      case 3:
        return <VerifyIdAndBank openSampleModal={openSampleModal} />;
      // case 4:
      //   return <AddProductForm />;
      default:
        return null;
    }
  };

  const editrenderForm = () => {
    switch (currentStep) {
      case 1:
        return <EditAddProfileForm />;
      case 2:
        return <AddAddressForm />;
      default:
        return null;
    }
  };

  const navigate = useNavigate();

  return (
    <div className="bg-gray-100 min-h-screen">
      {!isVendorEdit && <SignupHeader />}

      {isVendorEdit && (
        <div className="px-6 pt-4">
          <button
            className="bg-blue text-white rounded-md font-medium text-[15px] pl-2 pr-4 py-1.5 flex items-center gap-1"
            onClick={() => {
              setVendorToEdit([]);
              setIsVendorEdit(false);
              setCurrentStep(1);
              navigate("/admin/vendors-management");
            }}
          >
            <BiChevronLeft className="text-2xl" />
            Back
          </button>
        </div>
      )}

      <div
        className={` py-6 flex gap-4  ${
          !isVendorEdit
            ? "px-6 sm:px-8 sm:gap-8 md:px-12 lg:px-16"
            : "px-6 sm:gap-6"
        }`}
      >
        {isVendorEdit ? editrenderForm() : renderForm()}
        {isVendorEdit ? <StepsIndicator2 /> : <StepsIndicator />}

      </div>

      <button className="fixed z-10 bottom-0 right-4" onClick={openSampleModal}>
        <img src={InfoSvg} alt="" />
      </button>

      {isSampleModalOpen && <DocumentSampleModal onClose={closeSampleModal} />}
    </div>
  );
};

export default SellerSignupPage;
