import React from "react";
import { ToastContainer } from "react-toastify";
import BrowseCategory from "../../components/User/Homepage/BrowseCategory";
import FeaturedProducts from "../../components/User/Homepage/FeaturedProducts";
import HeroSection from "../../components/User/Homepage/HeroSection";
import MoreFeaturedProducts from "../../components/User/Homepage/MoreFeaturedProducts";
import Offers from "../../components/User/Homepage/Offers";
import Testimonials from "../../components/User/Homepage/Testimonials";
import TopCarMakers from "../../components/User/Homepage/TopCarMakers";
import Footer from "../../components/User/common/Footer";
import Header from "../../components/User/common/Header";
import ScrollToTopButton from "../../components/User/common/ScrollToTopButton";


const UserHomepage = () => {
  return (
    <div>
      <div className="relative">
        <ToastContainer position="top-center" autoClose={1000} />
        <Header />
      </div>

      <HeroSection />

      <FeaturedProducts />

      <Offers />

      <BrowseCategory />

      <TopCarMakers />

      <MoreFeaturedProducts />

      <Testimonials />

      <Footer showNewsletter={false} />
      <ScrollToTopButton/>


    </div>
  );
};

export default UserHomepage;
