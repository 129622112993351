import { atom, useAtom } from "jotai";
import React from "react";

export const currentStepAtom = atom(1);

const stepContent = {
  1: {
    title: "Add Profile",
    description: "Fill out the basic info about your company.",
  },
  2: {
    title: "Add Address",
    description: "Fill out the address of your company.",
  },
//   3: {
//     title: "Verify ID & Bank",
//     description: "Verify your bank account and link it here.",
//   },
  // 4: {
  //   title: "Add Product",
  //   description: "Add a product and enjoy being a seller.",
  // },
};

const stepColors = {
  1: "bg-blue",
  2: "bg-green",
//   3: "bg-red",
  // 4: "bg-indigo",
};

const StepsIndicator2 = () => {
  const [currentStep] = useAtom(currentStepAtom);

  return (
    <div className="relative z-10 bg-white h-fit px-4 sm:px-8 py-8 w-full max-w-[80px] sm:max-w-[250px] lg:max-w-[285px] flex flex-col gap-10">
      <h1 className="font-bold text-center">
        Steps <span className="hidden sm:inline-block">To Become Seller</span>
      </h1>

      <ol className="relative text-gray-200 border-l-2 left-6 flex flex-col gap-10 sm:block sm:left-0">
        {[1, 2, ].map((step) => (
          <li
            key={step}
            className={`mb-10 ml-8 ${
              step <= currentStep ? "text-gray-900" : "text-gray-500"
            }`}
          >
            <span
              className={`absolute flex items-center justify-center w-8 h-8 ${
                step <= currentStep ? stepColors[step] : "bg-gray-300"
              } text-white font-semibold rounded-full -left-4`}
            >
              {step}
            </span>

            <div className="flex-col gap-1.5 hidden sm:flex">
              <h3
                className={`font-medium leading-tight text-[15px] ${
                  step <= currentStep ? "text-gray-900" : "text-gray-500"
                }`}
              >
                {stepContent[step].title}
              </h3>
              <p
                className={`text-sm text-[14px] ${
                  step <= currentStep ? "text-gray-700" : "text-gray-400"
                }`}
              >
                {stepContent[step].description}
              </p>
            </div>
          </li>
        ))}
      </ol>
    </div>
  );
};

export default StepsIndicator2;
