import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import ProductCard from "../common/ProductCard";
import SliderNavButtons from "../common/SliderNavButtons";

const DifferentProducts = () => {
  const sliderRef = useRef(null);

  const settings = {
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <></>,
    prevArrow: <></>,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 968,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const handlePrev = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  // Simulated backend data for products
  const products = [];

  return (
    <div className="pt-6 sm:pt-10 pb-5">
      <div className="flex items-center justify-between">
        <h1 className="text-xl font-bold">Different Products</h1>
        <SliderNavButtons onNext={handleNext} onPrev={handlePrev} />
      </div>

      <div className="relative w-full mt-2 mb-10 bg-gray-300 h-[2px] flex">
        <div className="absolute top-0 left-0 flex">
          <div className="w-[160px] bg-yellow h-[10px]"></div>
          <div className="w-[50px] relative -left-4 bg-yellow h-[10px] skew-x-[-50deg]"></div>
        </div>
      </div>

      <div className="md:pt-2">
        <Slider {...settings} ref={sliderRef}>
          {products.map((product) => (
            <div key={product.id} className="px-2">
              <ProductCard
                imageUrl={product.imageUrl}
                brand={product.brand}
                rating={product.rating}
                title={product.title}
                price={product.price}
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default DifferentProducts;
