import React, { useState } from "react";
import { HiChevronDown, HiOutlineSearch } from "react-icons/hi";
import Unidentified from "./Unidentified/Unidentified";

const ManageUnidentifiedProducts = () => {
  const [orderStatus, setOrderStatus] = useState("Unidentified Products");
  const [searchQuery, setSearchQuery] = useState("");
  const [showMobileDropdown, setShowMobileDropdown] = useState(false);
  //   const [, setIsManageActive] = useAtom(isManageActiveAtom);
  const [moveToOption, setMoveToOption] = useState(null);
  const [showMoveToDropdown, setShowMoveToDropdown] = useState(false);
  const [isAnyRowSelected, setIsAnyRowSelected] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [mobileSearchOpen, setMobileSearchOpen] = useState(false);

  // Function to handle selecting an option in the "Move To" dropdown
  const handleMoveToOptionSelect = (option) => {
    if (option === "Move to Shipped") {
      console.log("To be shipped:");
      console.log(selectedRows);
    } else if (option === "Move to Canceled") {
      console.log("To be cancelled:");
      console.log(selectedRows);
    } else if (option === "Move to Ready To Ship") {
      console.log("To be Ready To Ship:");
      console.log(selectedRows);
    }

    setSelectedRows([]);
    setIsAnyRowSelected(false);
    setMoveToOption(option);
    setShowMoveToDropdown(false);
  };

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Function to handle selecting an option in the mobile dropdown
  const handleMobileOptionSelect = (option) => {
    setOrderStatus(option);
    setShowMobileDropdown(false);
  };

  return (
    <div className={`px-8 py-6 flex flex-col gap-4`}>
      <div className="flex items-stretch justify-between lg:items-center">
        <div className="flex items-center gap-4 xl:gap-6">
          {/* Mobile dropdown for product status */}
          <div className="xl:hidden relative">
            <button
              className="flex items-center gap-1.5 text-blue font-bold text-[16px] bg-white border-2 border-gray-300 rounded-md px-2 py-2"
              onClick={() => setShowMobileDropdown(!showMobileDropdown)}
            >
              <p>{orderStatus}</p>
              <HiChevronDown className="text-[20px]" />
            </button>

            {showMobileDropdown && (
              <div className="absolute z-30 mt-2 left-2 w-48 bg-white border border-gray-300 rounded-md">
                {["Unidentified Products"].map((option) => (
                  <button
                    key={option}
                    className={`w-full ${
                      orderStatus === option ? "bg-gray-100" : ""
                    } cursor-pointer p-1.5`}
                    onClick={(e) => {
                      handleMobileOptionSelect(option);
                      e.stopPropagation();
                    }}
                  >
                    {option}
                  </button>
                ))}
              </div>
            )}
          </div>

          {/* Desktop buttons for product status */}
          <div className="hidden xl:flex">
            <div className="flex items-center gap-6 font-medium text-[14px] text-gray-500">
              <button
                className={`${
                  orderStatus === "Unidentified Products"
                    ? "text-blue-900 font-bold border-b-2 pb-0.5 border-blue-900"
                    : ""
                }`}
                onClick={() => setOrderStatus("Unidentified Products")}
              >
                Unidentified Products
              </button>
            </div>
          </div>

          {isAnyRowSelected && (
            <div className="relative inline-block text-left">
              <button
                className="px-2 py-2 flex items-center gap-1 text-gray-500 text-[14px] font-medium"
                onClick={() => setShowMoveToDropdown(!showMoveToDropdown)}
              >
                Move To
                <HiChevronDown className="text-[20px]" />
              </button>

              {showMoveToDropdown && (
                <div className="absolute z-30 mt-0 left-0 w-48 bg-white border border-gray-300 rounded-md">
                  {["Move to Shipped", "Move to Canceled"].map((option) => (
                    <button
                      key={option}
                      className={`w-full text-[14px] text-gray-700 font-medium cursor-pointer hover:bg-gray-200 p-1.5`}
                      onClick={(e) => {
                        handleMoveToOptionSelect(option);
                        e.stopPropagation();
                      }}
                    >
                      {option}
                    </button>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>

        <div className="flex gap-4">
          <div className="hidden px-4 py-2 sm:flex items-center gap-3 bg-white border border-gray-300 rounded-md cursor-pointer sm:cursor-pointer">
            <HiOutlineSearch className="text-gray-500 text-[20px]" />

            <input
              type="text"
              placeholder="Search"
              className="bg-white outline-none font-medium text-[14px] placeholder:text-[13px] w-[120px] placeholder:text-gray-400"
              value={searchQuery}
              onChange={handleSearchInputChange}
            />
          </div>

          <div
            className="px-4 py-2 sm:hidden items-center gap-3 bg-white border border-gray-300 rounded-md cursor-pointer sm:cursor-pointer"
            onClick={() => {
              setMobileSearchOpen(!mobileSearchOpen);
            }}
          >
            <HiOutlineSearch className="text-gray-500 text-[20px]" />
          </div>
        </div>
      </div>

      {mobileSearchOpen && (
        <div className="px-4 py-2 flex items-center gap-3 bg-white border border-gray-300 rounded-md cursor-pointer sm:cursor-pointer">
          <HiOutlineSearch className="text-gray-500 text-[20px]" />

          <input
            type="text"
            placeholder="Search"
            className="bg-white outline-none font-medium text-[14px] placeholder:text-[13px] w-[120px] placeholder:text-gray-400 sm:block"
            value={searchQuery}
            onChange={handleSearchInputChange}
          />
        </div>
      )}

      {orderStatus === "Unidentified Products" ? (
        <Unidentified
          searchQuery={searchQuery}
          isAnyRowSelected={isAnyRowSelected}
          setIsAnyRowSelected={setIsAnyRowSelected}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default ManageUnidentifiedProducts;
