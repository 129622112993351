import axios from "axios";
import React, { useState } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { BiEdit, BiTrash } from "react-icons/bi";
import { HiMinus, HiPlus } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

const CreditTableRow = ({
  sellerId,
  creditProvided,
  currentBalance,
  creditDue,
  fetchData,
  formData,
}) => {
  const [isMobileTableRowOpen, setIsMobileTableRowOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedData, setEditedData] = useState({});
  const navigate = useNavigate();

  const onDeleteClick = async (e) => {
    e.preventDefault();
    const dataToDelete = {
      sellerId: formData.seller_id,
      creditProvided: creditProvided,
      currentBalance: currentBalance,
      creditDue: creditDue,
    };

    const url = process.env.REACT_APP_BASE_URL + "admin/update-credit/";
    try {
      const response = await axios.post(`${url}`, dataToDelete, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        body: JSON.stringify(dataToDelete),
      });
      console.log("Data sent successfully:", response.data);
      fetchData();
    } catch (error) {
      console.error("Error sending data:", error);
    }
  };

  const onSaveChangesClick = async () => {
    console.log("editedData:", editedData);

    // editedData['credit_used'] = n;
    const url = process.env.REACT_APP_BASE_URL + "admin/update-credit/";
    try {
      const response = await axios.post(`${url}`, editedData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        body: JSON.stringify(editedData),
      });
      if (response.status === 201) {
        setIsEditing(false);
      }
      console.log("Data sent successfully:", response.data);
      fetchData();
    } catch (error) {
      console.error("Error sending data:", error);
    } // Exit editing mode
  };

  const onEditClick = () => {
    setEditedData(formData);
    setIsEditing(true); // Enter editing mode
  };

  return (
    <>
      <div className="border-b-2 border-gray-400 px-5 py-3 sm:hidden">
        <div className="flex items-center gap-4">
          <div
            className={`w-6 h-6 flex justify-center items-center ${
              isMobileTableRowOpen
                ? "bg-blue text-white rounded-[4px]"
                : "border-2 border-gray-400 text-gray-400 rounded-sm"
            }`}
          >
            <button
              onClick={(event) => {
                event.preventDefault();
                setIsMobileTableRowOpen(!isMobileTableRowOpen);
              }}
            >
              {isMobileTableRowOpen ? (
                <HiMinus className="w-5 h-5" />
              ) : (
                <HiPlus className="w-5 h-5" />
              )}
            </button>
          </div>

          <div>
            <h1 className="text-gray-900 font-bold text-[15px] uppercase">
              Seller ID
            </h1>
            <p className="text-gray-800 text-start font-medium text-[15px]">
              {formData.sellerId}
            </p>
          </div>
        </div>

        {isMobileTableRowOpen && (
          <div className="flex flex-col gap-2 mt-4 ml-[2.4rem]">
            <div className="flex flex-row gap-2 items-center">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                Credit Provided:
              </h2>
              {isEditing ? (
                <input
                  type="text"
                  value={editedData.creditProvided || ""}
                  onChange={(e) =>
                    setEditedData({
                      ...editedData,
                      creditProvided: e.target.value,
                    })
                  }
                  className="text-[15px] text-gray-600 font-medium md:flex-1 border border-gray-300 rounded-md p-1 outline-none transition-all duration-300 hover:border-blue focus:border-blue"
                />
              ) : (
                <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                  {formData.creditProvided}
                </p>
              )}
            </div>

            <div className="flex flex-row gap-2 items-center">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                Current Balance:
              </h2>
              {isEditing ? (
                <input
                  type="text"
                  value={editedData.balanceUpdated || ""}
                  onChange={(e) =>
                    setEditedData({
                      ...editedData,
                      currentBalance: e.target.value,
                    })
                  }
                  className="text-[15px] text-gray-600 font-medium md:flex-1 border border-gray-300 rounded-md p-1 outline-none transition-all duration-300 hover:border-blue focus:border-blue"
                />
              ) : (
                <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                  {formData.currentBalance}
                </p>
              )}
            </div>

            <div className="flex flex-row gap-2 items-center">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                Credit Due:
              </h2>
              {isEditing ? (
                <input
                  type="text"
                  value={editedData.creditDue || ""}
                  onChange={(e) =>
                    setEditedData({ ...editedData, creditDue: e.target.value })
                  }
                  className="text-[15px] text-gray-600 font-medium md:flex-1 border border-gray-300 rounded-md p-1 outline-none transition-all duration-300 hover:border-blue focus:border-blue"
                />
              ) : (
                <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                  {formData.creditDue}
                </p>
              )}
            </div>

            <div className="flex items-center gap-2 my-4">
              {isEditing ? (
                <button
                  className="px-1.5 border-2 border-gray-300 font-medium rounded-md h-[35px]"
                  onClick={onSaveChangesClick}
                >
                  Save Changes
                </button>
              ) : (
                <button
                  className="px-1.5 border-2 border-gray-300 font-medium rounded-md h-[35px]"
                  onClick={onEditClick}
                >
                  Edit
                </button>
              )}

              <button
                className="px-1.5 border-2 border-gray-300 font-medium rounded-md h-[35px]"
                onClick={onDeleteClick}
              >
                <BiTrash className="w-5 h-5 text-red-900" />
              </button>

              <button
                className="px-1.5 border-2 border-gray-300 font-medium rounded-md h-[35px]"
                onClick={(event) => {
                  event.preventDefault();
                }}
              >
                <AiOutlineEye className="w-[22px] h-[22px] text-gray-700" />
              </button>
            </div>
          </div>
        )}
      </div>

      <tr className="border-b-2 border-gray-300 text-gray-700 relative hidden sm:table-row hover:bg-gray-100">
        <td>
          <span>{formData.sellerId}</span>
        </td>
        <td className="text-center">
          {isEditing ? (
            <input
              type="text"
              value={editedData.creditProvided || ""}
              onChange={(e) =>
                setEditedData({ ...editedData, creditProvided: e.target.value })
              }
              className="text-gray-700 font-medium border border-gray-300 rounded-md p-1 text-center  outline-none transition-all duration-300 hover:border-blue focus:border-blue"
            />
          ) : (
            <span>{formData.creditProvided}</span>
          )}
        </td>
        <td className="text-center">
          {isEditing ? (
            <input
              type="text"
              value={editedData.currentBalance || ""}
              onChange={(e) =>
                setEditedData({ ...editedData, currentBalance: e.target.value })
              }
              className="text-gray-700 font-medium border border-gray-300 rounded-md p-1 text-center  outline-none transition-all duration-300 hover:border-blue focus:border-blue"
            />
          ) : (
            <span>{formData.currentBalance}</span>
          )}
        </td>
        <td className="text-center">
          {isEditing ? (
            <input
              type="text"
              value={editedData.balanceUpdated || ""}
              onChange={(e) =>
                setEditedData({ ...editedData, balanceUpdated: e.target.value })
              }
              className="text-gray-700 font-medium border border-gray-300 rounded-md p-1 text-center  outline-none transition-all duration-300 hover:border-blue focus:border-blue"
            />
          ) : (
            <span>{formData.balanceUpdated || "N/A"}</span>
          )}
        </td>
        <td className="text-center">
          {isEditing ? (
            <input
              type="text"
              value={editedData.creditDue || ""}
              onChange={(e) =>
                setEditedData({ ...editedData, creditDue: e.target.value })
              }
              className="text-gray-700 font-medium border border-gray-300 rounded-md p-1 text-center  outline-none transition-all duration-300 hover:border-blue focus:border-blue"
            />
          ) : (
            <span>{formData.creditDue || "N/A"}</span>
          )}
        </td>
        <td>
          <div className="flex items-center justify-center gap-2">
            {isEditing ? (
              <button
                className="px-2 border-2 bg-blue text-white font-medium rounded-md h-[35px]"
                onClick={onSaveChangesClick}
              >
                Save Changes
              </button>
            ) : (
              <button
                className="px-1.5 border-2 border-gray-300 font-medium rounded-md h-[35px]"
                onClick={onEditClick}
              >
                <BiEdit className="w-5 h-5 text-blue" />
              </button>
            )}
          </div>
        </td>
      </tr>
    </>
  );
};

export default CreditTableRow;
