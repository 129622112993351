// TermsAndConditionsModal.js

import React from "react";
import Modal from "react-modal";

const TermsAndConditionsModal = ({ isOpen, onRequestClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Terms and Conditions Modal"
      className="fixed h-screen z-30 top-0 p-4 left-0 w-full flex items-center justify-center bg-gray-800 bg-opacity-75"
      overlayClassName="fixed bg-gray-800 bg-opacity-75"
    >
      <div className="bg-white p-8 py-6 rounded-lg max-w-[600px] overflow-y-auto max-h-full">
        <h2 className="text-2xl font-bold mb-4">Terms and Conditions</h2>
        <div className="text-gray-700">
          <p className="mb-4">
            Please read these terms and conditions carefully before using our
            services.
          </p>
          <p className="mb-4">
            By accessing or using our services, you agree to be bound by these
            terms. If you disagree with any part of the terms, then you may not
            access our services.
          </p>

          <h3 className="text-lg font-bold mb-2">1. Acceptance of Terms</h3>
          <p className="mb-4">
            You agree to the terms and conditions outlined in this agreement
            with respect to our services.
          </p>

          <h3 className="text-lg font-bold mb-2">2. Use License</h3>
          <p className="mb-4">
            Permission is granted to temporarily download one copy of the
            materials on our website for personal, non-commercial transitory
            viewing only.
          </p>

          <h3 className="text-lg font-bold mb-2">3. Disclaimer</h3>
          <p className="mb-4">
            The materials on our website are provided on an 'as is' basis. We
            make no warranties, expressed or implied, and hereby disclaim and
            negate all other warranties.
          </p>

          {/* Add more sections as needed */}
        </div>
        <button
          className="mt-4 px-4 py-2 bg-blue-700 text-white rounded"
          onClick={onRequestClose}
        >
          Close
        </button>
      </div>
    </Modal>
  );
};

export default TermsAndConditionsModal;
