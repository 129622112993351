import { useAtom } from "jotai";
import React from "react";
import { FaStar } from "react-icons/fa";
import { IoArrowBack } from "react-icons/io5";
import { viewOrderDetailAtom } from "./AllOrder/AllOrderTableRow";

const OrderDetail = () => {
  const [, setViewOrderDetail] = useAtom(viewOrderDetailAtom);

  return (
    <div className="bg-white p-4 pb-8 md:p-8 flex flex-col gap-6">
      <button
        className="flex items-center gap-2 text-gray-700 font-medium"
        onClick={() => setViewOrderDetail(false)}
      >
        <IoArrowBack />
        Back
      </button>

      <div className="flex gap-6 md:gap-12 flex-col md:flex-row">
        <div className="md:w-[65%] flex flex-col gap-6">
          <div className="flex flex-col gap-3">
            <h1 className="text-xl text-blue font-bold">
              Hitachi 255-3910 Ignition Coil Honda City ZX / Type 3
            </h1>

            <div className="flex items-center gap-3">
              <p className="font-medium">
                Brand: <span className="text-blue">Hyundai</span>
              </p>

              <div className="w-[2px] h-[15px] bg-gray-300"></div>

              <div className="text-gray-500 font-medium flex items-center gap-2">
                <FaStar className="text-yellow" />
                4.5
              </div>

              <div className="w-[2px] h-[15px] bg-gray-300"></div>

              <p className="font-medium text-gray-500">1 Review</p>
            </div>
          </div>

          <ul className="flex flex-col gap-4 font-bold">
            <li className="flex items-center gap-6">
              <p className="min-w-[150px]">Quantity :</p>
              <p>2</p>
            </li>

            <li className="flex items-center gap-6">
              <p className="min-w-[150px]">Origin :</p>
              <p>2</p>
            </li>

            <li className="flex items-center gap-6">
              <p className="min-w-[150px]">Class :</p>
              <p>5 items</p>
            </li>

            <li className="flex items-center gap-6">
              <p className="min-w-[150px]">Part Number :</p>
              <p>ZR 595</p>
            </li>
          </ul>

          <div className="w-full h-[2px] bg-gray-300"></div>

          <div className="flex flex-col gap-3">
            <div className="flex items-center justify-between">
              <h1 className="font-bold">Sub total</h1>
              <p className="font-bold text-gray-800">Rs 89,000</p>
            </div>

            <div className="flex items-center justify-between">
              <h1 className="font-medium text-gray-500">Shipping Charge</h1>
              <p className="font-medium text-gray-500">Rs 1,000</p>
            </div>

            <div className="flex items-center justify-between text-[20px]">
              <h1 className="font-bold">Total</h1>
              <p className="font-bold text-blue">Rs 89,000</p>
            </div>
          </div>
        </div>

        <div className="relative flex-1 flex flex-col items-center gap-6">
          <img
            src="https://i.ibb.co/0s3pdnc/1.jpg"
            alt=""
            className="w-full max-h-[300px] sm:max-h-[400px] md:max-h-[60%] object-fill"
          />
          <div
            className={`px-6 py-2.5 text-center rounded-full text-white bg-blue-700`}
          >
            To be Shipped
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetail;
