import React from "react";
import { BiChevronLeft } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

const ViewUser = () => {
  // Static JSON data for user information
  const userData = {
    name: "John Doe",
    phoneNumber: "123-456-7890",
    email: "john.doe@example.com",
    registrationDate: "2023-05-15",
    lastLogin: "2024-02-20 10:30 AM",
    transactions: [
      {
        transactionId: "T123456",
        date: "2024-02-20",
        totalAmount: "$150.00",
        products: [
          {
            productId: "P001",
            name: "Product A",
            price: "$50.00",
            quantity: 1,
          },
          {
            productId: "P002",
            name: "Product B",
            price: "$75.00",
            quantity: 2,
          },
        ],
      },
      {
        transactionId: "T789012",
        date: "2024-02-15",
        totalAmount: "$200.00",
        products: [
          {
            productId: "P003",
            name: "Product C",
            price: "$100.00",
            quantity: 1,
          },
        ],
      },
    ],
    // Add other necessary user data fields here
  };

  const navigate = useNavigate();

  return (
    <div className="md:px-8 md:py-6 flex gap-4 sm:gap-8">
      <div className="flex-1 bg-white py-4 flex flex-col gap-6">
        <div className="max-w-fit px-6 sm:px-8">
          <button
            className="bg-blue text-white rounded-md font-medium text-[15px] pl-2 pr-4 py-1.5 flex items-center gap-1"
            onClick={() => {
              navigate("/admin/user-management");
            }}
          >
            <BiChevronLeft className="text-2xl" />
            Back
          </button>
        </div>

        <div className="flex items-center justify-between px-6 sm:px-8">
          <h1 className="text-blue font-bold text-lg">User Information</h1>
        </div>

        <div className="flex flex-col gap-8 md:px-8">
          <div className="">
            <div className="flex flex-col gap-3 px-6 sm:px-8 md:px-0">
              <div className="flex flex-col sm:flex-row sm:justify-between">
                <h2 className="text-[14px] text-gray-900 font-bold md:flex-1">
                  Name:
                </h2>
                <p className="text-[14px] text-gray-700 font-medium md:flex-1">
                  {userData.name}
                </p>
              </div>

              <div className="flex flex-col sm:flex-row sm:justify-between">
                <h2 className="text-[14px] text-gray-900 font-bold md:flex-1">
                  Phone Number:
                </h2>
                <p className="text-[14px] text-gray-700 font-medium md:flex-1">
                  {userData.phoneNumber}
                </p>
              </div>

              <div className="flex flex-col sm:flex-row sm:justify-between">
                <h2 className="text-[14px] text-gray-900 font-bold md:flex-1">
                  Email:
                </h2>
                <p className="text-[14px] text-gray-700 font-medium md:flex-1">
                  {userData.email}
                </p>
              </div>

              <div className="flex flex-col sm:flex-row sm:justify-between">
                <h2 className="text-[14px] text-gray-900 font-bold md:flex-1">
                  Registration Date:
                </h2>
                <p className="text-[14px] text-gray-700 font-medium md:flex-1">
                  {userData.registrationDate}
                </p>
              </div>

              <div className="flex flex-col sm:flex-row sm:justify-between">
                <h2 className="text-[14px] text-gray-900 font-bold md:flex-1">
                  Last Login:
                </h2>
                <p className="text-[14px] text-gray-700 font-medium md:flex-1">
                  {userData.lastLogin}
                </p>
              </div>

              {/* Add other relevant user data fields here */}
            </div>
          </div>

          {/* Display transaction details */}
          <div className="px-6 sm:px-8 md:px-0">
            <div className="flex flex-col gap-3">
              <h2 className="text-blue font-bold text-lg">
                Transaction History
              </h2>
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Transaction ID
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Date
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Total Amount
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {userData.transactions.map((transaction, index) => (
                    <tr key={index}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {transaction.transactionId}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {transaction.date}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {transaction.totalAmount}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewUser;
