import React from "react";

const PhoneInputWithLabel = ({ label, name, placeholder, value, onChange }) => {
  return (
    <div className="flex flex-col items-start gap-1.5">
      <label htmlFor={name} className="text-[14px] uppercase text-gray-800 font-bold">
        {label}
      </label>

      <div className="w-full border border-gray-400 rounded-lg flex">
        <div className="w-16 bg-[#F3F3F6] text-center text-gray-500 text-[14px] font-medium rounded-tl-lg rounded-bl-lg py-2">
          +977
        </div>

        <input
          type="text"
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          className="outline-none w-full rounded-tr-lg rounded-br-lg px-4 py-2 text-[14px] font-medium placeholder:text-gray-300 placeholder:font-normal"
        />
      </div>
    </div>
  );
};

export default PhoneInputWithLabel;
