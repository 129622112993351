import { jwtDecode } from "jwt-decode";
import React, { useEffect, useRef, useState } from "react";
import { FiSend } from "react-icons/fi";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Chat.css";

const IndividualChat = () => {
  const [chatData, setChatData] = useState([]);
  const [messageInput, setMessageInput] = useState("");
  const [loading, setLoading] = useState(true); // State variable for loading
  const { vendorId } = useParams();
  const [senderId, setSenderId] = useState(null);
  const [recipientId, setRecipientId] = useState(null);

  // const recipientId = 49;

  const socket = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      // Handle the case where the token is not present (e.g., redirect to login page)
      toast.error("Authentication credentials not provided. Please log in.");
      return;
    }

    // Check if the authentication token has expired (example for JWT, may vary based on implementation)
    const isTokenExpired = () => {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000; // Convert to seconds
        console.log("decodedToken", decodedToken.exp);
        console.log("currentTime", currentTime);
        console.log("isTokenExpired", decodedToken.exp < currentTime);
        return decodedToken.exp < currentTime;
      } catch (error) {
        return true; // Error decoding token or invalid token, treat as expired
      }
    };

    if (isTokenExpired()) {
      // Handle token expiration (e.g., refresh the token or redirect to login page)
      toast.error("Authentication token has expired. Please log in again.");
      navigate("/login");
      return;
    }
    const currentrecipientId = vendorId;
    setRecipientId(currentrecipientId);
    console.log("recipientId", recipientId);
    // const senderId = localStorage.getItem("userId");
    const currentsenderId = 4;
    setSenderId(currentsenderId);
  }, [vendorId]);

  useEffect(() => {
    if (senderId && recipientId && !socket.current) {
      const url =
        process.env.REACT_APP_WEBSOCKET_URL +
        `/chat/${senderId}/${recipientId}/`;
      socket.current = new WebSocket(url);
      socket.current.onopen = () => {
        console.log("WebSocket connection established", socket.current);
        setLoading(false); // Set loading to false when connection is established
        refresh();
      };
      socket.current.onerror = (event) => {
        console.error("WebSocket error:", event);
        setLoading(false); // Set loading to false on error
      };
      socket.current.onclose = (event) => {
        console.log("WebSocket connection closed:", event);
        setLoading(false); // Set loading to false on close
      };
    }
  }, [senderId, recipientId]);

  const refresh = async () => {
    if (socket.current) {
      socket.current.onmessage = (event) => {
        console.log("WebSocket message received:", event.data);
        console.log("event", event);
        const data = JSON.parse(event.data);
        console.log(data);
        if (data.message === "success" || data.message === "previous_message") {
          setChatData((prevChatData) => [
            ...prevChatData,
            {
              text: data.messageInput,
              sender: data.sender,
              time: data.timestamp,
            },
          ]);
        }
      };
    }
    return () => {
      if (socket.current) {
        socket.current.onmessage = null;
      }
    };
  };

  // useEffect(() => {
  //   refresh();
  // }, [socket]);

  const sendMessage = () => {
    const data = {
      message: messageInput,
      sender_id: senderId,
      receiver_id: recipientId,
    };
    socket.current.send(JSON.stringify(data));
    setMessageInput("");
    refresh();
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      sendMessage();
    }
  };

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatData]);

  const sortedChatData = chatData
    .slice()
    .sort((a, b) => new Date(a.time) - new Date(b.time));

  console.log("chatData", chatData);
  console.log("sortedChatData", sortedChatData);

  return (
    <div className="home-page__content">
      <div className="container-fluid">
        <div className="row px-0 h-100">
          <div className="chat col-12 col-md-8 col-lg-7 col-xl-6 px-0 pl-md-1">
            <div className="chat__container ">
              <div className="relative bg-white h-[calc(100vh_-_125px)] lg:h-[calc(100vh_-_65px)] flex flex-col justify-end ">
                {loading ? (
                  <div className="lg:h-[calc(100vh_-_65px)] flex items-center justify-center">
                    <div class="flex flex-row gap-2">
                      <div class="w-4 h-4 rounded-full bg-blue-700 animate-bounce [animation-delay:.7s]"></div>
                      <div class="w-4 h-4 rounded-full bg-blue-700 animate-bounce [animation-delay:.3s]"></div>
                      <div class="w-4 h-4 rounded-full bg-blue-700 animate-bounce [animation-delay:.7s]"></div>
                    </div>
                  </div>
                ) : sortedChatData.length === 0 ? (
                  <div className="text-center font-medium lg:h-[calc(100vh_-_65px)] flex items-center justify-center">
                    No chats available
                  </div>
                ) : (
                  <ul className="chat__list-messages chat__content py-4 px-3">
                    {sortedChatData.map((chat, index) => (
                      <li key={index}>
                        <div
                          className={`chat__bubble chat__bubble--${chat.sender}`}
                        >
                          {chat.text}
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
                <div ref={messagesEndRef}></div>
                <div className="flex items-center pb-4 px-3">
                  <input
                    type="text"
                    placeholder="Type your message..."
                    className="border border-gray-300 rounded-full px-4 py-2.5 w-full mr-2 focus:outline-none focus:border-blue"
                    value={messageInput}
                    onChange={(e) => setMessageInput(e.target.value)}
                    onKeyPress={handleKeyPress}
                  />
                  <button
                    className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2.5 rounded"
                    onClick={sendMessage}
                  >
                    <FiSend className="text-xl text-blue" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndividualChat;
