import React from "react";

const SearchResultPart = ({ title, partsList, imageSrc }) => {
  return (
    <div className="bg-gray-100 p-6 flex items-center gap-6 justify-between">
      <div className="flex flex-col gap-4">
        <h1 className="md:text-lg font-bold text-gray-800">{title}</h1>

        <ul className="relative text-[15p] md:text-base font-medium left-6 flex flex-col gap-2 list-disc text-gray-700">
          {partsList.map((part, index) => (
            <li key={index}>{part}</li>
          ))}
        </ul>
      </div>

      <div>
        <img src={imageSrc} alt="" className="max-w-[100px] sm:max-w-[80px] md:max-w-[100px]" />
      </div>
    </div>
  );
};

export default SearchResultPart;
