import { useAtom } from "jotai";
import React, { useState, useEffect } from "react";
import { BiBuilding } from "react-icons/bi";
import { FaHome } from "react-icons/fa";
import { IoArrowBack } from "react-icons/io5";
import InputWithLabel from "../../../common/InputWithLabel";
import { addNewAddressFormOpenAtom } from "./Addresses";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const AddAddress = ({ onClose, address }) => {
  const [formData, setFormData] = useState({
    fullName: "",
    phoneNum: "",
    fullAddress: "",
    label: "",
    PAN: "",
  });

  const [errors, setErrors] = useState({
    fullName: "",
    phoneNum: "",
    fullAddress: "",
    label: "",
    PAN: "",
  });
  const [selectedLabel, setSelectedLabel] = useState("");
  useEffect(() => {
    if (address) {
      console.log("Address: ", address);
      // If editing an existing address, populate the form fields with address data
      setFormData({
        fullName: address.fullname,
        phoneNum: address.phone,
        fullAddress: address.descriptiveaddress,
        label: address.label,
      });
      setSelectedLabel(address.label);
    }
  }, [address]);

  

  const handleLabelSelection = (label) => {
    setSelectedLabel(label);
    setFormData((prevFormData) => ({
      ...prevFormData,
      label: label,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formErrors = {};

    if (!formData.fullName.trim()) {
      formErrors.fullName = "Full Name is required.";
    }
    if (!formData.phoneNum.trim()) {
      formErrors.phoneNum = "Phone Number is required.";
    } else if (!/^\d{10}$/.test(formData.phoneNum.trim())) {
      formErrors.phoneNum = "Invalid Phone Number. Please enter a 10-digit number.";
    }
    if (!formData.fullAddress.trim()) {
      formErrors.fullAddress = "Full Address is required.";
    }
    if (!formData.label.trim()) {
      formErrors.label = "Label is required.";
    }
if (!(formData.PAN.trim() === '' || /^\d{9}$/.test(formData.PAN.trim()))) {
  formErrors.PAN = "Please enter a 9-digit number or leave it empty.";
}

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    try {
      const url = process.env.REACT_APP_BASE_URL + `users/address/`;
      const token = localStorage.getItem("usertoken");

      if (address) {
        // If editing an existing address, send a PUT request
        const response = await axios.put(
          `${url}${address.id}/`,
          formData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status === 200) {
          toast.success("Address updated successfully");
        } else {
          toast.error("Failed to update address");
        }
      } else {
        // If adding a new address, send a POST request
        const response = await axios.post(
          `${url}`,
          formData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status === 200) {
          toast.success("Address added successfully");
        } else {
          toast.error("Failed to add address");
        }
      }

      onClose();
      
    } catch (error) {
      console.error("Error submitting form: ", error);
      toast.error("Failed to submit form");
    }
  };

  const [, setAddNewAddressFormOpen] = useAtom(addNewAddressFormOpenAtom);
  return (
    <div className="bg-white p-4 pb-8 md:p-8 flex flex-col gap-6">
      <ToastContainer />
      <h1 className="text-lg text-blue font-bold">
        {address ? "Edit Address" : "New Address Form"}
      </h1>
      <div className=" w-full flex  ">
        <div className="max-w-[500px] flex flex-col gap-4 2xl:max-w-[100%]">
          <div className="flex flex-col gap-1">
            <InputWithLabel
              htmlFor="fullName"
              label="Full Name"
              type="text"
              placeholder="Nischal Bista"
              name="fullName"
              value={formData.fullName}
              onChange={handleInputChange}
            />
            {errors.fullName && (
              <p className="text-red-900 text-[14px] font-medium">
                {errors.fullName}
              </p>
            )}
          </div>

          <div className="flex flex-col items-start gap-1.5">
            <label
              htmlFor="username"
              className="text-[14px] uppercase text-gray-800 font-bold"
            >
              Phone Number
            </label>

            <div className="w-full border border-gray-400 rounded-lg flex">
              <div className="w-16 bg-[#F3F3F6] text-center text-gray-500 text-[14px] font-medium rounded-tl-lg rounded-bl-lg py-2">
                +977
              </div>

              <input
                type="text"
                placeholder="9852545652"
                onChange={handleInputChange}
                value={formData.phoneNum}
                name="phoneNum"
                className="outline-none w-full rounded-tr-lg rounded-br-lg px-4 py-2 text-[14px] font-medium placeholder:text-gray-300 placeholder:font-normal"
              />
              
            </div>
            {errors.phoneNum && (
            <p className="text-red-900 text-[14px] font-medium">
              {errors.phoneNum}
            </p>
          )}
          </div>



          <div className="flex flex-col gap-1">
            <InputWithLabel
              htmlFor="fullAddress"
              label="Full Address"
              type="text"
              placeholder="Kathmandu Metropolitan City, Ward No. 10, New Baneshwor"
              name="fullAddress"
              value={formData.fullAddress}
              onChange={handleInputChange}
            />

            {errors.fullName && (
            <p className="text-red-900 text-[14px] font-medium">
              {errors.fullName}
            </p>
          )}
          </div>

          <div className="flex flex-col gap-1">
            <InputWithLabel
              htmlFor="PAN"
              label="PAN Number"
              type="text"
              placeholder="123456789"
              name="PAN"
              value={formData.PAN}
              onChange={handleInputChange}
            />
            {errors.PAN && (
              <p className="text-red-900 text-[14px] font-medium">
                {errors.PAN}
              </p>
            )}

          </div>
          

          <div className="flex flex-col gap-2 ">
            <p className="text-gray-500 font-medium text-sm">
              Select label for effective delivery
            </p>

            <div className="flex items-center gap-4">
              <div
                className={`cursor-pointer flex flex-col items-center gap-1 text-gray-500 font-medium text-[13px] border border-gray-300 rounded-[4px] px-6 py-2 ${
                  selectedLabel === "Home" ? "bg-blue text-white" : ""
                }`}
                onClick={() => handleLabelSelection("Home")}
              >
                <FaHome className="text-xl" />
                Home
              </div>

              <div
                className={`cursor-pointer flex flex-col items-center gap-1 text-gray-500 font-medium text-[13px] border border-gray-300 rounded-[4px] px-6 py-2 ${
                  selectedLabel === "Office" ? "bg-blue text-white" : ""
                }`}
                onClick={() => handleLabelSelection("Office")}
              >
                <BiBuilding className="text-xl" />
                Office
              </div>
            </div>

          </div>

          <div className="grid grid-cols-2 gap-3 md:gap-6 mt-2">
            <button
              className="bg-blue text-center px-4 text-white font-medium rounded-md py-2"
              onClick={handleSubmit}
            >
              Save Changes
            </button>

            <button className="border border-gray-300 text-center px-4 text-gray-500 font-medium rounded-md py-2"
            onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAddress;
