import React, { useState } from "react";
import { HiChevronDown, HiOutlineSearch } from "react-icons/hi";
import AllVendors from "./AcceptedVendors/AcceptedVendors";
import DeletedVendors from "./RejectedVendorsList/DeletedVendors";
import VendorRequests from "./VendorRequestsList/VendorRequests";

const ManageProducts = () => {
  const [productStatus, setProductStatus] = useState("Vendor Requests List");
  const [searchQuery, setSearchQuery] = useState("");
  const [showMobileDropdown, setShowMobileDropdown] = useState(false);

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Function to handle selecting an option in the mobile dropdown
  const handleMobileOptionSelect = (option) => {
    setProductStatus(option);
    setShowMobileDropdown(false);
  };

  return (
    <div className={`px-8 py-6 flex flex-col gap-4`}>
      <div className="flex items-stretch justify-between lg:items-center">
        {/* Mobile dropdown for product status */}
        <div className="xl:hidden relative">
          <button
            className="flex items-center gap-1.5 text-blue font-bold text-[16px] bg-white border-2 border-gray-300 rounded-md px-2 py-2"
            onClick={() => setShowMobileDropdown(!showMobileDropdown)}
          >
            <p>{productStatus}</p>
            <HiChevronDown className="text-[20px]" />
          </button>

          {showMobileDropdown && (
            <div className="absolute z-30 mt-2 left-2 w-48 bg-white border border-gray-300 rounded-md">
              {[
                "Requestsed Vendors",
                "Accepted Vendors",
                "Rejected Vendors",
              ].map((option) => (
                <button
                  key={option}
                  className={`w-full ${
                    productStatus === option ? "bg-gray-200" : ""
                  } cursor-pointer hover:bg-gray-200 p-1.5`}
                  onClick={(e) => {
                    handleMobileOptionSelect(option);
                    e.stopPropagation();
                  }}
                >
                  {option}
                </button>
              ))}
            </div>
          )}
        </div>

        {/* Desktop buttons for product status */}
        <div className="hidden xl:flex">
          <div className="flex items-center gap-6 font-medium text-[14px] text-gray-500">
            <button
              className={`${
                productStatus === "Vendor Requests List"
                  ? "text-blue-900 font-bold border-b-2 pb-0.5 border-blue-900"
                  : ""
              }`}
              onClick={() => setProductStatus("Vendor Requests List")}
            >
              Requested Vendors
            </button>

            <button
              className={`${
                productStatus === "Accepted Vendors"
                  ? "text-blue-900 font-bold border-b-2 pb-0.5 border-blue-900"
                  : ""
              }`}
              onClick={() => setProductStatus("Accepted Vendors")}
            >
              Accepted Vendors
            </button>

            <button
              className={`${
                productStatus === "Deleted Vendors List"
                  ? "text-blue-900 font-bold border-b-2 pb-0.5 border-blue-900"
                  : ""
              }`}
              onClick={() => setProductStatus("Deleted Vendors List")}
            >
              Rejected Vendors
            </button>
          </div>
        </div>

        <div className="flex gap-4">
          <div className="px-4 py-2 flex items-center gap-3 bg-white border border-gray-300 rounded-md cursor-pointer md:cursor-pointer">
            <HiOutlineSearch className="text-gray-500 text-[20px]" />

            <input
              type="text"
              placeholder="Search"
              className="hidden bg-white outline-none font-medium text-[14px] placeholder:text-[13px] w-[120px] placeholder:text-gray-400 sm:block"
              value={searchQuery}
              onChange={handleSearchInputChange}
            />
          </div>
        </div>
      </div>

      {productStatus === "Vendor Requests List" ? (
        <VendorRequests searchQuery={searchQuery} />
      ) : productStatus === "Accepted Vendors" ? (
        <AllVendors searchQuery={searchQuery} />
      ) : (
        <DeletedVendors searchQuery={searchQuery} />
      )}
    </div>
  );
};

export default ManageProducts;
