import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import { FaDesktop, FaMobile, FaTablet } from 'react-icons/fa';

const RevenueGoal = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    const dataDoughnut = {
      labels: ['Desktop', 'Mobile', 'Tablet'], // Labels without icons
      datasets: [
        {
          label: "My First Dataset",
          data: [300, 50, 100],
          backgroundColor: [
            "rgb(133, 105, 241)",
            "rgb(164, 101, 241)",
            "rgb(101, 143, 241)",
          ],
          hoverOffset: 4,
        },
      ],
    };

    const configDoughnut = {
      type: "doughnut",
      data: dataDoughnut,
      options: {
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              generateLabels: function (chart) {
                const data = chart.data;
                if (data.labels.length && data.datasets.length) {
                  return data.labels.map((label, i) => {
                    const dataset = data.datasets[0];
                    const value = dataset.data[i];
                    const color = dataset.backgroundColor[i];

                    // Customize the legend item structure here
                    return {
                      text: label,
                      fillStyle: color,
                      hidden: isNaN(value) || dataset.hidden,
                      index: i,
                      datasetIndex: 0,
                    };
                  });
                }
                return [];
              },
            },
          },
        },
      },
    };

    const doughnutChart = new Chart(chartRef.current, configDoughnut);

    // Cleanup the chart when the component is unmounted
    return () => {
      doughnutChart.destroy();
    };
  }, []); // Empty dependency array ensures the effect runs once on mount

  return (
    <div className="shadow-lg w-[calc(100vw_-_60px)] bg-white md:w-auto rounded-lg overflow-hidden">
      <div className="py-2 px-4 bg-gray-300 text-center">User Device</div>
      <canvas ref={chartRef} className="p-2 md:p-4 lg:p-6 xl:p-8" id="chartDoughnut" style={{ width: '100%', height: 'auto' }}></canvas>
    </div>
  );
};

export default RevenueGoal;
