import axios from "axios";
import { atom, useAtom } from "jotai";
import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ReactComponent as BackArrowSVG } from "../../../../assets/svg/arrow-left-circle.svg";
import { formDataAtom, isAddPartSuccessAtom } from "./AddPart";
import { useEffect } from "react";
export const isAddPartFormSubmittedAtom = atom(false);

const AddPartSummary = () => {
  const [formData,setFormData] = useAtom(formDataAtom);
  const [, setIsAddPartSuccess] = useAtom(isAddPartSuccessAtom);
  const [, setIsAddPartFormSubmitted] = useAtom(isAddPartFormSubmittedAtom);
  const url = process.env.REACT_APP_BASE_URL + "admin/addpart/";
  const handleSubmit = async () => {
    try {
      // Add Status as Approved
      formData.status = "Approved";
      const response = await axios.post(`${url}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        body: JSON.stringify(formData),
      });
      if (response.status === 201) {
        toast.success("Part added successfully");
        setIsAddPartFormSubmitted(true);
      }
    } catch (error) {
      console.error("Error sending data:", error);
      toast.error("Error adding part");
    }
  };

  console.log(formData);

  const IMAGE_URL = process.env.REACT_APP_BASE_URL + "/";


  return (
    <div className="md:px-8 md:py-6 flex gap-4 sm:gap-8">
      <ToastContainer />
      <div className="flex-1 bg-white py-4 flex flex-col gap-6">
        <div className="flex items-center justify-between px-6 sm:px-8">
          <div className="text-blue font-bold flex gap-4 items-center">
            <button onClick={() => setIsAddPartSuccess(false)}>
              <BackArrowSVG className="w-5 h-5" />
            </button>

            <p>Add Product Summary</p>
          </div>
        </div>

        <div className="flex flex-col gap-8 md:flex-row md:px-8 lg:px-[4.2rem] md:gap-16">
          <div className="md:w-[60%]">
            <div className="flex flex-col gap-3 px-6 sm:px-8 md:px-0">
              <h1 className="uppercase text-[15px] text-gray-800 font-bold">
                Vehicle Info
              </h1>

              <div className="flex flex-col gap-4">
                <div className="flex flex-col sm:flex-row sm:justify-between">
                  <h2 className="text-[14px] text-gray-900 font-bold md:flex-1">
                    Vehicle Company:
                  </h2>
                  <p className="text-[14px] text-gray-700 font-medium md:flex-1">
                    {formData.vehicleCompany}
                  </p>
                </div>

                <div className="flex flex-col sm:flex-row sm:justify-between">
                  <h2 className="text-[14px] text-gray-900 font-bold md:flex-1">
                    Sub-Category:
                  </h2>
                  <p className="text-[14px] text-gray-700 font-medium md:flex-1">
                    {formData.subCategory}
                  </p>
                </div>

                <div className="flex flex-col sm:flex-row sm:justify-between">
                  <h2 className="text-[14px] text-gray-900 font-bold md:flex-1">
                    Brand:
                  </h2>
                  <p className="text-[14px] text-gray-700 font-medium md:flex-1">
                    {formData.brand}
                  </p>
                </div>

                <div className="flex flex-col sm:flex-row sm:justify-between">
                  <h2 className="text-[14px] text-gray-900 font-bold md:flex-1">
                    Vehicle Company:
                  </h2>
                  <p className="text-[14px] text-gray-700 font-medium md:flex-1">
                    {formData.vehicleCompany}
                  </p>
                </div>

                <div className="flex flex-col sm:flex-row sm:justify-between">
                  <h2 className="text-[14px] text-gray-900 font-bold md:flex-1">
                    Model:
                  </h2>
                  <p className="text-[14px] text-gray-700 font-medium md:flex-1">
                    {formData.model}
                  </p>
                </div>

                <div className="flex flex-col sm:flex-row sm:justify-between">
                  <h2 className="text-[14px] text-gray-900 font-bold md:flex-1">
                    Year Of Manufacturing:
                  </h2>
                  <p className="text-[14px] text-gray-700 font-medium md:flex-1">
                    {formData.manufactureYear}
                  </p>
                </div>

                <div className="flex flex-col sm:flex-row sm:justify-between">
                  <h2 className="text-[14px] text-gray-900 font-bold md:flex-1">
                    Part Categories:
                  </h2>
                  <p className="text-[14px] text-gray-700 font-medium md:flex-1">
                    {formData.partCategories}
                  </p>
                </div>

                <div className="flex flex-col sm:flex-row sm:justify-between">
                  <h2 className="text-[14px] text-gray-900 font-bold md:flex-1">
                    Part Sub-Categories:
                  </h2>
                  <p className="text-[14px] text-gray-700 font-medium md:flex-1">
                    {formData.partSubCategories}
                  </p>
                </div>

                <div className="flex flex-col sm:flex-row sm:justify-between">
                  <h2 className="text-[14px] text-gray-900 font-bold md:flex-1">
                    Part Number:
                  </h2>
                  <p className="text-[14px] text-gray-700 font-medium md:flex-1">
  {formData.partNumbers.join(", ")}
</p>
                </div>

                <div className="flex flex-col sm:flex-row sm:justify-between">
                  <h2 className="text-[14px] text-gray-900 font-bold md:flex-1">
                    Part Name:
                  </h2>
                  <p className="text-[14px] text-gray-700 font-medium md:flex-1">
                    {formData.partName}
                  </p>
                </div>
              </div>
            </div>

            <div className="h-[1px] w-full bg-gray-400 my-8"></div>

            <div className="flex flex-col gap-3 px-6 sm:px-8 md:px-0">
              <h1 className="uppercase text-[15px] text-gray-800 font-bold">
                Price, Attributes & Description
              </h1>

              <div className="flex flex-col gap-4">
                {formData.otherAttributes.map((attribute, index) => (
                  <div
                    key={index}
                    className="flex flex-col sm:flex-row sm:justify-between"
                  >
                    <h2 className="text-[14px] text-gray-900 font-bold md:flex-1">{`Attribute ${
                      index + 1
                    }:`}</h2>
                    <p className="text-[14px] text-gray-700 font-medium md:flex-1">
                      {attribute.attributeValue || "N/A"}
                    </p>
                  </div>
                ))}

                <div className="flex flex-col sm:flex-row sm:justify-between">
                  <h2 className="text-[14px] text-gray-900 font-bold md:flex-1">
                    Description:
                  </h2>
                  <p className="text-[14px] text-gray-700 font-medium md:flex-1">
                    {formData.description}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="px-6 sm:px-8 flex flex-col items-center gap-2 md:px-0 md:items-end md:w-[40%]">
            <div className="w-full border-2 border-gray-300 flex items-center justify-center md:items-end">
              {/* <img src={URL.createObjectURL(formData.image)} alt="" /> */}
              <img
                src={
                  formData.image
                    ? `${URL.createObjectURL(formData.image)}`
                    : "https://via.placeholder.com/250"
                }
                alt=""
              />
            </div>

            <div className="flex flex-col items-center md:items-end">
              <h1 className="text-gray-900 text-[15px] font-bold">
                Availability
              </h1>
              <p
                className={`text-[14px] font-medium ${
                  formData.availability === "In Stock"
                    ? "text-green"
                    : "text-red"
                }`}
              >
                {" "}
                ● {formData.availability}
              </p>
            </div>
          </div>
        </div>

        <div className="flex justify-end border-t border-gray-200 mt-6">
          <button
            type="button"
            className="mx-6 mt-4 bg-blue px-8 py-1.5 rounded-md text-[14px] text-white font-semibold"
            onClick={handleSubmit}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddPartSummary;
