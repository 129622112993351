import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { BiChevronDown, BiChevronLeft, BiChevronUp } from "react-icons/bi";
import { BsBank2 } from "react-icons/bs";
import { FaTruck, FaTruckPickup } from "react-icons/fa";
import { ImLocation } from "react-icons/im";
import { IoPersonSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import {
  isVendorEditAtom,
  vendorToEditAtom,
} from "./AcceptedVendors/AcceptedVendorsTableRow";

const ViewVendor = () => {
  const [data, setData] = useState({
    avatarSrc: null,
    name: "",
    email: "",
    logoSrc: null,
    storeName: "",
    phone: "",
    label: "",
    website: "",
    description: "",
    storeAddress: "",
    province: "",
    area: "",
    district: "",
    additionalPhone: "",
    addressDescription: "",
    deliverystoreAddress: "",
    deliveryprovince: "",
    deliveryarea: "",
    deliverydistrict: "",
    deliveryadditionalPhone: "",
    deliveryaddressDescription: "",
    pickupstoreAddress: "",
    pickupprovince: "",
    pickuparea: "",
    pickupdistrict: "",
    pickupadditionalPhone: "",
    pickupaddressDescription: "",
    deliveryaddressownerName: "",
    registrationNo: "",
    businessDoc: null,
    bankPassbook: null,
  });

  const [showPickupAddress, setShowPickupAddress] = useState(false);
  const [showDeliveryAddress, setShowDeliveryAddress] = useState(false);

  const [vendorData, setVendorData] = useAtom(vendorToEditAtom);
  const [isVendorEdit, setIsVendorEdit] = useAtom(isVendorEditAtom);

  useEffect(() => {
    const fetchData = () => {
      if (vendorData.length > 0) {
        // Transform the API data into the desired format
        const transformedData = {
          avatarSrc: vendorData[0]?.sellerprofile?.storelogo || null,
          name: vendorData[0]?.sellerprofile?.storename || "",
          email: vendorData[0]?.sellerprofile?.storeemail || "",
          logoSrc: vendorData[0]?.sellerprofile?.storelogo || null,
          storeName: vendorData[0]?.sellerprofile?.storename || "",
          phone: vendorData[0]?.sellerprofile?.storephone || "",
          label: vendorData[0]?.sellerprofile?.storetype || "",
          website: vendorData[0]?.sellerprofile?.storeemail || "",
          description: vendorData[0]?.sellerprofile?.storedescription || "",
          storeAddress: vendorData[0]?.selleraddress?.address?.address || "",
          province: vendorData[0]?.selleraddress?.address?.province || "",
          area: vendorData[0]?.selleraddress?.address?.area || "",
          district: vendorData[0]?.selleraddress?.address?.district || "",
          additionalPhone:
            vendorData[0]?.selleraddress?.address?.additionalphone || "",
          addressDescription:
            vendorData[0]?.selleraddress?.address?.descriptiveaddress || "",
          deliverystoreAddress:
            vendorData[0]?.selleraddress?.deliveryaddress?.address || "",
          deliveryprovince:
            vendorData[0]?.selleraddress?.deliveryaddress?.province || "",
          deliveryarea:
            vendorData[0]?.selleraddress?.deliveryaddress?.area || "",
          deliverydistrict:
            vendorData[0]?.selleraddress?.deliveryaddress?.district || "",
          deliveryadditionalPhone:
            vendorData[0]?.selleraddress?.deliveryaddress?.additionalphone ||
            "",
          deliveryaddressDescription:
            vendorData[0]?.selleraddress?.deliveryaddress?.descriptiveaddress ||
            "",
          pickupstoreAddress:
            vendorData[0]?.selleraddress?.pickupaddress?.address || "",
          pickupprovince:
            vendorData[0]?.selleraddress?.pickupaddress?.province || "",
          pickuparea: vendorData[0]?.selleraddress?.pickupaddress?.area || "",
          pickupdistrict:
            vendorData[0]?.selleraddress?.pickupaddress?.district || "",
          pickupadditionalPhone:
            vendorData[0]?.selleraddress?.pickupaddress?.additionalphone || "",
          pickupaddressDescription:
            vendorData[0]?.selleraddress?.pickupaddress?.descriptiveaddress ||
            "",
          deliveryaddressownerName: vendorData[0]?.documents?.ownername || "",
          registrationNo: vendorData[0]?.documents?.businessregdnumber || "",
          businessDoc: Array.isArray(vendorData[0]?.documents?.businessdocuments)
            ? vendorData[0]?.documents?.businessdocuments : [] ,
          bankPassbook: vendorData[0]?.documents?.bankdocument || "",
        };

        const imageArray = transformedData.businessDoc.map(doc => doc.image);
        transformedData.businessDoc = imageArray;

        console.log("TRANSFORMED DATA", transformedData);

        setData(transformedData);
      }
    };

    fetchData();
  }, [vendorData]);

  console.log("DATA", data);

  const imageUrl = process.env.REACT_APP_BASE;

  const navigate = useNavigate();

  const sliderSettings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: (
      <div>
        <button className="relative -top-6 z-20 right-1 bg-gray-200 rounded-full w-8 h-8 flex items-center justify-center text-gray-700 text-2xl focus:outline-none">
          <span className="sr-only">Next</span>
          <span className="font-bold">{" > "}</span>
        </button>
      </div>
    ),
    prevArrow: (
      <div>
        <button className="relative -top-6 z-20 -left-2 bg-gray-200 rounded-full w-8 h-8 flex items-center justify-center text-gray-700 text-2xl focus:outline-none">
          <span className="sr-only">Previous</span>
          <span className="font-bold">{" < "}</span>
        </button>
      </div>
    ),
  };

  return (
    <div className="relative mx-8 my-6 px-5 py-5 pb-7 bg-white flex flex-col gap-4">
      <div>
        <button
          className="bg-blue text-white rounded-md font-medium text-[15px] pl-2 pr-4 py-1.5 flex items-center gap-1"
          onClick={() => {
            setVendorData([]);
            setIsVendorEdit(false);
            navigate("/admin/vendors-management");
          }}
        >
          <BiChevronLeft className="text-2xl" />
          Back
        </button>
      </div>

      <div className="w-full">
        <div aria-label="header" className="flex space-x-4 items-center p-4">
          <div
            aria-label="avatar"
            className="flex mr-auto items-center space-x-4"
          >
            <img
              src={`${imageUrl}${data.avatarSrc}`}
              alt={`avatar ${data.name}`}
              className="w-12 h-12 shrink-0 rounded-full"
            />
            <div className="flex flex-col">
              <div className="font-medium relative text-xl leading-tight text-gray-900">
                <span className="flex">
                  <span className="truncate relative pr-8">{data.name}</span>
                </span>
              </div>
              <p className="font-normal text-base leading-tight text-gray-500 truncate">
                {data.email}
              </p>
            </div>
          </div>
        </div>
        <div aria-label="navigation" className="py-1"></div>
      </div>

      <div className="px-3">
        <div className="flex items-center space-x-2 font-semibold text-blue leading-8">
          <IoPersonSharp />
          <span className="tracking-wide">Profile Information</span>
        </div>

        <div className="flex gap-4">

          <div className="text-gray-700">
            <div className="grid md:grid-cols-3 gap-4 text-sm">
              <div className="grid">
                <div className="px-4 py-1 font-semibold">Store Name</div>
                <div className="px-4 py-1">{data.storeName}</div>
              </div>

              <div className="grid">
                <div className="px-4 py-1 font-semibold">Phone No:</div>
                <div className="px-4 py-1">{data.phone}</div>
              </div>
              <div className="grid">
                <div className="px-4 py-1 font-semibold">Email</div>
                <div className="px-4 py-1">{data.email}</div>
              </div>
              <div className="grid">
                <div className="px-4 py-1 font-semibold">Label</div>
                <div className="px-4 py-1">{data.label}</div>
              </div>

              <div className="grid">
                <div className="px-4 py-1 font-semibold">WebSite</div>
                <div className="px-4 py-1">{data.website}</div>
              </div>

              <div className="grid col-span-3">
                <div className="px-4 py-1 font-semibold">Description</div>
                <div className="px-4 py-1">{data.description}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-slate-400 px-3 rounded-sm">
        <div className="flex items-center space-x-2 font-semibold text-blue leading-8">
          <ImLocation />
          <span className="tracking-wide">Address</span>
        </div>
        <div className="text-gray-700">
          <div className="grid md:grid-cols-3 gap-4 text-sm">
            <div className="grid">
              <div className="px-4 py-1 font-semibold">Store Address</div>
              <div className="px-4 py-1">{data.storeAddress}</div>
            </div>

            <div className="grid">
              <div className="px-4 py-1 font-semibold">Province</div>
              <div className="px-4 py-1">{data.province}</div>
            </div>
            <div className="grid">
              <div className="px-4 py-1 font-semibold">Area</div>
              <div className="px-4 py-1">{data.area}</div>
            </div>
            <div className="grid">
              <div className="px-4 py-1 font-semibold">District</div>
              <div className="px-4 py-1">{data.district}</div>
            </div>
            <div className="grid">
              <div className="px-4 py-1 font-semibold">
                Additional Phone No.
              </div>
              <div className="px-4 py-1">{data.additionalPhone}</div>
            </div>
            <div className="grid col-span-3">
              <div className="px-4 py-1 font-semibold">
                {" "}
                Address Description
              </div>
              <div className="px-4 py-1">{data.addressDescription}</div>
            </div>
          </div>
        </div>
      </div>

      {/* Pickup Address */}
      <div className="bg-slate-400 px-3 rounded-sm">
        <div
          className="flex cursor-pointer items-center space-x-2 font-semibold text-blue leading-8"
          onClick={() => setShowPickupAddress(!showPickupAddress)}
        >
          <FaTruckPickup className="text-xl" />
          <span className="tracking-wide">Pickup Address</span>
          <button>
            {showPickupAddress ? (
              <BiChevronUp className="text-xl" />
            ) : (
              <BiChevronDown className="text-xl" />
            )}
          </button>
        </div>

        {showPickupAddress && (
          <div className="text-gray-700">
            <div className="grid md:grid-cols-3 gap-4 text-sm">
              <div className="grid">
                <div className="px-4 py-1 font-semibold">Store Address</div>
                <div className="px-4 py-1">{data.pickupstoreAddress}</div>
              </div>

              <div className="grid">
                <div className="px-4 py-1 font-semibold">Province</div>
                <div className="px-4 py-1">{data.pickupprovince}</div>
              </div>
              <div className="grid">
                <div className="px-4 py-1 font-semibold">Area</div>
                <div className="px-4 py-1">{data.pickuparea}</div>
              </div>
              <div className="grid">
                <div className="px-4 py-1 font-semibold">District</div>
                <div className="px-4 py-1">{data.pickupdistrict}</div>
              </div>
              <div className="grid">
                <div className="px-4 py-1 font-semibold">
                  Additional Phone No.
                </div>
                <div className="px-4 py-1">{data.pickupadditionalPhone}</div>
              </div>
              <div className="grid col-span-3">
                <div className="px-4 py-1 font-semibold">
                  {" "}
                  Address Description
                </div>
                <div className="px-4 py-1">{data.pickupaddressDescription}</div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Delivery Address */}
      <div className="bg-slate-400 px-3 rounded-sm">
        <div
          className="flex cursor-pointer items-center space-x-2 font-semibold text-blue leading-8"
          onClick={() => setShowDeliveryAddress(!showDeliveryAddress)}
        >
          <FaTruck />
          <span className="tracking-wide">Delivery Address</span>
          <button>
            {showDeliveryAddress ? (
              <BiChevronUp className="text-xl" />
            ) : (
              <BiChevronDown className="text-xl" />
            )}
          </button>
        </div>
        {showDeliveryAddress && (
          <div className="text-gray-700">
            <div className="grid md:grid-cols-3 gap-4 text-sm">
              <div className="grid">
                <div className="px-4 py-1 font-semibold">Store Address</div>
                <div className="px-4 py-1">{data.deliverystoreAddress}</div>
              </div>

              <div className="grid">
                <div className="px-4 py-1 font-semibold">Province</div>
                <div className="px-4 py-1">{data.deliveryprovince}</div>
              </div>
              <div className="grid">
                <div className="px-4 py-1 font-semibold">Area</div>
                <div className="px-4 py-1">{data.deliveryarea}</div>
              </div>
              <div className="grid">
                <div className="px-4 py-1 font-semibold">District</div>
                <div className="px-4 py-1">{data.deliverydistrict}</div>
              </div>
              <div className="grid">
                <div className="px-4 py-1 font-semibold">
                  Additional Phone No.
                </div>
                <div className="px-4 py-1">{data.deliveryadditionalPhone}</div>
              </div>
              <div className="grid col-span-3">
                <div className="px-4 py-1 font-semibold">
                  {" "}
                  Address Description
                </div>
                <div className="px-4 py-1">
                  {data.deliveryaddressDescription}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="px-3 flex flex-col gap-2">
        <div className="flex items-center space-x-2 font-semibold text-blue leading-8">
          <BsBank2 />
          <span className="tracking-wide">Verify Id & Bank</span>
        </div>
        <div className="text-gray-700">
          <div className="grid md:grid-cols-2 items-start gap-4 text-sm">
            <div className="md:col-span-2">
              <div className="text-[15px] font-medium">
                Business Registration No:
              </div>
              <div className="">{data.registrationNo}</div>
            </div>

            <div className="">
              <div className="px-4 py-1 font-semibold">Business Doc</div>
              <div className="px-4 py-1">
                {Array.isArray(data.businessDoc) &&
                data.businessDoc.length > 1 ? (
                  <div className="w-[80%]">
                    <Slider {...sliderSettings}>
                      {data.businessDoc.map((image, index) => (
                        <img
                          key={index}
                          src={`${imageUrl}${image}`}
                          alt={`Business Doc ${index}`}
                          className="w-full max-h-[420px] md:max-h-max object-fill"
                        />
                      ))}
                    </Slider>
                  </div>
                ) : (
                  <img
                    src={
                      data.businessDoc
                        ? `${imageUrl}${data.businessDoc}`
                        : "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/832px-No-Image-Placeholder.svg.png"
                    }
                    alt="Business Document"
                    className="w-full max-w-[350px] max-h-[420px] md:max-h-max object-fill"
                  />
                )}
              </div>
            </div>

            <div className="w-full flex flex-col gap-1.5">
              <div className="text-[15px] font-medium">
                Bank Passbook/Cheque:
              </div>
              <div className="">
                <img
                  // src={`${imageUrl}${data.bankPassbook}`}
                  src={
                    data.bankPassbook
                      ? `${imageUrl}${data.bankPassbook}`
                      : "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png"
                  }
                  alt="Company Logo"
                  className="w-full max-w-[350px] max-h-[420px] object-fill"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewVendor;
