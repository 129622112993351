import React, { useState, useEffect } from "react";
import { IoClose } from "react-icons/io5";
import ImageInput from "../../../common/ImageInput";
import InputWithLabel from "../../../common/InputWithLabel";
import DictionaryCard from "../../common/DictionaryCard";
import axios from "axios";
import Brand from "../Brand/Brand";

const VechileCompany = () => {
    const [vehicleData, setVehicleData] = useState([]);
    const [isBrand , setIsBrand] = useState(false);
    const [id , setId] = useState(null);

    const fetchData = async () => {
        try {
            const url = process.env.REACT_APP_BASE_URL + "admin/get-company/"
    
            const response = await axios.get(`${url}`);
            const data = response.data;
            console.log("Data from API:", data);
            setVehicleData(data);
        } catch (error) {
            console.error("Error fetching data from API:", error);
        }
    };

    useEffect(() => {

        // Fetch data from API and set it in vehicleData state
        fetchData();
    }, []);

  



    const handleCard = (vehicle) => {
        console.log("Card clicked", id);
        setIsBrand(true);
        setId(vehicle);
        console.log(isBrand);
        
    };
    const IMAGE_URL = process.env.REACT_APP_BASE_URL + "uploads/";
    return (
        <>
            {isBrand ? <Brand id={id} /> : (
                <div>
                    <div className="bg-white rounded-md p-4 flex flex-col gap-6">
                        <div className="grid grid-cols-4 gap-6">
                            {vehicleData.map((vehicle) => (
                                <DictionaryCard
                                    key={vehicle}
                                    imageSrc= {
                                        vehicle.image
                                            ? `${IMAGE_URL}${vehicle}.jpg`
                                            : "https://via.placeholder.com/250"
                                        }
                                    name={vehicle}
                                    handleCardClick={() => handleCard(vehicle)}
                                />
                            ))}
                        </div>
                    </div>

           
                </div>
            )}

        </>
    );
};

export default VechileCompany;
