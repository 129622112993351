import React, { useState } from "react";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa6";
import FooterImage from "../../../assets/images/caleb-white-XGJBSkoqX_I-unsplash-removebg-preview 1.png";
import OsisLogoWhite from "../../../assets/svg/osis-white.svg";
import khalti from "../../../assets/images/3 copy.png";
import esewa from "../../../assets/images/2.png";
import fonepay from "../../../assets/images/fonepay.png";

const Footer = ({ showNewsletter }) => {
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setIsValidEmail(validateEmail(e.target.value));
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const handleSubmit = () => {
    if (validateEmail(email)) {
      // Handle submission logic here
      console.log("Email is valid:", email);
    } else {
      // Handle invalid email
      setIsValidEmail(false);
    }
  };

  return (
    <div
      className={`relative bg-gray-900 ${
        showNewsletter ? "pt-0" : "pt-10"
      }`}
    >
      {showNewsletter && (
        <div className="bg-[#444bd3] mx-6 w-[calc(100%_-_48px)] md:w-[calc(100%_-_80px)] lg:w-[calc(100%_-_160px)] md:mx-10 lg:mx-20 z-20 rounded-lg px-4 sm:px-8 py-6 flex flex-col lg:flex-row justify-between items-center gap-6 lg:gap-10 relative -top-20">
          <div className="flex flex-1 flex-col gap-4">
            <h1 className="text-xl md:text-2xl font-bold text-white text-center lg:text-start">
              Sign up for our newsletter
            </h1>
            <p className="text-[15px] text-gray-100 hidden lg:block">
              Lorem ipsum dolor sit amet consectetur adipisicing elit...
            </p>
          </div>

          <div className="w-full flex-1 flex flex-col gap-3">
            <div className="flex flex-col lg:flex-row items-center gap-4">
              <input
                type="text"
                placeholder="Enter email address"
                value={email}
                onChange={handleEmailChange}
                className={`w-full lg:w-[250px] outline-none bg-white rounded-xl px-4 py-3 text-[15px] ${
                  isValidEmail ? "" : "border-red-500"
                }`}
              />
              <button
                onClick={handleSubmit}
                className="w-full lg:w-fit bg-white whitespace-nowrap font-bold text-blue px-6 py-3 rounded-xl"
              >
                Notify Me
              </button>
            </div>
            {!isValidEmail && (
              <p className="text-sm text-red-500">
                Please enter a valid email address.
              </p>
            )}
            <p className="text-sm text-gray-100 hidden lg:block">
              We care about the protection of your data...{" "}
              <span className="block">
                Read our{" "}
                <span className="text-blue cursor-pointer hover:underline">
                  Privacy Policy
                </span>
              </span>
            </p>
          </div>
        </div>
      )}

      <footer className="relative px-6 md:px-10 lg:px-20 overflow-hidden">
        <div className="flex flex-col lg:flex-row gap-8 lg:gap-6 justify-between border-b border-gray-400 pb-14 xl:pr-40">
          <div className="flex flex-col gap-6 lg:max-w-[30%]">
            <div className="flex items-center gap-2 text-xl text-white font-bold">
              <img src={OsisLogoWhite} alt="" />
              OSIS
            </div>

            <p className="text-sm text-white">
              Making the world a better place through constructing elegant
              hierarchies
            </p>

            <div className="flex items-center gap-6">
              <button>
                <FaFacebook className="text-gray-300 text-[25px]" />
              </button>

              <button>
                <FaTwitter className="text-gray-300 text-[25px]" />
              </button>

              <button>
                <FaInstagram className="text-gray-300 text-[25px]" />
              </button>
            </div>
          </div>

          <div className="flex flex-col gap-5 pr-10  item-center lg:max-w-[30%]">
            {/* <div className="flex items-center gap-2 text-xl text-white font-bold">
              <img src={OsisLogoWhite} alt="" />
              OSIS
            </div> */}

            <p className="text-md pt-6  text-white">
             We Accept Payment:
            </p>

            <div className="flex items-center gap-6">
            <button>
                <img
                  src={khalti}
                  alt="Khalti"
                  className="text-gray-300 h-[60px] w-[50px]"
                />
              </button>

              <button>
                <img
                  src={esewa}
                  alt="e-sewa"
                  className="text-gray-300 h-[30px] w-[30px]"
                />
              </button>

              <button>
                <img
                  src={fonepay}
                  alt="Fonpay"
                  className="text-gray-300 h-[30px] w-[80px]"
                />
              </button>
            </div>
          </div>
        </div>

        <div className="pt-6 pb-12">
          <p className="text-center text-gray-400">
            © 2021 OSIS — All rights reserved.
          </p>
        </div>

        <img
          src={FooterImage}
          alt=""
          className="z-10 absolute -right-20 -bottom-4 hidden xl:block"
        />
      </footer>
    </div>
  );
};

export default Footer;
