// import { atom, useAtom } from "jotai";
// import React, { useState } from "react";
// import { HiOutlineSearch, HiOutlineDownload } from "react-icons/hi";
// import Image from "../../../../assets/images/Layer 2.png";

// // import AllProducts from "./AllCredit/AllCredit";
// // import DeletedProducts from "./DeletedProducts/DeletedProducts";
// import AllCredit from "./AllCredit/AllCredit";

// export const isManageActiveAtom = atom(true);
// export const isSellerProductViewedAtom = atom(false);
// export const viewedSellerProductAtom = atom(null);

// const Credit = () => {
//   const [productStatus, setProductStatus] = useState("All Products");
//   const [searchQuery, setSearchQuery] = useState("");
//   const [showMobileDropdown, setShowMobileDropdown] = useState(false);
//   const [, setIsManageActive] = useAtom(isManageActiveAtom);

//   const handleSearchInputChange = (event) => {
//     if (event && event.target && event.target.value !== undefined) {
//       console.log(event.target.value);
//       setSearchQuery(event.target.value);
//     }
//   };

//   // Function to handle selecting an option in the mobile dropdown
//   const handleMobileOptionSelect = (option) => {
//     setProductStatus(option);
//     setShowMobileDropdown(false);
//   };

//   return (
//     <div className={`px-8 py-6 flex flex-col gap-4`}>
//       <div className="flex items-stretch justify-between lg:items-center">
//         {/* Mobile dropdown for product status */}
//         <div className="bg-gray-100 p-4 rounded-lg shadow-md flex justify-between items-center gap-8">
//           <div>
//             <p className="text-sm text-gray-500">Total Transaction Amount</p>
//             <h1 className="text-3xl font-bold text-gray-800">Rs 12,500,0</h1>
//           </div>

//           <img src={Image} alt="dollar" className="w-[30px]"></img>
//         </div>

//         <div className="flex gap-4">
//           <div className="px-4 py-2 flex items-center gap-3 bg-white border border-gray-300 rounded-md cursor-pointer md:cursor-pointer">
//             <HiOutlineSearch className="text-gray-500 text-[20px]" />

//             <input
//               type="text"
//               placeholder="Search"
//               className="hidden bg-white outline-none font-medium text-[14px] placeholder:text-[13px] w-[120px] placeholder:text-gray-400 md:block"
//               value={searchQuery}
//               onChange={handleSearchInputChange}
//             />
//           </div>
//           <button className="bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center">
//             <HiOutlineDownload className="mr-2" />
//             Export
//           </button>
//         </div>
//       </div>

//       {/* {productStatus === "All Products" ? (
//         <AllProducts searchQuery={searchQuery} />
//       ) : (
//         <DeletedProducts searchQuery={searchQuery} />
//       )} */}
//       <AllCredit searchQuery={searchQuery} />
//     </div>
//   );
// };

// export default Credit;

import axios from "axios";
import { atom, useAtom } from "jotai";
import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from "react";
import { HiOutlineSearch } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify"; // Import ToastContainer and toast from react-toastify
import "react-toastify/dist/ReactToastify.css"; // Import CSS for react-toastify
import Image from "../../../../assets/images/Layer 2.png";
import AllCredit from "./AllCredit/AllCredit";

export const isManageActiveAtom = atom(true);
export const isSellerProductViewedAtom = atom(false);
export const viewedSellerProductAtom = atom(null);

const Credit = () => {
  const navigate = useNavigate();
  const [productStatus, setProductStatus] = useState("All Products");
  const [searchQuery, setSearchQuery] = useState("");
  const [showMobileDropdown, setShowMobileDropdown] = useState(false);
  const [, setIsManageActive] = useAtom(isManageActiveAtom);
  const [transactionAmount, setTransactionAmount] = useState(0);

  // Simulated fetch data from backend
  useEffect(() => {
    // Simulated API call to fetch transaction amount
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        // Handle the case where the token is not present (e.g., redirect to login page)
        toast.error("Authentication credentials not provided. Please log in.");
        return;
      }

      // Check if the authentication token has expired (example for JWT, may vary based on implementation)
      const isTokenExpired = () => {
        try {
          const decodedToken = jwtDecode(token);
          const currentTime = Date.now() / 1000; // Convert to seconds
          console.log("decodedToken", decodedToken.exp);
          console.log("currentTime", currentTime);
          console.log("isTokenExpired", decodedToken.exp < currentTime);
          return decodedToken.exp < currentTime;
        } catch (error) {
          return true; // Error decoding token or invalid token, treat as expired
        }
      };

      if (isTokenExpired()) {
        // Handle token expiration (e.g., refresh the token or redirect to login page)
        toast.error("Authentication token has expired. Please log in again.");
        navigate("/login");
        return;
      }
      const url =
        process.env.REACT_APP_BASE_URL + "sellerdashboard/balancedetails/";
      try {
        const response = await axios.get(`${url}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        console.log("RESPONSE", response);
        const apiData = response.data;
        console.log("API DATA", apiData);

        // Calculate total transaction amount
        const totalAmount = apiData.balance;
        setTransactionAmount(totalAmount);
        // setData(transformedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleExportButtonClick = () => {
    // Logic for exporting data
    console.log("Export button clicked!");
  };

  return (
    <div className={`px-8 py-6 flex flex-col gap-4`}>
      <div className="flex items-stretch justify-between lg:items-center">
        <div className="bg-gray-100 p-4 rounded-lg shadow-md flex justify-between items-center gap-8">
          <div>
            <p className="text-sm text-gray-500">Total Balance</p>
            <h1 className="text-3xl font-bold text-gray-800">
              Rs {transactionAmount.toLocaleString()}
            </h1>
          </div>

          <img src={Image} alt="dollar" className="w-[30px]"></img>
        </div>

        <div className="flex gap-4">
          <div className="px-4 py-2 flex items-center gap-3 bg-white border border-gray-300 rounded-md cursor-pointer md:cursor-pointer">
            <HiOutlineSearch className="text-gray-500 text-[20px]" />
            <input
              type="text"
              placeholder="Search"
              className="hidden bg-white outline-none font-medium text-[14px] placeholder:text-[13px] w-[120px] placeholder:text-gray-400 md:block"
              value={searchQuery}
              onChange={handleSearchInputChange}
            />
          </div>
        </div>
      </div>

      <AllCredit searchQuery={searchQuery} />
    </div>
  );
};

export default Credit;
