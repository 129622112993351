import { atom, useAtom } from "jotai";
import React, { useState } from "react";
import { AiOutlineEye } from "react-icons/ai";

export const viewOrderDetailAtom = atom(false);

const ReturnedTableRow = ({ formData }) => {
  //   const [isMobileTableRowOpen, setIsMobileTableRowOpen] = useState(false);
  const [, setViewOrderDetail] = useAtom(viewOrderDetailAtom);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedReason, setSelectedReason] = useState("");
  const imageUrl = process.env.REACT_APP_BASE;

  
  if (formData.status === "Deleted") {
    formData.status = "Cancelled";
  }

  const openModal = (data) => {
    // Extracting cart_items from formData
    console.log("data", data)
    
    const cartItems = data.cart_items.map((item) => item.product);
    setSelectedReason(data.return_status)
    setSelectedProducts(cartItems);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedProducts([]);
    setIsModalOpen(false);
  };

  return (
    <>
          <tr className="w-full border-b-2 border-gray-300 text-gray-700 relative hover:bg-gray-100">
        <td className="text-center">{formData.id}</td>
        <td className="text-center">
          {new Date(formData.date).toLocaleDateString()}
        </td>
        <td className="text-center text-blue-800">{formData.payment_method}</td>
        <td className="text-center">Rs. {formData.total_price}</td>
        <td className="text-center">{formData.total_items}</td>
        <td className="flex justify-center items-center">
          <div
            className={`mt-5 sm:min-w-[150px] px-3 sm:px-6 py-2.5 text-center rounded-full text-white ${
              formData.status === "To be shipped"
                ? "bg-blue-700"
                : formData.status === "Cancelled"
                ? "bg-red"
                : formData.status === "Pending To Pay"
                ? "bg-yellow"
                : "bg-blue"
            }`}
          >
            {formData.status}
          </div>
        </td>
        <td>
          <div className="flex justify-center items-center gap-2">
            <button
              className="px-1.5 border-2 border-gray-300 font-medium rounded-md h-[35px]"
              onClick={() => openModal(formData)}
            >
              <AiOutlineEye className="w-[22px] h-[22px] text-gray-700" />
            </button>
          </div>
        </td>
      </tr>
      {isModalOpen && selectedProducts.length > 0 && (
        <div className=" fixed inset-0 flex items-center justify-center bg-black bg-opacity-30 z-50">
          <div className="bg-white rounded shadow p-4 lg:px-10 lg:py-6 flex flex-col gap-6">
            {selectedProducts.map((product, index) => (
              <div key={index} className="flex items-center gap-6">
                <div>
                  <img
                    src={`${imageUrl}${product.part_id.image}`}
                    alt="Product Image"
                    className="w-[150px] h-[100px] md:w-[200px] md:h-[150px] object-cover"
                  />
                </div>
                <div>
                  <h1 className="text-[17px] font-bold">{product.part_id.partName}</h1>
                  <div className="flex items-center gap-2.5">
                    <p>Brand :</p>
                    <p>{product.part_id.brand}</p>
                  </div>
                </div>
              </div>
            ))}
            <div className="flex justify-center mt-4">
              <button
                className="bg-blue-700 hover:bg-[#FF5C5C] text-white font-bold py-2 px-4 rounded"
                onClick={closeModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </>    
  );
};

export default ReturnedTableRow;
