import { atom, useAtom } from "jotai";
import React from "react";

export const currentStepAtom = atom(1);

const stepContent = {
  1: {
    title: "Address",
  },
  2: {
    title: "Payment",
  },
  // 3: {
  //   title: "Review",
  // },
};

const stepColors = {
  1: "bg-blue",
  2: "bg-green",
  // 3: "bg-red",
};

const StepsIndicator3 = () => {
  const [currentStep] = useAtom(currentStepAtom);

  return (
    <div className="relative z-10 bg-white h-fit min-w-max">
      <ol className="w-full relative text-gray-200 flex gap-[20px] sm:gap-[40px]">
        {[1, 2].map((step) => (
          <li key={step} className="relative flex items-center">
            <div
              className={`w-[80px] flex flex-col gap-2 items-center ${
                step <= currentStep ? "text-gray-900" : "text-gray-500"
              }`}
            >
              <span
                className={`flex items-center justify-center w-8 h-8 ${
                  step <= currentStep ? stepColors[step] : "bg-gray-300"
                } text-white font-semibold rounded-full`}
              >
                {step}
              </span>

              <div>
                <h3
                  className={`font-medium leading-tight text-[15px] ${
                    step <= currentStep ? "text-gray-900" : "text-gray-500"
                  }`}
                >
                  {stepContent[step].title}
                </h3>
              </div>
            </div>

            {step !== 2 && (
              <div className="absolute left-[56px] top-[15px] w-[90px] h-[2px] bg-gray-400"></div>
            )}
          </li>
        ))}
      </ol>
    </div>
  );
};

export default StepsIndicator3;