import { atom, useAtom } from "jotai";
import React from "react";
import "../../../../styles/table.css";
import ProductTableRow from "./FeaturedProductsTableRow";

export const rowsPerPageAtom = atom(10);
export const isProductEditModeAtom = atom(false);
export const productIdAtom = atom(null);
export const dataAtom = atom([]);

const FeaturedProductsTable = ({ centerIndex, endIndex, searchQuery }) => {
  const [data, setData] = useAtom(dataAtom);
  const [, setIsEditButtonClicked] = useAtom(isProductEditModeAtom);
  const [, setProductId] = useAtom(productIdAtom);

  // Filter data based on the search query
  const filteredData = data.filter((item) => {
    // Check if any of the values at any level of nesting match the search query
    const hasMatch = (obj) => {
      for (const key in obj) {
        const value = obj[key];
        if (typeof value === "object") {
          // If the value is an object, recursively check its properties
          if (hasMatch(value)) {
            return true;
          }
        } else if (value !== null && value !== undefined) {
          // Check if the value matches the search query
          if (
            value.toString().toLowerCase().includes(searchQuery.toLowerCase())
          ) {
            return true;
          }
        }
      }
      return false;
    };

    return hasMatch(item);
  });

  return (
    <div className="relative bg-white border-2 border-gray-400 overflow-x-auto whitespace-nowrap table-container">
      <table className="w-full text-white text-center border-spacing-4">
        <div className="w-full  border-gray-400 bg-gray-300 text-gray-900 text-[14px] font-bold px-5 py-3 uppercase sm:hidden">
          <h1>Products</h1>
        </div>

        <thead className="text-gray-900 border-y-2 border-gray-400 uppercase hidden sm:table-header-group">
          <tr>
            <th className="text-center">Seller Name</th>
            <th className="text-center">Part Number</th>
            <th className="text-center">Part Name</th>
            <th className="text-center">Normal Rate</th>
            <th className="text-center">Bulk Rate</th>
            <th className="text-center">Status</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>

        {filteredData.length !== 0 && (
          <tbody className="text-gray-700">
            {filteredData.slice(centerIndex, endIndex).map((item, index) => (
              <ProductTableRow
                key={index}
                formData={item}
                onEditClick={() => {
                  console.log("Edit button clicked");
                  setIsEditButtonClicked(true);
                  setProductId(index);
                }}
                onDeleteClick={() => {
                  console.log("Delete button clicked");

                  // Delete product from the database with productId
                }}
              />
            ))}
          </tbody>
        )}
      </table>

      {filteredData.length === 0 && (
        <div className="w-full">
          <div className="border-b-2 border-gray-300 text-center h-[65px] flex items-center justify-center text-gray-700 relative hover:bg-gray-100">
            <p className="text-gray-700 font-semibold">No data available</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default FeaturedProductsTable;
