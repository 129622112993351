import { atom, useAtom } from "jotai";
import React from "react";
import "../../../../styles/table.css";
import VendorRequestsTableRow from "./VendorRequestsTableRow";

export const rowsPerPageAtom = atom(10);
export const isProductEditModeAtom = atom(false);
export const productIdAtom = atom(null);
export const dataAtom = atom([]);

const VendorRequestsTable = ({
  startIndex,
  endIndex,
  searchQuery,
  fetchData,
}) => {
  const [data, setData] = useAtom(dataAtom);
  const [, setIsEditButtonClicked] = useAtom(isProductEditModeAtom);
  const [, setProductId] = useAtom(productIdAtom);

  // Filter data based on the search query
  const filteredData = data.filter((item) =>
    Object.values(item).some((value) =>
      value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  return (
    <div className="relative bg-white border-2 border-gray-400 overflow-x-auto whitespace-nowrap table-container">
      <table className="w-full text-white text-center border-spacing-4">
        <div className="w-full  border-gray-400 bg-gray-300 text-gray-900 text-[14px] font-bold px-5 py-3 uppercase sm:hidden">
          <h1>Vendor Requests</h1>
        </div>

        <thead className="text-gray-900 border-y-2 border-gray-400 uppercase hidden sm:table-header-group">
          <tr>
            <th className="flex justify-center text-center items-center">
              <input type="checkbox" name="" id="" />
            </th>
            <th className="text-start">Vendor Name</th>
            <th className="text-center">Phone No.</th>
            <th className="text-center">Email</th>
            <th className="text-center">Type</th>
            <th className="text-center">User Type</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>

        {filteredData.length === 0 ? (
          <tbody>
            <tr className="border-b-2 border-gray-300 h-[65px] text-gray-700 relative hover:bg-gray-100">
              <td colSpan="6" className="text-center">
                <p className="text-gray-700 font-semibold">No data available</p>
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody className="text-gray-700">
            {filteredData.slice(startIndex, endIndex).map((item, index) => (
              <VendorRequestsTableRow
                key={index}
                formData={item}
                onAcceptClick={() => {
                  console.log("Accept button clicked");
                }}
                onDeclineClick={() => {
                  console.log("Decline button clicked");
                }}
                onEditClick={() => {
                  console.log("Edit button clicked");
                  setIsEditButtonClicked(true);
                  setProductId(index);
                }}
                onDeleteClick={() => {
                  console.log("Delete button clicked");

                  // Delete product from the database with productId
                }}
                fetchData={fetchData}
              />
            ))}
          </tbody>
        )}
      </table>
    </div>
  );
};

export default VendorRequestsTable;
