import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import ProductDetail from "./ProductDetail";
import SearchPart from "./SearchPart";
import UserHomepage from "./UserHomepage";
import ChooseModel from "./ChooseModel";
import ViewModel from "./ViewModel";
import FilterProduct from "./FilterProduct";
import UserAccount from "./UserAccount";
import PlaceOrder from "./PlaceOrder";
import ShoppingCart from "../../components/User/PlaceOrder/ShoppingCart";
import PartDetails from "./PartDetail";

const UserMainPage = () => {
  return (
    <div className="bg-gray-200 overflow-x-hidden">
      <Routes>
        <Route path="/" element={<Outlet />}>
          <Route index element={<UserHomepage />} />

          <Route path="productDetail/*" element={<ProductDetail />} />

          <Route path="partDetail/*" element={<PartDetails />} /> 

          <Route path="search" element={<SearchPart />} />

          <Route path="chooseModel" element={<ChooseModel />} />

          <Route path="viewModel" element={<ViewModel />} />

          <Route path="filter/:product" element={<FilterProduct />} />

          <Route path="account/*" element={<UserAccount />} />

          <Route path="place-order" element={<PlaceOrder />} />

          <Route path="cart" element={<ShoppingCart />} />
        </Route>
      </Routes>
    </div>
  );
};

export default UserMainPage;
