import React from 'react';

const PopupYesNo = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded shadow-lg">
        <h2 className="text-xl font-bold mb-4">Are you sure you want to ask for the rate?</h2>
        <div className="flex justify-end gap-4">
          <button 
            className="bg-green hover:bg-green-600 text-white py-2 px-4 rounded" 
            onClick={onClose}>
            No
          </button>
          <button 
            className="bg-blue-700 hover:bg-[#FF5C5C] text-white py-2 px-4 rounded" 
            onClick={onConfirm}>
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};

export default PopupYesNo;
