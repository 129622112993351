import React from "react";

const CategoryCard = ({ icon, title, productCount }) => {
  return (
    <div className="bg-white min-h-max cursor-pointer border border-gray-200 flex items-center gap-2 px-5 py-3 vsm:px-3 vsm:py-6">
      <div>
        <img src={icon} alt="" className="max-w-[40px]" />
      </div>

      <div>
        <h1 className="font-bold">{title}</h1>
        <p className="text-sm font-medium text-gray-500">{`${productCount} Product${
          productCount !== 1 ? "s" : ""
        }`}</p>
      </div>
    </div>
  );
};

export default CategoryCard;
