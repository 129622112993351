// ChooseModelWithDropdown.js
import React from "react";
import DropdownWithFilter from "../common/DropdownWithFilter";

const ChooseModelWithDropdown = ({ modelData }) => {
  return (
    <div className="border border-gray-200">
      <div>
        <img
          src={modelData.imageUrl}
          alt=""
          className="h-[200px] object-cover"
        />

        <div className="px-4 py-2 w-full flex flex-col items-center">
          <h1 className="font-bold">{modelData.name}</h1>
          <p className="text-sm text-gray-500">
            {modelData.yearStart} - {modelData.yearEnd}
          </p>
        </div>

        <div className="w-full">
          <DropdownWithFilter
            buttonLabel="Choose your car"
            items={modelData.dropdownItems}
            hasBgColor={true}
          />
        </div>
      </div>
    </div>
  );
};

export default ChooseModelWithDropdown;
