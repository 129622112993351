import React, { useState, useRef } from "react";
import { BiSolidDownload } from "react-icons/bi";

const FileInputWithLabel = ({ label, pathName, onChange }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    // Pass the selected file to the parent component
    onChange(file);
  };

  const handleUploadClick = () => {
    // Trigger click on the hidden input
    fileInputRef.current.click();
  };

  const getFileNameFromPath = (path) => {
    // Check if path ends with a common image file extension
    if (path) {
      return path; // Return the path as it's already an image URL
    } else {
      return ""; // Return empty string if path is not a string or not an image URL
    }
  };

  const imageurl = process.env.REACT_APP_BASE;

  return (
    <div className="flex flex-col items-start gap-1.5 relative">
      <label className="text-[14px] uppercase text-gray-800 font-bold">
        {label}
      </label>

      <div
        className="w-full bg-[#F9FBFF] border border-dashed border-blue rounded-lg py-9 flex items-center justify-center gap-1 text-blue cursor-pointer"
        onClick={handleUploadClick}
        style={{ overflow: "hidden" }}
      >
        <BiSolidDownload className="text-[18px]" />
        {selectedFile ? (
          <p
            className="text-[14px] font-semibold overflow-hidden text-ellipsis whitespace-nowrap"
            style={{
              maxWidth: "calc(100% - 30px)",
            }}
          >
            {selectedFile.name}
          </p>
        ) : pathName ? (
          <img
            src={`${imageurl}${pathName}`}
            alt="Selected Image"
            className="w-full h-auto"
            style={{
              maxWidth: "calc(100% - 30px)",
            }}
          />
        ) : (
          <p className="text-[14px] font-semibold">Upload</p>
        )}
        {/* Hidden file input */}
        <input
          type="file"
          className="hidden"
          ref={fileInputRef}
          onChange={handleFileChange}
        />
      </div>
    </div>
  );
};

export default FileInputWithLabel;
