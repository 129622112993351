import React from "react";
import RequestSection from "./RequestSection";
import SearchSection from "./SearchSection";

const RequestSellerMain = () => {
  return (
    <div className="relative mx-8 my-6 px-5 py-3 bg-white min-h-[calc(100vh-65px-48px)] flex flex-col gap-6 items-center">
      <SearchSection />

      <RequestSection />
    </div>
  );
};

export default RequestSellerMain;
