import { atom, useAtom } from "jotai";
import React from "react";
import { AiOutlineEye } from "react-icons/ai";

export const viewOrderDetailAtom = atom(false);

const HistoryTableRow = ({ formData }) => {
  //   const [isMobileTableRowOpen, setIsMobileTableRowOpen] = useState(false);
  const [, setViewOrderDetail] = useAtom(viewOrderDetailAtom);
  console.log("formData", formData);
  return (
    <>
      
      <tr className="w-full border-b-2 border-gray-300 text-gray-700 relative hover:bg-gray-100">


        <td>
          <div className="flex items-center gap-2 lg:gap-3 pr-6">
          <img
  src={formData.productImage || 'https://path-to-your-default-image.com/default.png'}
  alt=""
  className="w-[90px] h-[50px] sm:w-[110px] sm:h-[70px] object-fill rounded-[4px]"
/>
            <div className="flex flex-col gap-2">
              <p className="font-medium">{formData.productName}</p>
            </div>
          </div>
        </td>

        <td className="text-center">{formData.rating}</td>
        <td className="text-center">{formData.review}</td>

      

      
      </tr>
    </>
  );
};

export default HistoryTableRow;
