import { useAtom } from "jotai";
import React, { useEffect, useRef, useState } from "react";
import { HiChevronDown, HiChevronLeft, HiChevronRight } from "react-icons/hi";
import ToReviewTable, { dataAtom, rowsPerPageAtom } from "./ToReviewTable";
import axios from "axios";
import { jwtDecode } from "jwt-decode";// Import ToastContainer and toast from react-toastify
import { ToastContainer, toast } from "react-toastify"; // Import ToastContainer and toast from react-toastify
import "react-toastify/dist/ReactToastify.css"; // Import CSS for react-toastify
import { useNavigate } from "react-router-dom";

const ToReview = ({ searchQuery }) => {
  const [rowsPerPage, setRowsPerPage] = useAtom(rowsPerPageAtom);
  const [data, setData] = useAtom(dataAtom);
  const dataLength = data.length;

  const optionsRef = useRef(null);
  const navigate = useNavigate();

  // setData(staticData);



  // State for managing the visibility of the rows per page options
  const [showRowsPerPageOptions, setShowRowsPerPageOptions] = useState(false);

  // Array of options for rows per page
  const rowsPerPageOptions = [10, 12, 14, 16, 18, 20];

  const getuserdetails = async () => {
    const token = localStorage.getItem("usertoken");

    if (!token) {
      // Handle the case where the token is not present (e.g., redirect to login page)
      toast.error("Authentication credentials not provided. Please log in.");
      return;
    }

    // Check if the authentication token has expired (example for JWT, may vary based on implementation)
    const isTokenExpired = () => {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000; // Convert to seconds
        console.log("decodedToken", decodedToken.exp);
        console.log("currentTime", currentTime);
        console.log("isTokenExpired", decodedToken.exp < currentTime);
        return decodedToken.exp < currentTime;
      } catch (error) {
        return true; // Error decoding token or invalid token, treat as expired
      }
    };

    if (isTokenExpired()) {
      // Handle token expiration (e.g., refresh the token or redirect to login page)
      toast.error("Authentication token has expired. Please log in again.");
      navigate("/login");
      return;
    }

    const url =
      process.env.REACT_APP_BASE_URL + `users/getallorders/`;
    const response = await axios.get(`${url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const history_url =
      process.env.REACT_APP_BASE_URL + `users/getmyreviews/`;
    const history_response = await axios.get(`${history_url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });




if (response.status === 200 && history_response.status === 200) {
  const responseData = response.data.data;
  const historyData = history_response.data.data;
  // Extract product IDs from historyData
const reviewedProductIds = new Set(historyData.map(item => item.product.id));
console.log("reviewedProductIds",reviewedProductIds)
// Filter out product IDs from responseData that are not in reviewedProductIds
const allProductIds = responseData.flatMap(item =>
  item.cart_items.map(cartItem => cartItem.product.id)
);

console.log(allProductIds);
const filteredProductIds = allProductIds.filter(productId => !reviewedProductIds.has(productId));

const filteredData = responseData.filter(item => {
  // Check if any of the cart_items' product IDs are not in the reviewedProductIds set
  return item.cart_items.some(cartItem => !reviewedProductIds.has(cartItem.product.id));
});

console.log(filteredData);
// Transform filteredData
const transformedData = filteredData.flatMap(item =>
  item.cart_items
    .filter(cartItem => !reviewedProductIds.has(cartItem.product.id) && item.status == "Delivered") // Filter out reviewed products from cart_items
    .map(cartItem => ({
      productId: cartItem.product.id,
      productName: cartItem.product.part_id.partName,
      companyName: cartItem.product.part_id.vehicleCompany,
      productPrice: cartItem.product.normalRate,
      productImages: cartItem.product.images, // Assuming you want all images for each product
    }))
);

console.log(transformedData);

  
  

  setData(transformedData);
} else {
  console.error("Failed to fetch data");
}



    console.log("Filtered Data",data)

  };

  useEffect(() => {
    getuserdetails();
  }, []);

  useEffect(() => {
    // Event listener to close the popup when clicking outside of it
    const handleOutsideClick = (event) => {
      if (
        showRowsPerPageOptions &&
        optionsRef.current &&
        !optionsRef.current.contains(event.target)
      ) {
        setShowRowsPerPageOptions(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    // Cleanup the event listener when the component is unmounted
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showRowsPerPageOptions]);

  // State for managing the current page
  const [currentPage, setCurrentPage] = useState(1);

  // Number of items to display per page
  const itemsPerPage = rowsPerPage;

  // Calculate the total number of pages
  const totalPages = Math.ceil(dataLength / itemsPerPage);

  // Function to handle the click on the left arrow
  const handleLeftArrowClick = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  // Function to handle the click on the right arrow
  const handleRightArrowClick = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  // Calculate the range of items to display on the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  return (
    <div className="flex flex-col gap-4">
      <ToastContainer />
      <ToReviewTable
        startIndex={startIndex}
        endIndex={endIndex}
        searchQuery={searchQuery}
      />

      <div className="flex items-center justify-center text-gray-500 sm:justify-between">
        <div className="hidden sm:block">
          <p className="font-medium text-[15px]">
            1-
            <span>
              {rowsPerPage > dataLength ? dataLength : rowsPerPage}
            </span>{" "}
            of <span>{dataLength}</span>
          </p>
        </div>

        <div className="text-[15px] font-medium flex items-center gap-6">
          <div className="items-center gap-2 hidden sm:flex">
            <p>Rows per page:</p>

            <div
              className="relative flex items-center gap-1 cursor-pointer"
              onClick={() => setShowRowsPerPageOptions(!showRowsPerPageOptions)}
            >
              <p>{rowsPerPage}</p>
              <HiChevronDown className="text-[20px]" />

              {showRowsPerPageOptions && (
                <div
                  ref={optionsRef}
                  className="absolute right-6 bg-white border border-gray-300 mt-2 p-2 rounded-md"
                >
                  {rowsPerPageOptions.map((option) => (
                    <div
                      key={option}
                      className="cursor-pointer hover:bg-gray-200 p-1"
                      onClick={() => {
                        setRowsPerPage(option);
                        setShowRowsPerPageOptions(false);
                      }}
                    >
                      {option}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className="flex items-center gap-3">
            <button
              className="border-2 border-gray-300 text-gray-700 disabled:text-[#A1A9B8] rounded-xl p-1"
              onClick={handleLeftArrowClick}
              disabled={currentPage === 1}
            >
              <HiChevronLeft className="text-[24px]" />
            </button>

            <div>
              {currentPage}/{totalPages}
            </div>

            <button
              className="border-2 border-gray-300 text-gray-700 disabled:text-[#A1A9B8] rounded-xl p-1"
              onClick={handleRightArrowClick}
              disabled={currentPage === totalPages}
            >
              <HiChevronRight className="text-[24px]" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ToReview;
