import { atom } from "jotai";
import React, { useState, useEffect } from "react";
import { BiEdit, BiTrash } from "react-icons/bi";
import { FaPlus } from "react-icons/fa";
import "../../../../styles/table.css";
import AddAddress from "./AddAddress";
import Modal from "./Modle";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

export const addNewAddressFormOpenAtom = atom(false);

const Addresses = () => {
  const [addNewAddressFormOpen, setAddNewAddressFormOpen] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const url = process.env.REACT_APP_BASE_URL + `users/address/`;
      const token = localStorage.getItem("usertoken");
      const response = await axios.get(`${url}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        setAddresses(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
      toast.error("Failed to fetch addresses");
    }
  };

  const deleteAddress = async (id) => {
    try {
      const url = process.env.REACT_APP_BASE_URL + `users/address/${id}/`;
      const token = localStorage.getItem("usertoken");
      const response = await axios.delete(`${url}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },

      });
      if (response.status === 200) {
        toast.success("Address deleted successfully");
        fetchData();
      } else {
        toast.error("Failed to delete address");
      }
    } catch (error) {
      console.error("Error deleting address: ", error);
      toast.error("Failed to delete address");
    }
  };
  



  const openAddAddressForm = (address) => {
    setSelectedAddress(address); // Set the selected address for editing
    setAddNewAddressFormOpen(true);
  };

  const closeAddAddressForm = () => {
    setSelectedAddress(null); // Reset the selected address
    setAddNewAddressFormOpen(false);
    fetchData(); // Fetch the updated list of addresses
  };

  return (
    <div className="bg-white pb-10">
      <ToastContainer />
      <div className="px-4 md:px-8 py-3.5 border-b-2 border-gray-200 flex items-center justify-between gap-6">
        <h1 className="font-bold text-lg text-gray-900">Addresses</h1>

        <button
          className="flex items-center gap-2 bg-blue rounded-[4px] px-3 sm:px-5 py-1.5 text-white font-medium"
          onClick={() => openAddAddressForm(null)} // Pass null when adding a new address
        >
          <FaPlus className="" />
          New Address
        </button>
      </div>

      <Modal isOpen={addNewAddressFormOpen}>
        <AddAddress onClose={closeAddAddressForm} address={selectedAddress} />
      </Modal>

      <div className="px-4 md:px-8 pt-5">
        <div className="relative bg-white border-2 border-gray-400 overflow-x-auto whitespace-nowrap table-container">
          <table className="w-full text-white text-start border-spacing-4">
            <thead className="text-gray-900 border-y-2 border-gray-400 uppercase">
              <tr>
                <th className="text-start">Full Name</th>
                <th className="text-center">Phone Number</th>
                <th className="text-center">PAN Number</th>
                <th className="text-center">Address</th>
                <th className="text-center">Label</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>

            <tbody className="text-gray-700">
              {addresses.map((address) => (
                <tr
                  key={address.id}
                  className="w-full border-b-2 border-gray-300 text-gray-700 relative hover:bg-gray-100"
                >
                  <td className="flex flex-col gap-1.5">
                    <p className="font-medium">{address.fullname}</p>
                  </td>

                  <td className="text-center font-medium">{address.phone}</td>

                  <td className="text-center font-medium">{address.PAN ? address.PAN : 'N/A'}</td>

                  <td className="text-center font-medium">
                    {address.descriptiveaddress}
                  </td>

                  <td className="text-center font-medium">{address.label}</td>

                  <td>
                    <div className="flex justify-center items-center gap-2">
                      <button
                        className="px-1.5 border-2 border-gray-300 font-medium rounded-md h-[35px]"
                        onClick={() => openAddAddressForm(address)}
                      >
                        <BiEdit className="w-[22px] h-[22px] text-gray-700" />
                      </button>
                      <button 
                        className="px-1.5 border-2 border-gray-300 font-medium rounded-md h-[35px]"
                        onClick={() => deleteAddress(address.id)}
                      >
                        <BiTrash className="w-[22px] h-[22px] text-gray-700" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Addresses;
