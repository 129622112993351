import axios from "axios";
import { atom, useAtom } from "jotai";
import React, { useState } from "react";
import { HiChevronDown, HiOutlinePlus, HiOutlineSearch } from "react-icons/hi";
import AllProducts from "./AllProducts/AllProducts";
import DeletedProducts from "./DeletedProductList/DeletedProducts";
import ReviewCSVParts from "./ReviewCSVParts/ReviewCSVParts";
import ReviewSellerParts from "./ReviewSellerParts/ReviewSellerParts";
import { localSelectionAtom } from "./ReviewSellerParts/ReviewSellerPartsTable";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UnListedProducts from "./UnListed Products/UnListedProducts";
import { useNavigate } from "react-router-dom";

export const isManageActiveAtom = atom(true);
export const isProductViewedAtom = atom(false);
export const viewedProductAtom = atom(null);

export const isAdminPartEditActiveAtom = atom(false);
export const adminPartEditDataAtom = atom(null);



const ManageProducts = () => {
  const navigate = useNavigate();
  const [productStatus, setProductStatus] = useState("Review Seller Parts");
  const [searchQuery, setSearchQuery] = useState("");
  const [showMobileDropdown, setShowMobileDropdown] = useState(false);
  const [, setIsManageActive] = useAtom(isManageActiveAtom);
  const [moveToOption, setMoveToOption] = useState(null);
  const [showMoveToDropdown, setShowMoveToDropdown] = useState(false);
  const [isAnyRowSelected, setIsAnyRowSelected] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [localSelection, setLocalSelection] = useAtom(localSelectionAtom);
 
  // Function to handle selecting an option in the "Move To" dropdown
  const handleMoveToOptionSelect = async (option) => {
    console.log("selectedRows", selectedRows);
    console.log("option", option);
    if (option === "Accept") {
      for (let i = 0; i < selectedRows.length; i++) {
        let formData = new FormData();
        formData = selectedRows[i];

        formData["partinfo_id"] = selectedRows[i].productId;
        formData["status"] = "Approved";
        console.log("-------------------");
        console.log("formData chceking", formData);
        try {
          const url = process.env.REACT_APP_BASE_URL + "admin/update-partinfo/";

          const response = await axios.post(`${url}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            body: JSON.stringify(formData),
          });
          console.log("Data sent successfully:", response.data);
        } catch (error) {
          console.error("Error sending data:", error);
          // Handle error
        }
      }
    } else if (option === "Decline") {
      for (let i = 0; i < selectedRows.length; i++) {
        let formData = new FormData();
        formData = selectedRows[i];
        formData["partinfo_id"] = selectedRows[i].productId;
        formData["status"] = "Deleted";
        try {
          const UPDATEURL =
            process.env.REACT_APP_BASE_URL + "admin/update-partinfo/";

          const response = await axios.post(`${UPDATEURL}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            body: JSON.stringify(formData),
          });
          console.log("Data sent successfully:", response.data);
        } catch (error) {
          console.error("Error sending data:", error);
          // Handle error
        }
      }
    } else if (option === "Delete") {
      for (let i = 0; i < selectedRows.length; i++) {
        let formData = new FormData();
        formData = selectedRows[i];
        const currentstatus = selectedRows[i].status;
        if (currentstatus === "Approved") {
          formData["partinfo_id"] = formData.productId;
          formData["status"] = "Deleted";
          const url = process.env.REACT_APP_BASE_URL + "admin/update-partinfo/";
          try {
            const response = await axios.post(`${url}`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              body: JSON.stringify(formData),
            });
            console.log("Data sent successfully:", response.data);
          } catch (error) {
            console.error("Error sending data:", error);
            // Handle error
          }
        }
      }
    } else if (option === "Deleted") {
      for (let i = 0; i < selectedRows.length; i++) {
        let formData = new FormData();
        formData = selectedRows[i];

        formData["partinfo_id"] = formData.productId;
        const url = process.env.REACT_APP_BASE_URL + "admin/delete-partinfo/";
        try {
          const response = await axios.post(`${url}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            body: JSON.stringify(formData),
          });
          console.log("Data sent successfully:", response.data);
        } catch (error) {
          console.error("Error sending data:", error);
          // Handle error
        }
      }
    }


    


    
    setSelectedRows([]);
    setIsAnyRowSelected(false);
    setMoveToOption(option);
    setShowMoveToDropdown(false);
    setLocalSelection([]);


  };

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Function to handle selecting an option in the mobile dropdown
  const handleMobileOptionSelect = (option) => {
    setProductStatus(option);
    setShowMobileDropdown(false);
  };

  console.log("Product Status:", productStatus);


  return (
    <div className={`px-8 py-6 flex flex-col gap-4`}>
      <ToastContainer />
      <div className="flex items-stretch justify-between lg:items-center">
        <div className="flex items-center gap-4">
          {/* Mobile dropdown for product status */}
          <div className="xl:hidden relative">
            <button
              className="flex items-center gap-1.5 text-blue font-bold text-[16px] bg-white border-2 border-gray-300 rounded-md px-2 py-2"
              onClick={() => setShowMobileDropdown(!showMobileDropdown)}
            >
              <p>{productStatus}</p>
              <HiChevronDown className="text-[20px]" />
            </button>

            {showMobileDropdown && (
              <div className="absolute z-30 mt-2 left-2 w-48 bg-white border border-gray-300 rounded-md">
                {[
                  "Review Seller Parts",
                  "Review CSV Parts",
                  "All Products",
                  "Deleted Product List",
                  "UnListed Products"
                ].map((option) => (
                  <button
                    key={option}
                    className={`w-full ${
                      productStatus === option ? "bg-gray-200" : ""
                    } cursor-pointer hover:bg-gray-200 p-1.5`}
                    onClick={(e) => {
                      handleMobileOptionSelect(option);
                      e.stopPropagation();
                    }}
                  >
                    {option}
                  </button>
                ))}
              </div>
            )}
          </div>

          {/* Desktop buttons for product status */}
          <div className="hidden xl:flex">
            <div className="flex items-center gap-6 font-medium text-[14px] text-gray-500">
              <button
                className={`${
                  productStatus === "Review Seller Parts"
                    ? "text-blue-900 font-bold border-b-2 pb-0.5 border-blue-900"
                    : ""
                }`}
                onClick={() => setProductStatus("Review Seller Parts")}
              >
                Review Seller Parts
              </button>

              <button
                className={`${
                  productStatus === "Review CSV Parts"
                    ? "text-blue-900 font-bold border-b-2 pb-0.5 border-blue-900"
                    : ""
                }`}
                onClick={() => setProductStatus("Review CSV Parts")}
              >
                Review CSV Parts
              </button>

              <button
                className={`${
                  productStatus === "All Products"
                    ? "text-blue-900 font-bold border-b-2 pb-0.5 border-blue-900"
                    : ""
                }`}
                onClick={() => setProductStatus("All Products")}
              >
                All Products
              </button>

              <button
                className={`${
                  productStatus === "Deleted Product List"
                    ? "text-blue-900 font-bold border-b-2 pb-0.5 border-blue-900"
                    : ""
                }`}
                onClick={() => setProductStatus("Deleted Product List")}
              >
                Deleted Product List
              </button>

              <button
                className={`${
                  productStatus === "UnListed Products"
                    ? "text-blue-900 font-bold border-b-2 pb-0.5 border-blue-900"
                    : ""
                }`}
                onClick={() => setProductStatus("UnListed Products")}
              >
                UnListed Products
              </button>
            </div>
          </div>

          {isAnyRowSelected && (
            <div className="relative inline-block text-left">
            <button
              className="px-2 py-2 flex items-center gap-1 text-gray-500 text-[14px] font-medium"
              onClick={() => setShowMoveToDropdown(!showMoveToDropdown)}
            >
              Move To
              <HiChevronDown className="text-[20px]" />
            </button>
        
            {showMoveToDropdown &&
              (productStatus === "Review Seller Parts" ||
              productStatus === "Review CSV Parts" ? (
                <div className="absolute z-30 mt-0 left-0 w-48 bg-white border border-gray-300 rounded-md">
                  {["Accept", "Decline"].map((option) => (
                    <button
                      key={option}
                      className={`w-full text-[14px] text-gray-700 font-medium cursor-pointer hover:bg-gray-200 p-1.5`}
                      onClick={(e) => {
                        handleMoveToOptionSelect(option);
                        e.stopPropagation();
                      }}
                    >
                      {option}
                    </button>
                  ))}
                </div>
              ) : (
                productStatus === "All Products" ? (
                  <div className="absolute z-30 mt-0 left-0 w-48 bg-white border border-gray-300 rounded-md">
                    {["Delete"].map((option) => (
                      <button
                        key={option}
                        className={`w-full text-[14px] text-gray-700 font-medium cursor-pointer hover:bg-gray-200 p-1.5`}
                        onClick={(e) => {
                          handleMoveToOptionSelect(option);
                          e.stopPropagation();
                        }}
                      >
                        {option}
                      </button>
                    ))}
                  </div>
                ) : productStatus === "Deleted Product List" && (
                  <div className="absolute z-30 mt-0 left-0 w-48 bg-white border border-gray-300 rounded-md">
                    {["Deleted"].map((option) => (
                      <button
                        key={option}
                        className={`w-full text-[14px] text-gray-700 font-medium cursor-pointer hover:bg-gray-200 p-1.5`}
                        onClick={(e) => {
                          handleMoveToOptionSelect(option);
                          e.stopPropagation();
                        }}
                      >
                        {option}
                      </button>
                    ))}
                  </div>
                )
              ))}
          </div>
        )}
        </div>

        <div className="flex gap-4">
          <div className="px-4 py-2 flex items-center gap-3 bg-white border border-gray-300 rounded-md cursor-pointer md:cursor-pointer">
            <HiOutlineSearch className="text-gray-500 text-[20px]" />

            <input
              type="text"
              placeholder="Search"
              className="hidden bg-white outline-none font-medium text-[14px] placeholder:text-[13px] w-[120px] placeholder:text-gray-400 md:block"
              value={searchQuery}
              onChange={handleSearchInputChange}
            />
          </div>

          <button
            className="bg-blue px-3 rounded-md text-white text-[15px] font-bold xl:px-6"
            onClick={() => setIsManageActive(false)}
          >
            <HiOutlinePlus className="text-white text-[22px] lg:hidden" />
            <p className="hidden lg:block">Add New Product</p>
          </button>
        </div>
      </div>

      {productStatus === "Review Seller Parts" ? (
        <ReviewSellerParts
          searchQuery={searchQuery}
          isAnyRowSelected={isAnyRowSelected}
          setIsAnyRowSelected={setIsAnyRowSelected}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      ) : productStatus === "Review CSV Parts" ? (
        <ReviewCSVParts
          searchQuery={searchQuery}
          isAnyRowSelected={isAnyRowSelected}
          setIsAnyRowSelected={setIsAnyRowSelected}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      ) : productStatus === "All Products" ? (
        <AllProducts
          searchQuery={searchQuery}
          isAnyRowSelected={isAnyRowSelected}
          setIsAnyRowSelected={setIsAnyRowSelected}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      ) : productStatus === "Deleted Product List" ? (
        <DeletedProducts
          searchQuery={searchQuery}
          isAnyRowSelected={isAnyRowSelected}
          setIsAnyRowSelected={setIsAnyRowSelected}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      ) : (
        <UnListedProducts
        searchQuery={searchQuery}
        isAnyRowSelected={isAnyRowSelected}
        setIsAnyRowSelected={setIsAnyRowSelected}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
      />
      )   
      }
    </div>
  );
};

export default ManageProducts;
