import axios from "axios";
import { useAtom } from "jotai";
import React, { useState } from "react";
import { HiPlus } from "react-icons/hi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputWithLabel from "../../../common/InputWithLabel";
import TagsInputContainer from "../../../common/TagsInputContainer";
import { isSellerProductEditedAtom } from "../ManageProduct/AllProducts/AllProductsTableRow";
import { isProductSelectedAtom } from "./AddProductMain";
import { currentStepAtom } from "./StepsIndicator";

const DescriptionForm = ({
  formData,
  setFormData,
  onInputChange,
  handleOtherAttributesChange,
  handleTagAddDelete,
}) => {
  const [, setCurrentStep] = useAtom(currentStepAtom);
  const [errors, setErrors] = useState({
    description: "",
    tags: "",
  });
  const [numOtherAttributes, setNumOtherAttributes] = useState(1);
  const [isProductSelected] = useAtom(isProductSelectedAtom);
  const [isLoading, setIsLoading] = useState(false);
  const [isSellerProductEdited] = useAtom(isSellerProductEditedAtom);

  const handleNextButton = async (e) => {
    e.preventDefault();
  
    const newErrors = validateForm();
  
    console.log(newErrors);
  
    // if (Object.values(newErrors).some((error) => error !== "")) {
    //   setErrors(newErrors);
    //   return;
    // }
  
    console.log(formData);
  
    // Check if there are any errors related to tags before moving to the next step
    if (newErrors.tags !== "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        tags: newErrors.tags,
      }));
      return;
    }
  
    // Move to the next step
    isProductSelected || isSellerProductEdited
      ? setCurrentStep((prevStep) => prevStep + 1)
      : setCurrentStep((prevStep) => prevStep + 2);
  };
  

  const handleFormSubmit = async (e) => {
    console.log("INSIDE2 testtt");
  
    e.preventDefault();
  
    const newErrors = validateForm();
    newErrors.tags = "";
  
    console.log("errors", newErrors);
  
    if (Object.values(newErrors).some((error) => error !== "")) {
      setErrors(newErrors);
      return;
    }
  
    formData.partNumbers = formData.partNumber;
    // delete formData.partNumber;
  
    console.log("Form Data", formData);
  
    setIsLoading(true);
  
    try {
      formData.status = "SellerAdded";
      const url = process.env.REACT_APP_BASE_URL + "admin/addpart/";
      const response = await axios.post(`${url}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        body: JSON.stringify(formData),
      });
      console.log("Data sent successfully:", response.data);
      // navigate("/product");
      // Handle success or navigate to a success page
      toast.success("Product added successfully!");
  
      // Move to the next step
      isProductSelected
        ? setCurrentStep((prevStep) => prevStep + 1)
        : setCurrentStep((prevStep) => prevStep + 2);
    } catch (error) {
      console.error("Error sending data:", error);
      // Handle error
      toast.error("Error sending data!");
    } finally {
      setIsLoading(false);
    }
  };
  

  const handleAddRow = () => {
    if (numOtherAttributes < 5) {
      setNumOtherAttributes((prevNum) => prevNum + 1);
    }
  };

  const validateForm = () => {
    const { description, tags } = formData;
  
    const newErrors = {
      description: description ? "" : "Please enter the description.",
      tags: tags.length > 0 ? "" : "Please add at least one tag.",
    };
  
    return newErrors;
  };
  

  const clearError = (fieldName) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: "",
    }));
  };

  const setTagError = (error) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      tags: error,
    }));
  };

  return (
    <div className="flex-1 bg-white py-4 flex flex-col gap-6">
      <ToastContainer position="top-center" autoClose={3000} />

      <div className="flex items-center justify-between px-6 sm:px-8">
        <div className="text-blue font-bold">
          <p>Product Attributes, Tag & Description</p>
        </div>
      </div>

      <form
        onSubmit={handleFormSubmit}
        className="flex-1 flex flex-col justify-between gap-16"
        encType="multipart/form-data"
      >
        <div className="flex flex-col gap-4 lg:gap-6 px-6 sm:px-8">
          <div className="flex flex-col gap-2.5">
            <label className="text-[14px] uppercase text-gray-800 font-bold">
              Availabilty
            </label>

            <div className="flex gap-4">
              <div className="flex items-center gap-1">
                <input
                  type="radio"
                  id="inStock"
                  name="availability"
                  value="In Stock"
                  checked={formData.availability === "In Stock"}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      availability: e.target.value,
                    })
                  }
                />

                <label
                  htmlFor="inStock"
                  className={`text-[14px] font-medium ${
                    formData.availability === "In Stock"
                      ? "text-gray-800"
                      : "text-gray-500"
                  }
                        `}
                >
                  In Stock
                </label>
              </div>

              <div className="flex items-center gap-1">
                <input
                  type="radio"
                  id="outOfStock"
                  name="availability"
                  value="Out Of Stock"
                  checked={formData.availability === "Out Of Stock"}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      availability: e.target.value,
                    })
                  }
                />

                <label
                  htmlFor="outOfStock"
                  className={`text-[14px] font-medium ${
                    formData.availability === "Out Of Stock"
                      ? "text-gray-800"
                      : "text-gray-500"
                  }
                        `}
                >
                  Out of Stock
                </label>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-1">
            <InputWithLabel
              htmlFor="description"
              label="Description"
              isTextArea={true}
              rows={5}
              placeholder="Enter product description..."
              name="description"
              value={formData.description}
              onChange={(e) => {
                onInputChange("description", e.target.value);
                clearError("description");
              }}
            />

            {errors.description && (
              <p className="text-red-900 text-[14px] font-medium">
                {errors.description}
              </p>
            )}
          </div>

          {isProductSelected ? (
            <div className="col-span-2 flex flex-col gap-1">
              <label className="text-[14px] uppercase text-gray-800 font-bold">
                Tags
              </label>
              <TagsInputContainer
                tags={formData.tags}
                onTagAddDelete={handleTagAddDelete}
                setTagError={setTagError}
              />

{errors.tags && (
  <p className="text-red-900 text-[14px] font-medium">
    {errors.tags}
  </p>
)}

            </div>
          ) : (
            <div className="flex flex-col gap-4">
              <label className="text-[14px] uppercase text-gray-800 font-bold">
                Other Attributes
              </label>

              {Array.from({ length: numOtherAttributes }).map((_, index) => (
                <div
                  className="flex flex-col sm:flex-row gap-2 sm:gap-4 flex-1"
                  key={index}
                >
                  <div className="flex flex-col gap-1 flex-1">
                    <InputWithLabel
                      htmlFor={`attributeName${index}`}
                      label={`Attribute ${index + 1}`}
                      type="text"
                      placeholder={`Attribute ${index + 1}`}
                      name={`attributeName${index}`}
                      value={
                        formData.otherAttributes[index]?.attributeName || ""
                      }
                      onChange={(e) =>
                        handleOtherAttributesChange(
                          index,
                          "attributeName",
                          e.target.value
                        )
                      }
                    />
                  </div>
                  <div className="flex flex-col gap-1 flex-1">
                    <InputWithLabel
                      htmlFor={`attributeValue${index}`}
                      label={`Attribute ${index + 1} Value`}
                      type="text"
                      placeholder={`Attribute ${index + 1} Value`}
                      name={`attributeValue${index}`}
                      value={
                        formData.otherAttributes[index]?.attributeValue || ""
                      } // Check if formData.otherAttributes[index] exists
                      onChange={(e) =>
                        handleOtherAttributesChange(
                          index,
                          "attributeValue",
                          e.target.value
                        )
                      }
                    />
                  </div>
                </div>
              ))}

              {numOtherAttributes < 5 && (
                <button
                  type="button"
                  onClick={handleAddRow}
                  className="self-center bg-blue w-8 h-8 rounded-full flex items-center justify-center text-white font-semibold"
                >
                  <HiPlus className="text-xl" />
                </button>
              )}
            </div>
          )}
        </div>

        <div className="pt-6 px-6 sm:px-8 flex flex-row justify-between border-t border-gray-200">
          <button
            type="button"
            onClick={() =>
              setCurrentStep((prevStep) => Math.max(prevStep - 1, 1))
            }
            className="border border-gray-300 px-6 py-1 rounded-md text-[14px] text-gray-500 font-semibold"
          >
            Back
          </button>

          {isProductSelected || isSellerProductEdited ? (
            <button
              type="submit"
              className="bg-blue px-6 py-1 rounded-md text-[14px] text-white font-semibold"
              onClick={handleNextButton}
            >
              Next
            </button>
          ) : (
            <button
              type="submit"
              className="bg-blue min-w-[140px] px-2 py-1 rounded-md text-[14px] text-white font-semibold"
              onClick={handleFormSubmit}
            >
              {isLoading ? (
                <div role="status" className="flex justify-center items-center">
                  <svg
                    aria-hidden="true"
                    class="w-5 h-5 text-gray-300 animate-spin dark:text-gray-600 fill-white"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span class="sr-only">Loading...</span>
                </div>
              ) : (
                "Continue & Finish"
              )}
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default DescriptionForm;
