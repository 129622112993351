import { useAtom } from "jotai";
import React, { useState } from "react";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import SelectPaymentMethod from "../../components/User/PlaceOrder/SelectPaymentMethod";
import ShippingAddressForm from "../../components/User/PlaceOrder/ShippingAddressForm";
import StepsIndicator3, {
  currentStepAtom,
} from "../../components/User/PlaceOrder/STepIndicator3";
import {
  cartId,
  totalItemValue,
  totalPriceValue,
} from "../../components/User/PlaceOrder/ShoppingCart";
import Footer from "../../components/User/common/Footer";
import Header from "../../components/User/common/Header";


const PlaceOrder = () => {
  const [currentStep] = useAtom(currentStepAtom);
  const [totalItem] = useAtom(totalItemValue);
  const [totalPrice] = useAtom(totalPriceValue);
  const [cart] = useAtom(cartId);
  console.log("CART", cart);
  const [formData, setFormData] = useState({
    cart: cart,
    Address: "",
    payment_method: "",
    total_items: totalItem,
    total_price: totalPrice,
  });
  const [errors, setErrors] = useState({
    fullname: "",
    phone: "",
    Address: "",
    payment_method: "",
  });

  const navigate = useNavigate();

  const renderForm = () => {
    switch (currentStep) {
      case 1:
        return (
          <ShippingAddressForm
            formData={formData}
            setFormData={setFormData}
            errors={errors}
            setErrors={setErrors}
          />
        );
      case 2:
        return (
          <SelectPaymentMethod
            formData={formData}
            setFormData={setFormData}
            errors={errors}
            setErrors={setErrors}
          />
        );
      // case 3:
      //   return <OrderReview />;
      default:
        return null;
    }
  };
  console.log("TOTALITEMS", totalItem , "TOTALPRICE", totalPrice);
  return (
    <div>
      <ToastContainer position="top-center" />

      <Header showTopPart={false} showCategory={false} />

      <div className="relative w-full min-h-[calc(100vh_-_322.8px_-_64.625px)] py-4 pb-8 px-6 flex flex-col gap-4 md:px-10 lg:px-20">
        <button
          className="flex items-center gap-1.5 font-medium"
          onClick={() => navigate("/cart")}
        >
          <IoArrowBack />
          Back
        </button>

        <div className="w-full flex flex-col gap-6 lg:flex-row">
          <div className="flex-1 bg-white flex flex-col max-w-[80%]">
            <div className="w-full px-4 py-6 border-b-2 border-gray-300 flex justify-center">
              <StepsIndicator3 />
            </div>

            {renderForm()}
          </div>

          <div className="bg-white pt-5 flex w-full lg:w-[300px] h-fit flex-col">
            <div className="flex flex-col gap-6">
              <h1 className="text-xl px-6 font-bold">Order Summary</h1>

              <div className="px-6 flex flex-col gap-3 text-[15px] pb-5 border-b-2 border-gray-300">
                <div className="font-medium flex justify-between gap-4">
                  <p>Items:</p>
                  <p>{totalItem}</p>
                </div>
              </div>
            </div>

            <div className="px-6 py-5 font-bold text-[17px] text-blue flex justify-between gap-4">
              <p>Order Total:</p>
              <p>{totalPrice}</p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default PlaceOrder;
