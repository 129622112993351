import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import {
  BiSolidChevronLeftCircle,
  BiSolidChevronRightCircle,
} from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { ReactComponent as BackArrowSVG } from "../../../assets/svg/arrow-left-circle.svg";
import { isSellerProductViewedAtom } from "../ProductManagement/ManageProduct/ManageProduct";

const ViewProduct = ({ formData }) => {
  const [isProductViewed, setIsProductViewed] = useAtom(
    isSellerProductViewedAtom
  );
  const [allPhotos, setAllPhotos] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  console.log(formData);

  const navigate = useNavigate();

  useEffect(() => {
    if (formData.otherPhotos) {
      setAllPhotos([formData.coverPhoto, ...formData.otherPhotos]);
    } else {
      setAllPhotos([formData.coverPhoto]);
    }
  }, [formData]);

  const handlePrevClick = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + allPhotos.length) % allPhotos.length
    );
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % allPhotos.length);
  };

  const IMAGE_URL = process.env.REACT_APP_BASE;

  return (
    <div className="md:px-8 md:py-6 flex gap-4 sm:gap-8">
      <div className="flex-1 bg-white py-4 flex flex-col gap-6">
        <div className="flex items-center justify-between px-6 sm:px-8">
          <div className="text-blue font-bold flex gap-4 items-center">
            <button
              onClick={() => {
                setIsProductViewed(false);

                if (isProductViewed === false) {
                  navigate(-1);
                }
              }}
            >
              <BackArrowSVG className="w-5 h-5" />
            </button>

            <p>Add Product Summary</p>
          </div>
        </div>

        <div className="flex flex-col gap-8 md:flex-row md:px-8 lg:px-[4.2rem] md:gap-16">
          <div className="md:w-[60%]">
            <div className="flex flex-col gap-3 px-6 sm:px-8 md:px-0">
              <h1 className="uppercase text-[15px] text-gray-800 font-bold">
                Vehicle Info
              </h1>

              <div className="flex flex-col gap-4">
                <div className="flex flex-col sm:flex-row sm:justify-between">
                  <h2 className="text-[14px] text-gray-900 font-bold md:flex-1">
                    Vehicle Company:
                  </h2>
                  <p className="text-[14px] text-gray-700 font-medium md:flex-1">
                    {formData.vehicleCompany}
                  </p>
                </div>

                <div className="flex flex-col sm:flex-row sm:justify-between">
                  <h2 className="text-[14px] text-gray-900 font-bold md:flex-1">
                    Sub-Category:
                  </h2>
                  <p className="text-[14px] text-gray-700 font-medium md:flex-1">
                    {formData.subCategory}
                  </p>
                </div>

                <div className="flex flex-col sm:flex-row sm:justify-between">
                  <h2 className="text-[14px] text-gray-900 font-bold md:flex-1">
                    Brand:
                  </h2>
                  <p className="text-[14px] text-gray-700 font-medium md:flex-1">
                    {formData.brand}
                  </p>
                </div>

                <div className="flex flex-col sm:flex-row sm:justify-between">
                  <h2 className="text-[14px] text-gray-900 font-bold md:flex-1">
                    Vehicle Company:
                  </h2>
                  <p className="text-[14px] text-gray-700 font-medium md:flex-1">
                    {formData.vehicleCompany}
                  </p>
                </div>

                <div className="flex flex-col sm:flex-row sm:justify-between">
                  <h2 className="text-[14px] text-gray-900 font-bold md:flex-1">
                    Model:
                  </h2>
                  <p className="text-[14px] text-gray-700 font-medium md:flex-1">
                    {formData.model}
                  </p>
                </div>

                <div className="flex flex-col sm:flex-row sm:justify-between">
                  <h2 className="text-[14px] text-gray-900 font-bold md:flex-1">
                    Year Of Manufacturing:
                  </h2>
                  <p className="text-[14px] text-gray-700 font-medium md:flex-1">
                    {formData.manufactureYear}
                  </p>
                </div>

                <div className="flex flex-col sm:flex-row sm:justify-between">
                  <h2 className="text-[14px] text-gray-900 font-bold md:flex-1">
                    Part Categories:
                  </h2>
                  <p className="text-[14px] text-gray-700 font-medium md:flex-1">
                    {formData.partCategories}
                  </p>
                </div>

                <div className="flex flex-col sm:flex-row sm:justify-between">
                  <h2 className="text-[14px] text-gray-900 font-bold md:flex-1">
                    Part Sub-Categories:
                  </h2>
                  <p className="text-[14px] text-gray-700 font-medium md:flex-1">
                    {formData.partSubCategories}
                  </p>
                </div>

                <div className="flex flex-col sm:flex-row sm:justify-between">
                  <h2 className="text-[14px] text-gray-900 font-bold md:flex-1">
                    Part Number:
                  </h2>
                  <p className="text-[14px] text-gray-700 font-medium md:flex-1">
                    {formData.partNumber}
                  </p>
                </div>

                <div className="flex flex-col sm:flex-row sm:justify-between">
                  <h2 className="text-[14px] text-gray-900 font-bold md:flex-1">
                    Part Name:
                  </h2>
                  <p className="text-[14px] text-gray-700 font-medium md:flex-1">
                    {formData.partName}
                  </p>
                </div>
              </div>
            </div>

            <div className="h-[1px] w-full bg-gray-400 my-8"></div>

            <div className="flex flex-col gap-3 px-6 sm:px-8 md:px-0">
              <h1 className="uppercase text-[15px] text-gray-800 font-bold">
                Price, Attributes & Description
              </h1>

              <div className="flex flex-col gap-4">
                <div className="flex flex-col sm:flex-row sm:justify-between">
                  <h2 className="text-[14px] text-gray-900 font-bold md:flex-1">
                    Description:
                  </h2>
                  <p className="text-[14px] text-gray-700 font-medium md:flex-1">
                    {formData.description}
                  </p>
                </div>

                {formData.otherAttributes && (
                  <>
                    {formData.otherAttributes.map((attribute, index) => (
                      <div
                        key={index}
                        className="flex flex-col sm:flex-row sm:justify-between"
                      >
                        <h2 className="text-[14px] text-gray-900 font-bold md:flex-1">{`Attribute ${
                          index + 1
                        }:`}</h2>
                        <p className="text-[14px] text-gray-700 font-medium md:flex-1">
                          {attribute.attributeValue || "N/A"}
                        </p>
                      </div>
                    ))}
                  </>
                )}

                {formData.tags && formData.tags.length > 0 && (
                  <div className="flex flex-col sm:flex-row sm:justify-between">
                    <h2 className="text-[14px] text-gray-900 font-bold md:flex-1">
                      Tags
                    </h2>

                    <p className="text-[14px] text-gray-700 font-medium md:flex-1">
                      {formData.tags.map((tag, index) => (
                        <React.Fragment key={index}>
                          {tag}
                          {index !== formData.tags.length - 1 && ", "}
                        </React.Fragment>
                      ))}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="px-6 sm:px-8 flex flex-col items-center gap-2 md:px-0 md:items-end md:w-[40%]">
            {allPhotos && allPhotos.length > 0 && (
              <div className="w-full max-w-[350px] h-[350px] lg:h-[300px] xl:h-[350px] relative overflow-hidden">
                <div
                  className="flex w-full max-w-[350px] h-[350px] lg:h-[300px] xl:h-[350px] absolute transition-transform duration-300 ease-in-out"
                  style={{
                    transform: `translateX(-${currentIndex * 100}%)`,
                  }}
                >
                  {allPhotos.map((photo, index) => (
                    <img
                      key={index}
                      className="w-full h-full object-cover"
                      src={`${IMAGE_URL}${photo}`}
                      alt=""
                    />
                  ))}
                </div>

                <button
                  className="absolute left-2 top-1/2 transform -translate-y-1/2"
                  onClick={handlePrevClick}
                >
                  <BiSolidChevronLeftCircle className="w-6 h-6 text-gray-200" />
                </button>
                <button
                  className="absolute right-2 top-1/2 transform -translate-y-1/2"
                  onClick={handleNextClick}
                >
                  <BiSolidChevronRightCircle className="w-6 h-6 text-gray-200" />
                </button>
              </div>
            )}

            <div className="flex flex-col items-center md:items-end">
              <h1 className="text-gray-900 text-[15px] font-bold">
                Availability
              </h1>

              <p
                className={`text-[14px] font-medium ${
                  formData.quantity < 5
                    ? "text-yellow"
                    : formData.quantity <= 0
                    ? "text-red"
                    : "text-green"
                }`}
              >
                {" "}
                ●{" "}
                {formData.quantity < 5
                  ? "Low on Stock"
                  : formData.quantity <= 0
                  ? "Out of Stock"
                  : "In Stock"}
              </p>
            </div>

            {formData.quantity && (
              <div className="flex gap-2 items-center md:items-end">
                <h1 className="text-gray-900 text-[15px] font-bold">
                  Quantity Selected :
                </h1>

                <p className="text-[15px] font-medium">{formData.quantity}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewProduct;
