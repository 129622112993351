import { atom, useAtom } from "jotai";
import React, { useEffect } from "react";
import "../../../../../styles/table.css";
import ReviewAllProductsTableRow from "./ReviewAllProductsTableRow";

export const rowsPerPageAtom = atom(10);
export const isProductEditModeAtom = atom(false);
export const productIdAtom = atom(null);
export const dataAtom = atom([]);

const ReviewAllProductsTable = ({
  startIndex,
  endIndex,
  searchQuery,
  fetchData,
}) => {
  const [data, setData] = useAtom(dataAtom);
  const [, setIsEditButtonClicked] = useAtom(isProductEditModeAtom);
  const [, setProductId] = useAtom(productIdAtom);

  // Filter data based on the search query
  const filteredData = data.filter((item) =>
    Object.values(item).some((value) => {
      if (value !== null && value !== undefined) {
        return value
          .toString()
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
      }
      return false;
    })
  );

  useEffect(() => {
    async function fetchData() {
      const manageproducturl =
        process.env.REACT_APP_BASE_URL + "sellerdashboard/api/manageproduct/";
      try {
        const response = await fetch(`${manageproducturl}`);
        const data = await response.json();
        console.log(data);
        setData(data);
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);

  return (
    <div className="relative bg-white border-2 border-gray-400 overflow-x-auto whitespace-nowrap table-container">
      <table className="w-full text-white text-center border-spacing-4">
        <div className="w-full  border-gray-400 bg-gray-300 text-gray-900 text-[14px] font-bold px-5 py-3 uppercase sm:hidden">
          <h1>All Products</h1>
        </div>

        <thead className="text-gray-900 border-y-2 border-gray-400 uppercase hidden sm:table-header-group">
          <tr>
            <th className="text-center">Part Id</th>
            <th className="text-center">Normal Rate</th>
            <th className="text-center">Bulk Rate</th>
            <th className="text-center">Stock Quantity</th>

          </tr>
        </thead>

        {filteredData.length !== 0 && (
          <tbody className="text-gray-700">
            {filteredData.slice(startIndex, endIndex).map((item, index) => (
              <ReviewAllProductsTableRow
                key={index}
                formData={item}
                onEditClick={() => {
                  console.log("Edit button clicked");
                  setIsEditButtonClicked(true);
                  setProductId(index);
                }}
                onDeleteClick={() => {
                  console.log("Delete button clicked");

                  // Delete product from the database with productId
                }}
                fetchData={fetchData}
              />
            ))}
          </tbody>
        )}
      </table>

      {filteredData.length === 0 && (
        <div className="w-full">
          <div className="border-b-2 border-gray-300 text-center h-[65px] flex items-center justify-center text-gray-700 relative hover:bg-gray-100">
            <p className="text-gray-700 font-semibold">No data available</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReviewAllProductsTable;
