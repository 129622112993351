import React, { useState } from "react";
import SideImage from "../assets/images/Frame 1000003234.jpg";
import BrandLogo from "../assets/images/osis.png";
import LoginForm from "../components/Forms/LoginForm";
import VerificationModal from "../components/modals/VerificationModal";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
  // const [selectedAccountType, setSelectedAccountType] = useState("personal");
  const [selectedProcessType] = useState("login");
  const [verificationDigits, setVerificationDigits] = useState([
    "",
    "",
    "",
    "",
  ]);
  const navigate = useNavigate();
  const [isVerificationModalOpen, setVerificationModalOpen] = useState(false);

   // Function to handle successful Google login
   const handleGoogleLoginSuccess = (response) => {
    // Implement your logic for successful Google authentication here
    console.log("Google login successful!", response);
  };

  // Function to handle failed Google login
  const handleGoogleLoginFailure = (error) => {
    // Implement your logic for failed Google authentication here
    console.error("Google login failed!", error);
  };


  return (
    <div className="w-full md:flex md:flex-row-reverse">
      <div className="p-6 py-10 md:w-[50%] lg:py-8 lg:px-[4%]">
        <div className="flex items-center gap-4 mb-6" onClick={() => navigate('/')}>
          <img src={BrandLogo} alt="" className="w-10" />
          <p className="text-2xl font-bold text-blue">Osis</p>
        </div>

        <div className="flex flex-col gap-8">
          <h1 className="text-center text-gray-900 text-2xl font-bold">
            Login to Osis
          </h1>

          <div className="flex flex-col gap-8">
            
           

            <LoginForm />
          </div>
        </div>
      </div>

      <div className="hidden md:block md:fixed w-[50%] h-screen left-0 top-0">
        <img
          src={SideImage}
          alt=""
          className="min-w-full min-h-full object-cover"
        />
      </div>

      {isVerificationModalOpen && (
        <VerificationModal
          setVerificationModalOpen={setVerificationModalOpen}
          verificationDigits={verificationDigits}
          setVerificationDigits={setVerificationDigits}
          selectedProcessType={selectedProcessType}
        />
      )}
    </div>
  );
};

export default LoginPage;
