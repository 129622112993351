import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import {
  BiSolidChevronLeftCircle,
  BiSolidChevronRightCircle,
} from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { ReactComponent as BackArrowSVG } from "../../../../assets/svg/arrow-left-circle.svg";
import { isSellerProductViewedAtom } from "./Quotation";

const ViewProduct = ({ formData }) => {
  const [isProductViewed, setIsProductViewed] = useAtom(
    isSellerProductViewedAtom
  );
  const [allPhotos, setAllPhotos] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  console.log(formData);

  const navigate = useNavigate();

  useEffect(() => {
    if (formData.otherPhotos) {
      setAllPhotos([formData.coverPhoto, ...formData.otherPhotos]);
    } else {
      setAllPhotos([formData.coverPhoto]);
    }
  }, [formData]);

  const handlePrevClick = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + allPhotos.length) % allPhotos.length
    );
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % allPhotos.length);
  };

  const IMAGE_URL = process.env.REACT_APP_BASE;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
      <div className=" md:max-3xl bg-white rounded-lg overflow-hidden">
        <div className="px-6 sm:px-8 py-4">
          <div className="flex items-center justify-between">
            <button
                onClick={() => {
                  setIsProductViewed(false);

                }}
              >
              <BackArrowSVG className="w-5 h-5" />
            </button>
          </div>
        </div>

        <div className="flex flex-col gap-8 md:flex-row md:px-8 lg:px-[4.2rem] md:gap-16">

            <div className="w-[500px] flex flex-col gap-3 px-6 sm:px-8 md:px-0 mb-8">


              <div className="flex flex-col gap-4">
                <div className="flex flex-col sm:flex-row sm:justify-between ">
                  <h2 className="text-[14px] text-gray-900 font-bold md:flex-1">
                    Vehicle Company:
                  </h2>
                  <p className="text-[14px] text-gray-700 font-medium md:flex-1">
                    {formData.vehicleCompany}
                  </p>
                </div>

                <div className="flex flex-col sm:flex-row sm:justify-between">
                  <h2 className="text-[14px] text-gray-900 font-bold md:flex-1">
                    Sub-Category:
                  </h2>
                  <p className="text-[14px] text-gray-700 font-medium md:flex-1">
                    {formData.subCategory}
                  </p>
                </div>

                <div className="flex flex-col sm:flex-row sm:justify-between">
                  <h2 className="text-[14px] text-gray-900 font-bold md:flex-1">
                    Brand:
                  </h2>
                  <p className="text-[14px] text-gray-700 font-medium md:flex-1">
                    {formData.brand}
                  </p>
                </div>


                <div className="flex flex-col sm:flex-row sm:justify-between">
                  <h2 className="text-[14px] text-gray-900 font-bold md:flex-1">
                    Model:
                  </h2>
                  <p className="text-[14px] text-gray-700 font-medium md:flex-1 ">
                    {formData.model}
                  </p>
                </div>

                <div className="flex flex-col sm:flex-row sm:justify-between">
                  <h2 className="text-[14px] text-gray-900 font-bold md:flex-1">
                    Year Of Manufacturing:
                  </h2>
                  <p className="text-[14px] text-gray-700 font-medium md:flex-1">
                    {formData.manufactureYear}
                  </p>
                </div>

                <div className="flex flex-col sm:flex-row sm:justify-between">
                  <h2 className="text-[14px] text-gray-900 font-bold md:flex-1">
                    Part Categories:
                  </h2>
                  <p className="text-[14px] text-gray-700 font-medium md:flex-1">
                    {formData.partCategories}
                  </p>
                </div>

                <div className="flex flex-col sm:flex-row sm:justify-between">
                  <h2 className="text-[14px] text-gray-900 font-bold md:flex-1">
                    Part Sub-Categories:
                  </h2>
                  <p className="text-[14px] text-gray-700 font-medium md:flex-1">
                    {formData.partSubCategories}
                  </p>
                </div>

                <div className="flex flex-col sm:flex-row sm:justify-between">
                  <h2 className="text-[14px] text-gray-900 font-bold md:flex-1">
                    Part Number:
                  </h2>
                  <p className="text-[14px] text-gray-700 font-medium md:flex-1">
                    {formData.partNumber}
                  </p>
                </div>

                <div className="flex flex-col sm:flex-row sm:justify-between">
                  <h2 className="text-[14px] text-gray-900 font-bold md:flex-1">
                    Part Name:
                  </h2>
                  <p className="text-[14px] text-gray-700 font-medium md:flex-1">
                    {formData.partName}
                  </p>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default ViewProduct;
