import axios from "axios";
import { jwtDecode } from "jwt-decode"; // Correct import statement
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify"; 
import "react-toastify/dist/ReactToastify.css"; 
import InputWithLabel from "../../common/InputWithLabel";

const MyProfile = () => {
  const [formData, setFormData] = useState({
    username: "",
    name: "",
    password: "",
    password2: "",
    role: "user",
  });
  const [errors, setErrors] = useState({
    username: "",
    name: "",
    password: "",
    password2: "",
  });
  const [newPassword, setNewPassword] = useState(""); // New state for password fields
  const fileInputRef = useRef(null);
  const [id, setId] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showchangePassword, setShowChangePassword] = useState(false);

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const validateForm = () => {
    const newErrors = {};

    console.log("formData", formData);

    if (!formData.username) {
      newErrors.username = "Email or Phone Number is required";
    }

    if (!formData.name) {
      newErrors.name = "Full Name is required";
    }

    if (!formData.password) {
      newErrors.password = "Password is required";
    }

    if (!formData.password2) {
      newErrors.password2 = "Confirm Password is required";
    } else if (formData.password !== formData.password2) {
      newErrors.password2 = "Passwords do not match";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const fetchData = async () => {
    const token = localStorage.getItem("usertoken");
    if (!token) {
      return;
    }

    const isTokenExpired = () => {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        return decodedToken.exp < currentTime;
      } catch (error) {
        return true;
      }
    };

    if (isTokenExpired()) {
      toast.error("Please log in.");
      navigate("/login");
      return;
    } else {
      setIsLoggedIn(true);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const getuserdetails = async () => {
    const token = localStorage.getItem("usertoken");
    const username = localStorage.getItem("user");

    const url =
      process.env.REACT_APP_BASE_URL + `auth/getuserdetails/${username}/`;
    const response = await axios.get(`${url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    console.log("response", response);
    if (response.status === 200) {
      setFormData((prevState) => ({
        ...prevState,
        username: response.data.username,
        name: response.data.name,
      }));
      setId(response.data.id);
    }
  };

  useEffect(() => {
    getuserdetails();
  }, []);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const formIsValid = validateForm();

    if (formIsValid) {
      const url = process.env.REACT_APP_BASE_URL + `auth/updateuser/${id}/`;
      const token = localStorage.getItem("usertoken");
      console.log(formData, "formData");
      const response = await axios.put(`${url}`, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: formData,
      });
      if (response.status === 200) {
        toast.success("Profile updated successfully");
      } else {
        toast.error("Profile update failed");
      }
      console.log("Form submitted successfully:", formData);
    }
  };

  return (
    <div className="bg-white w-full relative">
      <div className="px-4 md:px-8 py-2 border-b-2 border-gray-200 flex items-center justify-between gap-4">
        <h1 className="font-bold text-lg text-gray-900">My Reviews</h1>
      </div>

      <form onSubmit={handleFormSubmit}>
        <div className="w-full px-4 md:px-8 py-4 md:py-8 pb-10 flex gap-6 md:gap-12 flex-col items-start md:flex-row">
          <div className="w-full md:flex-1 md:max-w-[500px] flex flex-col gap-4 2xl:max-w-[50%]">
            <div className="flex flex-col gap-1">
              <InputWithLabel
                htmlFor="username"
                label="Email or Phone Number"
                type="text"
                placeholder="testuser@gmail.com"
                name="username"
                value={formData.username}
                disabled
              />
              {errors.username && (
                <p className="text-red-900 text-[14px] font-medium">
                  {errors.username}
                </p>
              )}
            </div>

            <div className="flex flex-col gap-1">
              <InputWithLabel
                htmlFor="name"
                label="Full Name"
                type="text"
                placeholder="Swopnil Bista"
                name="name"
                value={formData.name}
                disabled
              />
              {errors.name && (
                <p className="text-red-900 text-[14px] font-medium">
                  {errors.name}
                </p>
              )}
            </div>

            {!showchangePassword && (

            <div className="grid grid-cols-2 gap-3 md:gap-6 mt-2">
              <button
                className="bg-blue text-center px-4 text-white text-sm sm:text-base font-medium rounded-md py-2"
                onClick={() => setShowChangePassword(!showchangePassword)}
              >
                Change Password
              </button>

              
            </div>
            )}

            {showchangePassword && (
              <>
                <div className="flex flex-col gap-1">
                <InputWithLabel
                htmlFor="password"
                label="Change Password"
                type="password"
                placeholder="Helloecommerce"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
              />
                  {errors.password && (
                    <p className="text-red-900 text-[14px] font-medium">
                      {errors.password}
                    </p>
                  )}
                </div>

                <div className="flex flex-col gap-1">
                  <InputWithLabel
                    htmlFor="password2"
                    label="Confirm New Password"
                    type="password"
                    placeholder="Helloecommerce"
                    name="password2"
                    value={formData.password2}
                    onChange={handleInputChange}
                  />
                  {errors.password2 && (
                    <p className="text-red-900 text-[14px] font-medium">
                      {errors.password2}
                    </p>
                  )}
                </div>

                <div className="grid grid-cols-2 gap-3 md:gap-6 mt-2">
                  <button className="bg-blue text-center px-4 text-white text-sm sm:text-base font-medium rounded-md py-2"
                  onClick={handleFormSubmit}
                  >
                    Save Changes
                  </button>

                  <button className="border border-gray-300 text-sm sm:text-base text-center px-4 text-gray-500 font-medium rounded-md py-2"
                  onClick={()=> setShowChangePassword(!showchangePassword)}
                  >
                    Cancel
                  </button>
                </div>
              </>
            )}

            
          </div>
        </div>
      </form>
    </div>
  );
};

export default MyProfile;
