
import React from "react";
import OfferCard from "../common/OfferCard";
import OfferProductImage1 from "../../../assets/images/Suspensions 1.png";
import OfferProductImage2 from "../../../assets/images/Suspensions 1 (1).png";

const Offers = () => {
  // Simulated backend data for offers
  const offers = [
    {
      id: 1,
      imageUrl: "https://thedriven.io/wp-content/uploads/2020/08/lucid-air-gallery-012.jpg",
      offerType: "Special Offer",
      offerTitle: "Automotive excellence meets unbeatable value!",
      offerDescription: "Hurry up! Limited time offer.",
      buttonText: "Grab Now",
      productImage: OfferProductImage1,
    },
    {
      id: 2,
      imageUrl: "https://thedriven.io/wp-content/uploads/2020/08/lucid-air-gallery-012.jpg",
      offerType: "Special Offer",
      offerTitle: "Get Festive Offer this weekend",
      offerDescription: "The past, present and future will continue",
      buttonText: "Grab Now",
      productImage: OfferProductImage2,
    },
  ];

  return (
    <div className="bg-gray-200 px-6 md:px-10 lg:px-20 pt-5 md:pt-10 pb-5">
      <div className="grid gap-6 xl:grid-cols-2">
        {offers.map((offer) => (
          <OfferCard
            key={offer.id}
            imageUrl={offer.imageUrl}
            offerType={offer.offerType}
            offerTitle={offer.offerTitle}
            offerDescription={offer.offerDescription}
            buttonText={offer.buttonText}
            productImage={offer.productImage}
          />
        ))}
      </div>
    </div>
  );
};

export default Offers;

