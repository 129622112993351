import axios from "axios";
import { useAtom } from "jotai";
import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from "react";
import { FaFilePdf } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify"; // Import ToastContainer and toast from react-toastify
import "react-toastify/dist/ReactToastify.css"; // Import CSS for react-toastify
import { selectedProductsForRequestAtom } from "./RequestSection";
import {
  searchResultsAtom,
  selectedProductsFromSearchAtom,
} from "./SearchSection";

const InvoiceBill = () => {
  const navigate = useNavigate();
  const [selectedProductsForRequest, setSelectedProductsForSearch] = useAtom(
    selectedProductsForRequestAtom
  );
  const [, setSearchResults] = useAtom(searchResultsAtom);
  const [, setSelectedProductsFromSearch] = useAtom(
    selectedProductsFromSearchAtom
  );

  const [loading, setLoading] = useState(false);

  const [apiData, setApiData] = useState(null);
  const [currentDate, setCurrentDate] = useState("");
  const [formData] = useState(new FormData());
  const [unidentifiedformData] = useState({
    partNumber: "",
    partName: "",
    partCategories: "",
    vehicleCompany: "",
    brand: "",
    model: "",
    quantity: "",
    order_id: "",
  });

  const location = useLocation();
  const popupFormData = location.state;

  console.log("popupFormData", popupFormData);

  console.log(selectedProductsForRequest);

  const fetchData = async () => {
    const token =
      localStorage.getItem("token") || localStorage.getItem("agenttoken");
    if (!token) {
      // Handle the case where the token is not present (e.g., redirect to login page)
      toast.error("Authentication credentials not provided. Please log in.");
      return;
    }

    // Check if the authentication token has expired (example for JWT, may vary based on implementation)
    const isTokenExpired = () => {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000; // Convert to seconds
        console.log("decodedToken", decodedToken.exp);
        console.log("currentTime", currentTime);
        console.log("isTokenExpired", decodedToken.exp < currentTime);
        return decodedToken.exp < currentTime;
      } catch (error) {
        return true; // Error decoding token or invalid token, treat as expired
      }
    };

    if (isTokenExpired()) {
      // Handle token expiration (e.g., refresh the token or redirect to login page)
      toast.error("Authentication token has expired. Please log in again.");
      navigate("/login");
      return;
    }

    const url = process.env.REACT_APP_BASE_URL + "newseller/getsellerprofile/";
    try {
      const response = await axios.get(`${url}`, {
        headers: {
          Authorization: `Bearer ${token}`, // Add the authorization token to the request header
        },
      }); // Replace with your actual API endpoint
      const apiDatas = response.data;
      setApiData(apiDatas[0]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const IMAGE_URL = process.env.REACT_APP_BASE;

  useEffect(() => {
    fetchData();

    const today = new Date();
    const formattedDate = today.toLocaleDateString();
    setCurrentDate(formattedDate);
  }, []);

  const handlePurchaseOrder = async (e) => {
    e.preventDefault();
    setLoading(true);

    console.log(selectedProductsForRequest);

    const token =
      localStorage.getItem("token") || localStorage.getItem("agenttoken");
    if (!token) {
      // Handle the case where the token is not present (e.g., redirect to login page)
      toast.error("Authentication credentials not provided. Please log in.");
      return;
    }

    // Check if the authentication token has expired (example for JWT, may vary based on implementation)
    const isTokenExpired = () => {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000; // Convert to seconds
        console.log("decodedToken", decodedToken.exp);
        console.log("currentTime", currentTime);
        console.log("isTokenExpired", decodedToken.exp < currentTime);
        return decodedToken.exp < currentTime;
      } catch (error) {
        return true; // Error decoding token or invalid token, treat as expired
      }
    };

    if (isTokenExpired()) {
      // Handle token expiration (e.g., refresh the token or redirect to login page)
      toast.error("Authentication token has expired. Please log in again.");
      navigate("/login");
      return;
    }

    const ordercreateurl =
      process.env.REACT_APP_BASE_URL + "quotation/ordercreate/";

    try {
      // Create Order
      if (selectedProductsForRequest.length > 0) {
        const productsWithId = selectedProductsForRequest.filter(
          (product) => product.productId
        );
        const productsWithoutId = selectedProductsForRequest.filter(
          (product) => !product.productId || product.productId === ""
        );

        const orderResponse = await axios.post(
          `${ordercreateurl}`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );


        const order_id = orderResponse.data.id;

        // Step 1: If there are products with productId, send them to sellers
        if (productsWithId.length > 0) {
          for (const productData of productsWithId) {
            console.log(productData);

            const quotation =
              process.env.REACT_APP_BASE_URL + "quotation/quotationcreate/";

            const quotationResponse = await axios.post(
              `${quotation}${order_id}/${productData.partId}/${productData.quantity}`,
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );

          }
        }

        // Step 2: If there are products without productId, handle them (Admin intervention)
        if (productsWithoutId.length > 0) {
          for (const productData of productsWithoutId) {
            console.log(productData);
            const formData = {
              partNumber: productData.partNumber,
              partName: productData.partName,
              partCategories: productData.partCategories,
              vehicleCompany: productData.vehicleCompany,
              brand: productData.brand,
              model: productData.model,
              quantity: productData.quantity,
              order_id: order_id,
            };

            const unidentifiedproducturl =
              process.env.REACT_APP_BASE_URL +
              "quotation/unidentified-products/";
            const quotationResponse = await axios.post(
              `${unidentifiedproducturl}`,
              formData,
              {
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
              }
            );
            if (quotationResponse.status === 201) {
              console.log(
                "Quotation created successfully:",
                quotationResponse.data
              );
            }
          }
        }
      }

      toast.success("Order and Quotations processed successfully");

      // Clear atoms
      setSelectedProductsForSearch([]);
      setSearchResults([]);
      setSelectedProductsFromSearch([]);

      setLoading(false);
      setTimeout(() => {
      navigate("/seller/business-orders/purchase-order");
      }, 1000);
    } catch (error) {
      console.error("Error processing order and quotations:", error);
      // Handle error
      setLoading(false);
      toast.error("Error processing order and quotations");
    }
  };

  useEffect(() => {
    console.log(unidentifiedformData);
  }, [unidentifiedformData]);

  return (
    <div className="mx-auto m-6 flex flex-col gap-4">
      
      <ToastContainer position="top-center" autoClose={1000} />
      <button
        className="bg-blue w-fit mx-6 px-4 py-1.5 rounded-md text-white font-medium"
        onClick={(event) => {
          event.preventDefault();
          window.history.back();
        }}
      >
        Back
      </button>
      <div className="bg-white mx-6 rounded-lg shadow-lg px-8 py-10">
        <div className="flex items-center justify-between mb-8">
          <div className="flex items-center">
            {apiData && apiData.storelogo && (
              <img
                className="h-8 w-8 mr-2"
                src={`${IMAGE_URL}${apiData.storelogo}`}
                alt="Logo"
              />
            )}
            {apiData && apiData.storename && (
              <div className="text-gray-700 font-semibold text-lg">
                {apiData.storename}
              </div>
            )}
          </div>
          <div className="text-gray-700 font-bold">
            <div className="text-sm">
              Date:<span className="font-medium"> {currentDate}</span>
            </div>
          </div>
        </div>
        <div className="pb-8 mb-4">
          <h2 className="text-2xl font-bold mb-4">Bill To:</h2>
          {apiData && apiData.storename && (
            <div className="text-gray-700 mb-2 uppercase">
              {apiData.storename}
            </div>
          )}
          {apiData && apiData.storephone && (
            <div className="text-gray-700 mb-2 uppercase">
              {apiData.storephone}
            </div>
          )}
          {apiData && apiData.storewebsite && (
            <div className="text-gray-700 mb-2 uppercase">
              {apiData.storewebsite}
            </div>
          )}
        </div>

        <div className="flex flex-col">
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
              <div className="overflow-hidden">
                <table className="min-w-full border text-center text-sm font-light">
                  <thead className="border-b font-bold text-lg">
                    <tr>
                      <th scope="col" className="border-r h-[34px] px-4 py-0">
                        SN
                      </th>
                      <th scope="col" className="border-r h-[34px] px-4 py-0">
                        Parts No.
                      </th>
                      <th scope="col" className="border-r h-[34px] px-4 py-0">
                        Parts Description
                      </th>
                      <th scope="col" className="border-r h-[34px] px-4 py-0">
                        Unit
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {selectedProductsForRequest.map((product, index) => (
                      <tr className="border-b" key={index}>
                        <td className="whitespace-nowrap border-r h-[34px] px-4 py-0 font-medium">
                          {index + 1}
                        </td>
                        <td className="whitespace-nowrap border-r h-[34px] px-4 py-0">
                          {product.partNumber}
                        </td>
                        <td className="whitespace-nowrap border-r h-[34px] px-4 py-0">
                          {product.partCategories}
                        </td>

                        <td class="whitespace-nowrap border-r h-[34px] px-4 py-0">
                          {product.quantity}
                        </td>
                      </tr>
                    ))}

                    <tr className="border-b">
                      <td className="whitespace-nowrap border-r h-[34px] px-4 py-0"></td>
                      <td className="whitespace-nowrap border-r h-[34px] px-4 py-0"></td>
                      <td className="whitespace-nowrap border-r h-[34px] px-4 py-0"></td>
                      <td className="whitespace-nowrap border-r h-[34px] px-4 py-0"></td>
                    </tr>

                    <tr className="border-b">
                      <td
                        className="whitespace-nowrap border-r h-[34px] px-4 py-0 text-start font-bold"
                        colSpan={6}
                      >
                        Prepared By: <span className="font-medium">©OSIS</span>
                      </td>
                    </tr>

                    <tr className="border-b">
                      <td
                        className="whitespace-nowrap border-r h-[34px] px-4 py-0 text-start font-bold"
                        colSpan={6}
                      >
                        Remarks: <span className="font-medium"></span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center gap-4 justify-between mt-4 mx-6">
        <button className="bg-blue px-4 py-1.5 rounded-md text-white font-medium flex items-center gap-1.5">
          <FaFilePdf />
          Download PDF
        </button>

        <button
          className="bg-blue min-w-[187.425px] px-4 py-1.5 rounded-md text-white font-medium"
          onClick={handlePurchaseOrder}
        >
          {loading ? (
            <div role="status" className="flex justify-center items-center">
              <svg
                aria-hidden="true"
                class="w-6 h-6 text-gray-300 animate-spin dark:text-gray-600 fill-white"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span class="sr-only">Loading...</span>
            </div>
          ) : (
            "Send Purchase Order"
          )}
        </button>
      </div>
    </div>
  );
};

export default InvoiceBill;
