import React, { useState, useEffect, useMemo } from "react";
import CarVector from "../../assets/images/carVector.png";
import Footer from "../../components/User/common/Footer";
import Header from "../../components/User/common/Header";
import ProductCard from "../../components/User/common/ProductCard";
import { useParams } from "react-router-dom";
import { useAtom } from "jotai";
import { selectedDetailsAtom } from "../../components/User/Search/SearchModal";
import { IoIosArrowDown, IoIosArrowDropleftCircle, IoIosArrowDroprightCircle, IoIosArrowUp } from "react-icons/io";
import { BiSortDown, BiSortUp } from "react-icons/bi";
import PartCard from "../../components/User/common/PartCard";

const FilterProduct = () => {
  const [products, setProducts] = useState([]);
  const [parts, setParts] = useState([]);
  const [sortOrder, setSortOrder] = useState("asc");
  const [foundcompany, setFoundCompany] = useState([]);
  const [foundmodel, setFoundModel] = useState([]);
  const [foundyear, setFoundYear] = useState([]);
  const [selectedFilters, setSelectedFilters] = useAtom(selectedDetailsAtom);
  const [isSortOpen, setIsSortOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const selectedCategory = selectedFilters.category;
  const selectedBrand = selectedFilters.brand;
  const model = selectedFilters.model;
  const selectedYear = selectedFilters.year;

  const { product } = useParams();
  const apiUrl = process.env.REACT_APP_BASE_URL;

  if (!apiUrl) {
    console.error(
      "REACT_APP_API_URL is not defined in the environment variables."
    );
  }
  const productUrl = apiUrl
    ? `${apiUrl}users/search/${selectedFilters.search}`
    : null;

  if (productUrl === null) {
    console.log("Product URL is null");
  }else{
    console.log("Product URL is not null");
  }

  const sortByPrice = (order) => {
    const sortedProducts = [...products].sort((a, b) => {
      const priceA = parseFloat(a.price);
      const priceB = parseFloat(b.price);
      if (order === "asc") {
        return priceA - priceB;
      } else {
        return priceB - priceA;
      }
    });
    setProducts(sortedProducts);
    setSortOrder(order);

    setIsSortOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(productUrl);
        const searchData = await response.json();

        const filteredData = searchData.filtered_result.filter(
          (item) =>
            item.subCategory === selectedCategory &&
            item.brand === selectedBrand &&
            item.manufactureYear === selectedYear &&
            item.model === model
        );

        const filteredDataIds = filteredData.map((item) => item.id);
        const idsQueryParam = filteredDataIds.join(",");

        const productDataUrl = `${apiUrl}users/search/product/?ids=${idsQueryParam}`;
        const productResponse = await fetch(productDataUrl, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        const productData = await productResponse.json();

        if (Array.isArray(productData.data)) {
          const flattenedProductData = productData.data.reduce(
            (acc, productsArray) => acc.concat(productsArray),
            []
          );

          const transformedProducts = flattenedProductData.map((product) => ({
            imageUrl: product.part_id.image,
            brand: product.part_id.brand,
            rating: 4.6,
            price: product.normalRate,
            title: product.part_id.partName,
            unit: product.units,
            id: product.id,
          }));

          const company = flattenedProductData.map(
            (product) => product.part_id.vehicleCompany
          );
          const model = flattenedProductData.map(
            (product) => product.part_id.model
          );
          const year = flattenedProductData.map(
            (product) => product.part_id.manufctureYear
          );

          setFoundCompany(company);
          setFoundModel(model);
          setFoundYear(year);

          const sortedProducts = transformedProducts.sort(
            (a, b) => parseFloat(a.price) - parseFloat(b.price)
          );
          setProducts(sortedProducts);
        } else {
          console.error("API response for products is not an array:", productData);
        }

        if (Array.isArray(productData.part)) {
          const flattenedPartData = productData.part.reduce(
            (acc, partArray) => acc.concat(partArray),
            []
          );

          const transformedParts = flattenedPartData.map((part) => ({
            imageUrl: part.image,
            brand: part.brand,
            title: part.partName,
            id: part.id,
          }));

          setParts(transformedParts);
        } else {
          console.error("API response for parts is not an array:", productData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (productUrl) {
      fetchData();
    }
  }, [productUrl, selectedCategory, selectedBrand, selectedYear, model]);

  const totalProductCount = products.length + parts.length;
  const totalproductfound = products.length;
  const totalpartfound = parts.length;

  const combinedData = useMemo(() => [...products, ...parts], [products, parts]);

  const itemsPerPage = 20;
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentProducts = useMemo(() => combinedData.slice(indexOfFirstItem, indexOfLastItem), [combinedData, indexOfFirstItem, indexOfLastItem]);

  const totalPages = Math.ceil(totalProductCount / itemsPerPage);

  return (
    <div>
      <Header showTopPart={false} showCategory={false} />
      <div className="flex flex-col gap-4 min-h-[calc(100vh_-_66.225px_-_342.8px)]">
        <div className="lg:pl-20 px-6 relative bg-blue w-full h-[100px] flex items-center">
          <div className="text-white flex flex-col gap-1">
            <p className="text-lg font-bold">Your Search Results</p>
            {isLoading ? (
              <p className="text-sm font-medium">Searching...</p>
            ) : (
              <>
                <p className="text-sm font-medium">{totalproductfound} Products Found</p>
                <p className="text-sm font-medium">{totalpartfound} parts Found</p>
              </>
            )}
          </div>
          <img src={CarVector} alt="" className="absolute h-full right-0 object-contain hidden lg:block" />
        </div>
        {isLoading ? (
          <div className=" min-h-[calc(100vh_-_66.225px_-_342.8px)] flex items-center justify-center">
            <div className="flex flex-row gap-2">
              <div className="w-4 h-4 rounded-full bg-blue-700 animate-bounce"></div>
              <div className="w-4 h-4 rounded-full bg-blue-700 animate-bounce [animation-delay:-.3s]"></div>
              <div className="w-4 h-4 rounded-full bg-blue-700 animate-bounce [animation-delay:-.5s]"></div>
            </div>{" "}
          </div>
        ) : totalproductfound > 0 || totalpartfound > 0 ? (
          <div className="w-full px-6 pb-12 md:px-10 lg:px-20 flex flex-col gap-4">
            <div className="w-full flex flex-col gap-6 xl:flex-col">
              <div className="w-full flex flex-col items-end">
                <div className="flex items-center justify-end">
                  <span className="mr-2 font-medium">Sort By:</span>
                  <div className="relative inline-block text-left">
                    <button
                      type="button"
                      className={`flex items-center gap-2 justify-center w-full px-4 py-1.5 bg-blue text-white font-medium rounded-full focus:outline-none`}
                      onClick={() => setIsSortOpen(!isSortOpen)}
                    >
                      {sortOrder === "asc" ? (
                        <BiSortUp className="text-lg" />
                      ) : (
                        <BiSortDown className="text-lg" />
                      )}
                      <span className="ml-1">
                        {sortOrder === "asc" ? "Price Low to High" : "Price High to Low"}
                      </span>
                    </button>
                    {isSortOpen && (
                      <div className="origin-top-right z-20 absolute right-0 mt-2 w-44 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1 w-full" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                          <button className="flex items-center gap-2 justify-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem" onClick={() => sortByPrice("asc")}>
                            <BiSortUp className="text-lg" />
                            Price Low to High
                          </button>
                          <button className="flex items-center gap-2 justify-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem" onClick={() => sortByPrice("desc")}>
                            <BiSortDown className="text-lg" />
                            Price High to Low
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-6 2xl:grid-cols-5">
              {currentProducts.map((item, index) => {
                if ("price" in item) {
                  // This item corresponds to product data
                  return <ProductCard key={index} {...item} />;
                } else {
                  // This item corresponds to part data
                  return <PartCard key={index} {...item} />;
                }
              })}

              </div>
              <div className="flex justify-center mt-8">
                <button className={`${currentPage === 1 ? "bg-gray-200 text-gray-400 cursor-not-allowed" : "bg-gray-200 text-gray-700"} py-2 px-4 rounded-l-md flex items-center`} onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>
                  <IoIosArrowDropleftCircle className="text-lg" />
                </button>
                <div className="px-4 my-1 text-xl">
                  Page {currentPage} of {totalPages}
                </div>
                <button className={`${currentPage === totalPages ? "bg-gray-200 text-gray-400 cursor-not-allowed" : "bg-gray-200 text-gray-700"} py-2 px-4 rounded-r-md flex items-center`} onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === totalPages}>
                  <IoIosArrowDroprightCircle className="text-lg" />
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex items-center justify-center h-[calc(100vh_-_66.225px_-_342.8px)]">
            <p className="text-[20px] font-medium text-gray-600">No Details Found</p>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default FilterProduct;
