import React from "react";
import { HiDocument } from "react-icons/hi";
import { IoClose } from "react-icons/io5";
import DocumentSample from "../../assets/images/sample.png"

const DocumentSampleModal = ({ onClose }) => {
  return (
    <div className="fixed bg-[#000000b6] z-20 px-7 min-h-screen min-w-full top-0 flex justify-center items-center">
      <div className="relative bg-white w-[95%] sm:w-[80%] max-w-[650px]">
        <div className="border-b border-gray-200">
          <div className="flex items-center justify-between px-6 py-5">
            <div className="flex items-center gap-2 text-blue-700 font-bold">
              <HiDocument className="text-[22px]" />
              <p>Sample Of Document</p>
            </div>

            <button onClick={onClose}>
              <IoClose className="text-[22px] text-gray-400" />
            </button>
          </div>
        </div>

        <div className="w-full flex justify-center py-8">
            <img src={DocumentSample} alt="" />
          </div>
      </div>
    </div>
  );
};

export default DocumentSampleModal;
