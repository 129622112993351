import axios from "axios";
import { useAtom } from "jotai";
import { jwtDecode } from "jwt-decode";
import React from "react";
import { MdPayment } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify"; // Import ToastContainer and toast from react-toastify
import "react-toastify/dist/ReactToastify.css"; // Import CSS for react-toastify
import Khalti from "../../../assets/images/3 copy.png";
import EwalletImage from "../../../assets/images/ewallet.png";
import FonepayImage from "../../../assets/images/fonepay.png";
import { totalItemValue, totalPriceValue } from "./ShoppingCart";
import { currentStepAtom } from "./StepsIndicator";

const Selectpayment_method = ({ formData, setFormData, errors, setErrors }) => {
  const [, setCurrentStep] = useAtom(currentStepAtom);
  const [, totalItem] = useAtom(totalItemValue);
  const [, totalPrice] = useAtom(totalPriceValue);
  const navigate = useNavigate();


  const handlepayment_methodSelection = (method) => {
    setFormData((prevData) => ({
      ...prevData,
      payment_method: method,
    }));
  };

  const removeAll = async () => {
    const removeall =
      process.env.REACT_APP_BASE_URL + `users/remove_all_from_cart/`;
    const token = localStorage.getItem("usertoken");
    const response = await axios.get(removeall, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      toast.success("Success", "Product Removed");
    }
  };

  const validateForm = () => {
    const { payment_method } = formData;
    const newErrors = {
      payment_method: payment_method ? "" : "Please select a payment method.",
    };
    return newErrors;
  };

  const url = process.env.REACT_APP_BASE_URL + "users/create_order/";

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = validateForm();

    if (Object.values(newErrors).some((error) => error !== "")) {
      setErrors(newErrors);
      return;
    }

    const token = localStorage.getItem("usertoken");
    if (!token) {
      // Handle the case where the token is not present (e.g., redirect to login page)
      toast.error("Authentication credentials not provided. Please log in.");
      return;
    }

    // Check if the authentication token has expired (example for JWT, may vary based on implementation)
    const isTokenExpired = () => {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000; // Convert to seconds
        console.log("decodedToken", decodedToken.exp);
        console.log("currentTime", currentTime);
        console.log("isTokenExpired", decodedToken.exp < currentTime);
        return decodedToken.exp < currentTime;
      } catch (error) {
        return true; // Error decoding token or invalid token, treat as expired
      }
    };

    if (isTokenExpired()) {
      // Handle token expiration (e.g., refresh the token or redirect to login page)
      toast.error("Authentication token has expired. Please log in again.");
      navigate("/login");
      return;
    }
    try {
      const response = await axios.post(
        url,
        formData, // formData should be sent as the second argument
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data && response.data.payment_url) {
        // Redirect user to the payment_url
        window.location.href = response.data.payment_url;
      } else {
        toast.error("Failed to Process Order. Please try again later.");
      }
      setCurrentStep(1);
      totalItem(0);
      totalPrice(0);
      setTimeout(() => {
        navigate("/");
        window.location.reload();
      }, 2000);
    } catch (error) {
      toast.error("Failed to Process Order.Order will process next time bying any items")
      // navigate("/");
      // window.location.reload();
    }
  };

  return (
    <div className="flex-1 bg-white py-6 px-6 sm:px-10 flex flex-col gap-4">
      <div className="flex items-center justify-between">
        <div className="flex text-lg items-center gap-2 text-blue-800 font-bold">
          <MdPayment className="min-w-[20px] min-h-[20px]" />
          <p>Select Payment Method</p>
        </div>
      </div>

      <div className="mb-2 grid text-center vsm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4 md:gap-6">
        <div
          className={`cursor-pointer w-full rounded-md border-2 p-2.5 flex flex-col items-center justify-end gap-1 transition-all duration-300 ${
            formData.payment_method === "cash-on-delivery"
              ? "border-blue bg-blue-500"
              : "border-gray-400"
          }`}
          onClick={() => handlepayment_methodSelection("cash-on-delivery")}
        >
          <img src={Khalti} alt="" className="w-[50px]" />
          <p className="font-medium text-blue text-[15px]">Khalti</p>
        </div>

        <div
          className={`cursor-pointer w-full rounded-md border-2 p-2.5 flex flex-col items-center justify-end gap-1 transition-all duration-300 ${
            formData.payment_method === "e-wallet"
              ? "border-blue bg-blue-500"
              : "border-gray-400"
          }`}
          onClick={() => handlepayment_methodSelection("e-wallet")}
        >
          <img src={EwalletImage} alt="" className="w-[50px]" />
          <p className="font-medium text-blue text-[15px]">E-Wallet</p>
        </div>

        <div
          className={`cursor-pointer w-full rounded-md border-2 p-2.5 flex flex-col items-center justify-end gap-1 transition-all duration-300 ${
            formData.payment_method === "fonepay"
              ? "border-blue bg-blue-500"
              : "border-gray-400"
          }`}
          onClick={() => handlepayment_methodSelection("fonepay")}
        >
          <img src={FonepayImage} alt="" className="w-[100px]" />
          <p className="font-medium text-blue text-[15px]">Fonepay</p>
        </div>

      </div>

      {errors.payment_method && (
        <p className="text-red-900 text-[13px] font-medium">
          {errors.payment_method}
        </p>
      )}

      <div className="flex items-center justify-between">
        {/* Back button */}
        <button
          className="w-fit text-[17px] border-2 border-gray-400 px-8 py-2 rounded-md text-gray-400 font-semibold"
          onClick={() =>
            setCurrentStep((prevStep) => Math.max(prevStep - 1, 3
            ))
          }
        >
          Back
        </button>
        

        <button
          type="submit"
          onClick={handleSubmit}
          className="w-full lg:w-fit text-[17px] bg-blue px-8 py-2 rounded-md text-white font-semibold"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Selectpayment_method;
