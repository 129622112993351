import { useAtom } from "jotai";
import React, { useState } from "react";
import { HiOutlineMenuAlt1 } from "react-icons/hi";
import { ReactComponent as ChevronDownSVG } from "../../../assets/svg/chevron-down.svg";
import { ReactComponent as HelpSVG } from "../../../assets/svg/help-circle.svg";
import { ReactComponent as NotificationSVG } from "../../../assets/svg/notifications-none.svg";
import BrandLogo from "../../../assets/svg/osis.svg";
import { currentStepAtom } from "../ProductManagement/AddProduct/StepsIndicator";
import { activeNavNameAtom, activeSubNavNameAtom, sidebarOpenAtom } from "./Sidebar";
import Notification from "./Notification";

const MainPageHeader = () => {
  const [activeNavName] = useAtom(activeNavNameAtom);
  const [activeSubNavName] = useAtom(activeSubNavNameAtom);
  const [currentStep] = useAtom(currentStepAtom);
  const [, setSidebarOpen] = useAtom(sidebarOpenAtom);
  const [showNotificationPopup, setShowNotificationPopup] = useState(false); // State for notification popup

  const toggleNotificationPopup = () => {
    setShowNotificationPopup(!showNotificationPopup);
  };

  return (
    <header>
      <div className="bg-gray-700 w-full h-[60px] flex items-center justify-center px-8 lg:hidden">
        <button className="absolute left-6" onClick={
          () => setSidebarOpen(true)
        }>
          <HiOutlineMenuAlt1 className="w-7 h-7 text-white" />
        </button>

        <div className={`flex items-center gap-2`}>
          <img src={BrandLogo} alt="" className="min-w-[30px]" />
          <p
            className={`text-xl font-bold text-white
          `}
          >
            Osis
          </p>
        </div>
      </div>

      <div className="px-8 min-h-[65px] border-b border-gray-400 flex items-center justify-between">
        <div>
          {currentStep !== 6 && (
            <div className="flex flex-col">
              {activeSubNavName ? (
                <h1 className="text-gray-700 font-bold">{activeSubNavName}</h1>
              ) : (
                <h1 className="text-gray-700 font-bold">{activeNavName}</h1>
              )}
              {activeSubNavName && (
                <p className="text-sm text-gray-500 font-medium">
                  {activeNavName}{" "}
                  <span className="text-gray-800 font-bold hidden sm:inline">
                    {">"} {activeSubNavName}
                  </span>
                </p>
              )}
            </div>
          )}
        </div>

        <div className="flex items-center gap-3 sm:gap-6">
          <button onClick={toggleNotificationPopup}> {/* Toggle notification popup */}
            <NotificationSVG className="w-7 h-7 text-gray-700" />
          </button>

          <button>
            <HelpSVG className="w-7 h-7 text-gray-700" />
          </button>
        </div>
      </div>

      {showNotificationPopup && 
        <div className="absolute top-[115px] lg:top-[54px] right-[160px] z-30">
          <Notification setShowNotificationPopup={setShowNotificationPopup} />
        </div>
      }
    </header>
  );
};

export default MainPageHeader;
