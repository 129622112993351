import { atom } from "jotai";
import React, { useState } from "react";
import { HiChevronDown, HiOutlineSearch } from "react-icons/hi";
import OngoingRequests from "./OngoingRequests/OngoingRequests";
import PreviousRequest from "./PreviousRequests/PreviousRequests";

export const orderNumAtom = atom(null);
export const purchaseOrderTypeAtom = atom(null);

const PurchaseOrder = () => {
  const [requestStatus, setRequestStatus] = useState("Ongoing Requests");
  const [searchQuery, setSearchQuery] = useState("");
  const [showMobileDropdown, setShowMobileDropdown] = useState(false);
  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const [mobileSearchOpen, setMobileSearchOpen] = useState(false);

  // Function to handle selecting an option in the mobile dropdown
  const handleMobileOptionSelect = (option) => {
    setRequestStatus(option);
    setShowMobileDropdown(false);
  };

  return (
    <div className={`px-8 py-6 flex flex-col gap-4`}>
      <div className="flex items-stretch justify-between lg:items-center">
        <div className="flex items-center gap-4 xl:gap-6">
          {/* Mobile dropdown for product status */}
          <div className="xl:hidden relative">
            <button
              className="flex items-center gap-1.5 text-blue font-bold text-[16px] bg-white border-2 border-gray-300 rounded-md px-2 py-2"
              onClick={() => setShowMobileDropdown(!showMobileDropdown)}
            >
              <p>{requestStatus}</p>
              <HiChevronDown className="text-[20px]" />
            </button>

            {showMobileDropdown && (
              <div className="absolute z-30 mt-2 left-2 w-48 bg-white border border-gray-300 rounded-md">
                {["Ongoing Requests", "Previous Requests"].map((option) => (
                  <button
                    key={option}
                    className={`w-full ${
                      requestStatus === option ? "bg-gray-100" : ""
                    } cursor-pointer p-1.5`}
                    onClick={(e) => {
                      handleMobileOptionSelect(option);
                      e.stopPropagation();
                    }}
                  >
                    {option}
                  </button>
                ))}
              </div>
            )}
          </div>

          {/* Desktop buttons for product status */}
          <div className="hidden xl:flex">
            <div className="flex items-center gap-6 font-medium text-[14px] text-gray-500">
              <button
                className={`${
                  requestStatus === "Ongoing Requests"
                    ? "text-blue-900 font-bold border-b-2 pb-0.5 border-blue-900"
                    : ""
                }`}
                onClick={() => setRequestStatus("Ongoing Requests")}
              >
                Ongoing Requests
              </button>

              <button
                className={`${
                  requestStatus === "Previous Requests"
                    ? "text-blue-900 font-bold border-b-2 pb-0.5 border-blue-900"
                    : ""
                }`}
                onClick={() => setRequestStatus("Previous Requests")}
              >
                Previous Requests
              </button>
            </div>
          </div>
        </div>

        <div className="flex gap-4">
          <div className="hidden px-4 py-2 sm:flex items-center gap-3 bg-white border border-gray-300 rounded-md cursor-pointer sm:cursor-pointer">
            <HiOutlineSearch className="text-gray-500 text-[20px]" />

            <input
              type="text"
              placeholder="Search"
              className="bg-white outline-none font-medium text-[14px] placeholder:text-[13px] w-[120px] placeholder:text-gray-400"
              value={searchQuery}
              onChange={handleSearchInputChange}
            />
          </div>

          <div
            className="px-4 py-2 sm:hidden items-center gap-3 bg-white border border-gray-300 rounded-md cursor-pointer sm:cursor-pointer"
            onClick={() => {
              setMobileSearchOpen(!mobileSearchOpen);
            }}
          >
            <HiOutlineSearch className="text-gray-500 text-[20px]" />
          </div>
        </div>
      </div>

      {mobileSearchOpen && (
        <div className="px-4 py-2 flex items-center gap-3 bg-white border border-gray-300 rounded-md cursor-pointer sm:cursor-pointer">
          <HiOutlineSearch className="text-gray-500 text-[20px]" />

          <input
            type="text"
            placeholder="Search"
            className="bg-white outline-none font-medium text-[14px] placeholder:text-[13px] w-[120px] placeholder:text-gray-400 sm:block"
            value={searchQuery}
            onChange={handleSearchInputChange}
          />
        </div>
      )}

      {requestStatus === "Ongoing Requests" ? (
        <OngoingRequests searchQuery={searchQuery} />
      ) : requestStatus === "Previous Requests" ? (
        <PreviousRequest searchQuery={searchQuery} />
      ) : null}
    </div>
  );
};

export default PurchaseOrder;
