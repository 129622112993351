import { jwtDecode } from "jwt-decode";
import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify"; // Import ToastContainer and toast from react-toastify
import "react-toastify/dist/ReactToastify.css"; // Import CSS for react-toastify
import ChangePwImage from "../../../../assets/images/change-pw.jpg";
import { useNavigate } from "react-router-dom";
import InputWithLabel from "../../../common/InputWithLabel";

const Setting = () => {
  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const navigate = useNavigate();

  const [error, setError] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const isStrongPassword = (password) => {
    // Define your criteria for a strong password
    const minLength = 8;
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    return (
      password.length >= minLength &&
      hasUppercase &&
      hasLowercase &&
      hasNumber &&
      hasSpecialChar
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { oldPassword, newPassword, confirmPassword } = formData;

    // Validation logic
    if (!oldPassword || !newPassword || !confirmPassword) {
      setError("Please fill in all fields.");
    } else if (newPassword === oldPassword) {
      setError("New password cannot be the same as the old password.");
    } else if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
    } else if (!isStrongPassword(newPassword)) {
      let errorMessage = "Password must contain ";
      if (newPassword.length < 8) {
        errorMessage += "at least 8 characters, ";
      }
      if (!/[A-Z]/.test(newPassword)) {
        errorMessage += "an uppercase letter, ";
      }
      if (!/[a-z]/.test(newPassword)) {
        errorMessage += "a lowercase letter, ";
      }
      if (!/\d/.test(newPassword)) {
        errorMessage += "a number, ";
      }
      if (!/[!@#$%^&*(),.?":{}|<>]/.test(newPassword)) {
        errorMessage += "a special character, ";
      }
      errorMessage = errorMessage.slice(0, -2); // Remove the trailing comma and space

      setError(errorMessage);
    } else {
      const token = localStorage.getItem("token");
      if (!token) {
        // Handle the case where the token is not present (e.g., redirect to login page)
        toast.error("Authentication credentials not provided. Please log in.");
        return;
      }

      // Check if the authentication token has expired (example for JWT, may vary based on implementation)
      const isTokenExpired = () => {
        try {
          const decodedToken = jwtDecode(token);
          const currentTime = Date.now() / 1000; // Convert to seconds
          console.log("decodedToken", decodedToken.exp);
          console.log("currentTime", currentTime);
          console.log("isTokenExpired", decodedToken.exp < currentTime);
          return decodedToken.exp < currentTime;
        } catch (error) {
          return true; // Error decoding token or invalid token, treat as expired
        }
      };

      if (isTokenExpired()) {
        // Handle token expiration (e.g., refresh the token or redirect to login page)
        toast.error("Authentication token has expired. Please log in again.");
        navigate("/login");
        return;
      }

      const url = process.env.REACT_APP_BASE_URL + "auth/updatepassword/";
      // Perform password change logic
      fetch(`${url}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      })
        .then((response) => response.json())
        .then((data) => {
          const msg = data.msg;
          console.log("Data sent successfully:", msg);
          if (msg === "Password Updated Successfully") {
            toast.success(msg);
          } else {
            toast.error(msg);
          }
        });

      setError("");
      setFormData({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
    }
  };

  return (
    <div className="relative mx-8 my-6 px-5 py-5 pb-7 bg-white flex flex-col gap-4">
      <div className="flex items-center gap-2 text-blue-800 font-bold">
        <p>Change Password</p>
      </div>

      <div className="flex justify-between gap-8 xl:gap-10 2xl:gap-14">
        <form onSubmit={handleSubmit} className="w-full flex flex-col gap-4">
          <InputWithLabel
            htmlFor="oldPassword"
            label="Old Password"
            type="password"
            placeholder="Old Password"
            name="oldPassword"
            value={formData.oldPassword}
            onChange={handleInputChange}
          />
          <InputWithLabel
            htmlFor="newPassword"
            label="New Password"
            type="password"
            placeholder="New Password"
            name="newPassword"
            value={formData.newPassword}
            onChange={handleInputChange}
          />
          <InputWithLabel
            htmlFor="confirmPassword"
            label="Confirm Password"
            type="password"
            placeholder="Confirm Password"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleInputChange}
          />
          {error && (
            <p className="text-red-900 text-[14px] font-medium">{error}</p>
          )}
          <button
            type="submit"
            className="bg-blue text-white rounded-md py-2 px-4 lg:mt-2"
            onClick={handleSubmit}
          >
            Change Password
          </button>
        </form>

        <img
          src={ChangePwImage}
          alt=""
          className="hidden xl:block max-w-[360px]"
        />
      </div>

      <ToastContainer position="top-center" autoClose={3000} />
    </div>
  );
};

export default Setting;
