import React, { useState, useEffect } from "react";
import { IoClose } from "react-icons/io5";
import ImageInput from "../../../common/ImageInput";
import InputWithLabel from "../../../common/InputWithLabel";
import DictionaryCard from "../../common/DictionaryCard";
import axios from "axios";
import Category from "../Category/Category";

const Model = ({ companyid, modelid } = {}) => {
  const [models, setModels] = useState([]);
  const [isCategory , setisCategory] = useState(false);

  const fetchData = async () => {        
    const url = process.env.REACT_APP_BASE_URL + "admin/modelinfo/" + companyid + "/" + modelid + "/"
    

    try {
        const response = await axios.get(`${url}`);
        const data = response.data;
        setModels(data);
    } catch (error) {
        console.error("Error fetching data from API:", error);
    }
};

useEffect(() => {
    fetchData();
}, []);



  const IMAGE_URL = process.env.REACT_APP_BASE;
  return (
    <>
    {isCategory ? <> </> : (
    <div>
      <div className="bg-white rounded-md p-4 flex flex-col gap-6">

        <div className="grid grid-cols-4 gap-6">
          {models.map((model) => (
            <DictionaryCard
              key={model}
              imageSrc={
                model.image
                  ? `${IMAGE_URL}/${model}`
                  : "https://via.placeholder.com/250"
              }
              name={model}
            />
          ))}
        </div>
      </div>


    </div>
    )}
    </>
  );
};

export default Model;
