import React, { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const InputWithLabel = ({
  htmlFor,
  label,
  type,
  placeholder,
  name,
  value,
  onChange,
  isTextArea = false, // New prop to indicate if it's a textarea
  isDisabled = false,
  rows = 4, // Number of rows for textarea (customize as needed)
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const InputElement = isTextArea ? (
    <textarea
      id={htmlFor}
      placeholder={placeholder}
      name={name}
      value={value}
      onChange={onChange}
      rows={rows}
      className="w-full text-[14px] outline-none font-medium placeholder:text-gray-300 placeholder:font-normal"
    />
  ) : (
    <input
      type={showPassword ? "text" : type}
      id={htmlFor}
      placeholder={placeholder}
      name={name}
      value={value}
      onChange={onChange}
      disabled={isDisabled}
      className="w-full text-[14px] bg-white disabled:bg-white outline-none font-medium placeholder:text-gray-300 placeholder:font-normal"
    />
  );

  return (
    <div className={`w-full flex flex-col gap-1.5 items-start`}>
      {label && (
        <label
          htmlFor={htmlFor}
          className="text-[14px] uppercase text-gray-800 font-bold"
        >
          {label}
        </label>
      )}

      <div className="w-full bg-white flex items-center justify-between gap-4 border border-gray-400 rounded-md px-3 py-2">
        {InputElement}

        {type === "password" && (
          <div onClick={togglePasswordVisibility} className="cursor-pointer">
            {showPassword ? (
              <AiOutlineEye className="w-5 h-5 text-gray-400" />
            ) : (
              <AiOutlineEyeInvisible className="w-5 h-5 text-gray-400" />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default InputWithLabel;
