import { atom, useAtom } from "jotai";
import React, { useEffect } from "react";
import "../../../../../styles/table.css";
import AllRequestsTableRow from "./AllRequestsTableRow";

export const rowsPerPageAtom = atom(10);
export const isProductEditModeAtom = atom(false);
export const productIdAtom = atom(null);
export const dataAtom = atom([]);

const AllRequestsTable = ({ startIndex, endIndex, searchQuery }) => {
  const [data, setData] = useAtom(dataAtom);
  const [, setIsEditButtonClicked] = useAtom(isProductEditModeAtom);
  const [, setProductId] = useAtom(productIdAtom);

  // Filter data based on the search query
  const filteredData = data.filter((item) =>
    Object.values(item).some((value) =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  useEffect(() => {
    async function fetchData() {
      const manageproducturl=process.env.REACT_APP_BASE_URL+"sellerdashboard/api/manageproduct/"
      try {
        const response = await fetch(
          `${manageproducturl}`
        );
        const data = await response.json();
        console.log(data);
        setData(data);
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);

  return (
    <div className="relative bg-white border-2 border-gray-400 overflow-x-auto whitespace-nowrap table-container">
      <table className="w-full text-white text-start border-spacing-4">
        <div className="w-full  border-gray-400 bg-gray-300 text-gray-900 text-[14px] font-bold px-5 py-3 uppercase sm:hidden">
          <h1>All Product Detail</h1>
        </div>

        <thead className="text-gray-900 border-y-2 border-gray-400 uppercase hidden sm:table-header-group">
          <tr>
            <th className="text-start">Part Number</th>
            <th className="text-start">Part Name</th>
            <th className="text-start">Part Category</th>
            <th className="text-start">Vehicle Company</th>
            <th className="text-start">Brand</th>
            <th className="text-start">Model</th>
            <th className="text-start">Quantity</th>
            <th className="text-start">Action</th>
          </tr>
        </thead>

        <tbody className="text-gray-700">
          {filteredData.slice(startIndex, endIndex).map((item, index) => (
            <AllRequestsTableRow
              key={index}
              formData={item}
              onEditClick={() => {
                console.log("Edit button clicked");
                setIsEditButtonClicked(true);
                setProductId(index);
              }}
              onDeleteClick={() => {
                console.log("Delete button clicked");

                // Delete product from the database with productId
              }}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AllRequestsTable;
