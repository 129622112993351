import axios from "axios";
import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from "react";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { BsBank2 } from "react-icons/bs";
import { FaTruck, FaTruckPickup } from "react-icons/fa";
import { ImLocation } from "react-icons/im";
import { IoPersonSharp } from "react-icons/io5";

import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { ToastContainer, toast } from "react-toastify"; // Import ToastContainer and toast from react-toastify
import "react-toastify/dist/ReactToastify.css"; // Import CSS for react-toastify
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const Profile = () => {
  const [data, setData] = useState({
    avatarSrc: null,
    name: "",
    email: "",
    logoSrc: null,
    storeName: "",
    phone: "",
    label: "",
    website: "",
    description: "",
    storeAddress: "",
    province: "",
    area: "",
    district: "",
    additionalPhone: "",
    addressDescription: "",
    deliverystoreAddress: "",
    deliveryprovince: "",
    deliveryarea: "",
    deliverydistrict: "",
    deliveryadditionalPhone: "",
    deliveryaddressDescription: "",
    pickupstoreAddress: "",
    pickupprovince: "",
    pickuparea: "",
    pickupdistrict: "",
    pickupadditionalPhone: "",
    pickupaddressDescription: "",
    deliveryaddressownerName: "",
    registrationNo: "",
    businessDoc: null,
    bankPassbook: "",
  });
  const [showPickupAddress, setShowPickupAddress] = useState(false);
  const [showDeliveryAddress, setShowDeliveryAddress] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");

        // Check if the authentication token has expired (example for JWT, may vary based on implementation)
        const isTokenExpired = () => {
          try {
            const decodedToken = jwtDecode(token);
            const currentTime = Date.now() / 1000; // Convert to seconds
            console.log("decodedToken", decodedToken.exp);
            console.log("currentTime", currentTime);
            console.log("isTokenExpired", decodedToken.exp < currentTime);
            return decodedToken.exp < currentTime;
          } catch (error) {
            return true; // Error decoding token or invalid token, treat as expired
          }
        };

        if (isTokenExpired()) {
          // Handle token expiration (e.g., refresh the token or redirect to login page)
          toast.error("Authentication token has expired. Please log in again.");
          navigate("/login");
          return;
        }
        const url = process.env.REACT_APP_BASE_URL + "newseller/profile/";
        const response = await axios.get(`${url}`, {
          headers: {
            Authorization: `Bearer ${token}`, // Add the authorization token to the request header
          },
        });
        const apiData = response.data[0];
        console.log("Api data from profile", apiData);

        if (
          apiData.detail === "Authentication credentials were not provided."
        ) {
          toast.error("Authentication credentials were not provided.");
          navigate("/login");
          return;
        }

        // Transform the API data into the desired format
        const transformedData = {
          avatarSrc: apiData.sellerprofile?.storelogo || null,
          name: apiData.sellerprofile?.storename || "",
          email: apiData.sellerprofile?.storeemail || "",
          logoSrc: apiData.sellerprofile?.storelogo || null,
          storeName: apiData.sellerprofile?.storename || "",
          phone: apiData.sellerprofile?.storephone || "",
          label: apiData.sellerprofile?.storetype || "",
          website: apiData.sellerprofile?.storeemail || "",
          description: apiData.sellerprofile?.storedescription || "",
          storeAddress: apiData.selleraddress?.address?.address || "",
          province: apiData.selleraddress?.address?.province || "",
          area: apiData.selleraddress?.address?.area || "",
          district: apiData.selleraddress?.address?.district || "",
          additionalPhone:
            apiData.selleraddress?.address?.additionalphone || "",
          addressDescription:
            apiData.selleraddress?.address?.descriptiveaddress || "",
          deliverystoreAddress:
            apiData.selleraddress?.deliveryaddress?.address || "",
          deliveryprovince:
            apiData.selleraddress?.deliveryaddress?.province || "",
          deliveryarea: apiData.selleraddress?.deliveryaddress?.area || "",
          deliverydistrict:
            apiData.selleraddress?.deliveryaddress?.district || "",
          deliveryadditionalPhone:
            apiData.selleraddress?.deliveryaddress?.additionalphone || "",
          deliveryaddressDescription:
            apiData.selleraddress?.deliveryaddress?.descriptiveaddress || "",
          pickupstoreAddress:
            apiData.selleraddress?.pickupaddress?.address || "",
          pickupprovince: apiData.selleraddress?.pickupaddress?.province || "",
          pickuparea: apiData.selleraddress?.pickupaddress?.area || "",
          pickupdistrict: apiData.selleraddress?.pickupaddress?.district || "",
          pickupadditionalPhone:
            apiData.selleraddress?.pickupaddress?.additionalphone || "",
          pickupaddressDescription:
            apiData.selleraddress?.pickupaddress?.descriptiveaddress || "",
          deliveryaddressownerName: apiData.documents?.ownername || "",
          registrationNo: apiData.documents?.businessregdnumber || "",
          businessDoc: Array.isArray(apiData.documents?.businessdocuments) ? 
                apiData.documents.businessdocuments.map(doc => doc.image) : [],
          bankPassbook: apiData.documents?.bankdocument || "",
        };

        console.log("TRANSFORNED DATA", transformedData);

        setData(transformedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  const imageUrl = process.env.REACT_APP_BASE;

  const sliderSettings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: (
      <div>
        <button className="relative -top-6 z-20 right-1 bg-gray-200 rounded-full w-8 h-8 flex items-center justify-center text-gray-700 text-2xl focus:outline-none">
          <span className="sr-only">Next</span>
          <span className="font-bold">{" > "}</span>
        </button>
      </div>
    ),
    prevArrow: (
      <div>
        <button className="relative -top-6 z-20 -left-2 bg-gray-200 rounded-full w-8 h-8 flex items-center justify-center text-gray-700 text-2xl focus:outline-none">
          <span className="sr-only">Previous</span>
          <span className="font-bold">{" < "}</span>
        </button>
      </div>
    ),
  };

  return (
    <div className="relative mx-8 my-6 px-5 py-5 pb-7 bg-white flex flex-col gap-4">
      <div className="w-full flex flex-col gap-1.5">
        <div aria-label="header" className="flex space-x-4 items-center p-4">
          <div
            aria-label="avatar"
            className="flex mr-auto items-center space-x-4"
          >
            <img
              src={`${imageUrl}${data.avatarSrc}`}
              alt={`avatar ${data.name}`}
              className="w-12 h-12 shrink-0 rounded-full"
            />
            <div className="flex flex-col">
              <div className="font-medium relative text-xl leading-tight text-gray-900">
                <span className="flex">
                  <span className="truncate relative pr-8">{data.name}</span>
                </span>
              </div>
              <p className="font-normal text-base leading-tight text-gray-500 truncate">
                {data.email}
              </p>
            </div>
          </div>
        </div>
        <div aria-label="navigation" className="py-1"></div>
      </div>

      <div className="px-3 flex flex-col gap-2">
        <div className="flex items-center space-x-2 font-semibold text-blue leading-8">
          <IoPersonSharp />
          <span className="tracking-wide">Profile Information</span>
        </div>

        <div className="w-full">
          <div className="text-gray-700">
            <div className="flex flex-col sm:grid sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-3 text-sm xl:gap-4">
              <div className="w-full flex flex-col gap-1.5">
                <div className="text-[15px] font-medium">Store Name:</div>
                <div className="">{data.storeName}</div>
              </div>

              <div className="w-full flex flex-col gap-1.5">
                <div className="text-[15px] font-medium">Phone No:</div>
                <div className="">{data.phone}</div>
              </div>

              <div className="w-full flex flex-col gap-1.5">
                <div className="text-[15px] font-medium">Email:</div>
                <div className="">{data.email}</div>
              </div>

              <div className="w-full flex flex-col gap-1.5">
                <div className="text-[15px] font-medium">Label:</div>
                <div className="">{data.label}</div>
              </div>

              <div className="w-full flex flex-col gap-1.5">
                <div className="text-[15px] font-medium">Website:</div>
                <div className="">{data.website}</div>
              </div>

              <div className="w-full flex flex-col gap-1.5">
                <div className="text-[15px] font-medium">Description:</div>
                <div className="">{data.description}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="px-3 flex flex-col gap-2">
        <div className="flex items-center space-x-2 font-semibold text-blue leading-8">
          <ImLocation />
          <span className="tracking-wide">Address</span>
        </div>
        <div className="text-gray-700">
          <div className="flex flex-col sm:grid sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-3 text-sm xl:gap-4">
            <div className="w-full flex flex-col gap-1.5">
              <div className="text-[15px] font-medium">Store Address:</div>
              <div className="">{data.storeAddress}</div>
            </div>

            <div className="w-full flex flex-col gap-1.5">
              <div className="text-[15px] font-medium">Province:</div>
              <div className="">{data.province}</div>
            </div>
            <div className="w-full flex flex-col gap-1.5">
              <div className="text-[15px] font-medium">Area:</div>
              <div className="">{data.area}</div>
            </div>
            <div className="w-full flex flex-col gap-1.5">
              <div className="text-[15px] font-medium">District:</div>
              <div className="">{data.district}</div>
            </div>
            <div className="w-full flex flex-col gap-1.5">
              <div className="text-[15px] font-medium">
                Additional Phone Num:
              </div>
              <div className="">{data.additionalPhone}</div>
            </div>
            <div className="w-full flex flex-col gap-1.5">
              <div className="text-[15px] font-medium">
                {" "}
                Address Description:
              </div>
              <div className="">{data.addressDescription}</div>
            </div>
          </div>
        </div>
      </div>

      {/* Pickup Address */}
      <div className="px-3 flex flex-col gap-2">
        <div
          className="flex cursor-pointer items-center space-x-2 font-semibold text-blue leading-8"
          onClick={() => setShowPickupAddress(!showPickupAddress)}
        >
          <FaTruckPickup className="text-xl" />
          <span className="tracking-wide">Pickup Address</span>
          <button>
            {showPickupAddress ? (
              <BiChevronUp className="text-xl" />
            ) : (
              <BiChevronDown className="text-xl" />
            )}
          </button>
        </div>

        {showPickupAddress && (
          <div className="text-gray-700">
            <div className="flex flex-col sm:grid sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-3 text-sm xl:gap-4">
              <div className="w-full flex flex-col gap-1.5">
                <div className="text-[15px] font-medium">Store Address:</div>
                <div className="">{data.pickupstoreAddress}</div>
              </div>

              <div className="w-full flex flex-col gap-1.5">
                <div className="text-[15px] font-medium">Province:</div>
                <div className="">{data.pickupprovince}</div>
              </div>
              <div className="w-full flex flex-col gap-1.5">
                <div className="text-[15px] font-medium">Area:</div>
                <div className="">{data.pickuparea}</div>
              </div>
              <div className="w-full flex flex-col gap-1.5">
                <div className="text-[15px] font-medium">District:</div>
                <div className="">{data.pickupdistrict}</div>
              </div>
              <div className="w-full flex flex-col gap-1.5">
                <div className="text-[15px] font-medium">
                  Additional Phone Num:
                </div>
                <div className="">{data.pickupadditionalPhone}</div>
              </div>
              <div className="w-full flex flex-col gap-1.5">
                <div className="text-[15px] font-medium">
                  {" "}
                  Address Description:
                </div>
                <div className="">{data.pickupaddressDescription}</div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Delivery Address */}
      <div className="px-3 flex flex-col gap-2">
        <div
          className="flex cursor-pointer items-center space-x-2 font-semibold text-blue leading-8"
          onClick={() => setShowDeliveryAddress(!showDeliveryAddress)}
        >
          <FaTruck />
          <span className="tracking-wide">Delivery Address</span>
          <button>
            {showDeliveryAddress ? (
              <BiChevronUp className="text-xl" />
            ) : (
              <BiChevronDown className="text-xl" />
            )}
          </button>
        </div>
        {showDeliveryAddress && (
          <div className="text-gray-700">
            <div className="flex flex-col sm:grid sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-3 text-sm xl:gap-4">
              <div className="w-full flex flex-col gap-1.5">
                <div className="text-[15px] font-medium">Store Address:</div>
                <div className="">{data.deliverystoreAddress}</div>
              </div>

              <div className="w-full flex flex-col gap-1.5">
                <div className="text-[15px] font-medium">Province:</div>
                <div className="">{data.deliveryprovince}</div>
              </div>
              <div className="w-full flex flex-col gap-1.5">
                <div className="text-[15px] font-medium">Area:</div>
                <div className="">{data.deliveryarea}</div>
              </div>
              <div className="w-full flex flex-col gap-1.5">
                <div className="text-[15px] font-medium">District:</div>
                <div className="">{data.deliverydistrict}</div>
              </div>
              <div className="w-full flex flex-col gap-1.5">
                <div className="text-[15px] font-medium">
                  Additional Phone Num:
                </div>
                <div className="">{data.deliveryadditionalPhone}</div>
              </div>
              <div className="w-full flex flex-col gap-1.5">
                <div className="text-[15px] font-medium">
                  {" "}
                  Address Description:
                </div>
                <div className="">{data.deliveryaddressDescription}</div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="px-3 flex flex-col gap-2">
        <div className="flex items-center space-x-2 font-semibold text-blue leading-8">
          <BsBank2 />
          <span className="tracking-wide">Verify Id & Bank</span>
        </div>
        <div className="text-gray-700">
          <div className="grid md:grid-cols-2 items-start gap-4 text-sm">
            <div className="md:col-span-2">
              <div className="text-[15px] font-medium">
                Business Registration No:
              </div>
              <div className="">{data.registrationNo}</div>
            </div>

            <div className="">
              <div className="px-4 py-1 font-semibold">Business Doc</div>
              <div className="px-4 py-1">
                {Array.isArray(data.businessDoc) &&
                data.businessDoc.length > 1 ? (
                  <div className="w-[80%]">
                    <Slider {...sliderSettings}>
                      {data.businessDoc.map((image, index) => (
                        <img
                          key={index}
                          src={`${imageUrl}${image}`}
                          alt={`Business Doc ${index}`}
                          className="w-full max-h-[420px] md:max-h-max object-fill"
                        />
                      ))}
                    </Slider>
                  </div>
                ) : (
                  <img
                    src={
                      data.businessDoc
                        ? `${imageUrl}${data.businessDoc}`
                        : "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/832px-No-Image-Placeholder.svg.png"
                    }
                    alt="Business Document"
                    className="w-full max-w-[350px] max-h-[420px] md:max-h-max object-fill"
                  />
                )}
              </div>
            </div>

            <div className="w-full flex flex-col gap-1.5">
              <div className="text-[15px] font-medium">
                Bank Passbook/Cheque:
              </div>
              <div className="">
                <img
                  // src={`${imageUrl}${data.bankPassbook}`}
                  src={
                    data.bankPassbook
                      ? `${imageUrl}${data.bankPassbook}`
                      : "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png"
                  }
                  alt="Company Logo"
                  className="w-full max-w-[350px] max-h-[420px] object-fill"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer position="top-center" autoClose={3000} />
    </div>
  );
};

export default Profile;
