import React, { useEffect, useState } from "react";
import AccerssoriesImg from "../../assets/images/category/accessories.png";
import ElectronicsImg from "../../assets/images/category/electronics.png";
import LightingImg from "../../assets/images/category/lighting.png";
import SearchResultPart from "../../components/User/SearchPart/SearchResultPart";
import DifferentProducts from "../../components/User/ViewModel/DifferentProducts";
import Footer from "../../components/User/common/Footer";
import Header from "../../components/User/common/Header";
import "../../styles/table.css";

const staticData = {
  imageUrl:
    "https://cdni.autocarindia.com/utils/imageresizer.ashx?n=https://cms.haymarketindia.net/model/uploads/modelimages/Hyundai-Grand-i10-Nios-200120231541.jpg&w=872&h=578&q=75&c=1",
  title: "Mahindra Alturas test",
  dateRange: "07.2018 - 12.2022",
  details: [
    { label: "Engine", value: "22" },
    { label: "Engine type", value: "Diesel" },
    { label: "Body type", value: "Off-road vehicles" },
    { label: "Part Number", value: "ZR 595" },
  ],
  description:
    "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Temporibus adipisci modi vitae minus expedita ullam fugit mollitia quis suscipit architecto culpa eligendi sint exercitationem eaque aut odio, ut neque sit?",
};

const ViewModel = () => {
  const [modelData, setModelData] = useState({
    imageUrl: "",
    title: "",
    dateRange: "",
    details: [],
    description: "",
  });

  useEffect(() => {
    setModelData(staticData);
  }, []);

  const modificationsData = [
    {
      modification: "Test Modification",
      year: "2022",
      engine: "V8",
      engineType: "Gasoline",
      powerHp: "300",
      powerKW: "224",
      engineCode: "ABC123",
    },
    {
      modification: "Test Modification",
      year: "2022",
      engine: "V8",
      engineType: "Gasoline",
      powerHp: "300",
      powerKW: "224",
      engineCode: "ABC123",
    },
    {
      modification: "Test Modification",
      year: "2022",
      engine: "V8",
      engineType: "Gasoline",
      powerHp: "300",
      powerKW: "224",
      engineCode: "ABC123",
    },
  ];

  const partsData = [
    {
      title: "Maintenance Service Parts",
      partsList: [
        "Timing Belt",
        "Front Brake Pads",
        "Drum Brake",
        "Rear Brake Disc",
      ],
      imageSrc: AccerssoriesImg,
    },
    {
      title: "Electronics",
      partsList: [
        "Timing Belt",
        "Front Brake Pads",
        "Drum Brake",
        "Rear Brake Disc",
      ],
      imageSrc: ElectronicsImg,
    },
    {
      title: "Lighting",
      partsList: [
        "Timing Belt",
        "Front Brake Pads",
        "Drum Brake",
        "Rear Brake Disc",
      ],
      imageSrc: LightingImg,
    },
    // Add more sets of data as needed
  ];

  return (
    <div>
      <Header showTopPart={false} showCategory={false} />

      <div className="relative">
        <div className="absolute top-0 left-0 bg-blue w-full min-h-[300px]"></div>

        <div className="relative z-10 px-6 md:px-10 lg:px-20 flex flex-col gap-10">
          <div className="pt-5 md:pt-10 flex flex-col gap-2">
            <div>
              <p className="text-white ">Home - Hyundai</p>
            </div>

            <div className="p-4 sm:p-6 pb-6 sm:pb-10 bg-white flex flex-col gap-4">
              <div className="grid md:grid-cols-2 gap-8">
                <div>
                  <img src={modelData.imageUrl} alt="" />
                </div>

                <div className="font-medium text-gray-500 flex flex-col gap-7">
                  <div className="flex flex-col gap-2">
                    <h1 className="text-xl font-bold text-gray-900">
                      {modelData.title}
                    </h1>
                    <p className="text-[15px]">{modelData.dateRange}</p>
                  </div>

                  <ul className="flex flex-col gap-2.5">
                    {modelData.details.map((item, index) => (
                      <li key={index}>
                        {item.label}: {item.value}
                      </li>
                    ))}
                  </ul>

                  <p className="">{modelData.description}</p>
                </div>
              </div>

              <div className="overflow-x-auto whitespace-nowrap table-container">
                <table className="w-full text-white text-center border-spacing-4">
                  <thead className="text-gray-800 bg-[#ECF9FF] uppercase">
                    <tr>
                      <th className="text-center">Modification</th>
                      <th className="text-center">Year</th>
                      <th className="text-center">Engine</th>
                      <th className="text-center">Engine Type</th>
                      <th className="text-center">Power(hp)</th>
                      <th className="text-center">Power(KW)</th>
                      <th className="text-center">Engine Code</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>

                  <tbody className="text-gray-700">
                    {modificationsData.map((modification, index) => (
                      <tr
                        key={index}
                        className="border-b-2 border-gray-300 text-gray-700 relative hover:bg-gray-100"
                      >
                        <td>
                          <div className="max-w-[180px] 2xl:max-w-[250px]  gap-2 lg:gap-3 pr-6">
                            <span className="whitespace-break-spaces leading-5">
                              {modification.modification}
                            </span>
                          </div>
                        </td>
                        <td className="text-center">{modification.year}</td>
                        <td className="text-center">{modification.engine}</td>
                        <td className="text-center">
                          {modification.engineType}
                        </td>
                        <td className="text-center">{modification.powerHp}</td>
                        <td className="text-center">{modification.powerKW}</td>
                        <td className="text-center">
                          {modification.engineCode}
                        </td>
                        <td className="flex justify-center">
                          <button className="bg-blue px-4 py-1.5 text-sm text-white font-medium rounded-[4px]">
                            Choose
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="bg-white px-4 py-4 sm:p-6 mt-6 flex flex-col gap-4">
              <div className="flex items-center gap-4">
                <button className="font-bold text-blue border-b-2 underline-offset-8 text-[13px] vsm:text-[14px] sm:text-[15px]">
                  Parts & Accessories
                </button>

                <div className="bg-gray-300 w-[2px] h-[15px]"></div>

                <button className="font-medium text-gray-500 text-[13px] vsm:text-[14px] sm:text-[15px]">
                  Low Price Spare Parts
                </button>
              </div>

              <div className="grid md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 md:gap-6">
                {partsData.map((data, index) => (
                  <SearchResultPart key={index} {...data} />
                ))}
              </div>
            </div>

            <DifferentProducts />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ViewModel;
