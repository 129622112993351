import axios from "axios";
import { atom, useAtom } from "jotai";
import React, { useEffect, useRef, useState } from "react";
import { BiSolidDownload } from "react-icons/bi";
import { FaArrowLeft } from "react-icons/fa";
import { HiPlus } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputWithLabel from "../../../common/InputWithLabel";
import TagsInputContainer from "../../../common/TagsInputContainer";
import {
  adminPartEditDataAtom,
  isAdminPartEditActiveAtom,
} from "../ManageProduct/ManageProducts";

export const formDataAtom = atom({
  vehicleCompany: "",
  subCategory: "",
  availability: "In Stock",
  brand: "",
  model: "",
  manufactureYear: "",
  partCategories: "",
  partSubCategories: "",
  partNumbers: [],
  partName: "",
  description: "",
  image: null,
  otherAttributes: Array.from({ length: 5 }, (_, index) => ({
    attributeName: `Attribute ${index + 1}`,
    attributeValue: "",
  })),
});

export const isAddPartSuccessAtom = atom(false);

const AddPart = () => {
  const [formData, setFormData] = useAtom(formDataAtom);
  const [isAdminPartEditActive, setIsAdminPartEditActive] = useAtom(
    isAdminPartEditActiveAtom
  );
  const [adminPartEditData, setAdminPartEditData] = useAtom(
    adminPartEditDataAtom
  );

  console.log("adminPartEditData", adminPartEditData);

  const navigate = useNavigate();

  const [partNumbersList, setPartNumbersList] = useState([]);

  const [updateLoading, setUpdateLoading] = useState(false);
  const [continueLoading, setContinueLoading] = useState(false);

  useEffect(() => {
    if (isAdminPartEditActive && adminPartEditData) {
      // set partNumbersList to the partNumber field of the adminPartEditData
      setPartNumbersList(adminPartEditData.partNumber.split(","));
    }
  }, [isAdminPartEditActive, adminPartEditData]);

  const [errors, setErrors] = useState({
    vehicleCompany: "",
    subCategory: "",
    brand: "",
    model: "",
    manufactureYear: "",
    partCategories: "",
    partSubCategories: "",
    partNumbers: "",
    partName: "",
    stockQuantity: "",
    sellingPrice: "",
    units: "",
    description: "",
    image: "",
    otherAttributes: "",
  });
  const [tagError, setTagError] = useState("");
  const [, setIsAddPartSuccess] = useAtom(isAddPartSuccessAtom);
  const [numOtherAttributes, setNumOtherAttributes] = useState(1);

  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState("");

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleInputChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const onChange = (file) => {
    setFormData({ ...formData, image: file });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    onChange && onChange(file);

    if (!file) {
      // Set an error message for the image input
      setErrors((prevErrors) => ({
        ...prevErrors,
        image: "Image is required",
      }));
    } else {
      // Clear the error message when a valid file is selected
      setErrors((prevErrors) => ({
        ...prevErrors,
        image: "",
      }));
    }

    // Set the file name for display
    setFileName(file ? file.name : "");
  };

  const handleTagAddDelete = (partNumber) => {
    if (isAdminPartEditActive) {
      setPartNumbersList((prevList) => {
        if (prevList.includes(partNumber)) {
          return prevList.filter((number) => number !== partNumber);
        } else {
          return [...prevList, partNumber];
        }
      });
    } else {
      setFormData((prevFormData) => {
        const partNumbers = Array.isArray(prevFormData.partNumbers)
          ? [...prevFormData.partNumbers]
          : [];
        if (partNumbers.includes(partNumber)) {
          return {
            ...prevFormData,
            partNumbers: partNumbers.filter((number) => number !== partNumber),
          };
        } else {
          return {
            ...prevFormData,
            partNumbers: [...partNumbers, partNumber],
          };
        }
      });
    }
  };

  const handleOtherAttributesChange = (index, name, value) => {
    setFormData((prevData) => {
      const otherAttributes = [...prevData.otherAttributes];
      otherAttributes[index] = {
        ...otherAttributes[index],
        [name]: value,
      };
      return {
        ...prevData,
        otherAttributes,
      };
    });
  };

  const clearError = (fieldName) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: "",
    }));
  };

  const handleAddRow = () => {
    if (numOtherAttributes < 5) {
      setNumOtherAttributes((prevNum) => prevNum + 1);
    }
  };

  const validateForm = () => {
    const {
      vehicleCompany,
      subCategory,
      brand,
      model,
      manufactureYear,
      partCategories,
      partSubCategories,
      partNumbers,
      partName,
      description,
      image,
      otherAttributes,
    } = formData;

    const newErrors = {
      vehicleCompany: vehicleCompany ? "" : "Vehicle company is required",
      subCategory: subCategory ? "" : "Sub-category is required",
      brand: brand ? "" : "Brand is required",
      model: model ? "" : "Model is required",
      manufactureYear: manufactureYear ? "" : "Year of manufacture is required",
      partCategories: partCategories ? "" : "Part category is required",
      partSubCategories: partSubCategories
        ? ""
        : "Part sub-category is required",
      partNumbers: partNumbers ? "" : "Part number is required",
      partName: partName ? "" : "Part name is required",
      description: description ? "" : "Description is required",
      image: image ? "" : "Image is required",
      otherAttributes:
        otherAttributes.filter((attribute) => attribute.attributeValue !== "")
          .length > 0
          ? ""
          : "At least one other attribute is required",
    };

    return newErrors;
  };

  const validateTags = () => {
    if (formData.partNumbers && formData.partNumbers.length === 0) {
      return "Please add at least one part number.";
    }
    return "";
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    console.log(formData);

    const newErrors = validateForm();
    const tagValidationError = validateTags();

    if (tagValidationError) {
      setTagError(tagValidationError);
    }

    if (Object.values(newErrors).some((error) => error !== "")) {
      setErrors(newErrors);
      return;
    }

    setIsAddPartSuccess(true);
  };

  const UpdateFormSubmit = async (e) => {
    e.preventDefault();

    // Set loading state to true
    setUpdateLoading(true);

    // Validate partNumbersList
    if (partNumbersList.length === 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        partNumbers: "Part number is required",
      }));
      // Set loading state to false
      setUpdateLoading(false);
      return; // Stop form submission
    }

    // Create a copy of formData without the partNumber field
    const { partNumber, ...formDataWithoutPartNumber } = formData;

    const updatedFormData = {
      ...formDataWithoutPartNumber,
      partNumbers: partNumbersList,
    };

    console.log(updatedFormData);

    // Check if image is present
    // if (!adminPartEditData.coverPhoto) {
    //   setErrors((prevErrors) => ({
    //     ...prevErrors,
    //     image: "Image is required",
    //   }));
    //   // Set loading state to false
    //   setUpdateLoading(false);
    //   return; // Stop form submission
    // }

    const partId = formData.productId;

    const url =
      process.env.REACT_APP_BASE_URL + "admin/update-part/" + partId + "/";
    try {
      const response = await axios.put(`${url}`, updatedFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status === 200) {
        toast.success("Part updated successfully");
        navigate("/admin/product-management/manage");
      } else {
        toast.error("Error updating part");
      }
    } catch (error) {
      // Handle error
      toast.error("Error updating part");
    } finally {
      // Set loading state to false after form submission completes
      setUpdateLoading(false);
    }
  };

  useEffect(() => {
    if (isAdminPartEditActive && adminPartEditData) {
      console.log(
        "Admin part edit data:",
        adminPartEditData.otherAttributes.length
      );

      if (adminPartEditData.otherAttributes.length === 0) {
        adminPartEditData.otherAttributes = Array.from(
          { length: 5 },
          (_, index) => ({
            attributeName: `Attribute ${index + 1}`,
            attributeValue: "",
          })
        );

        setFormData(adminPartEditData);
      } else if (adminPartEditData.partNumber === undefined) {
        adminPartEditData.partNumber = "";

        setFormData(adminPartEditData);
      } else {
        setFormData(adminPartEditData);

        const partNumbers = adminPartEditData.partNumber.split(",");

        if (partNumbers[0] !== "") {
          setPartNumbersList(partNumbers);
        } else {
          setPartNumbersList([]); // Ensure it's initialized as an empty array
        }
      }
    }
  }, [isAdminPartEditActive, adminPartEditData, setFormData]);

  const imageurl = process.env.REACT_APP_BASE;

  console.log(formData);

  return (
    <div className="md:px-8 md:py-6 flex flex-col gap-4">
      <ToastContainer />
      {isAdminPartEditActive ? (
        <div className="flex items-center gap-2 ml-2 md:ml-0">
          <button
            onClick={() => {
              setIsAddPartSuccess(false);
              setIsAdminPartEditActive(false);
              setAdminPartEditData(null);
              navigate(-1);
            }}
            className="flex items-center gap-2 bg-blue text-white px-4 py-1.5 rounded-md"
          >
            <FaArrowLeft className="text-lg" />
            <p className="text-[15px] font-medium">Back</p>
          </button>
        </div>
      ) : null}

      <div className="flex-1 bg-white py-4 flex flex-col gap-6">
        <div className="flex items-center justify-between px-8">
          <div className="text-blue font-bold">
            <p>{isAdminPartEditActive ? "Edit Part" : "Add Part"}</p>
          </div>
        </div>

        <form
          className="flex-1 flex flex-col justify-between gap-16"
          encType="multipart/form-data"
        >
          <div className="flex flex-col gap-4 lg:gap-6 px-8">
            <div className="flex flex-col gap-4 lg:flex-row lg:gap-6">
              <div className="flex flex-col gap-1 flex-1">
                <InputWithLabel
                  htmlFor="vehicleCompany"
                  label="Vehicle Company"
                  type="text"
                  placeholder="Maruti"
                  name="vehicleCompany"
                  value={formData.vehicleCompany}
                  onChange={(e) => {
                    handleInputChange("vehicleCompany", e.target.value);
                    clearError("vehicleCompany");
                  }}
                />

                {errors.vehicleCompany && (
                  <p className="text-red-900 text-[14px] font-medium">
                    {errors.vehicleCompany}
                  </p>
                )}
              </div>

              <div className="flex flex-col gap-1 flex-1">
                <InputWithLabel
                  htmlFor="subCategory"
                  label="Sub-Category"
                  type="text"
                  placeholder="Breeza"
                  name="subCategory"
                  value={formData.subCategory}
                  onChange={(e) => {
                    handleInputChange("subCategory", e.target.value);
                    clearError("subCategory");
                  }}
                />

                {errors.subCategory && (
                  <p className="text-red-900 text-[14px] font-medium">
                    {errors.subCategory}
                  </p>
                )}
              </div>

              <div className="flex flex-col gap-2.5">
                <label className="text-[14px] uppercase text-gray-800 font-bold">
                  Availabilty
                </label>

                <div className="flex gap-4">
                  <div className="flex items-center gap-1">
                    <input
                      type="radio"
                      id="inStock"
                      name="availability"
                      value="In Stock"
                      checked={formData.availability === "In Stock"}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          availability: e.target.value,
                        })
                      }
                    />

                    <label
                      htmlFor="inStock"
                      className={`text-[14px] font-medium ${
                        formData.availability === "In Stock"
                          ? "text-gray-800"
                          : "text-gray-500"
                      }
                        `}
                    >
                      In Stock
                    </label>
                  </div>

                  <div className="flex items-center gap-1">
                    <input
                      type="radio"
                      id="outOfStock"
                      name="availability"
                      value="Out of Stock"
                      checked={formData.availability === "Out of Stock"}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          availability: e.target.value,
                        })
                      }
                    />

                    <label
                      htmlFor="outOfStock"
                      className={`text-[14px] font-medium ${
                        formData.availability === "Out Of Stock"
                          ? "text-gray-800"
                          : "text-gray-500"
                      }
                        `}
                    >
                      Out of Stock
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-4 lg:flex-row lg:gap-6">
              <div className="flex flex-col gap-1 flex-1">
                <InputWithLabel
                  htmlFor="brand"
                  label="Brand"
                  type="text"
                  placeholder="Brand name"
                  name="brand"
                  value={formData.brand}
                  onChange={(e) => {
                    handleInputChange("brand", e.target.value);
                    clearError("brand");
                  }}
                />

                {errors.brand && (
                  <p className="text-red-900 text-[14px] font-medium">
                    {errors.brand}
                  </p>
                )}
              </div>

              <div className="flex flex-col gap-1 flex-1">
                <InputWithLabel
                  htmlFor="model"
                  label="Model"
                  type="text"
                  placeholder="M-1"
                  name="model"
                  value={formData.model}
                  onChange={(e) => {
                    handleInputChange("model", e.target.value);
                    clearError("model");
                  }}
                />

                {errors.model && (
                  <p className="text-red-900 text-[14px] font-medium">
                    {errors.model}
                  </p>
                )}
              </div>

              <div className="flex flex-col gap-1 flex-1">
                <InputWithLabel
                  htmlFor="manufactureYear"
                  label="Year of manufacturing"
                  type="text"
                  placeholder="2011"
                  name="manufactureYear"
                  value={formData.manufactureYear}
                  onChange={(e) => {
                    handleInputChange("manufactureYear", e.target.value);
                    clearError("manufactureYear");
                  }}
                />

                {errors.manufactureYear && (
                  <p className="text-red-900 text-[14px] font-medium">
                    {errors.manufactureYear}
                  </p>
                )}
              </div>
            </div>

            <div className="flex flex-col gap-4 lg:flex-row lg:gap-6">
              <div className="flex flex-col gap-1 flex-1">
                <InputWithLabel
                  htmlFor="partCategories"
                  label="Part Categories"
                  type="text"
                  placeholder="Tires"
                  name="partCategories"
                  value={formData.partCategories}
                  onChange={(e) => {
                    handleInputChange("partCategories", e.target.value);
                    clearError("partCategories");
                  }}
                />

                {errors.partCategories && (
                  <p className="text-red-900 text-[14px] font-medium">
                    {errors.partCategories}
                  </p>
                )}
              </div>

              <div className="flex flex-col gap-1 flex-1">
                <InputWithLabel
                  htmlFor="partSubCategories"
                  label="Part Sub-Category"
                  type="text"
                  placeholder="Sub Category"
                  name="partSubCategories"
                  value={formData.partSubCategories}
                  onChange={(e) => {
                    handleInputChange("partSubCategories", e.target.value);
                    clearError("partSubCategories");
                  }}
                />

                {errors.partSubCategories && (
                  <p className="text-red-900 text-[14px] font-medium">
                    {errors.partSubCategories}
                  </p>
                )}
              </div>
            </div>

            <div className="flex flex-col gap-4 lg:gap-6">
              <div className={`flex flex-col gap-1.5 items-start flex-1`}>
                <label className="text-[14px] uppercase text-gray-800 font-bold">
                  Part Number{" "}
                  <span className="text-[13px] text-gray-500">
                    (Press Enter to add)
                  </span>
                </label>
                <div className="w-full flex flex-col gap-1">
                  {isAdminPartEditActive ? (
                    <TagsInputContainer
                      tags={partNumbersList}
                      onTagAddDelete={handleTagAddDelete}
                      setTagError={setTagError}
                    />
                  ) : (
                    <TagsInputContainer
                      tags={formData.partNumbers}
                      onTagAddDelete={handleTagAddDelete}
                      setTagError={setTagError}
                    />
                  )}

                  {tagError && (
                    <p className="text-red-900 text-[14px] font-medium">
                      {tagError}
                    </p>
                  )}

                  {errors.partNumbers && (
                    <p className="text-red-900 text-[14px] font-medium">
                      {errors.partNumbers}
                    </p>
                  )}
                </div>
              </div>

              <div className="flex flex-col gap-1 flex-1">
                <InputWithLabel
                  htmlFor="partName"
                  label="Part name"
                  type="text"
                  placeholder="partName"
                  name="partName"
                  value={formData.partName}
                  onChange={(e) => {
                    handleInputChange("partName", e.target.value);
                    clearError("partName");
                  }}
                />

                {errors.partName && (
                  <p className="text-red-900 text-[14px] font-medium">
                    {errors.partName}
                  </p>
                )}
              </div>
            </div>

            <div className="flex flex-col gap-1 flex-1">
              <InputWithLabel
                htmlFor="description"
                label="Description"
                isTextArea={true}
                rows={5}
                placeholder="Say something about your vehicle..."
                name="description"
                value={formData.description}
                onChange={(e) => {
                  handleInputChange("description", e.target.value);
                  clearError("description");
                }}
              />

              {errors.description && (
                <p className="text-red-900 text-[14px] font-medium">
                  {errors.description}
                </p>
              )}
            </div>

            <div className="flex flex-col gap-4 lg:gap-6">
              <div className="flex flex-col gap-1.5 flex-1">
                <label className="text-[14px] uppercase text-gray-800 font-bold">
                  Image
                </label>

                <div
                  className={`w-full min-h-[180px] bg-[#F9FBFF] border border-dashed border-blue rounded-lg flex items-center justify-center gap-1 text-blue cursor-pointer xl:h-full xl:flex-1`}
                  onClick={handleClick}
                >
                 {fileName ? (
    <img src={`${imageurl}${fileName}`} alt="Uploaded file" className="object-cover w-full h-[250px]" />
) : formData.coverPhoto ? (
    <img src={`${imageurl}${formData.coverPhoto}`} alt="Cover photo" className="object-cover w-full h-[250px]" />
) : (
    <div className="flex items-center gap-2">
        <BiSolidDownload className="text-lg text-blue" />
        <p className="text-gray-700 text-[15px] font-medium">
            <span className="text-blue underline">Browse</span> or{" "}
            <span className="text-blue underline">Upload</span>{" "}
            image
        </p>
    </div>
)}

                  <input
                    type="file"
                    className="hidden"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    name="image"
                  />
                </div>

                {errors.image && (
                  <p className="text-red-900 text-[14px] font-medium">
                    {errors.image}
                  </p>
                )}
              </div>

              <div className="flex flex-col gap-4">
                <label className="text-[15px] uppercase text-gray-800 font-bold">
                  Other Attributes
                </label>

                {Array.from({ length: numOtherAttributes }).map((_, index) => (
                  <div className="flex gap-4 flex-1 lg:gap-6" key={index}>
                    <div className="flex flex-col gap-1 flex-1">
                      <InputWithLabel
                        htmlFor={`attributeName${index}`}
                        label={`Attribute ${index + 1}`}
                        type="text"
                        placeholder={`Attribute ${index + 1}`}
                        name={`attributeName${index}`}
                        value={
                          formData.otherAttributes[index]?.attributeName || ""
                        }
                        onChange={(e) =>
                          handleOtherAttributesChange(
                            index,
                            "attributeName",
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <div className="flex flex-col gap-1 flex-1">
                      <InputWithLabel
                        htmlFor={`attributeValue${index}`}
                        label={`Attribute ${index + 1} Value`}
                        type="text"
                        placeholder={`Attribute ${index + 1} Value`}
                        name={`attributeValue${index}`}
                        value={
                          formData.otherAttributes[index]?.attributeValue || ""
                        }
                        onChange={(e) =>
                          handleOtherAttributesChange(
                            index,
                            "attributeValue",
                            e.target.value
                          )
                        }
                      />
                    </div>
                  </div>
                ))}

                {numOtherAttributes < 5 && (
                  <button
                    type="button"
                    onClick={handleAddRow}
                    className="self-center bg-blue w-8 h-8 rounded-full flex items-center justify-center text-white font-semibold"
                  >
                    <HiPlus className="text-xl" />
                  </button>
                )}
              </div>
            </div>
          </div>

          <div className="flex justify-end border-t border-gray-200">
            {isAdminPartEditActive ? (
              <button
                type="submit"
                className="mx-6 mt-4 bg-blue px-8 py-1.5 rounded-md text-[14px] text-white font-semibold"
                onClick={UpdateFormSubmit}
              >
                {updateLoading ? (
                  <div
                    role="status"
                    className="flex justify-center items-center"
                  >
                    <svg
                      aria-hidden="true"
                      class="w-6 h-6 text-gray-300 animate-spin dark:text-gray-600 fill-white"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span class="sr-only">Loading...</span>
                  </div>
                ) : (
                  "Update"
                )}
              </button>
            ) : (
              <button
                type="button"
                className="mx-6 mt-4 bg-blue px-8 py-1.5 rounded-md text-[14px] text-white font-semibold"
                onClick={handleFormSubmit}
              >
                Continue
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddPart;
