import axios from "axios";
import { atom, useAtom } from "jotai";
import React, { useEffect, useRef, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { IoClose } from "react-icons/io5";
import VerificationLogo from "../../assets/images/verification.png";
import { phoneNumAtom } from "../Forms/BusinessSignupForm";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify"; // Import ToastContainer and toast from react-toastify
import "react-toastify/dist/ReactToastify.css"; // Import CSS for react-toastify

export const sentOtpAtom = atom("")
export const useridAtom = atom("");


const VerificationModal = ({
  setVerificationModalOpen,
  verificationDigits,
  setVerificationDigits,
}) => {
  const inputRefs = useRef([
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ]);

  const [Signupphone] = useAtom(phoneNumAtom);

  const navigate = useNavigate();

  let phoneNum;
  phoneNum = Signupphone;

  const [NumData] = useState({
    phoneNumber: "",
  });
  NumData.phoneNumber = phoneNum;

  const [formData, setFormData] = useState({
    username: "",
    role: "Seller",
    name: "",
    password: "",
    password2: "",
  });

  const [loginformData, setloginFormData] = useState({
    username: "",
    password: "",
  });

  // Set Form Data and username & name value in it to be phoneNum and role to be true
  formData.username = phoneNum;
  formData.name = phoneNum;
  loginformData.username = phoneNum;

  const [sentotp, setSentOtp] = useAtom(sentOtpAtom);
  const [userid, setUserId] = useAtom(useridAtom);

  
  useEffect(() => {
    const sendOtp = async () => {
      try {
        const url = process.env.REACT_APP_BASE_URL + "auth/sendotp/";

        const response = await axios.post(
          `${url}`,
          NumData,
          {
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(NumData),
          }
        );
        console.log("Responded data is : ", response.data);
        setSentOtp(response.data);
        // Set Form Data and password value in it to be response.data
        setFormData((prevData) => ({
          ...prevData,
          password: response.data,
          password2: response.data,
        }));

        // Handle success or navigate to a success page
        // navigate("/seller_registration");
      } catch (error) {
        console.error("Error sending data:", error);
        // Handle error
      }
    };

    sendOtp();
  }, []);

  const handleInputChange = (index, value) => {
    if (/^\d*$/.test(value)) {
      // Only allow digits or an empty string (for clearing)
      const newDigits = [...verificationDigits];
      newDigits[index] = value;
  
      setVerificationDigits(newDigits);
  
      // Move to the previous input if Backspace is pressed and the field is empty
      if (value === "" && index > 0) {
        inputRefs.current[index - 1].current.focus();
      }
      // Move to the next input
      else if (index < inputRefs.current.length - 1 && value !== "") {
        inputRefs.current[index + 1].current.focus();
      }
    }
  };
  

  const handleSubmit = async () => {
    const combinedDigits = verificationDigits.join("");
    console.log(combinedDigits);
    
    if (combinedDigits === sentotp) {
      try {
        const url = process.env.REACT_APP_BASE_URL + "auth/register/";

        const response = await axios.post(
          `${url}`,
          formData,
          {
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          }
        );
        console.log(response);
        if (response.status === 201) {
          const responseData = response.data;
          const token = responseData.token.access;

          console.log("Responded Token is : ", token);

          // Set token in local storage
          localStorage.setItem("token", token);

          const userid = responseData.id;
          console.log("Responded User Id is : ", userid);
          setUserId(userid);
          // Show success notification
          toast.success("Signup successful!");
          setVerificationModalOpen(false);
          setVerificationDigits(["", "", "", ""]);

          navigate("/seller_registration");
        } else {
          // Handle errors here
          console.error("Error creating Seller");
          // Show error notification
          toast.error("Signup failed!");
        }
      } catch (error) {
        toast.error("User with this phone number already exists!");
      }
    } else {
      // Show error notification for mismatched OTP
      toast.error("Verification code mismatch!");
    }
  };

  return (
    <div className="fixed bg-[#000000b6] md:px-7 min-h-screen min-w-full top-0 flex justify-center items-center">
      <div className="relative bg-white w-full min-h-screen md:min-h-fit h-full md:max-w-[500px] px-5 md:px-10 py-8 flex flex-col items-center justify-center md:justify-start gap-6 sm:gap-8">
        <button
          className="hidden md:block absolute right-3.5 top-2.5"
          onClick={() => {
            setVerificationModalOpen(false)
            setVerificationDigits(["", "", "", ""]);
          }}
        >
          <IoClose className="text-[22px] text-gray-400" />
        </button>

        {/* Back button for mobile screen */}
        <button
          className="absolute top-6 left-6 flex items-center gap-2 text-gray-500 border border-gray-200 px-4 py-1.5 rounded-lg md:hidden"
          onClick={() => {
            setVerificationModalOpen(false)
            setVerificationDigits(["", "", "", ""]);
          }}
        >
          <BiArrowBack className="text-[20px]" />
          <p className="font-medium text-[15px]">Back</p>
        </button>

        <h1 className="text-lg text-gray-900 font-bold ">Verification Code</h1>

        <img src={VerificationLogo} alt="" />

        <p className="text-[14px] text-center text-gray-700 font-medium">
          Enter the verification code we just sent you on your mobile number{" "}
          {phoneNum.length > 5
            ? phoneNum.substring(0, 2) +
              "*****" +
              phoneNum.substring(phoneNum.length - 3)
            : phoneNum}
          .
        </p>

        <div className="flex gap-4 sm:gap-6 md:gap-8">
          {verificationDigits.map((digit, index) => (
            <div
              key={index}
              className="relative border-2 w-[3.1rem] py-2.5 border-blue bg-blue-500 rounded-md"
            >
              <input
                type="text"
                className="outline-none bg-blue-500 w-full px-4 font-bold"
                value={digit}
                onChange={(e) => handleInputChange(index, e.target.value)}
                ref={inputRefs.current[index]}
              />
            </div>
          ))}
        </div>

        <div className="flex items-center gap-1 text-[14px]">
          <p className="text-gray-400">If you didn’t receive any code.</p>
          <button className="text-blue font-semibold underline">Resend</button>
        </div>

        <button
          className="bg-blue px-8 py-2 rounded-md text-[14px] text-white font-semibold"
          onClick={handleSubmit}
        >
          Verify
        </button>
      </div>

      <ToastContainer position="top-center" autoClose={3000} />
    </div>
  );
};

export default VerificationModal;
