// UserReview.js
import React from "react";
import { BsStar, BsStarFill, BsStarHalf } from "react-icons/bs";

const UserReview = ({ imageUrl, name, role, rating, content }) => {
  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 !== 0;

    const stars = [];

    for (let i = 0; i < fullStars; i++) {
      stars.push(<BsStarFill key={i} className="star-icon text-yellow" />);
    }

    if (hasHalfStar) {
      stars.push(
        <BsStarHalf key="half-star" className="star-icon text-yellow" />
      );
    }

    const remainingStars = 5 - fullStars - (hasHalfStar ? 1 : 0);

    for (let i = 0; i < remainingStars; i++) {
      stars.push(<BsStar key={`empty-star-${i}`} className="star-icon" />);
    }

    return stars;
  };

  return (
    <div className="px-4 md:px-10 py-3 md:py-6 flex gap-2 md:gap-4 border-b-2 border-gray-200">


      <div className="flex flex-col gap-2">
        <div>
          <h1 className="font-bold">{name}</h1>
        </div>

        <div className="flex items-center gap-1">
          {renderStars(rating)}
          
        </div>

        <p className="text-sm font-medium text-gray-500">{content}</p>
      </div>
    </div>
  );
};

export default UserReview;
