import React, { useState, useEffect } from "react";
import DictionaryCard from "../../common/DictionaryCard";
import axios from "axios";
import Model from "../Model/Model";

const Brand = ({id}) => {
  const [brandData, setBrandData] = useState([]);
  const [isModel , setIsModel] = useState(false);
  const [modelId , setModelId] = useState(null);
  const [companyid , setCompanyid] = useState(id);

  const fetchData = async () => {
    try {
      const url = process.env.REACT_APP_BASE_URL + "admin/brandinfo/" + id + "/" 
        const response = await axios.get(`${url}`);
        const data = response.data;
        setBrandData(data);
    } catch (error) {
        console.error("Error fetching data from API:", error);
    }
};

useEffect(() => {
    fetchData();
}, []);

  const handleCard = (modelid) => {
    setIsModel(true);
    console.log("Model ID : " , modelid)
    console.log("Company ID : " , id)
    setModelId(modelid);
    setCompanyid(id);
  };

  const IMAGE_URL = process.env.REACT_APP_BASE;


  return (
    <>
    {isModel ? <Model companyid={companyid } modelid={modelId} /> : (
    <div>
      <div className="bg-white rounded-md p-4 flex flex-col gap-6">
        <div className="grid grid-cols-4 gap-6">
          {brandData.map((data) => (
            <DictionaryCard
              key={data}
              imageSrc={
                data.image
                  ? `${IMAGE_URL}/${data}.jpg`
                  : "https://via.placeholder.com/250"
              }
              name={data}
              handleCardClick={() => {
                handleCard(data);
              }}
            />
          ))}
        </div>
      </div>


    </div>
    )}
    </>
  );
};

export default Brand;
