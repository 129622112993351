import React, { useEffect } from 'react';
import Chart from 'chart.js/auto';

const LineChart = () => {
  useEffect(() => {
    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const data = {
      labels: daysOfWeek,
      datasets: [
        {
          label: "Order",
          backgroundColor: "hsl(252, 82.9%, 67.8%)",
          borderColor: "hsl(252, 82.9%, 67.8%)",
          data: [0, 10, 5, 2, 20, 30, 45],
        },
        {
          label: "Revenue",
          backgroundColor: "hsl(34, 100%, 50%)", // Orange color
          borderColor: "hsl(34, 100%, 50%)",
          data: [15, 25, 12, 8, 30, 40, 55], // Sample revenue data
        },
      ],
    };

    const configLineChart = {
      type: "line",
      data,
      options: {
        scales: {
          x: {
            title: {
              display: true,
              text: 'Days of the Week',
            },
          },
          y: {
            title: {
              display: true,
              text: 'Value',
            },
          },
        },
      },
    };

    const chartLine = new Chart(document.getElementById("chartLine"), configLineChart);

    // Cleanup on component unmount
    return () => {
      chartLine.destroy();
    };
  }, []); // Empty dependency array to ensure the effect runs only once on mount

  return (
    <div className="w-[calc(100vw_-_60px)] lg:w-[calc(100vw_-_340px)] shadow-lg rounded-lg overflow-hidden">
      <div className="py-3 px-5 bg-gray-300 font-medium">Revenue Vs Order</div>
      <canvas className="p-2" id="chartLine"></canvas>
    </div>
  );
};

export default LineChart;