import { useAtom } from "jotai";
import React, { useState } from "react";
import { HiPhotograph } from "react-icons/hi";
import ImageInput from "../../../common/ImageInput";
import { isProductSelectedAtom } from "./AddProductMain";
import { currentStepAtom } from "./StepsIndicator";

const GalleryForm = ({ formData, onCoverPhotoChange, onOtherPhotoChange }) => {
  const [, setCurrentStep] = useAtom(currentStepAtom);
  const [isProductSelected] = useAtom(isProductSelectedAtom);

  console.log("formData", formData);

  const [errors, setErrors] = useState({
    coverPhoto: "",
    otherPhotos: "",
  });

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const newErrors = validateForm();

    if (Object.values(newErrors).some((error) => error !== "")) {
      setErrors(newErrors);
      return;
    }

    console.log("FormData: ", formData);

    setCurrentStep((prevStep) => Math.min(prevStep + 1, 5));
  };

  const validateForm = () => {
    let newErrors;
    console.log(isProductSelected);
    console.log(formData.otherPhotos.some((photo) => photo !== null));

    isProductSelected
      ? (newErrors = {
          coverPhoto: formData.coverPhoto ? "" : "Please select a cover photo.",
          otherPhotos: formData.otherPhotos.some((photo) => photo !== null)
            ? ""
            : "Please select at least one other photo.",
        })
      : (newErrors = {
          coverPhoto: formData.coverPhoto ? "" : "Please select a cover photo.",
        });

    return newErrors;
  };

  const clearError = (fieldName) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: "",
    }));
  };
  const url = process.env.REACT_APP_BASE;

  console.log("OtherPhotos", formData.otherPhotos);

  return (
    <div className="flex-1 bg-white py-4 px-6 sm:px-8 flex flex-col gap-6">
      <div className="flex items-center justify-between">
        <div className="text-blue font-bold">
          <p>Gallery</p>
        </div>
      </div>

      <form
        onSubmit={handleFormSubmit}
        className="flex-1 flex flex-col justify-between gap-16"
        encType="multipart/form-data"
      >
        <div className="flex flex-col gap-8 xl:gap-10 xl:flex-row">
          <div className="flex flex-col gap-6 xl:w-[50%]">
            <div className="flex items-center gap-2 text-gray-700 font-semibold text-[14px]">
              <HiPhotograph className="text-xl" />
              <p>Add Cover Photo</p>
            </div>

            <div className="flex-1 flex flex-col gap-1">
              {formData.coverPhoto === null ||
              formData.coverPhoto instanceof File ? (
                <ImageInput
                  text="Click to upload"
                  height="300px"
                  existingFileName={
                    formData.coverPhoto ? formData.coverPhoto.name : ""
                  }
                  onChange={(file) => {
                    onCoverPhotoChange(file);
                    clearError("coverPhoto");
                  }}
                />
              ) : (
                <div
                  className={`flex-1 relative w-full max-h-[350px] bg-[#F9FBFF] border border-dashed border-blue rounded-lg flex items-center justify-center gap-1 text-blue cursor-pointer xl:h-full xl:flex-1`}
                >
                  <img
                    src={`${url}${formData.coverPhoto}`}
                    alt="cover"
                    className="w-full h-full object-fill"
                  ></img>
                </div>
              )}

              {errors.coverPhoto && (
                <p className="text-red-900">{errors.coverPhoto}</p>
              )}
            </div>
          </div>

          {isProductSelected ? (
            <div className="flex flex-col gap-6 xl:flex-1 xl:w-[50%]">
              <div className="text-gray-700 font-semibold text-[14px]">
                <p>Add Other Photo</p>
              </div>

              <div className="flex flex-col gap-1">
                <div className="grid grid-cols-2 gap-4 lg:grid-cols-3">
                {formData.otherPhotos.map((photo, index) => (
  (photo === null || photo instanceof File) ? (
    <ImageInput
      key={index}
      height="100px"
      iconSize="30px"
      existingFileName={photo ? photo.name : ""}
      onChange={(file) => {
        onOtherPhotoChange(file, index);
        console.log(formData.otherPhotos.length);
        clearError("otherPhotos");
      }}
    />
  ) : (
    <div key={index}>
      {typeof photo === 'string' ? (
        <img
          src={`${url}${photo}`}
          alt={`Photo ${index}`}
          style={{ height: "100px", width: "auto" }}
        />
      ) : null}
    </div>
  )
))}


                </div>

                {errors.otherPhotos && (
                  <p className="text-red-900">{errors.otherPhotos}</p>
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className="pt-6 flex flex-row justify-between border-t border-gray-200">
          <button
            type="button"
            onClick={() =>
              setCurrentStep((prevStep) => Math.max(prevStep - 1, 1))
            }
            className="border border-gray-300 px-6 py-1 rounded-md text-[14px] text-gray-500 font-semibold"
          >
            Back
          </button>

          <button
            type="submit"
            className="bg-blue px-6 py-1 rounded-md text-[14px] text-white font-semibold"
          >
            Next
          </button>
        </div>
      </form>
    </div>
  );
};

export default GalleryForm;
