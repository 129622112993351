import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Chat.css";

const ChatList = () => {
  const navigate = useNavigate();
  const [vendors, setVendors] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true); // State variable for loading

  useEffect(() => {
    const fetchVendors = async () => {
      try {
        const url =
          process.env.REACT_APP_BASE_URL + "newseller/getallapprovedseller/";
        const response = await axios.get(url);
        setVendors(response.data);
        setLoading(false); // Set loading to false when vendors are fetched
      } catch (error) {
        console.error("Error fetching vendors:", error);
        setLoading(false); // Set loading to false on error
      }
    };

    fetchVendors();
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredVendors = vendors.filter((vendor) =>
    vendor.sellerprofile.storename
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  return (
    <div className="home-page__content">
      <div className="container-fluid">
        <div className="row">
          <div className="bg-white min-h-[calc(100vh_-_65px)] p-2 overflow-y-auto col-12 col-md-4 col-lg-5 col-xl-3 px-0 messages-page__list-scroll">
            <div className="mb-2 px-2">
              <input
                type="text"
                placeholder="Search vendors..."
                value={searchTerm}
                onChange={handleSearchChange}
                className="border border-gray-300 rounded-full px-4 py-2.5 w-full focus:outline-none focus:border-blue"
              />
            </div>

            {loading ? ( // Conditional rendering of loader
              <div className="lg:h-[calc(100vh_-_135px)] flex items-center justify-center">
                <div class="flex flex-row gap-2">
                  <div class="w-4 h-4 rounded-full bg-blue-700 animate-bounce [animation-delay:.7s]"></div>
                  <div class="w-4 h-4 rounded-full bg-blue-700 animate-bounce [animation-delay:.3s]"></div>
                  <div class="w-4 h-4 rounded-full bg-blue-700 animate-bounce [animation-delay:.7s]"></div>
                </div>
              </div>
            ) : (
              <ul className="messages-page__list pb-5 px-1 px-md-3">
                {filteredVendors.map((vendor) => (
                  <li
                    key={vendor.id}
                    className="messaging-member"
                    onClick={() => navigate(`/admin/chat/${vendor.sellerprofile.id}`)}
                  >
                    <div className="p-2 cursor-pointer flex items-center gap-4">
                      <div className="messaging-member__avatar w-14 h-14">
                        <img
                          // src={`${process.env.REACT_APP_BASE}${vendor.sellerprofile.storelogo}`}
                          src={
                            vendor.sellerprofile.storelogo
                              ? `${process.env.REACT_APP_BASE}${vendor.sellerprofile.storelogo}`
                              : "https://www.riversideplazashepparton.com.au/wp-content/uploads/2015/10/image-store-placeholder-logo.png"
                          }
                          alt={vendor.sellerprofile.storename}
                          loading="lazy"
                          className="w-14 h-14 rounded-full"
                        />
                      </div>
                      <div className="font-bold">
                        {vendor.sellerprofile.storename}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatList;
