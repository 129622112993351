import axios from "axios";
import { atom, useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { HiMinus, HiOutlineSearch } from "react-icons/hi";

export const showCustomProductFormAtom = atom(false);
export const isProductViewedAtom = atom(false);
export const isProductSelectedAtom = atom(false);
export const selectedProductDataAtom = atom();

const AddProductMain = () => {
  const [productsData, setProductsData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url =
          process.env.REACT_APP_BASE + "/api/admin/approved-partinfo/";

        const response = await axios.get(`${url}`);
        const apiData = response.data;

        console.log(apiData);

        // Transform the API data into the desired format
        const transformedData = apiData.map((item, index) => {
          return {
            productId: item.id,
            vehicleCompany: item.vehicleCompany,
            subCategory: item.subCategory,
            availability: item.availability,
            brand: item.brand,
            model: item.model,
            manufactureYear: parseInt(item.manufactureYear),
            partCategories: item.partCategories,
            partSubCategories: item.partSubCategories,
            partName: item.partName,
            partNumber: Array.isArray(item.partNumber)
              ? item.partNumber.map((part) => part.partNumber).join(", ")
              : item.partNumber,
            description: item.description,
            coverPhoto: item.image ? item.image : null,
            otherAttributes: Array.isArray(item.attributes)
              ? item.attributes.map((attribute) => ({
                  attributeName: attribute.attributeName,
                  attributeValue: attribute.attributeValue,
                }))
              : [],
          };
        });

        console.log("TRANSFORMEDDATA", transformedData);
        setProductsData(transformedData);
        console.log("PRODUCTSDATA", productsData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const [, setShowCustomProductForm] = useAtom(showCustomProductFormAtom);
  const [, setIsProductViewed] = useAtom(isProductViewedAtom);
  const [, setIsProductSelected] = useAtom(isProductSelectedAtom);
  const [, setSelectedProductData] = useAtom(selectedProductDataAtom);

  const [searchProductId, setSearchProductId] = useState("");
  const [foundProduct, setFoundProduct] = useState(null);
  const [error, setError] = useState("");

  const handleSearch = () => {
    if (!searchProductId.trim()) {
      setError("Please enter a Product ID.");
      setFoundProduct(null);
      return;
    }

    console.log("PRODUCTSDATA", productsData);

    const foundProducts = productsData
      ? productsData.filter((product) => {
          const partNumbers =
            typeof product.partNumber === "string"
              ? product.partNumber.split(", ")
              : Array.isArray(product.partNumber)
              ? product.partNumber
              : [];

          return partNumbers.includes(searchProductId);
        })
      : [];

    const foundProduct = foundProducts[0];

    // Console foundProduct,
    console.log("FOUNDPRODUCT", foundProduct);
    console.log(searchProductId);

    if (foundProduct) {
      setFoundProduct(foundProduct);
      setError("");
    } else {
      setFoundProduct(null);
      setError("Product not found.");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <div className="relative mx-8 my-6 px-5 py-3 bg-white min-h-[calc(100vh-65px-48px)] flex flex-col items-center">
      <div className="w-full flex flex-col gap-8">
        <div className="w-full sm:self-end flex flex-col sm:flex-row sm:items-center gap-3 sm:gap-6 sm:w-fit">
          <div className="px-4 py-2 flex items-center gap-3 bg-white border border-gray-300 rounded-md flex-1 sm:flex-none">
            <HiOutlineSearch className="text-gray-500 text-[20px]" />

            <input
              type="text"
              placeholder="Search Product by Product Id"
              className="flex-1 bg-white outline-none font-medium text-[14px] placeholder:text-[13px] sm:min-w-[300px] placeholder:text-gray-400"
              value={searchProductId}
              onChange={(e) => setSearchProductId(e.target.value)}
              onKeyDown={handleKeyPress}
            />
          </div>

          <button
            type="button"
            className="bg-blue px-8 py-1.5 rounded-md text-[14px] text-white font-semibold"
            onClick={handleSearch}
          >
            Find
          </button>
        </div>

        {foundProduct ? (
          <div className="relative bg-white border-2 border-gray-400 overflow-x-auto whitespace-nowrap table-container">
            <table className="w-full text-white text-start border-spacing-4">
              <div className="w-full  border-gray-400 bg-gray-300 text-gray-900 text-[14px] font-bold px-5 py-3 uppercase sm:hidden">
                <h1>Product Detail</h1>
              </div>

              <thead className="text-gray-900 border-y-2 border-gray-400 uppercase hidden sm:table-header-group">
                <tr>
                  <th className="text-start">Vehicle Company</th>
                  <th className="text-start">Sub-Category</th>
                  <th className="text-start">Brand</th>
                  <th className="text-start">Model</th>
                  <th className="text-start">Year of Manufacturing</th>
                  <th className="text-start">Parts Categories</th>
                  <th className="text-start">Sub-Parts Categories</th>
                  <th className="text-start">Parts Names</th>
                  <th className="text-start">Action</th>
                </tr>
              </thead>

              <tbody className="text-gray-700">
                <div className="border-b-2 border-gray-400 px-5 py-3 sm:hidden">
                  <div className="flex items-center gap-4">
                    <div
                      className={`w-6 h-6 flex justify-center items-center bg-blue text-white rounded-[4px]`}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault();
                        }}
                      >
                        <HiMinus className="w-5 h-5" />
                      </button>
                    </div>

                    <div>
                      <h1 className="text-gray-900 font-bold text-[15px] uppercase">
                        Vehicle Company
                      </h1>
                      <p className="text-gray-800 font-medium text-[15px]">
                        {foundProduct.vehicleCompany}
                      </p>
                    </div>
                  </div>

                  <div className="flex flex-col gap-2 mt-4 ml-[2.4rem]">
                    <div className="flex flex-row gap-2 items-center">
                      <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                        Sub-Category:
                      </h2>
                      <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                        {foundProduct.subCategory}
                      </p>
                    </div>

                    <div className="flex flex-row gap-2 items-center">
                      <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                        Brand:
                      </h2>
                      <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                        {foundProduct.brand}
                      </p>
                    </div>

                    <div className="flex flex-row gap-2 items-center">
                      <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                        Year of Manufacturing:
                      </h2>
                      <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                        {foundProduct.manufactureYear}
                      </p>
                    </div>

                    <div className="flex flex-row gap-2 items-center">
                      <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                        Parts Categories:
                      </h2>
                      <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                        {foundProduct.partsCategories}
                      </p>
                    </div>

                    <div className="flex flex-row gap-2 items-center">
                      <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                        Sub-Parts Categories:
                      </h2>
                      <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                        {foundProduct.partSubCategories}
                      </p>
                    </div>

                    <div className="flex flex-row gap-2 items-center">
                      <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                        Parts Names:
                      </h2>
                      <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                        {foundProduct.partName}
                      </p>
                    </div>
                    <button
                      type="submit"
                      className="w-fit my-2 bg-blue px-6 py-1.5 rounded-md text-[14px] text-white font-semibold"
                    >
                      Select
                    </button>
                  </div>
                </div>

                <tr className="border-b-2 border-gray-300 text-gray-700 relative hidden sm:table-row hover:bg-gray-100">
                  <td>
                    <div className="max-w-[180px] 2xl:max-w-[250px]  gap-2 lg:gap-3 pr-6">
                      <span className="whitespace-break-spaces leading-5">
                        {foundProduct.vehicleCompany}
                      </span>
                    </div>
                  </td>

                  <td className="text-center">{foundProduct.subCategory}</td>

                  <td className="text-center">{foundProduct.brand}</td>

                  <td className="text-center">{foundProduct.model}</td>

                  <td className="text-center">
                    {foundProduct.manufactureYear}
                  </td>

                  <td className="text-center">{foundProduct.partCategories}</td>

                  <td className="text-center">
                    {foundProduct.partSubCategories}
                  </td>

                  <td className="text-center">{foundProduct.partName}</td>

                  <td className="text-center">
                    <button
                      type="submit"
                      className="bg-blue px-6 py-1.5 rounded-md text-[14px] text-white font-semibold"
                      onClick={() => {
                        setIsProductViewed(true);
                        setSelectedProductData(foundProduct);
                      }}
                    >
                      Select
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
          <p className="text-red-900 text-center text-[14px] font-medium">
            {error}
          </p>
        )}
      </div>

      <div className="flex-1 flex items-center justify-center">
        <button
          type="button"
          className="self-center bg-blue px-8 py-1.5 rounded-md text-[14px] text-white font-semibold"
          onClick={() => setShowCustomProductForm(true)}
        >
          Add Your Custom Product
        </button>
      </div>
    </div>
  );
};

export default AddProductMain;
