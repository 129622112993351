import axios from "axios";
import { useAtom } from "jotai";
import React, { useState } from "react";
import { AiOutlineCheck, AiOutlineEye } from "react-icons/ai";
import { BiX } from "react-icons/bi";
import { HiMinus, HiPlus } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import {
  isVendorEditAtom,
  vendorToEditAtom,
} from "../AcceptedVendors/AcceptedVendorsTableRow";
import { apiDataOfRequestedVendorsAtom } from "./VendorRequests";

const VendorRequestsTableRow = ({ formData, fetchData }) => {
  const [isMobileTableRowOpen, setIsMobileTableRowOpen] = useState(false);

  const [apiDataOfRequestedVendors, setApiDataOfRequestedVendors] = useAtom(
    apiDataOfRequestedVendorsAtom
  );

  const [isVendorEdit, setIsVendorEdit] = useAtom(isVendorEditAtom);

  const navigate = useNavigate();

  const [vendorToEdit, setVendorToEdit] = useAtom(vendorToEditAtom);

  const handleView = (id) => {
    // Find the vendor data associated with the provided id
    const vendor = apiDataOfRequestedVendors.find((vendor) => vendor.id === id);
    // Set the found vendor data in the atom
    console.log(vendor);
    setVendorToEdit([vendor]);
    setIsVendorEdit(true);
    navigate("/admin/vendors-management/view");
  };

  const onAcceptButtonClick = async (e) => {
    e.preventDefault();
    formData["seller"] = formData.id;
    const url = process.env.REACT_APP_BASE_URL + "newseller/managevendor/";
    try {
      const response = await axios.post(`${url}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        body: JSON.stringify(formData),
      });
      console.log("Data sent successfully:", response.data);
      fetchData();

      // navigate("/product");
      // Handle success or navigate to a success page
    } catch (error) {
      console.error("Error sending data:", error);
      // Handle error
    }
  };

  const onDeclineButtonClick = async (e) => {
    e.preventDefault();
    formData["seller"] = formData.id;
    formData["status"] = formData.usertype;
    const url = process.env.REACT_APP_BASE_URL + "newseller/managevendor/";
    try {
      const response = await axios.post(`${url}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        body: JSON.stringify(formData),
      });
      console.log("Data sent successfully:", response.data);
      // fetchData();

      // navigate("/product");
      // Handle success or navigate to a success page
    } catch (error) {
      console.error("Error sending data:", error);
      // Handle error
    }
  };

  const url = process.env.REACT_APP_BASE;
  return (
    <>
      <div className="border-b-2 border-gray-400 px-5 py-3 sm:hidden">
        <div className="flex items-center gap-4">
          <div
            className={`w-6 h-6 flex justify-center items-center ${
              isMobileTableRowOpen
                ? "bg-blue text-white rounded-[4px]"
                : "border-2 border-gray-400 text-gray-400 rounded-sm"
            }`}
          >
            <button
              onClick={(event) => {
                event.preventDefault();
                setIsMobileTableRowOpen(!isMobileTableRowOpen);
              }}
            >
              {isMobileTableRowOpen ? (
                <HiMinus className="w-5 h-5" />
              ) : (
                <HiPlus className="w-5 h-5" />
              )}
            </button>
          </div>

          <div className="text-start">
            <h1 className="text-gray-900 font-bold text-[15px] uppercase">
              Vendor Name
            </h1>
            <p className="text-gray-800 font-medium text-[15px]">
              {formData.name}
            </p>
          </div>
        </div>

        {isMobileTableRowOpen && (
          <div className="flex flex-col gap-2 mt-4 ml-[2.4rem]">
            <div className="flex flex-row gap-2 items-center">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                Phone No.:
              </h2>
              <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                {formData.phone}
              </p>
            </div>

            <div className="flex flex-row gap-2 items-center">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                Email:
              </h2>
              <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                {formData.email}
              </p>
            </div>

            <div className="flex flex-row gap-2 items-center">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                Type:
              </h2>
              <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                {formData.type}
              </p>
            </div>

            <div className="flex flex-row gap-2 items-center">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                Type:
              </h2>
              <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                {formData.usertype}
              </p>
            </div>



            <div className="flex items-center gap-2">
              <button
                className="px-1.5 border-2 border-gray-300 font-medium rounded-md h-[35px]"
                onClick={onAcceptButtonClick}
              >
                <AiOutlineCheck className="w-5 h-5 text-blue" />
              </button>

              <button
                className="px-1.5 border-2 border-gray-300 font-medium rounded-md h-[35px]"
                onClick={onDeclineButtonClick}
              >
                <BiX className="w-5 h-5 text-red-900" />
              </button>

              <button
                className="px-1.5 border-2 border-gray-300 font-medium rounded-md h-[35px]"
                onClick={() => handleView(formData.id)}
              >
                <AiOutlineEye className="w-[22px] h-[22px] text-gray-700" />
              </button>
            </div>
          </div>
        )}
      </div>

      <tr className="border-b-2 border-gray-300 text-gray-700 relative hidden sm:table-row hover:bg-gray-100">
        <td className="max-w-[1.5rem]">
          <div className="flex justify-between items-center ml-1">
            <input type="checkbox" name="" id="" />
          </div>
        </td>

        <td>
          <div className="flex items-center gap-2 lg:gap-3 pr-6">
            <img
              src={
                formData.photo
                  ? `${url}${formData.photo}`
                  : "https://via.placeholder.com/250"
              }
              alt="vendor"
              className="w-[50px] h-[50px]"
            />
            <span className="leading-5">{formData.name}</span>
          </div>
        </td>

        <td className="text-center">{formData.phone}</td>

        <td className="text-center">{formData.email}</td>

        <td className="text-center">{formData.type}</td>
        <td className="text-center">{formData.usertype}</td>

        <td>
          <div className="flex items-center justify-center gap-2">
            <button
              className="px-1.5 border-2 border-gray-300 font-medium rounded-md h-[35px]"
              onClick={onAcceptButtonClick}
            >
              <AiOutlineCheck className="w-5 h-5 text-blue" />
            </button>

            <button
              className="px-1.5 border-2 border-gray-300 font-medium rounded-md h-[35px]"
              onClick={onDeclineButtonClick}
            >
              <BiX className="w-5 h-5 text-red-900" />
            </button>

            <button
              className="px-1.5 border-2 border-gray-300 font-medium rounded-md h-[35px]"
              onClick={() => handleView(formData.id)}
            >
              <AiOutlineEye className="w-[22px] h-[22px] text-gray-700" />
            </button>
          </div>
        </td>
      </tr>
    </>
  );
};

export default VendorRequestsTableRow;
