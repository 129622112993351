import React from "react";
import BrandLogo from "../../../assets/images/osis.png";

const SignupHeader = () => {
  return (
    <header className="bg-gray-100 flex items-center justify-between pt-2 pb-2.5 px-6 border-b border-gray-200 sm:px-8 md:px-12 lg:px-16">
      <div className="flex items-center gap-4">
        <img src={BrandLogo} alt="" className="w-10" />
        <p className="text-2xl font-bold text-blue">Osis</p>
      </div>

      {/* <div className="flex items-center gap-1 text-[14px]">
        <p className="text-gray-800">Having Trouble filling Form?</p>
        <button className="text-gray-900 font-medium underline">Message Us</button>
      </div> */}
    </header>
  );
};

export default SignupHeader;
