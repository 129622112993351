import React from "react";
import ProductCard from "../common/ProductCard";
import { useEffect, useState } from "react";

const MoreFeaturedProducts = () => {

  const [products, setProducts] = useState([]);

  
  useEffect(() => {
    const fetchData = async () => {
      const featuredProductUrl = process.env.REACT_APP_BASE_URL + "homepage/latestproduct/";
      try {
        const response = await fetch(featuredProductUrl);
        const data = await response.json();
        
        // Fetching the image URL for each product asynchronously
        const transformedData = await Promise.all(data.map(async (product) => {
          // Assuming you have some unique identifier for each product, like partNumber
          const partNumber = product.part_numbers[0]; // Assuming part_numbers is an array
          const productImageUrl = process.env.REACT_APP_BASE_URL + `users/getpartimage/${partNumber}`;
          
          // Fetching the image for the current product
          const imageResponse = await fetch(productImageUrl);
          const imageData = await imageResponse.json();

          console.log("imageData", imageData);
          
          return {
            id: product.id,
            imageUrl: imageData[0], // Provide a placeholder image URL if the image is not available
            brand: product.part_id.brand,
            title: product.part_id.partName,
            price: product.normalRate,
            unit: product.units,
            status: product.status,
            sellerId: product.seller_id
          };
        }));
        
        // Setting the transformed data with updated image URLs
        setProducts(transformedData);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
    
    fetchData();
  }, []);
  
  

  const imageurl = process.env.REACT_APP_BASE;


  return (
    <div className="bg-gray-200 px-6 md:px-10 lg:px-20 pt-5 md:pt-10 pb-12">
      <div className="flex items-center justify-between">
        <h1 className="text-lg vsm:text-xl  font-bold">Latest Products</h1>

        {/* <div>
          <button className="text-blue text-[15px] font-medium hover:underline">
            View all
          </button>
        </div> */}
      </div>

      <div className="relative w-full mt-2 mb-5 md:mb-10 bg-gray-300 h-[2px] flex">
        <div className="absolute top-0 left-0 flex">
          <div className="w-[160px] bg-yellow h-[10px]"></div>
          <div className="w-[50px] relative -left-4 bg-yellow h-[10px] skew-x-[-50deg]"></div>
        </div>
      </div>

      <div className="grid vsm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 pt-6 gap-6 sm:gap-8">
      {products.map((product, index) => (
        <ProductCard
          imageUrl={product.imageUrl}
          brand={product.brand}
          rating={product.rating}
          title={product.title}
          price={product.price} 
          unit =  {product.unit}
          id = {product.id}
        />
      ))}

      </div>
    </div>
  );
};

export default MoreFeaturedProducts;
