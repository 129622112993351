import axios from "axios";
import { atom, useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { BiEdit, BiTrash } from "react-icons/bi";
import { FaCheck, FaTimes } from "react-icons/fa";
import { HiMinus, HiPlus } from "react-icons/hi";
import { IoClose } from "react-icons/io5";
import { MdOutlineLocalPrintshop } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import InputWithLabel from "../../../../common/InputWithLabel";
import { orderNumAtom } from "../../PurchaseOrder/PurchaseOrder";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { jwtDecode } from "jwt-decode";
import {
  viewedSellerProductAtom,
  isSellerProductViewedAtom,
} from "../Quotation";
import ViewProduct from "../ViewProduct";

const AvailableTableRow = ({
  formData,
  onAcceptButtonClick,
  onDeclineButtonClick,
  onEditClick,
}) => {
  const [isMobileTableRowOpen, setIsMobileTableRowOpen] = useState(false);
  const [openPopupForm, setOpenPopupForm] = useState(null);
  const [errors, setErrors] = useState({});
  const [orderNum, setOrderNum] = useAtom(orderNumAtom);
  const [proceedButtonType, setProceedButtonType] = useState("");
  const [isProductViewed, setIsProductViewed] = useAtom(
    isSellerProductViewedAtom
  );
  const [, setViewedProduct] = useAtom(viewedSellerProductAtom);

  const [popupFormData, setPopupFormData] = useState({
    quotation_id: "",
    unit: "",
    partNum: "",
    perUnitPrice: "",
    total: "",
    deliveryTime: "",
    remarks: "",
  });

  const navigate = useNavigate();

  const handleInputChange = (name, value) => {
    setPopupFormData((prevData) => {
      // Clear other fields if "remarks" is changed
      const updatedData = {
        ...prevData,
        [name]: value,
      };

      if (name === "remarks") {
        // Reset other fields when "remarks" is changed
        updatedData.unit = "";
        updatedData.partNum = "";
        updatedData.perUnitPrice = "";
        updatedData.total = "";
        updatedData.deliveryTime = "";
      } else if (name === "perUnitPrice") {
        const perUnitPrice = parseFloat(updatedData.perUnitPrice) || 0;
        const unit = parseFloat(updatedData.unit) || 0;
        updatedData.total = (perUnitPrice * unit).toFixed(2);
        updatedData.remarks = "";
      } else if (name === "deliveryTime") {
        // Convert deliveryTime to YYYY/MM/DD format
        const date = new Date(value);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        updatedData.deliveryTime = `${year}/${month}/${day}`;
      } else {
        updatedData.remarks = "";
      }

      return updatedData;
    });
  };

  const clearError = (name) => {
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  useEffect(() => {
    // setData(staticData)

    const fetchData = async () => {
      try {
        const orderurl =
          process.env.REACT_APP_BASE_URL +
          `quotation/order-details/${orderNum}/`;
        const response = await axios.get(`${orderurl}`);
        const apiData = response.data;

        console.log(apiData);

        // Transform the API data into the desired format
        const transformedData = apiData.map((item, index) => {
          return {
            quotation_id: item.id,
            partNum: item.part_id.partNumber,
            unit: item.quantity,
          };
        });

        console.log("TRANSFORNED DATA", transformedData);

        // popupFormData.partNum = transformedData.partNum;
        // popupFormData.unit = transformedData.unit;

        setPopupFormData({
          ...popupFormData,
          partNum: transformedData[0].partNum,
          unit: transformedData[0].unit,
          quotation_id: transformedData[0].quotation_id,
        });

        console.log("popupFormData", popupFormData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [orderNum]);

  const validateForm = () => {
    const validationRules =
      proceedButtonType === "Accept"
        ? {
            perUnitPrice: "Please enter the per unit price.",
            deliveryTime: "Please enter the delivery time.",
          }
        : {
            remarks: "Please enter the remarks.",
          };

    let isValid = true;

    for (const [key, value] of Object.entries(validationRules)) {
      if (!popupFormData[key]) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [key]: value,
        }));
        isValid = false;
      }
    }

    return isValid;
  };

  const onSubmit = async () => {
    const isValid = validateForm();

    if (isValid) {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          // Handle the case where the token is not present (e.g., redirect to login page)
          toast.error(
            "Authentication credentials not provided. Please log in."
          );
          return;
        }

        // Check if the authentication token has expired (example for JWT, may vary based on implementation)
        const isTokenExpired = () => {
          try {
            const decodedToken = jwtDecode(token);
            const currentTime = Date.now() / 1000; // Convert to seconds
            console.log("decodedToken", decodedToken.exp);
            console.log("currentTime", currentTime);
            console.log("isTokenExpired", decodedToken.exp < currentTime);
            return decodedToken.exp < currentTime;
          } catch (error) {
            return true; // Error decoding token or invalid token, treat as expired
          }
        };

        if (isTokenExpired()) {
          // Handle token expiration (e.g., refresh the token or redirect to login page)
          toast.error("Authentication token has expired. Please log in again.");
          navigate("/login");
          return;
        }
        const url =
          process.env.REACT_APP_BASE_URL + "quotation/createquotation/";
        const response = await axios.post(`${url}`, popupFormData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(popupFormData),
        });

        if (response.status === 201) {
          setOpenPopupForm(false);
          setErrors({});
        } else {
          console.error("Error WHILE OPERATING");
        }
      } catch (error) {
        console.error("Error sending data:", error);
      }
    }
  };

  return (
    <>
      <div className="border-b-2 border-gray-400 px-5 py-3 sm:hidden">
        <div className="flex items-center gap-4">
          <div
            className={`w-6 h-6 flex justify-center items-center ${
              isMobileTableRowOpen
                ? "bg-blue text-white rounded-[4px]"
                : "border-2 border-gray-400 text-gray-400 rounded-sm"
            }`}
          >
            <button
              onClick={(event) => {
                event.preventDefault();
                setIsMobileTableRowOpen(!isMobileTableRowOpen);
              }}
            >
              {isMobileTableRowOpen ? (
                <HiMinus className="w-5 h-5" />
              ) : (
                <HiPlus className="w-5 h-5" />
              )}
            </button>
          </div>

          <div>
            <h1 className="text-gray-900 font-bold text-[15px] uppercase">
              Order Number
            </h1>
            <p className="text-gray-800 font-medium text-[15px]">
              {formData.orderNo}
            </p>
          </div>
        </div>

        {isMobileTableRowOpen && (
          <div className="flex flex-col gap-2 mt-4 ml-[2.4rem]">
            <div className="flex flex-row gap-2 items-center">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                Order No:
              </h2>
              <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                {formData.orderNo}
              </p>
            </div>

            <div className="flex flex-row gap-2 items-center">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                Order Date:
              </h2>
              <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                {formData.orderDate}
              </p>
            </div>

            <div className="flex flex-row gap-2 items-center">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                Status:
              </h2>
              <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                {formData.status}
              </p>
            </div>

            <div className="flex items-center gap-2 my-4">
              <button className="px-1.5 border-2 border-gray-300 font-medium rounded-md h-[35px]">
                <MdOutlineLocalPrintshop className="w-6 h-6 text-gray-400" />
              </button>

              <button className="px-1.5 border-2 border-gray-300 font-medium rounded-md h-[35px]">
                <BiEdit className="w-5 h-5 text-blue" />
              </button>

              <button className="px-1.5 border-2 border-gray-300 font-medium rounded-md h-[35px]">
                <BiTrash className="w-5 h-5 text-red-900" />
              </button>

              <button className="px-1.5 border-2 border-gray-300 font-medium rounded-md h-[35px]">
                <AiOutlineEye className="w-[22px] h-[22px] text-gray-700" />
              </button>
            </div>
          </div>
        )}
      </div>

      <tr className="border-b-2 border-gray-300 text-gray-700 relative hidden sm:table-row hover:bg-gray-100">
        <td className="text-center">{formData.partNumber}</td>

        <td className="text-center">{formData.partName}</td>

        <td className="text-center">{formData.subCategory}</td>

        <td className="text-center">{formData.vehicleCompany}</td>

        <td className="text-center">{formData.brand}</td>

        <td className="text-center">{formData.model}</td>

        <td>
          <div className="flex items-center justify-center gap-2">
            <button
              className="px-1.5 border-2 border-gray-300 font-medium rounded-md h-[35px]"
              onClick={() => {
                setOpenPopupForm("Accept");
                setOrderNum(formData.orderNo);
              }}
            >
              <FaCheck className="w-[20px] h-[20px] text-green" />
            </button>

            <button
              className="px-1.5 border-2 border-gray-300 font-medium rounded-md h-[35px]"
              onClick={() => {
                setOpenPopupForm("Decline");
                setOrderNum(formData.orderNo);
              }}
            >
              <FaTimes className="w-[20px] h-[20px] text-red" />
            </button>

            <button
              className="px-1.5 border-2 border-gray-300 font-medium rounded-md h-[35px]"
              onClick={(event) => {
                event.preventDefault();
                setIsProductViewed(!isProductViewed);
                setViewedProduct(formData);
              }}
            >
              <AiOutlineEye className="w-[22px] h-[22px] text-gray-700" />
            </button>
          </div>
        </td>
      </tr>

      {isProductViewed && <ViewProduct formData={formData} />}

      {/* ------------------------ Popup Form for accept ------------------------------- */}
      {openPopupForm === "Accept" && (
        <div className="bg-[#0000007c] z-50 fixed top-0 left-0 w-screen h-screen flex items-center justify-center p-4">
          <div className="relative bg-white max-w-[800px] max-h-[95vh] overflow-y-auto sm:min-w-[600px] rounded-lg px-10 py-8">
            <div className="flex flex-col gap-4">
              <button
                className="absolute right-2 top-2 text-red-900"
                onClick={() => {
                  setOpenPopupForm(null);
                }}
              >
                <IoClose className="w-7 h-7" />
              </button>

              <div className="flex flex-col gap-1">
                <InputWithLabel
                  htmlFor="unit"
                  label="Unit"
                  type="text"
                  name="unit"
                  value={popupFormData.unit}
                  isDisabled={true}
                />

                {errors.unit && (
                  <p className="text-red-900 text-[14px] font-medium">
                    {errors.unit}
                  </p>
                )}
              </div>

              <div className="flex flex-col gap-1">
                <InputWithLabel
                  htmlFor="partNum"
                  label="Part Number"
                  type="text"
                  name="partNum"
                  value={popupFormData.partNumber}
                  isDisabled={true}
                />

                {errors.partNum && (
                  <p className="text-red-900 text-[14px] font-medium">
                    {errors.partNum}
                  </p>
                )}
              </div>

              <div className="flex flex-col gap-1">
                <InputWithLabel
                  htmlFor="perUnitPrice"
                  label="Per unit price"
                  type="text"
                  placeholder="Enter Per unit price"
                  name="perUnitPrice"
                  value={popupFormData.perUnitPrice}
                  onChange={(e) => {
                    handleInputChange("perUnitPrice", e.target.value);
                    clearError("perUnitPrice");
                  }}
                />

                {errors.perUnitPrice && (
                  <p className="text-red-900 text-[14px] font-medium">
                    {errors.perUnitPrice}
                  </p>
                )}
              </div>

              <div className="flex flex-col gap-1">
                <InputWithLabel
                  htmlFor="total"
                  label="Total"
                  type="text"
                  name="total"
                  value={popupFormData.total}
                  isDisabled={true}
                />
              </div>


              <div className="flex flex-col gap-1">
              <label
          htmlFor="deliveryTime"
          className="text-[14px] uppercase text-gray-800 font-bold"
        >
          Enter Delivery Date
        </label>
        <div className="w-full bg-white flex items-center justify-between gap-4 border border-gray-400 rounded-md px-3 py-2">
      
              <DatePicker
                  selected={popupFormData.deliveryTime} // Set the selected date
                  onChange={(date) => {
                    handleInputChange("deliveryTime", date); // Update the deliveryTime in the state
                    clearError("deliveryTime");
                  }}
                  dateFormat="yyyy-MM-dd" // Customize the date format if needed
                  placeholderText="Select Delivery Date"
                  className="w-full text-[14px] outline-none font-medium placeholder:text-gray-300 placeholder:font-normal"
                />
              </div>

                {errors.deliveryTime && (
                  <p className="text-red-900 text-[14px] font-medium">
                    {errors.deliveryTime}
                  </p>
                )}
              </div>

              <button
                type="button"
                className="w-fit self-end mt-4 bg-blue px-6 py-1.5 rounded-md text-[14px] text-white font-semibold"
                onClick={() => {
                  setProceedButtonType("Accept");
                  onSubmit();
                }}
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
      )}

      {/* ------------------------ Popup Form for decline ------------------------------- */}
      {openPopupForm === "Decline" && (
        <div className="bg-[#0000007c] z-50 fixed top-0 left-0 w-screen h-screen flex items-center justify-center p-4">
          <div className="relative bg-white max-w-[800px] max-h-[95vh] overflow-y-auto sm:min-w-[600px] rounded-lg px-10 py-8">
            <div className="flex flex-col gap-4">
              <button
                className="absolute right-2 top-2 text-red-900"
                onClick={() => {
                  setOpenPopupForm(null);
                }}
              >
                <IoClose className="w-7 h-7" />
              </button>

              <div className="flex flex-col gap-1">
                <InputWithLabel
                  htmlFor="remarks"
                  label="Remarks"
                  placeholder="Enter remarks"
                  name="remarks"
                  value={popupFormData.remarks}
                  isTextArea={true}
                  rows={5}
                  onChange={(e) => {
                    handleInputChange("remarks", e.target.value);
                    clearError("remarks");
                  }}
                />

                {errors.remarks && (
                  <p className="text-red-900 text-[14px] font-medium">
                    {errors.remarks}
                  </p>
                )}
              </div>

              <button
                type="button"
                className="w-fit self-end mt-4 bg-blue px-6 py-1.5 rounded-md text-[14px] text-white font-semibold"
                onClick={() => {
                  setProceedButtonType("Decline");
                  onSubmit();
                }}
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AvailableTableRow;
