import React from "react";

const AddressCard = ({ name, phone, address, label, id, onClick }) => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md mx-3 my-1">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-xl font-bold">{name}</h1>
        <h2 className="text-xs uppercase">{label}</h2>
      </div>
      <div className="mb-4">
        <p className="text-sm">{address}</p>
      </div>
      <div className="flex justify-between items-center">
        <p className="text-xs uppercase">{phone}</p>
        <button
          onClick={() => onClick(id)}
          className="px-3 py-1 bg-blue-600 text-white rounded-md text-xs uppercase hover:bg-blue-800 focus:outline-none focus:bg-blue-800"
        >
          Select
        </button>
      </div>
    </div>
  );
};

export default AddressCard;
