import { useAtom } from "jotai";
import React, { useEffect, useRef, useState } from "react";
import { HiChevronDown, HiChevronLeft, HiChevronRight } from "react-icons/hi";
import DeclinedRequestsTable, {
    dataAtom,
    rowsPerPageAtom,
} from "./DeclinedRequestsTable";

const DeclinedRequests = ({ searchQuery }) => {
  const [rowsPerPage, setRowsPerPage] = useAtom(rowsPerPageAtom);
  const [data, setData] = useAtom(dataAtom);
  const dataLength = data.length;

  const optionsRef = useRef(null);

  // State for managing the visibility of the rows per page options
  const [showRowsPerPageOptions, setShowRowsPerPageOptions] = useState(false);

  // Array of options for rows per page
  const rowsPerPageOptions = [10, 12, 14, 16, 18, 20];

  useEffect(() => {
    const fetchData = async () => {
        const staticData = [
            {
              productId: "1",
              vehicleCompany: "Vendor 1",
              subCategory: "Category 1",
              availability: "In Stock",
              brand: "Brand 1",
              model: "Model 1",
              manufactureYear: 2022,
              partCategories: "Category A",
              partSubCategories: "Sub-category A",
              partName: "Part A",
              partNumber: "Part Number A",
              description: "Description A",
              coverPhoto: null,
              otherPhotos: Array.from({ length: 7 }, () => null),
              otherAttributes: Array.from({ length: 5 }, () => ({ attributeValue: "" })),
              quantity: 10
            },
            {
              productId: "2",
              vehicleCompany: "Vendor 2",
              subCategory: "Category 2",
              availability: "Out of Stock",
              brand: "Brand 2",
              model: "Model 2",
              manufactureYear: 2022,
              partCategories: "Category A",
              partSubCategories: "Sub-category A",
              partName: "Part A",
              partNumber: "Part Number A",
              description: "Description A",
              coverPhoto: null,
              otherAttributes: Array.from({ length: 5 }, () => ({ attributeValue: "" })),
              quantity: 8
            },
          ];

      setData(staticData);

    //   Handle the API call here
    };

    fetchData();
  }, []);

  useEffect(() => {
    // Event listener to close the popup when clicking outside of it
    const handleOutsideClick = (event) => {
      if (
        showRowsPerPageOptions &&
        optionsRef.current &&
        !optionsRef.current.contains(event.target)
      ) {
        setShowRowsPerPageOptions(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    // Cleanup the event listener when the component is unmounted
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showRowsPerPageOptions]);

  // State for managing the current page
  const [currentPage, setCurrentPage] = useState(1);

  // Number of items to display per page
  const itemsPerPage = rowsPerPage;

  // Calculate the total number of pages
  const totalPages = Math.ceil(dataLength / itemsPerPage);

  // Function to handle the click on the left arrow
  const handleLeftArrowClick = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  // Function to handle the click on the right arrow
  const handleRightArrowClick = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  // Calculate the range of items to display on the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  return (
    <>
      <DeclinedRequestsTable
        startIndex={startIndex}
        endIndex={endIndex}
        searchQuery={searchQuery}
      />

      <div className="flex items-center justify-center text-gray-500 sm:justify-between">
        <div className="hidden sm:block">
          <p className="font-medium text-[15px]">
            1-
            <span>
              {rowsPerPage > dataLength ? dataLength : rowsPerPage}
            </span>{" "}
            of <span>{dataLength}</span>
          </p>
        </div>

        <div className="text-[15px] font-medium flex items-center gap-6">
          <div className="items-center gap-2 hidden sm:flex">
            <p>Rows per page:</p>

            <div
              className="relative flex items-center gap-1 cursor-pointer"
              onClick={() => setShowRowsPerPageOptions(!showRowsPerPageOptions)}
            >
              <p>{rowsPerPage}</p>
              <HiChevronDown className="text-[20px]" />

              {showRowsPerPageOptions && (
                <div
                  ref={optionsRef}
                  className="absolute right-6 bg-white border border-gray-300 mt-2 p-2 rounded-md"
                >
                  {rowsPerPageOptions.map((option) => (
                    <div
                      key={option}
                      className="cursor-pointer hover:bg-gray-200 p-1"
                      onClick={() => {
                        setRowsPerPage(option);
                        setShowRowsPerPageOptions(false);
                      }}
                    >
                      {option}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className="flex items-center gap-3">
            <button
              className="border-2 border-gray-300 text-gray-700 disabled:text-[#A1A9B8] rounded-xl p-1"
              onClick={handleLeftArrowClick}
              disabled={currentPage === 1}
            >
              <HiChevronLeft className="text-[24px]" />
            </button>

            <div>
              {currentPage}/{totalPages}
            </div>

            <button
              className="border-2 border-gray-300 text-gray-700 disabled:text-[#A1A9B8] rounded-xl p-1"
              onClick={handleRightArrowClick}
              disabled={currentPage === totalPages}
            >
              <HiChevronRight className="text-[24px]" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeclinedRequests;
