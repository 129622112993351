import { atom, useAtom } from "jotai";
import React, { useState } from "react";
import { BsFolder } from "react-icons/bs";
import { FaChevronDown } from "react-icons/fa";
import { FaRankingStar } from "react-icons/fa6";
import { HiOutlineMenuAlt1 } from "react-icons/hi";
import { MdCreditScore, MdPriceCheck } from "react-icons/md";
import { RiAdvertisementFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { ReactComponent as AgentsSVG } from "../../../assets/svg/agents.svg";
import { ReactComponent as DashboardSVG } from "../../../assets/svg/dashboard.svg";
import { ReactComponent as LogoutSVG } from "../../../assets/svg/exit.svg";
import { ReactComponent as InventorySVG } from "../../../assets/svg/inventory.svg";
import { ReactComponent as MessageSVG } from "../../../assets/svg/message.svg";
import { ReactComponent as OrderSVG } from "../../../assets/svg/order.svg";
import BrandLogo from "../../../assets/svg/osis.svg";
import { ReactComponent as StaffSVG } from "../../../assets/svg/people-outline.svg";
import { ReactComponent as UserSVG } from "../../../assets/svg/user.svg";
import { ReactComponent as VendorsSVG } from "../../../assets/svg/vendors.svg";
import { showCustomProductFormAtom } from "../../SellerPage/ProductManagement/AddProduct/AddProductMain";
import { currentStepAtom } from "../../SellerPage/ProductManagement/AddProduct/StepsIndicator";
import { isImportSuccessAtom } from "../../SellerPage/ProductManagement/ImportProduct/ImportProduct";
import { formDataAtom } from "../ProductManagement/AddPart/AddPart";
import {
  adminPartEditDataAtom,
  isAdminPartEditActiveAtom,
  isManageActiveAtom,
} from "../ProductManagement/ManageProduct/ManageProducts";

export const activeNavItemAtom = atom(0);
export const activeSubNavItemAtom = atom(null);
export const activeNavNameAtom = atom("Dashboard");
export const activeSubNavNameAtom = atom(null);
export const sidebarOpenAtom = atom(false);

const Sidebar = () => {
  const [openSubNav, setOpenSubNav] = useState(null);
  const [activeNavItem, setActiveNavItem] = useAtom(activeNavItemAtom);
  const [activeSubNavItem, setActiveSubNavItem] = useAtom(activeSubNavItemAtom);
  const [, setActiveNavName] = useAtom(activeNavNameAtom);
  const [, setActiveSubNavName] = useAtom(activeSubNavNameAtom);
  const [sidebarOpen, setSidebarOpen] = useAtom(sidebarOpenAtom);
  const [, setShowCustomProductForm] = useAtom(showCustomProductFormAtom);
  const [, setIsImportSuccess] = useAtom(isImportSuccessAtom);
  const [, setCurrentStep] = useAtom(currentStepAtom);
  const [, setIsManageActive] = useAtom(isManageActiveAtom);
  const [, setIsAdminPartEditActive] = useAtom(isAdminPartEditActiveAtom);
  const [, setAdminPartEditData] = useAtom(adminPartEditDataAtom);
  const [, setFormData] = useAtom(formDataAtom);

  const navigate = useNavigate();

  const productManager = localStorage.getItem("productManager") !== null;
  const logisticsManager = localStorage.getItem("logisticsManager") !== null;
  const financeManager = localStorage.getItem("financeManager") !== null;
  const infoManager = localStorage.getItem("infoManager") !== null;
  const customersupportManager = localStorage.getItem("customersupportManager") !== null;
  const admin = localStorage.getItem("admin") !== null;

  const handleSubNavToggle = (
    mainIndex,
    subIndex,
    navName,
    subNavName,
    subNavItems,
    hasSubNav
  ) => {
    setSidebarOpen(false);

    setFormData({
      vehicleCompany: "",
      subCategory: "",
      availability: "In Stock",
      brand: "",
      model: "",
      manufactureYear: "",
      partCategories: "",
      partSubCategories: "",
      partNumber: "",
      partName: "",
      description: "",
      image: null,
      otherAttributes: Array.from({ length: 5 }, () => ({
        attributeValue: "",
      })),
    });

    if (navName !== "Product Management") {
      setOpenSubNav(null);
    } else {
      setIsManageActive(true);
      setIsAdminPartEditActive(false);
      setAdminPartEditData(null);
    }

    if (mainIndex !== activeNavItem) {
      // If switching to a different main navigation item, reset activeSubNavName
      setActiveSubNavName(null);
    }

    setActiveNavItem(mainIndex);
    setActiveSubNavItem(subIndex);
    setActiveNavName(navName);
    setActiveSubNavName(subNavName);

    if (navName === "Product Management" && subNavName === "Add Part") {
      setShowCustomProductForm(false);
      setCurrentStep(1);
    } else if (
      navName === "Product Management" &&
      subNavName === "Import Part"
    ) {
      setIsImportSuccess(false);
    }

    if (hasSubNav) {
      // Toggle sub-navigation only if the clicked item has sub-navigation
      setOpenSubNav(openSubNav === mainIndex ? null : mainIndex);

      setSidebarOpen(true);

      if (subIndex === 0) {
        // Set activeSubNavName to the first sub-navigation item if clicking on the first sub-item
        setActiveSubNavName(subNavItems[0].text);
      } else {
        // Otherwise, set it to the clicked sub-navigation item
        setActiveSubNavName(subNavName);
      }
    }

    if (navName === "Dashboard") {
      navigate("/admin/");
    } else if (navName === "Product Management" && subNavName === "Add Part") {
      navigate("/admin/product-management/add");
    } else if (
      navName === "Product Management" &&
      subNavName === "Manage Product"
    ) {
      navigate("/admin/product-management/manage");
    } else if (
      navName === "Product Management" &&
      subNavName === "Import Part"
    ) {
      navigate("/admin/product-management/import");
    } else if (
      navName === "Product Management" &&
      subNavName === "Upload Photos"
    ) {
      navigate("/admin/product-management/upload-photos");
    } else if (navName === "Product Management") {
      navigate("/admin/product-management/add");
    } else if (navName === "Add Ads") {
      navigate("/admin/ad/add");
    } else if (navName === "Order Management") {
      navigate("/admin/order-management");
    } else if (navName === "Vendors Management") {
      navigate("/admin/vendors-management");
    } else if (navName === "Staff Management") {
      navigate("/admin/staff-management");
    } else if (navName === "User Management") {
      navigate("/admin/user-management");
    } else if (navName === "Product Info") {
      navigate("/admin/dictionary");
    } else if (navName === "Agent Management") {
      navigate("/admin/agent-management");
    } else if (navName === "Chat") {
      navigate("/admin/chat");
    } else if (navName === "Add Credit") {
      navigate("/admin/add-credit");
    } else if (navName === "Featured Product") {
      navigate("/admin/inventory-management");
    } else if (navName === "Unidentified Products") {
      navigate("/admin/unidentified-products");
    } else if (navName === "Ask for Rate") {
      navigate("/admin/ask-for-rate");
    } else {
      navigate("/admin/");
    }
  };

  const renderNavItem = (icon, text, hasSubNav, subNavItems = [], index) => (
    <li className="cursor-pointer" key={index}>
      <div
        onClick={() =>
          handleSubNavToggle(index, 0, text, null, subNavItems, hasSubNav)
        }
        className={`${
          activeNavItem === index ? "bg-blue-900" : ""
        } px-5 py-2 text-white relative flex items-center gap-2 font-semibold text-[15px]`}
      >
        {icon && React.cloneElement(icon, { className: "w-6 h-6" })}
        <p>{text}</p>
        {hasSubNav && (
          <FaChevronDown
            className={`absolute right-6 text-[13px] transition-transform lg:right-6 ${
              openSubNav === index ? "transform rotate-180" : ""
            }`}
          />
        )}
      </div>

      {hasSubNav && openSubNav === index && (
        <div
          className={`relative left-8 pl-6 pt-3 border-l-2 border-gray-300 flex flex-col gap-3`}
        >
          {subNavItems.map(({ text: subText }, subIndex) => (
            <div
              key={subIndex}
              onClick={() => handleSubNavToggle(index, subIndex, text, subText)}
              className={`w-[80%] relative flex items-center gap-2 px-4 py-1 text-white ${
                activeSubNavItem === subIndex
                  ? "bg-blue-900 text-white"
                  : "text-white"
              }`}
            >
              <BsFolder
                className={`w-5 h-5 ${
                  activeSubNavItem === subIndex ? "text-white" : ""
                }`}
              />
              <p
                className={`text-[15px] font-medium ${
                  activeSubNavItem === subIndex ? "text-white" : ""
                }`}
              >
                {subText}
              </p>

              {subIndex === activeSubNavItem && (
                <div className="bg-gray-300 w-[25px] h-[2px] absolute -left-[24px]"></div>
              )}
            </div>
          ))}
        </div>
      )}
    </li>
  );

  const renderNavItemsByRole = () => {
    if (!productManager && !logisticsManager && !financeManager && !infoManager && !customersupportManager) {
      return (
        <>
          {renderNavItem(<DashboardSVG />, "Dashboard", false, [], 0)}

          {renderNavItem(
            <InventorySVG />,
            "Product Management",
            true,
            [
              { text: "Add Part" },
              { text: "Manage Product" },
              { text: "Import Part" },
              { text: "Upload Photos" },
            ],
            1
          )}

          {renderNavItem(<InventorySVG />, "Order Management", false, [], 2)}

          {renderNavItem(<FaRankingStar />, "Featured Product", false, [], 3)}

          {renderNavItem(<OrderSVG />, "Unidentified Products", false, [], 4)}

          {renderNavItem(<UserSVG />, "User Management", false, [], 5)}

          {renderNavItem(<AgentsSVG />, "Agent Management", false, [], 6)}

          {renderNavItem(<VendorsSVG />, "Vendors Management", false, [], 7)}

          {renderNavItem(<StaffSVG />, "Staff Management", false, [], 8)}

          {renderNavItem(<RiAdvertisementFill />, "Add Ads", false, [], 9)}

          {renderNavItem(<InventorySVG />, "Product Info", false, [], 10)}

          {renderNavItem(
            <MessageSVG className="text-white" />,
            "Chat",
            false,
            [],
            11
          )}

          {renderNavItem(<MdCreditScore />, "Add Credit", false, [], 12)}

          {renderNavItem(<MdPriceCheck /> , "Ask for Rate", false, [], 13)}
        </>
      );
    } else if (productManager) {
      return (
        <>
          {renderNavItem(
            <InventorySVG />,
            "Product Management",
            true,
            [
              { text: "Add Part" },
              { text: "Manage Product" },
              { text: "Import Part" },
              { text: "Upload Photos" },
            ],
            1
          )}

          {renderNavItem(<InventorySVG />, "Order Management", false, [], 2)}

          {renderNavItem(<OrderSVG />, "Unidentified Products", false, [], 4)}

          {renderNavItem(<InventorySVG />, "Product Info", false, [], 10)}
        </>
      );
    } else if (logisticsManager) {
      return (
        <>
          {renderNavItem(<DashboardSVG />, "Dashboard", false, [], 0)}

          {renderNavItem(
            <InventorySVG />,
            "Product Management",
            true,
            [{ text: "Manage Product" }],
            1
          )}

          {renderNavItem(<InventorySVG />, "Order Management", false, [], 2)}
        </>
      );
    } else if (financeManager) {
      return (
        <>
          {renderNavItem(<VendorsSVG />, "Vendors Management", false, [], 7)}

          {renderNavItem(<MdCreditScore />, "Add Credit", false, [], 12)}
        </>
      );
    } else if (infoManager) {
      return (
        <>
          {renderNavItem(<FaRankingStar />, "Featured Product", false, [], 3)}

          {renderNavItem(<UserSVG />, "User Management", false, [], 5)}

          {renderNavItem(<AgentsSVG />, "Agent Management", false, [], 6)}

          {renderNavItem(<VendorsSVG />, "Vendors Management", false, [], 7)}

          {renderNavItem(<StaffSVG />, "Staff Management", false, [], 8)}

          {renderNavItem(<RiAdvertisementFill />, "Add Ads", false, [], 9)}
        </>
      );
    } else if (customersupportManager) {
      return (
        <>
          {renderNavItem(<VendorsSVG />, "Vendors Management", false, [], 7)}

          {renderNavItem(
            <MessageSVG className="text-white" />,
            "Chat",
            false,
            [],
            11
          )}
        </>
      );
    }
  };

  return (
    <div className={`relative w-fit lg:mr-[280px]`}>
      <div
        className={`bg-gray-700 z-40 w-full min-h-screen fixed left-0 flex flex-col gap-6 py-4 lg:fixed lg:overflow-x-hidden lg:overflow-y-auto lg:max-h-full lg:w-full lg:min-w-[280px] ${
          sidebarOpen
            ? "translate-x-0 lg:max-w-[280px]"
            : "-translate-x-full lg:translate-x-0 lg:min-w-min lg:max-w-[280px]"
        }`}
      >
        <button
          className="w-fit ml-6 flex items-center gap-3 text-white font-medium lg:hidden"
          onClick={() => setSidebarOpen(false)}
        >
          <HiOutlineMenuAlt1 className="w-7 h-7 text-white" />
          Menu
        </button>

        <div className={`hidden items-center gap-4 lg:flex px-6`}>
          <img
            src={BrandLogo}
            alt=""
            className={` ${sidebarOpen ? "min-w-0 w-10" : "w-[40px]"}
          
          `}
          />
          <p
            className={`text-2xl font-bold text-white
          `}
          >
            Osis
          </p>
        </div>

        <nav className="flex-1 flex flex-col gap-6 justify-between">
          <ul className="flex flex-col gap-1.5">{renderNavItemsByRole()}</ul>

          <button
            onClick={() => {
              localStorage.removeItem("usertoken");
              localStorage.removeItem("admintoken");
              localStorage.removeItem("agenttoken");
              localStorage.removeItem("token");

              navigate("/login");
            }}
            className={`px-5 py-2 mb-6 text-white relative flex items-center gap-2 font-semibold text-[15px]`}
          >
            <LogoutSVG className="w-6 h-6" />
            <p>Logout</p>
          </button>
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
