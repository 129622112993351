import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import CarVector from "../../../assets/images/carVector2.png";
import CarVector3 from "../../../assets/images/carVector3.png";
import AccessoriesIcon from "../../../assets/images/category/accessories.png";
import CategoryCard from "../common/CategoryCard";
import SliderNavButtons from "../common/SliderNavButtons";

const BrowseCategory = () => {
  const [categories, setCategories] = useState([]);
  const sliderRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      const categoryurl = process.env.REACT_APP_BASE_URL + "homepage/category/";
      try {
        const response = await fetch(`${categoryurl}`);
        const data = await response.json();
        console.log(data);
        const transformedData = data.map((product) => ({
          icon: product.image ? product.image : AccessoriesIcon,
          title: product.partCategories
            ? product.partCategories
            : "Accessories",
          productCount: product.product_count,
        }));
        setCategories(transformedData);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchData();
  }, []);

  const settings = {
    infinite: false,
    slidesToShow: categories.length >= 5 ? 5 : categories.length,
    slidesToScroll: 1,
    rows: 2,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: <></>,
    prevArrow: <></>,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 968,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const handleNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const handlePrev = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  return (
    <div className="relative bg-gray-200 px-6 md:px-10 lg:px-20 pt-20 pb-5 overflow-x-hidden">
      <div className="h-[200px] w-screen absolute top-10 left-0 bg-[#444BD3]">
        <img
          src={CarVector3}
          alt=""
          className="absolute left-0 bottom-0 max-w-[50%] md:max-w-[25%] object-contain opacity-50"
        />
        <img
          src={CarVector}
          alt=""
          className="absolute right-0 bottom-0 max-w-[35%] object-contain opacity-50 hidden md:block"
        />
      </div>

      <div className="relative z-10">
        <div className="flex items-center justify-between">
          <h1 className="text-white text-lg vsm:text-xl font-bold">
            Browse by Category
          </h1>
          <SliderNavButtons onNext={handleNext} onPrev={handlePrev} />
        </div>

        <div className="relative w-full mt-2 mb-10 bg-gray-300 h-[2px] flex">
          <div className="absolute top-0 left-0 flex">
            <div className="w-[160px] bg-yellow h-[10px]"></div>
            <div className="w-[50px] relative -left-4 bg-yellow h-[10px] skew-x-[-50deg]"></div>
          </div>
        </div>

        <div className="slider-container">
          <Slider {...settings} ref={sliderRef}>
            {categories.map((category, index) => (
              <div
                key={index}
                className="flex-1"
              >
                <CategoryCard
                  icon={category.icon}
                  title={category.title}
                  productCount={category.productCount}
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default BrowseCategory;
