import axios from "axios";
import React, { useState } from "react";
import { BiTrash } from "react-icons/bi";
import { FaUndo } from "react-icons/fa";
import { HiMinus, HiPlus } from "react-icons/hi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DeletedProductsTableRow = ({
  formData,
  fetchData,
  isSelected,
  onCheckboxChange,
}) => {
  const [isMobileTableRowOpen, setIsMobileTableRowOpen] = useState(false);

  const onDeleteClick = async (e) => {
    e.preventDefault();
    console.log("Delete btn clicked");
    formData["partinfo_id"] = formData.productId;
    const url = process.env.REACT_APP_BASE_URL + "admin/delete-partinfo/";
    try {
      const response = await axios.post(`${url}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        body: JSON.stringify(formData),
      });
      toast.success("Product Deleted Successfully");
      fetchData();
      // navigate("/product");
      // Handle success or navigate to a success page
    } catch (error) {
      toast.error("Product Deletion Failed");
      // Handle error
    }
  };

  const onRedoClick = async (e) => {
    e.preventDefault();
    formData["partinfo_id"] = formData.productId;
    formData["status"] = "Approved";
    const url = process.env.REACT_APP_BASE_URL + "admin/undo-partinfo/";
    try {
      const response = await axios.post(`${url}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        body: JSON.stringify(formData),
      });
      toast.success("Product Restored Successfully");

      fetchData();
      // navigate("/product");
      // Handle success or navigate to a success page
    } catch (error) {
      toast.error("Product Restoration Failed");
      // Handle error
    }
  };

  return (
    <>
      <div className="border-b-2 border-gray-400 px-5 py-3 sm:hidden">
        <ToastContainer />
        <div className="flex items-center gap-4">
          <div
            className={`w-6 h-6 flex justify-center items-center ${
              isMobileTableRowOpen
                ? "bg-blue text-white rounded-[4px]"
                : "border-2 border-gray-400 text-gray-400 rounded-sm"
            }`}
          >
            <button
              onClick={(event) => {
                event.preventDefault();
                setIsMobileTableRowOpen(!isMobileTableRowOpen);
              }}
            >
              {isMobileTableRowOpen ? (
                <HiMinus className="w-5 h-5" />
              ) : (
                <HiPlus className="w-5 h-5" />
              )}
            </button>
          </div>

          <div>
            <h1 className="text-gray-900 font-bold text-[15px] uppercase">
              Vehicle Company
            </h1>
            <p className="text-gray-800 font-medium text-[15px]">
              {formData.vehicleCompany}
            </p>
          </div>
        </div>

        {isMobileTableRowOpen && (
          <div className="flex flex-col gap-2 mt-4 ml-[2.4rem]">
            <div className="flex flex-row gap-2 items-center">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                Sub-Category:
              </h2>
              <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                {formData.subCategory}
              </p>
            </div>

            <div className="flex flex-row gap-2 items-center">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                Availability:
              </h2>
              <p
                className={`text-[15px] font-medium ${
                  formData.availability === "In Stock"
                    ? "text-green"
                    : "text-red"
                }`}
              >
                {" "}
                ● {formData.availability}
              </p>
            </div>

            <div className="flex flex-row gap-2 items-center">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                Part Number:
              </h2>
              <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                {formData.partNumber}
              </p>
            </div>

            <div className="flex flex-row gap-2 items-center">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                Year of Manufacturing:
              </h2>
              <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                {formData.manufactureYear}
              </p>
            </div>

            <div className="flex flex-row gap-2 items-center">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                Parts Categories:
              </h2>
              <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                {formData.partsCategories}
              </p>
            </div>

            <div className="flex flex-row gap-2 items-center">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                Sub-Parts Categories:
              </h2>
              <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                {formData.partSubCategories}
              </p>
            </div>

            <div className="flex flex-row gap-2 items-center">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                Parts Names:
              </h2>
              <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                {formData.partName}
              </p>
            </div>

            {/* <div className="flex flex-row gap-2 items-center">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                Stock Quantity:
              </h2>
              <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                {formData.stockQuantity}
              </p>
            </div>

            <div className="flex flex-row gap-2 items-center">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                Selling Price:
              </h2>
              <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                {formData.sellingPrice}
              </p>
            </div>

            <div className="flex flex-row gap-2 items-center">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                Units:
              </h2>
              <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                {formData.units}
              </p>
            </div> */}

            <div className="flex items-center gap-2 my-4">
              <button
                className="px-1.5 border-2 border-gray-300 font-medium rounded-md h-[35px]"
                onClick={onDeleteClick}
              >
                <BiTrash className="w-5 h-5 text-red-900" />
              </button>
            </div>
          </div>
        )}
      </div>

      <tr className="border-b-2 border-gray-300 text-gray-700 relative hidden sm:table-row hover:bg-gray-100">
        <td className="max-w-[1.5rem]">
          <div className="flex justify-between items-center ml-1">
            <input
              type="checkbox"
              name=""
              id=""
              checked={isSelected}
              onChange={onCheckboxChange}
            />
          </div>
        </td>

        <td>
          <div className="max-w-[180px] 2xl:max-w-[250px]  gap-2 lg:gap-3 pr-6">
            <span className="whitespace-break-spaces leading-5">
              {formData.vehicleCompany}
            </span>
          </div>
        </td>

        <td className="text-center">{formData.subCategory}</td>

        <td className="text-center">
          <p
            className={`text-[15px] font-medium ${
              formData.availability === "In Stock" ? "text-green" : "text-red"
            }`}
          >
            {" "}
            ● {formData.availability}
          </p>
        </td>

        <td className="text-center">{formData.partNumber}</td>

        <td className="text-center">{formData.model}</td>

        <td className="text-center">{formData.manufactureYear}</td>

        <td className="text-center">{formData.partCategories}</td>

        <td className="text-center">{formData.partSubCategories}</td>

        <td className="text-center">{formData.partName}</td>

        {/* <td className="text-center">{formData.stockQuantity}</td>

        <td className="text-center">{formData.sellingPrice}</td>

        <td className="text-center">{formData.units}</td> */}

        <td>
          <div className="flex items-center gap-2">
            <button
              className="px-1.5 border-2 border-gray-300 font-medium rounded-md h-[35px]"
              onClick={onDeleteClick}
            >
              <BiTrash className="w-5 h-5 text-red-900" />
            </button>

            <button
              className="px-1.5 border-2 border-gray-300 font-medium rounded-md h-[35px]"
              onClick={onRedoClick}
            >
              <FaUndo className="w-4 h-4 text-green" />
            </button>
          </div>
        </td>
      </tr>
    </>
  );
};

export default DeletedProductsTableRow;
