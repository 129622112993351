import { useAtom } from "jotai";
import React from "react";
import { ReactComponent as BackArrowSVG } from "../../../assets/svg/arrow-left-circle.svg";
import { isProductViewedAtom } from "../ProductManagement/ManageProduct/ManageProducts";

const ViewProduct = ({ formData }) => {
  const [, setIsProductViewed] = useAtom(isProductViewedAtom);
  const IMAGE_URL = process.env.REACT_APP_BASE;
  console.log(IMAGE_URL);
  console.log("cover photo", formData.coverPhoto);

  return (
    <div className="md:px-8 md:py-6 flex gap-4 sm:gap-8">
      <div className="flex-1 bg-white py-4 flex flex-col gap-6">
        <div className="flex items-center justify-between px-6 sm:px-8">
          <div className="text-blue font-bold flex gap-4 items-center">
            <button
              onClick={() => {
                setIsProductViewed(false);
              }}
            >
              <BackArrowSVG className="w-5 h-5" />
            </button>

            <p>Add Product Summary</p>
          </div>
        </div>

        <div className="flex flex-col gap-8 md:flex-row md:px-8 lg:px-[4.2rem] md:gap-16">
          <div className="md:w-[60%]">
            <div className="flex flex-col gap-3 px-6 sm:px-8 md:px-0">
              <h1 className="uppercase text-[15px] text-gray-800 font-bold">
                Vehicle Info
              </h1>

              <div className="flex flex-col gap-4">
                <div className="flex flex-col sm:flex-row sm:justify-between">
                  <h2 className="text-[14px] text-gray-900 font-bold md:flex-1">
                    Vehicle Company:
                  </h2>
                  <p className="text-[14px] text-gray-700 font-medium md:flex-1">
                    {formData.vehicleCompany}
                  </p>
                </div>

                <div className="flex flex-col sm:flex-row sm:justify-between">
                  <h2 className="text-[14px] text-gray-900 font-bold md:flex-1">
                    Sub-Category:
                  </h2>
                  <p className="text-[14px] text-gray-700 font-medium md:flex-1">
                    {formData.subCategory}
                  </p>
                </div>

                <div className="flex flex-col sm:flex-row sm:justify-between">
                  <h2 className="text-[14px] text-gray-900 font-bold md:flex-1">
                    Brand:
                  </h2>
                  <p className="text-[14px] text-gray-700 font-medium md:flex-1">
                    {formData.brand}
                  </p>
                </div>

                <div className="flex flex-col sm:flex-row sm:justify-between">
                  <h2 className="text-[14px] text-gray-900 font-bold md:flex-1">
                    Vehicle Company:
                  </h2>
                  <p className="text-[14px] text-gray-700 font-medium md:flex-1">
                    {formData.vehicleCompany}
                  </p>
                </div>

                <div className="flex flex-col sm:flex-row sm:justify-between">
                  <h2 className="text-[14px] text-gray-900 font-bold md:flex-1">
                    Model:
                  </h2>
                  <p className="text-[14px] text-gray-700 font-medium md:flex-1">
                    {formData.model}
                  </p>
                </div>

                <div className="flex flex-col sm:flex-row sm:justify-between">
                  <h2 className="text-[14px] text-gray-900 font-bold md:flex-1">
                    Year Of Manufacturing:
                  </h2>
                  <p className="text-[14px] text-gray-700 font-medium md:flex-1">
                    {formData.manufactureYear}
                  </p>
                </div>

                <div className="flex flex-col sm:flex-row sm:justify-between">
                  <h2 className="text-[14px] text-gray-900 font-bold md:flex-1">
                    Part Categories:
                  </h2>
                  <p className="text-[14px] text-gray-700 font-medium md:flex-1">
                    {formData.partCategories}
                  </p>
                </div>

                <div className="flex flex-col sm:flex-row sm:justify-between">
                  <h2 className="text-[14px] text-gray-900 font-bold md:flex-1">
                    Part Sub-Categories:
                  </h2>
                  <p className="text-[14px] text-gray-700 font-medium md:flex-1">
                    {formData.partSubCategories}
                  </p>
                </div>

                <div className="flex flex-col sm:flex-row sm:justify-between">
                  <h2 className="text-[14px] text-gray-900 font-bold md:flex-1">
                    Part Number:
                  </h2>
                  <p className="text-[14px] text-gray-700 font-medium md:flex-1">
                    {formData.partNumber}
                  </p>
                </div>

                <div className="flex flex-col sm:flex-row sm:justify-between">
                  <h2 className="text-[14px] text-gray-900 font-bold md:flex-1">
                    Part Name:
                  </h2>
                  <p className="text-[14px] text-gray-700 font-medium md:flex-1">
                    {formData.partName}
                  </p>
                </div>
              </div>
            </div>

            <div className="h-[1px] w-full bg-gray-400 my-8"></div>

            <div className="flex flex-col gap-3 px-6 sm:px-8 md:px-0">
              <h1 className="uppercase text-[15px] text-gray-800 font-bold">
                Price, Attributes & Description
              </h1>

              <div className="flex flex-col gap-4">
                {formData.otherAttributes.map((attribute, index) => (
                  <div
                    key={index}
                    className="flex flex-col sm:flex-row sm:justify-between"
                  >
                    <h2 className="text-[14px] text-gray-900 font-bold md:flex-1">
                      {attribute.attributeName || `Attribute ${index + 1}`}
                    </h2>
                    <p className="text-[14px] text-gray-700 font-medium md:flex-1">
                      {attribute.attributeValue || "N/A"}
                    </p>
                  </div>
                ))}

                <div className="flex flex-col sm:flex-row sm:justify-between">
                  <h2 className="text-[14px] text-gray-900 font-bold md:flex-1">
                    Description:
                  </h2>
                  <p className="text-[14px] text-gray-700 font-medium md:flex-1">
                    {formData.description}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="px-6 sm:px-8 flex flex-col items-center gap-2 md:px-0 md:items-end md:w-[40%]">
            <div className="w-full border-2 border-gray-300 flex items-center justify-center md:items-end">
              <img
                src={
                  formData.coverPhoto
                    ? `${IMAGE_URL}${formData.coverPhoto}`
                    : "https://via.placeholder.com/250"
                }
                alt=""
              />
            </div>

            <div className="flex flex-col items-center md:items-end">
              <h1 className="text-gray-900 text-[15px] font-bold">
                Availability
              </h1>
              <p
                className={`text-[14px] font-medium ${
                  formData.availability === "In Stock"
                    ? "text-green"
                    : "text-red"
                }`}
              >
                {" "}
                ● {formData.availability}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewProduct;
