import axios from "axios";
import { atom, useAtom } from "jotai";
import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import sampleFile from "../../../../assets/admin-osis-sheet.xlsx";

export const isImportSuccessAtom = atom(false);

const ImportPart = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileError, setFileError] = useState("");
  const [, setIsImportSuccess] = useAtom(isImportSuccessAtom);
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    setFileError("");
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();

    const file = e.dataTransfer.files[0];
    setSelectedFile(file);
    setFileError("");
  };

  const handleDownloadSample = () => {
    const a = document.createElement("a");
    a.href = sampleFile;
    a.download = "sample";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedFile) {
      setFileError("Please select a file.");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    // Move this line of code to try block to handle it as per the response
    
    setIsImportSuccess(true);
    setIsLoading(true);

    const url = process.env.REACT_APP_BASE_URL + "admin/addpartcsv/";

    // Perform form submission logic here
    try {
      const response = await axios.post(`${url}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("Data sent successfully:", response.data);
      // Handle success or navigate to a success page
      toast.success("Import successful!");
      setIsImportSuccess(true);
    } catch (error) {
      console.error("Error sending data:", error);
      // Handle error
      toast.error("Import failed!");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className="w-full min-h-[calc(100vh-65px)] px-8 flex flex-col gap-6 items-center justify-center"
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <ToastContainer position="top-center" autoClose={3000} />

      <div className="absolute top-[150px] right-8 lg:top-[90px]">
        <button
          className="bg-blue px-4 py-1.5 rounded-md text-white font-semibold"
          onClick={handleDownloadSample}
        >
          Download Sample
        </button>
      </div>

      <div className="max-w-xl min-w-[340px]">
        <label
          className={`flex justify-center w-full h-36 px-4 transition duration-200 bg-white border-2 border-gray-400 border-dashed rounded-md appearance-none cursor-pointer ${
            fileError ? "border-red-500" : "hover:border-gray-700"
          } focus:outline-none`}
          htmlFor="file_upload"
        >
          <span className="flex items-center space-x-2 overflow-hidden">
            {selectedFile ? (
              <span className="font-medium text-gray-600 overflow-hidden overflow-ellipsis">
                {selectedFile.name}
              </span>
            ) : (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6 text-gray-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                  />
                </svg>
                <span className="font-medium text-gray-600">
                  Drop files to Attach, or{" "}
                  <span className="text-blue-600 underline">browse</span>
                </span>
              </>
            )}
          </span>
          <input
            type="file"
            name="file_upload"
            id="file_upload"
            className="hidden"
            onChange={handleFileChange}
          />
        </label>
        {fileError && (
          <p className="text-red-900 text-[14px] font-medium mt-2">
            {fileError}
          </p>
        )}
      </div>

      <button
        className="bg-blue px-10 py-1.5 rounded-md text-white font-semibold relative"
        onClick={handleSubmit}
        disabled={isLoading} // Disable button while loading
      >
        {isLoading && (
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="w-6 h-6 border-2 border-t-2 border-blue-400 rounded-full animate-spin"></div>
          </div>
        )}
        {!isLoading && "Add"} 
      </button>
    </div>
  );
};

export default ImportPart;
