import { atom, useAtom } from "jotai";
import React from "react";
import { isSellerProductEditedAtom } from "../ManageProduct/AllProducts/AllProductsTableRow";
import { isProductSelectedAtom } from "./AddProductMain";

export const currentStepAtom = atom(1);

const stepContent = {
  1: {
    title: "Basic Information",
    description: "Fill out basic info like Product name, category and so on.",
  },
  2: {
    title: "Parts Information",
    description: "Add additional information about your product.",
  },
  3: {
    title: "Gallery",
    description: "Add photos of your product.",
  },
  4: {
    title: "Description",
    description: "Describe in detail about it.",
  },
  5: {
    title: "Variants, Price & Stock",
    description: "Add variants, price and stock of your product.",
  },
};

const stepColors = {
  1: "bg-blue",
  2: "bg-green",
  3: "bg-red",
  4: "bg-indigo",
  5: "bg-secondary-900",
};

const StepsIndicator = () => {
  const [currentStep] = useAtom(currentStepAtom);
  const [isProductSelected] = useAtom(isProductSelectedAtom);
  const [isSellerProductEdited] = useAtom(isSellerProductEditedAtom);

  // Define the number of steps based on the isProductSelected value
  const totalSteps = isProductSelected || isSellerProductEdited ? 5 : 4;

  return (
    <div className="relative z-10 bg-white h-fit px-4 md:px-8 py-8 w-full max-w-[80px] md:max-w-[250px] lg:max-w-[285px] flex flex-col gap-10">
      <h1 className="font-bold text-center">
        Steps <span className="hidden md:inline-block">To Add Product</span>
      </h1>

      <ol className="relative text-gray-200 border-l-2 left-6 flex flex-col gap-10 md:block md:left-0">
        {[1, 2, 3, 4, 5].slice(0, totalSteps).map((step) => (
          <li
            key={step}
            className={`mb-7 ml-8 ${
              step <= currentStep ? "text-gray-900" : "text-gray-500"
            }`}
          >
            <span
              className={`absolute flex items-center justify-center w-8 h-8 ${
                step <= currentStep ? stepColors[step] : "bg-gray-300"
              } text-white font-semibold rounded-full -left-4`}
            >
              {step}
            </span>

            <div className="flex-col gap-1.5 hidden md:flex">
              <h3
                className={`leading-tight text-[15px] ${
                  step <= currentStep
                    ? "text-gray-900 font-bold"
                    : "text-gray-500 font-medium"
                }`}
              >
                {stepContent[step].title}
              </h3>
              <p
                className={`text-sm text-[14px] ${
                  step <= currentStep ? "text-gray-700" : "text-gray-400"
                }`}
              >
                {stepContent[step].description}
              </p>
            </div>
          </li>
        ))}
      </ol>
    </div>
  );
};

export default StepsIndicator;
