import React, { useState, useEffect } from "react";
import AllOrder from "./AllOrder/AllOrder";
import Cancelled from "./Cancelled/Cancelled";
import Returned from "./Returned/Returned";
import ToPay from "./ToPay/ToPay";
import ToShip from "./ToShip/ToShip";


const MyOrders = () => {
  const [orderStatus, setOrderStatus] = useState("All Orders");


  return (
    <div className="bg-white pb-10">
      <div className="px-8 py-2 border-b-2 border-gray-200">
        <h1 className="font-bold text-lg text-gray-900">My Orders</h1>
      </div>

      <div className="px-8 py-5 pb-5">
        <div className="flex flex-col gap-4 lg:flex-row items-stretch justify-between lg:items-center">
          <div className="w-full flex">
            <div className="w-full flex items-center whitespace-nowrap overflow-x-auto lg:overflow-x-hidden gap-6 font-medium text-[14px] text-gray-500">
              <button
                className={`px-1.5 pb-0.5 ${
                  orderStatus === "All Orders"
                    ? "text-blue-900 font-bold border-b-2 border-blue-900"
                    : ""
                }`}
                onClick={() => setOrderStatus("All Orders")}
              >
                All Orders
              </button>

              <button
                className={`px-1.5 pb-0.5 ${
                  orderStatus === "To Pay"
                    ? "text-blue-900 font-bold border-b-2 border-blue-900"
                    : ""
                }`}
                onClick={() => setOrderStatus("To Pay")}
              >
                Processing
              </button>

              <button
                className={`${
                  orderStatus === "To Ship"
                    ? "text-blue-900 font-bold border-b-2 px-1.5 pb-0.5 border-blue-900"
                    : ""
                }`}
                onClick={() => setOrderStatus("To Ship")}
              >
                Received
              </button>

              <button
                className={`px-1.5 pb-0.5 ${
                  orderStatus === "Returned"
                    ? "text-blue-900 font-bold border-b-2 border-blue-900"
                    : ""
                }`}
                onClick={() => setOrderStatus("Returned")}
              >
                Returned
              </button>

              <button
                className={`px-1.5 pb-0.5 ${
                  orderStatus === "Cancelled"
                    ? "text-blue-900 font-bold border-b-2 border-blue-900"
                    : ""
                }`}
                onClick={() => setOrderStatus("Cancelled")}
              >
                Cancelled
              </button>
            </div>
          </div>


        </div>
      </div>

      <div className="px-8">
      {orderStatus === "All Orders" ? (
      <AllOrder />
    ) : orderStatus === "To Pay" ? (
      <ToShip />
    ) : orderStatus === "To Ship" ? (
      <ToPay />
    ) : orderStatus === "Returned" ? (
      <Returned />
    ) : orderStatus === "Cancelled" ? (
      <Cancelled />
    ) : (
      <></>
    )}
      </div>
    </div>
  );
};

export default MyOrders;
