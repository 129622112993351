import { atom, useAtom } from "jotai";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import PhoneInputWithLabel from "../common/PhoneInputWithLabel";
import PrimaryButton from "../common/PrimaryButton";
import TermsAndConditionsModal from "../common/TermsAndConditionModal";

// Create an atom for phoneNum
export const phoneNumAtom = atom("");

const BusinessSignupForm = ({ setVerificationModalOpen }) => {
  const [isCheckboxChecked, setCheckboxChecked] = useState(false);
  const [error, setError] = useState("");

  const [isTermsModalOpen, setTermsModalOpen] = useState(false);

  const openTermsModal = () => {
    setTermsModalOpen(true);
  };

  const closeTermsModal = () => {
    setTermsModalOpen(false);
  };

  // Use jotai's useAtom hook to get and set the phone number state
  const [, setPhoneNum] = useAtom(phoneNumAtom);

  const [formData, setFormData] = useState({
    phoneNum: "",
  });

  const handleCheckboxChange = () => {
    setCheckboxChecked(!isCheckboxChecked);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Set the phoneNum in the jotai atom
    setPhoneNum(value);
    // Set the phoneNum in the jotai atom
    setPhoneNum(value);

    // Update the formData state with the new phoneNum value
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    // Update the formData state with the new phoneNum value
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { phoneNum } = formData;
    const cleanedPhoneNum = phoneNum.trim();
    const isValidPhone = /^[0-9]{10}$/.test(cleanedPhoneNum);

    if (!isCheckboxChecked || !isValidPhone) {
      setError("Please enter a valid phone number.");
    } else {
      setError("");
      setVerificationModalOpen(true);
    }
  };

  return (
    <form
      action=""
      className="self-start w-full flex flex-col items-start gap-10 lg:px-[10%]"
    >
      <div className="w-full flex flex-col gap-2">
        <PhoneInputWithLabel
          label="Phone Number"
          name="phoneNum"
          placeholder="9852545652"
          value={formData.phoneNum}
          onChange={handleInputChange}
        />

        {error && (
          <p className="text-red-900 text-[14px] font-medium">{error}</p>
        )}

        <div className="flex items-center gap-2 mt-1">
          <input
            type="checkbox"
            name=""
            id=""
            className="w-3.5 h-3.5"
            onChange={handleCheckboxChange}
            checked={isCheckboxChecked}
          />
          <label htmlFor="" className="text-gray-500 font-medium  text-[14px]">
            I agree to{" "}
            <span
              className="text-blue cursor-pointer hover:underline underline-offset-4"
              onClick={openTermsModal}
            >
              terms & conditions and privacy policy
            </span>
            .
          </label>
        </div>
      </div>

      <div className="w-full flex flex-col gap-5 items-center">
        <PrimaryButton
          onClick={handleSubmit}
          label="Sign up"
          disabled={!isCheckboxChecked}
        />

        <div className="flex text-[14px] text-gray-900 items-center gap-2 self-center">
          <p>Doesn't have an account?</p>
          <Link to="/login">
            <button className="font-semibold">Login</button>
          </Link>
        </div>
      </div>

      <TermsAndConditionsModal
        isOpen={isTermsModalOpen}
        onRequestClose={closeTermsModal}
      />
    </form>
  );
};

export default BusinessSignupForm;
