import React from "react";
import AccerssoriesImg from "../../assets/images/category/accessories.png";
import ElectronicsImg from "../../assets/images/category/electronics.png";
import LightingImg from "../../assets/images/category/lighting.png";
import Offers from "../../components/User/Homepage/Offers";
import SearchResultPart from "../../components/User/SearchPart/SearchResultPart";
import Footer from "../../components/User/common/Footer";
import Header from "../../components/User/common/Header";
import { useAtom } from "jotai";
import { selectedDetailsAtom } from "../../components/User/Search/SearchModal";

const SearchPart = () => {
  const [selectedFilters, setSelectedFilters] = useAtom(selectedDetailsAtom);

  console.log(selectedFilters);

  const searchResultsData = [
    {
      title: "Maintenance Service Parts",
      partsList: [
        "Timing Belt",
        "Front Brake Pads",
        "Drum Brake",
        "Rear Brake Disc",
      ],
      imageSrc: AccerssoriesImg,
    },
    {
      title: "Electronics",
      partsList: [
        "Timing Belt",
        "Front Brake Pads",
        "Drum Brake",
        "Rear Brake Disc",
      ],
      imageSrc: ElectronicsImg,
    },
    {
      title: "Lighting",
      partsList: [
        "Timing Belt",
        "Front Brake Pads",
        "Drum Brake",
        "Rear Brake Disc",
      ],
      imageSrc: LightingImg,
    },
    // Add more sets of data as needed
  ];

  return (
    <div>
      <Header />

      <div className="relative">
        <div className="relative z-10 flex flex-col gap-10">
          <div className="pt-5 pb-6 flex flex-col gap-2">
            <div className="px-6 md:px-10 lg:px-20 mt-4 flex flex-col gap-4">
              <div className="grid sm:grid-cols-2 xl:grid-cols-3 2xl:0grid-cols-4 gap-4 mt-4">
                {searchResultsData.map((data, index) => (
                  <SearchResultPart key={index} {...data} />
                ))}
              </div>
            </div>

            <Offers />
          </div>
        </div>
      </div>

      <Footer showNewsletter={false} />
    </div>
  );
};

export default SearchPart;
