import React, { useRef, useState } from "react";
import { BiSolidDownload } from "react-icons/bi";

const AddAd = () => {
  const [homepageAds, setHomepageAds] = useState([null, null]);
  const [productPageAds, setProductPageAds] = useState([null, null]);
  const homepageFileInputRef = useRef([null, null]);
  const productPageFileInputRef = useRef([null, null]);

  const handleFileChange = (index, type, e) => {
    const file = e.target.files[0];

    if (type === "homepage") {
      const updatedAds = [...homepageAds];
      updatedAds[index] = file;
      setHomepageAds(updatedAds);
    } else if (type === "productPage") {
      const updatedAds = [...productPageAds];
      updatedAds[index] = file;
      setProductPageAds(updatedAds);
    }
  };

  const handleClick = (type, index) => {
    if (type === "homepage" && homepageFileInputRef.current[index]) {
      homepageFileInputRef.current[index].click();
    } else if (
      type === "productPage" &&
      productPageFileInputRef.current[index]
    ) {
      productPageFileInputRef.current[index].click();
    }
  };

  return (
    <div className="md:px-8 md:py-6 flex flex-col gap-4">
      <div className="flex-1 bg-white py-4 flex flex-col gap-6">
        <div className="flex items-center justify-between px-8">
          <div className="text-blue font-bold">Add Ads</div>
        </div>

        <div className="flex flex-col gap-6 lg:gap-8 px-8">
          <div className="flex flex-col gap-4 flex-1">
            <label className="text-[14px] uppercase text-gray-800 font-bold">
              Homepage Ads
            </label>
            <div className="w-full flex flex-col items-center gap-4 md:flex-row">
              {homepageAds.map((ad, index) => (
                <div key={index} className="w-full flex items-center gap-2">
                  <div
                    className={`w-full min-h-[180px] bg-[#F9FBFF] border border-dashed border-blue rounded-lg flex items-center justify-center gap-1 text-blue cursor-pointer`}
                    onClick={() => handleClick("homepage", index)}
                  >
                    {ad ? (
                      <p className="text-gray-700 px-1 text-[15px] font-medium overflow-hidden overflow-ellipsis">
                        {ad.name}
                      </p>
                    ) : (
                      <>
                        <BiSolidDownload className="text-lg" />
                        <span className="text-gray-700 text-[15px] font-medium">
                          <span className="text-blue underline">Browse</span> or{" "}
                          <span className="text-blue underline">Upload</span>{" "}
                          image
                        </span>
                      </>
                    )}
                    <input
                      type="file"
                      className="hidden"
                      ref={(el) => (homepageFileInputRef.current[index] = el)}
                      onChange={(e) => handleFileChange(index, "homepage", e)}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="flex flex-col gap-4 flex-1">
            <label className="text-[14px] uppercase text-gray-800 font-bold">
              Product Page Ads
            </label>
            <div className="w-full flex flex-col items-center gap-4 md:flex-row">
              {productPageAds.map((ad, index) => (
                <div key={index} className="w-full flex items-center gap-2">
                  <div
                    className={`w-full min-h-[180px] bg-[#F9FBFF] border border-dashed border-blue rounded-lg flex items-center justify-center gap-1 text-blue cursor-pointer`}
                    onClick={() => handleClick("productPage", index)}
                  >
                    {ad ? (
                      <p className="text-gray-700 px-1 text-[15px] font-medium overflow-hidden overflow-ellipsis">
                        {ad.name}
                      </p>
                    ) : (
                      <>
                        <BiSolidDownload className="text-lg" />
                        <span className="text-gray-700 text-[15px] font-medium">
                          <span className="text-blue underline">Browse</span> or{" "}
                          <span className="text-blue underline">Upload</span>{" "}
                          image
                        </span>
                      </>
                    )}
                    <input
                      type="file"
                      className="hidden"
                      ref={(el) =>
                        (productPageFileInputRef.current[index] = el)
                      }
                      onChange={(e) =>
                        handleFileChange(index, "productPage", e)
                      }
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="flex justify-end border-t border-gray-200">
          <button
            type="button"
            className="mx-6 mt-4 bg-blue px-8 py-1.5 rounded-md text-[14px] text-white font-semibold"
          >
            Add
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddAd;
