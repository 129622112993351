import React, { useRef, useState } from "react";
import { BiSolidDownload } from "react-icons/bi";
import { IoClose } from "react-icons/io5";
import InputWithLabel from "../../../../common/InputWithLabel";

const AddProductPopupForm = ({setShowPopupForm}) => {
  const [formData, setFormData] = useState({
    vehicleCompany: "",
    subCategory: "",
    availability: "In Stock",
    brand: "",
    model: "",
    manufactureYear: "",
    partCategories: "",
    partSubCategories: "",
    partNumber: "",
    partName: "",
    description: "",
    image: null,
    otherAttributes: Array.from({ length: 5 }, () => ({ attributeValue: "" })),
    quantity: "",
  });

  const [errors, setErrors] = useState({
    vehicleCompany: "",
    subCategory: "",
    brand: "",
    model: "",
    manufactureYear: "",
    partCategories: "",
    partSubCategories: "",
    partNumber: "",
    partName: "",
    stockQuantity: "",
    sellingPrice: "",
    units: "",
    description: "",
    image: "",
    otherAttributes: "",
  });

  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState("");

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleInputChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const onChange = (file) => {
    setFormData({ ...formData, image: file });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    onChange && onChange(file);

    if (!file) {
      // Set an error message for the image input
      setErrors((prevErrors) => ({
        ...prevErrors,
        image: "Image is required",
      }));
    } else {
      // Clear the error message when a valid file is selected
      setErrors((prevErrors) => ({
        ...prevErrors,
        image: "",
      }));
    }

    // Set the file name for display
    setFileName(file ? file.name : "");
  };

  const handleOtherAttributesChange = (index, value) => {
    setFormData((prevData) => {
      const otherAttributes = [...prevData.otherAttributes];
      otherAttributes[index].attributeValue = value;
      return {
        ...prevData,
        otherAttributes,
      };
    });

    clearError("otherAttributes");
  };

  const clearError = (name) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const validateForm = () => {
    const {
      vehicleCompany,
      subCategory,
      brand,
      model,
      manufactureYear,
      partCategories,
      partSubCategories,
      partNumber,
      partName,
      description,
      image,
      otherAttributes,
    } = formData;

    const newErrors = {
      vehicleCompany: vehicleCompany ? "" : "Vehicle company is required",
      subCategory: subCategory ? "" : "Sub-category is required",
      brand: brand ? "" : "Brand is required",
      model: model ? "" : "Model is required",
      manufactureYear: manufactureYear ? "" : "Year of manufacture is required",
      partCategories: partCategories ? "" : "Part category is required",
      partSubCategories: partSubCategories
        ? ""
        : "Part sub-category is required",
      partNumber: partNumber ? "" : "Part number is required",
      partName: partName ? "" : "Part name is required",
      description: description ? "" : "Description is required",
      image: image ? "" : "Image is required",
      otherAttributes:
        otherAttributes.filter((attribute) => attribute.attributeValue !== "")
          .length > 0
          ? ""
          : "At least one other attribute is required",
    };

    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = validateForm();

    if (Object.values(newErrors).every((error) => error === "")) {
      // Submit the form
      console.log("Form submitted");
    } else {
      setErrors(newErrors);
    }
  }

  return (
    <div className="bg-[#0000007c] z-50 fixed top-0 left-0 w-screen h-screen flex items-center justify-center p-4">
      <div className="w-full relative bg-white max-w-[900px] max-h-[90vh] overflow-y-auto sm:min-w-[600px] rounded-lg px-5 py-8">
        <div className="flex flex-col gap-4">
          <button
            className="absolute right-2 top-2 text-red-900"
            onClick={() => {
                setShowPopupForm(false);
            }}
          >
            <IoClose className="w-7 h-7" />
          </button>

          <form
            className="flex-1 flex flex-col justify-between gap-16"
            encType="multipart/form-data"
          >
            <div className="flex flex-col gap-4 px-6">
              <div className="flex flex-col gap-4 lg:flex-row">
                <div className="flex flex-col gap-1 flex-1">
                  <InputWithLabel
                    htmlFor="vehicleCompany"
                    label="Vehicle Company"
                    type="text"
                    placeholder="Maruti"
                    name="vehicleCompany"
                    value={formData.vehicleCompany}
                    onChange={(e) => {
                      handleInputChange("vehicleCompany", e.target.value);
                      clearError("vehicleCompany");
                    }}
                  />

                  {errors.vehicleCompany && (
                    <p className="text-red-900 text-[14px] font-medium">
                      {errors.vehicleCompany}
                    </p>
                  )}
                </div>

                <div className="flex flex-col gap-1 flex-1">
                  <InputWithLabel
                    htmlFor="subCategory"
                    label="Sub-Category"
                    type="text"
                    placeholder="Breeza"
                    name="subCategory"
                    value={formData.subCategory}
                    onChange={(e) => {
                      handleInputChange("subCategory", e.target.value);
                      clearError("subCategory");
                    }}
                  />

                  {errors.subCategory && (
                    <p className="text-red-900 text-[14px] font-medium">
                      {errors.subCategory}
                    </p>
                  )}
                </div>

                <div className="flex flex-col gap-2.5">
                  <label className="text-[14px] uppercase text-gray-800 font-bold">
                    Availabilty
                  </label>

                  <div className="flex gap-4">
                    <div className="flex items-center gap-1">
                      <input
                        type="radio"
                        id="inStock"
                        name="availability"
                        value="In Stock"
                        checked={formData.availability === "In Stock"}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            availability: e.target.value,
                          })
                        }
                      />

                      <label
                        htmlFor="inStock"
                        className={`text-[14px] font-medium ${
                          formData.availability === "In Stock"
                            ? "text-gray-800"
                            : "text-gray-500"
                        }
                        `}
                      >
                        In Stock
                      </label>
                    </div>

                    <div className="flex items-center gap-1">
                      <input
                        type="radio"
                        id="outOfStock"
                        name="availability"
                        value="Out of Stock"
                        checked={formData.availability === "Out of Stock"}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            availability: e.target.value,
                          })
                        }
                      />

                      <label
                        htmlFor="outOfStock"
                        className={`text-[14px] font-medium ${
                          formData.availability === "Out Of Stock"
                            ? "text-gray-800"
                            : "text-gray-500"
                        }
                        `}
                      >
                        Out of Stock
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-4 lg:flex-row">
                <div className="flex flex-col gap-1 flex-1">
                  <InputWithLabel
                    htmlFor="brand"
                    label="Brand"
                    type="text"
                    placeholder="Brand name"
                    name="brand"
                    value={formData.brand}
                    onChange={(e) => {
                      handleInputChange("brand", e.target.value);
                      clearError("brand");
                    }}
                  />

                  {errors.brand && (
                    <p className="text-red-900 text-[14px] font-medium">
                      {errors.brand}
                    </p>
                  )}
                </div>

                <div className="flex flex-col gap-1 flex-1">
                  <InputWithLabel
                    htmlFor="model"
                    label="Model"
                    type="text"
                    placeholder="M-1"
                    name="model"
                    value={formData.model}
                    onChange={(e) => {
                      handleInputChange("model", e.target.value);
                      clearError("model");
                    }}
                  />

                  {errors.model && (
                    <p className="text-red-900 text-[14px] font-medium">
                      {errors.model}
                    </p>
                  )}
                </div>

                <div className="flex flex-col gap-1 flex-1">
                  <InputWithLabel
                    htmlFor="manufactureYear"
                    label="Year of manufacturing"
                    type="text"
                    placeholder="2011"
                    name="manufactureYear"
                    value={formData.manufactureYear}
                    onChange={(e) => {
                      handleInputChange("manufactureYear", e.target.value);
                      clearError("manufactureYear");
                    }}
                  />

                  {errors.manufactureYear && (
                    <p className="text-red-900 text-[14px] font-medium">
                      {errors.manufactureYear}
                    </p>
                  )}
                </div>
              </div>

              <div className="flex flex-col gap-4 lg:flex-row">
                <div className="flex flex-col gap-1 flex-1">
                  <InputWithLabel
                    htmlFor="partCategories"
                    label="Part Categories"
                    type="text"
                    placeholder="Tires"
                    name="partCategories"
                    value={formData.partCategories}
                    onChange={(e) => {
                      handleInputChange("partCategories", e.target.value);
                      clearError("partCategories");
                    }}
                  />

                  {errors.partCategories && (
                    <p className="text-red-900 text-[14px] font-medium">
                      {errors.partCategories}
                    </p>
                  )}
                </div>

                <div className="flex flex-col gap-1 flex-1">
                  <InputWithLabel
                    htmlFor="partSubCategories"
                    label="Part Sub-Category"
                    type="text"
                    placeholder="Sub Category"
                    name="partSubCategories"
                    value={formData.partSubCategories}
                    onChange={(e) => {
                      handleInputChange("partSubCategories", e.target.value);
                      clearError("partSubCategories");
                    }}
                  />

                  {errors.partSubCategories && (
                    <p className="text-red-900 text-[14px] font-medium">
                      {errors.partSubCategories}
                    </p>
                  )}
                </div>
              </div>

              <div className="flex flex-col gap-4 lg:flex-row">
                <div className="flex flex-col gap-1 flex-1">
                  <InputWithLabel
                    htmlFor="partNumber"
                    label="Part Number"
                    type="text"
                    placeholder="2011"
                    name="partNumber"
                    value={formData.partNumber}
                    onChange={(e) => {
                      handleInputChange("partNumber", e.target.value);
                      clearError("partNumber");
                    }}
                  />

                  {errors.partNumber && (
                    <p className="text-red-900 text-[14px] font-medium">
                      {errors.partNumber}
                    </p>
                  )}
                </div>

                <div className="flex flex-col gap-1 flex-1">
                  <InputWithLabel
                    htmlFor="partName"
                    label="Part name"
                    type="text"
                    placeholder="partName"
                    name="partName"
                    value={formData.partName}
                    onChange={(e) => {
                      handleInputChange("partName", e.target.value);
                      clearError("partName");
                    }}
                  />

                  {errors.partName && (
                    <p className="text-red-900 text-[14px] font-medium">
                      {errors.partName}
                    </p>
                  )}
                </div>
              </div>

              <div className="flex flex-col gap-1 flex-1">
                <InputWithLabel
                  htmlFor="description"
                  label="Description"
                  isTextArea={true}
                  rows={5}
                  placeholder="Say something about your vehicle..."
                  name="description"
                  value={formData.description}
                  onChange={(e) => {
                    handleInputChange("description", e.target.value);
                    clearError("description");
                  }}
                />

                {errors.description && (
                  <p className="text-red-900 text-[14px] font-medium">
                    {errors.description}
                  </p>
                )}
              </div>

              <div className="flex flex-col gap-4 lg:flex-row">
                <div className="flex flex-col gap-1.5 flex-1">
                  <label className="text-[14px] uppercase text-gray-800 font-bold">
                    Image
                  </label>

                  <div
                    className={`w-full min-h-[90px] bg-[#F9FBFF] border border-dashed border-blue rounded-lg flex items-center justify-center gap-1 text-blue cursor-pointer xl:h-full xl:flex-1`}
                    onClick={handleClick}
                  >
                    {fileName ? (
                      <p className="text-gray-700 px-1 text-[15px] font-medium overflow-hidden overflow-ellipsis">
                        {fileName}
                      </p>
                    ) : (
                      <div className="flex items-center gap-2">
                        <BiSolidDownload className="text-lg text-blue" />

                        <p className="text-gray-700 text-[15px] font-medium">
                          <span className="text-blue underline">Browse</span> or{" "}
                          <span className="text-blue underline">Upload</span>{" "}
                          image
                        </p>
                      </div>
                    )}

                    <input
                      type="file"
                      className="hidden"
                      ref={fileInputRef}
                      onChange={handleFileChange}
                      name="image"
                    />
                  </div>

                  {errors.image && (
                    <p className="text-red-900 text-[14px] font-medium">
                      {errors.image}
                    </p>
                  )}
                </div>

                <div className="flex flex-col gap-1 flex-1">
                  <div className="flex flex-col gap-4">
                    {formData.otherAttributes.map((attribute, index) => (
                      <div className="flex gap-4 flex-1" key={index}>
                        <div className="flex flex-col gap-1 flex-1">
                          <InputWithLabel
                            htmlFor={`attributeName${index}`}
                            label={`Attribute ${index + 1}`}
                            type="text"
                            placeholder={`Attribute ${index + 1}`}
                            name="attributeName"
                            value={attribute.attributeValue}
                            onChange={(e) =>
                              handleOtherAttributesChange(index, e.target.value)
                            }
                          />
                        </div>
                      </div>
                    ))}
                  </div>

                  {errors.otherAttributes && (
                    <p className="text-red-900 text-[14px] font-medium">
                      {errors.otherAttributes}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </form>

          <button
            type="button"
            className="w-fit self-end mt-4 bg-blue px-6 py-1.5 rounded-md text-[14px] text-white font-semibold"
            onClick={handleSubmit}
          >
            Proceed
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddProductPopupForm;
