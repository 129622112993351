
import React, { useState } from "react";
import { HiChevronDown, HiOutlineSearch, HiUserAdd } from "react-icons/hi";
import AllUsers from "./AllUsers/AllUsers";
import DeletedUsers from "./DeletedUsers/DeletedUsers";


const ManageStaff = () => {
  const [productStatus, setProductStatus] = useState("All Users");
  const [searchQuery, setSearchQuery] = useState("");
  const [showMobileDropdown, setShowMobileDropdown] = useState(false);

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Function to handle selecting an option in the mobile dropdown
  const handleMobileOptionSelect = (option) => {
    setProductStatus(option);
    setShowMobileDropdown(false);
  };

  return (
    <div className={`px-8 py-6 flex flex-col gap-4`}>
      <div className="flex items-stretch justify-between lg:items-center">
        {/* Mobile dropdown for product status */}
        <div className="xl:hidden relative">
          <button
            className="flex items-center gap-1.5 text-blue font-bold text-[16px] bg-white border-2 border-gray-300 rounded-md px-2 py-2"
            onClick={() => setShowMobileDropdown(!showMobileDropdown)}
          >
            <p>{productStatus}</p>
            <HiChevronDown className="text-[20px]" />
          </button>

          {showMobileDropdown && (
            <div className="absolute z-30 mt-2 left-2 w-48 bg-white border border-gray-300 rounded-md">
              {["All Users", "Banned Users"].map((option) => (
                <button
                  key={option}
                  className={`w-full ${
                    productStatus === option ? "bg-gray-200" : ""
                  } cursor-pointer hover:bg-gray-200 p-1.5`}
                  onClick={(e) => {
                    handleMobileOptionSelect(option);
                    e.stopPropagation();
                  }}
                >
                  {option}
                </button>
              ))}
            </div>
          )}
        </div>

        {/* Desktop buttons for product status */}
        <div className="hidden xl:flex">
          <div className="flex items-center gap-6 font-medium text-[14px] text-gray-500">
            <button
              className={`${
                productStatus === "All Users"
                  ? "text-blue-900 font-bold border-b-2 pb-0.5 border-blue-900"
                  : ""
              }`}
              onClick={() => setProductStatus("All Users")}
            >
              All Users
            </button>
            <button
              className={`${
                productStatus === "Banned Users"
                  ? "text-blue-900 font-bold border-b-2 pb-0.5 border-blue-900"
                  : ""
              }`}
              onClick={() => setProductStatus("Banned Users")}
            >
              Banned Users
            </button>
          </div>
        </div>

        {/* Buttons for Add Staff and Search */}
        <div className="flex gap-4">
          {/* Add Staff Button */}
          {/* <button
            className="px-4 py-2 flex items-center gap-3 bg-blue-900 text-white rounded-md cursor-pointer"
            onClick={() => {
              // Handle the action when the "Add Staff" button is clicked
            }}
          >
            <HiUserAdd className="text-[20px]" />
            Create New Staff
          </button> */}

          {/* Search Input */}
          <div className="px-4 py-2 flex items-center gap-3 bg-white border border-gray-300 rounded-md cursor-pointer md:cursor-pointer">
            <HiOutlineSearch className="text-gray-500 text-[20px]" />
            <input
              type="text"
              placeholder="Search"
              className="hidden bg-white outline-none font-medium text-[14px] placeholder:text-[13px] w-[120px] placeholder:text-gray-400 sm:block"
              value={searchQuery}
              onChange={handleSearchInputChange}
            />
          </div>
        </div>
      </div>

      {/* Render AllUsers component based on productStatus */}
      {productStatus === "All Users" ? (
        <AllUsers searchQuery={searchQuery} />
      ) : (
        <DeletedUsers searchQuery={searchQuery} />
      )}
    </div>
  );
};

export default ManageStaff;
