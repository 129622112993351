import React, { useState } from "react";
import { HiOutlineSearch } from "react-icons/hi";
import Credit from "./Credit/Credit";

const AddCredit = () => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <div className={`px-8 py-6 flex flex-col gap-4`}>
      <div className="flex items-stretch justify-end lg:items-center">
        <div className="flex gap-4">
          <div className="px-4 py-2 flex items-center gap-3 bg-white border border-gray-300 rounded-md cursor-pointer md:cursor-pointer">
            <HiOutlineSearch className="text-gray-500 text-[20px]" />

            <input
              type="text"
              placeholder="Search"
              className="hidden bg-white outline-none font-medium text-[14px] placeholder:text-[13px] w-[120px] placeholder:text-gray-400 md:block"
              value={searchQuery}
              onChange={handleSearchInputChange}
            />
          </div>
        </div>
      </div>

      <Credit searchQuery={searchQuery} />
    </div>
  );
};

export default AddCredit;
