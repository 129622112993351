import React from "react";

const CarMakerCard = ({ imageUrl, logoUrl, makerName, availability }) => {
  return (
    <div className="rounded-lg relative bg-white shadow-md pt-1.5">
      <div className="relative px-1 h-[100px] w-full flex items-center justify-center">
        <img
          src={imageUrl}
          alt=""
          className="rounded-md w-full object-cover h-[100px]"
        />
      </div>

      <div className="relative flex flex-col gap-2 px-2 items-center pb-3 mt-[50px]">
        <div className="absolute -top-[70px] w-[60px] h-[60px] rounded-full border-gray-400">
          <img
            src={logoUrl}
            alt=""
            className="w-[60px] h-[60px] object-cover"
          />
        </div>

        <div className="flex flex-col items-center">
          <h1 className="text-[17px] font-bold">{makerName}</h1>
          <p className="text-gray-500 text-sm font-medium">
            {availability} Parts Available
          </p>
        </div>
      </div>
    </div>
  );
};

export default CarMakerCard;
