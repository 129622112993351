
import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import AdminLoginPage from "./pages/AdminPage/AdminLoginPage";
import AdminMainPage from "./pages/AdminPage/AdminMainPage";
import AdminSignupPage from "./pages/AdminPage/AdminSignupPage";
import LoginPage from "./pages/LoginPage";
import SellerMainPage from "./pages/SellerPage/SellerMainPage";
import SellerSignupPage from "./pages/SellerPage/SellerSignupPage";
import SignupPage from "./pages/SignupPage";
import UserMainPage from "./pages/UserPages/UserMainPage";
import Credit from "./components/SellerPage/Credit/CreditManage/Credit";
import { Navigate, useLocation } from 'react-router-dom';
import PaymentCallback from "./components/User/PlaceOrder/PaymentCallback";

function ProtectedRoute({ children }) {
  const location = useLocation();
  const adminToken = localStorage.getItem('admintoken'); // replace this with your actual token check

  if (!adminToken) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
}

function App() {

  return (
    <Router>
      <Routes>
        <Route path = "/payment-callback" element = {<PaymentCallback />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/seller_registration" element={<SellerSignupPage />} />
        <Route path="/seller/*" element={<SellerMainPage />} />
        
        <Route path="/admin/*" element={
          <ProtectedRoute>
            <AdminMainPage />
          </ProtectedRoute>
        } />

            <Route path="/admin_login" element={<LoginPage />} />
        
        <Route path="/*" element={<UserMainPage />} />
        <Route path="/test" element={<Credit />} />
      </Routes>
    </Router>
  );
}

export default App;
