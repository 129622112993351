import { useAtom } from "jotai";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import SuccessImage from "../../../../assets/images/success.png";
import {
  activeNavItemAtom,
  activeNavNameAtom,
  activeSubNavItemAtom,
  activeSubNavNameAtom,
} from "../../common/Sidebar";
import { currentStepAtom } from "./StepsIndicator";

const SuccessPage = () => {
  const [, setActiveNavItem] = useAtom(activeNavItemAtom);
  const [, setActiveSubNavItem] = useAtom(activeSubNavItemAtom);
  const [, setActiveNavName] = useAtom(activeNavNameAtom);
  const [, setActiveSubNavName] = useAtom(activeSubNavNameAtom);
  const [, setCurrentStep] = useAtom(currentStepAtom);

  const navigate = useNavigate();

  return (
    <div className="flex flex-col gap-4 items-center justify-center w-full min-h-[calc(100vh-65px-48px)]">
      <ToastContainer position="top-center" />

      <img src={SuccessImage} alt="" />

      <div className="flex flex-col items-center gap-2">
        <h1 className="text-lg font-semibold text-green-600 ">
          Congratulations!
        </h1>

        <p className="text-gray-500">
          You have successfully added the product.
        </p>
      </div>

      <button
        className="border border-gray-[#E5E5EB] text-gray-500 rounded-full px-10 py-1.5"
        onClick={() => {
          setActiveNavItem(0);
          setActiveSubNavItem(null);
          setActiveNavName("Dashboard");
          setActiveSubNavName(null);
          setCurrentStep(1);
          navigate("/seller");
        }}
      >
        Go to Dashboard
      </button>
    </div>
  );
};

export default SuccessPage;
