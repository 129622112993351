import React from "react";
import ProductDescription from "../../components/User/ProductDetail/ProductDescription";
import ProductDetailsTop from "../../components/User/ProductDetail/ProductDetailsTop";
import RatingsReviews from "../../components/User/ProductDetail/RatingsReviews";
import Footer from "../../components/User/common/Footer";
import Header from "../../components/User/common/Header";
import ScrollToTopButton from "../../components/User/common/ScrollToTopButton";

const ProductDetail = () => {
  return (
    <div>
      <Header />

      <div className="relative">
        <div className="absolute top-0 left-0 bg-blue w-full min-h-[300px] hidden md:block"></div>

        <div className="relative z-10 px-6 md:px-10 lg:px-20 flex flex-col gap-6 md:gap-10">
          <ProductDetailsTop />

          <ProductDescription />

          <div className="flex flex-col md:flex-row gap-4 md:gap-6">
            <div className="flex flex-col gap-4 md:gap-6 md:w-[60%]">
              <RatingsReviews />

              {/* <QuestionsAnswers /> */}
            </div>

            <div className="flex flex-col items-stretch gap-4 md:gap-6 md:w-[40%]">
              {/* <InquiryForm /> */}

              <div className="cursor-pointer flex-1 h-full bg-[url(https://www.audi.com/content/dam/ci/Guides/Communication_Media/Primeter_Advertising/02_Anwendungsbeispiele/audi-ci-communication-media-dealer-advertisements-example6.png?imwidth=1200)] bg-cover bg-center bg-no-repeat"></div>

              </div>
          </div>

          {/* <SimilarProducts /> */}
        </div>
      </div>

      <Footer showNewsletter={false} />

      <ScrollToTopButton />
    </div>
  );
};

export default ProductDetail;
