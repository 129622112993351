import axios from "axios";
import React, { useState } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { BiEdit, BiTrash } from "react-icons/bi";
import { HiMinus, HiPlus } from "react-icons/hi";
// import { isSellerProductViewedAtom, viewedSellerProductAtom } from "../ManageProduct";

const DeclinedRequestsTableRow = ({
  formData,
  onAcceptButtonClick,
  onDeclineButtonClick,
  onEditClick,
}) => {
  const [isMobileTableRowOpen, setIsMobileTableRowOpen] = useState(false);
  //   const [isProductViewed, setIsProductViewed] = useAtom(isSellerProductViewedAtom);
  //   const [, setViewedProduct] = useAtom(viewedSellerProductAtom);

  const onDeleteClick = async (e) => {
    e.preventDefault();
    console.log(formData);
    formData["productinfo_id"] = formData.productId;
    formData["status"] = "Deleted";
    const updateproducturl=process.env.REACT_APP_BASE_URL+"sellerdashboard/updateproduct/"
    try {
      const response = await axios.post(
        `${updateproducturl}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          body: JSON.stringify(formData),
        }
      );
      console.log("Data sent successfully:", response.data);

      // navigate("/product");
      // Handle success or navigate to a success page
    } catch (error) {
      console.error("Error sending data:", error);
      // Handle error
    }
  };
  return (
    <>
      <div className="border-b-2 border-gray-400 px-5 py-3 sm:hidden">
        <div className="flex items-center gap-4">
          <div
            className={`w-6 h-6 flex justify-center items-center ${
              isMobileTableRowOpen
                ? "bg-blue text-white rounded-[4px]"
                : "border-2 border-gray-400 text-gray-400 rounded-sm"
            }`}
          >
            <button
              onClick={(event) => {
                event.preventDefault();
                setIsMobileTableRowOpen(!isMobileTableRowOpen);
              }}
            >
              {isMobileTableRowOpen ? (
                <HiMinus className="w-5 h-5" />
              ) : (
                <HiPlus className="w-5 h-5" />
              )}
            </button>
          </div>

          <div>
            <h1 className="text-gray-900 font-bold text-[15px] uppercase">
              Vehicle Company
            </h1>
            <p className="text-gray-800 font-medium text-[15px]">
              {formData.vehicleCompany}
            </p>
          </div>
        </div>

        {isMobileTableRowOpen && (
          <div className="flex flex-col gap-2 mt-4 ml-[2.4rem]">
            <div className="flex flex-row gap-2 items-center">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                Sub-Category:
              </h2>
              <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                {formData.subCategory}
              </p>
            </div>

            <div className="flex flex-row gap-2 items-center">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                Availability:
              </h2>
              <p
                className={`text-[15px] font-medium ${
                  formData.availability === "In Stock"
                    ? "text-green"
                    : "text-red"
                }`}
              >
                {" "}
                ● {formData.availability}
              </p>
            </div>

            <div className="flex flex-row gap-2 items-center">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                Brand:
              </h2>
              <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                {formData.brand}
              </p>
            </div>

            <div className="flex flex-row gap-2 items-center">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                Year of Manufacturing:
              </h2>
              <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                {formData.manufactureYear}
              </p>
            </div>

            <div className="flex flex-row gap-2 items-center">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                Parts Categories:
              </h2>
              <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                {formData.partsCategories}
              </p>
            </div>

            <div className="flex flex-row gap-2 items-center">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                Sub-Parts Categories:
              </h2>
              <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                {formData.partSubCategories}
              </p>
            </div>

            <div className="flex flex-row gap-2 items-center">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                Parts Names:
              </h2>
              <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                {formData.partName}
              </p>
            </div>

            <div className="flex flex-row gap-2 items-center">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                Selling Price:
              </h2>
              <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                {formData.sellingPrice}
              </p>
            </div>
            <div className="flex flex-row gap-2 items-center">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                Stock Quantity:
              </h2>
              <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                {formData.stockQuantity} {formData.units}
              </p>
            </div>

            <div className="flex items-center gap-2 my-4">
              <button className="px-1.5 border-2 border-gray-300 font-medium rounded-md h-[35px]">
                <BiEdit className="w-5 h-5 text-blue" />
              </button>

              <button className="px-1.5 border-2 border-gray-300 font-medium rounded-md h-[35px]">
                <BiTrash className="w-5 h-5 text-red-900" />
              </button>

              <button className="px-1.5 border-2 border-gray-300 font-medium rounded-md h-[35px]">
                <AiOutlineEye className="w-[22px] h-[22px] text-gray-700" />
              </button>
            </div>
          </div>
        )}
      </div>

      <tr className="border-b-2 border-gray-300 text-gray-700 relativetable-row">
        <td className="text-center">{formData.partNumber}</td>

        <td className="text-center">{formData.partName}</td>

        <td className="text-center"> {formData.partCategories}</td>

        <td className="text-center">{formData.vehicleCompany}</td>

        <td className="text-center">{formData.brand}</td>

        <td className="text-center">{formData.model}</td>

        <td className="text-center">{formData.quantity}</td>

        <td className="text-center">
          <div className="flex items-center gap-2">
            <button
              className="px-1.5 border-2 border-gray-300 font-medium rounded-md h-[35px]"
              onClick={(event) => {
                event.preventDefault();
              }}
            >
              <AiOutlineEye className="w-[22px] h-[22px] text-gray-700" />
            </button>

            <button
              className="px-1.5 border-2 border-gray-300 font-medium rounded-md h-[35px]"
              onClick={(event) => {
                event.preventDefault();
              }}
            >
              <BiTrash className="w-5 h-5 text-red-900" />
            </button>
          </div>
        </td>
      </tr>
    </>
  );
};

export default DeclinedRequestsTableRow;
