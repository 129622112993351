import { atom, useAtom } from "jotai";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import InputWithLabel from "../../common/InputWithLabel";
import PrimaryButton from "../../common/PrimaryButton";

// Create an atom for phoneNum
export const phoneNumAtom = atom("");

const AdminSignupForm = ({
  formData,
  setFormData,
  setVerificationModalOpen,
}) => {
  const [, setPhoneNum] = useAtom(phoneNumAtom);

  const [error, setError] = useState("");

  const validateEmailOrPhone = (input) => {
    // const emailRegex =
    //   /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    const phoneRegex = /^[0-9]{10}$/;

    // if (emailRegex.test(input)) {
    //   return "email";
    // } else if (phoneRegex.test(input)) {
    //   return "phone";
    // } else {
    //   return "invalid";
    // }

    if (phoneRegex.test(input)) {
      return "phone";
    } else {
      return "invalid";
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "phoneNum") {
      setPhoneNum(value);
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { phoneNum, name, password, password2 } = formData;

    if (!phoneNum || !name || !password || !password2) {
      setError("Please fill in all fields.");
    } else if (password !== password2) {
      setError("Passwords do not match!");
    } else {
      const validationType = validateEmailOrPhone(phoneNum);

      if (validationType === "invalid") {
        setError("Please enter a valid phone number.");
      } else {
        setError("");

        // Perform form submission logic here
        setVerificationModalOpen(true);
      }
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="self-start w-full flex flex-col items-start gap-5 lg:px-[10%]"
    >
      <InputWithLabel
        htmlFor="fullName"
        label="Full Name"
        type="text"
        placeholder="Lexi Alexandra"
        name="name"
        value={formData.name}
        onChange={handleInputChange}
      />

      <InputWithLabel
        htmlFor="phoneNum"
        label="Phone number"
        type="text"
        placeholder="09123456789"
        name="phoneNum"
        value={formData.phoneNum}
        onChange={handleInputChange}
      />

      <InputWithLabel
        htmlFor="password"
        label="Create Password"
        type="password"
        placeholder="Helloecommerce"
        name="password"
        value={formData.password}
        onChange={handleInputChange}
      />

      <InputWithLabel
        htmlFor="password2"
        label="Confirm Password"
        type="password"
        placeholder="Helloecommerce"
        name="password2"
        value={formData.password2}
        onChange={handleInputChange}
      />

      {error && <p className="text-red-900 text-[14px] font-medium">{error}</p>}

      <div className="mt-2 w-full">
        <PrimaryButton onClick={handleSubmit} label="Sign up" />
      </div>

      <div className="flex text-[14px] text-gray-900 items-center gap-2 self-center">
        <p>Already have an account?</p>
        <Link to="/admin_login">
          <button className="font-semibold">Login</button>
        </Link>
      </div>
    </form>
  );
};

export default AdminSignupForm;
