import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { FaUser } from "react-icons/fa";
import { IoCartOutline } from "react-icons/io5";
import { MdOutlineRateReview } from "react-icons/md";
import { FaHouse } from "react-icons/fa6";
import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { viewOrderDetailAtom } from "../../components/User/Account/MyOrders/AllOrder/AllOrderTableRow";
import MyOrders from "../../components/User/Account/MyOrders/MyOrders";
import OrderDetail from "../../components/User/Account/MyOrders/OrderDetail";
import MyProfile from "../../components/User/Account/MyProfile";
import MyReviews from "../../components/User/Account/MyReviews/MyReviews";
import Footer from "../../components/User/common/Footer";
import Header from "../../components/User/common/Header";
import Addresses from "../../components/User/Account/Addresses/Addresses";


const UserAccount = () => {
  const [activeButton, setActiveButton] = useState("profile");
  const [viewOrderDetail] = useAtom(viewOrderDetailAtom);

  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname;

    if (currentPath === "/account/profile") {
      setActiveButton("profile");
    } else if (currentPath === "/account/wishlist") {
      setActiveButton("wishlist");
    } else if (currentPath === "/account/reviews") {
      setActiveButton("myReviews");
    } else if (currentPath === "/account/address") {
      setActiveButton("address");
    }
  }, [location]);

  const navigate = useNavigate();

  return (
    <div>
      <Header showCategory={false} showTopPart={false} />

      <div className="px-6 min-h-[calc(100vh_-_322.8px_-_64.625px)] md:px-10 lg:px-20 py-8 flex gap-5 lg:gap-10 flex-col lg:flex-row">
        <div className="lg:shadow-lg h-fit overflow-x-auto whitespace-nowrap md:overflow-x-hidden flex lg:flex-col">
          <button
            className={`rounded-t-md lg:min-w-[180px] text-[15px]  px-5 py-2 font-medium flex items-center gap-2 transition-all duration-200 ${
              activeButton === "profile"
                ? "bg-blue text-white"
                : "bg-white text-black"
            }`}
            onClick={() => {
              setActiveButton("profile");
              navigate("/account/profile");
            }}
          >
            <FaUser />
            My Profile
          </button>

          <button
            className={`lg:min-w-[180px] text-[15px]  px-5 py-2 font-medium flex items-center gap-2 transition-all duration-200 ${
              activeButton === "myOrder"
                ? "bg-blue text-white"
                : "bg-white text-black"
            }`}
            onClick={() => {
              setActiveButton("myOrder");
              navigate("/account/orders");
            }}
          >
            <IoCartOutline className="text-xl" />
            My Order
          </button>

          <button
            className={`lg:min-w-[180px] text-[15px]  px-5 py-2 font-medium flex items-center gap-2 transition-all duration-200 ${
              activeButton === "myReviews"
                ? "bg-blue text-white"
                : "bg-white text-black"
            }`}
            onClick={() => {
              setActiveButton("myReviews");
              navigate("/account/reviews");
            }}
          >
            <MdOutlineRateReview className="text-xl" />
            My Reviews
          </button>

          <button
            className={`rounded-b-md lg:min-w-[180px] text-[15px]  px-5 py-2 font-medium flex items-center gap-2 transition-all duration-200 ${
              activeButton === "address"
                ? "bg-blue text-white"
                : "bg-white text-black"
            }`}
            onClick={() => {
              setActiveButton("address");
              navigate("/account/address");
            }}
          >
            <FaHouse />
            My Address
          </button>
        </div>

        <div className="flex-1 overflow-x-hidden">
          <Routes>
            <Route path="/profile" element={<Outlet />}>
              <Route index element={<MyProfile />} />
            </Route>

            <Route path="/orders" element={<Outlet />}>
              <Route
                index
                element={viewOrderDetail ? <OrderDetail /> : <MyOrders />}
              />
            </Route>

            <Route path="/reviews" element={<Outlet />}>
              <Route index element={<MyReviews />} />
            </Route>

            <Route path="/address" element={<Outlet />}>
              <Route index element={<Addresses />} />
            </Route>
          </Routes>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default UserAccount;
