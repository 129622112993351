import axios from "axios";
import { jwtDecode } from "jwt-decode";
import React from "react";
import { FaRegHeart, FaStar } from "react-icons/fa";

import { MdInfo, MdOutlineShoppingCart } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const PartCard = ({ imageUrl, brand, title,id }) => {
  console.log("imageUrl", imageUrl);
  const navigate = useNavigate();
  const imageUrlPath = process.env.REACT_APP_BASE;

  const handleCardClick = () => {

    navigate(`/partDetail/${id}`);
    window.scrollTo(0, 0); 
  };



  return (
    <div
      className="relative cursor-pointer bg-white py-4 rounded-md shadow-md border border-gray-300"
      onClick={handleCardClick}
    >
      <ToastContainer position="top-center" />
      <div className="relative px-2 h-[250px] pb-4 border-b-2 border-gray-200 w-full flex items-center justify-center">
        <img
          src={imageUrl ? `${imageUrlPath}${imageUrl}` : "https://th.bing.com/th/id/R.f145fbfe280056043be63570724ac0c4?rik=E7e2uVHaKcWmdw&pid=ImgRaw&r=0"}
          alt="No Image"
          className="rounded-md w-full h-[250px] object-cover"
        />
      </div>




      <div className="py-2 px-4 flex flex-col gap-2">
        <div className="flex items-center justify-between font-medium">
          <span className="text-sm text-blue">{brand}</span>

        </div>

        <div className="flex flex-col gap-3">
          <h1 className="text-[17px] font-medium">{title}</h1>

          <button
            className="text-[15px] bg-blue text-white font-medium border rounded-[4px] w-full py-2 flex items-center justify-center gap-2"
            onClick={handleCardClick}
          >

            <MdInfo className="text-lg" />
            More Details 
          </button>
        </div>
      </div>
      {/* <ToastContainer position="top-center" autoClose={3000} /> */}
    </div>
  );
};

export default PartCard;
