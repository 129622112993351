import { atom, useAtom } from "jotai";
import React, { useEffect, useRef, useState } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { FaStar } from "react-icons/fa";
import { MdOutlineRateReview } from "react-icons/md";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { jwtDecode } from "jwt-decode";

export const viewOrderDetailAtom = atom(false);

const ToReviewTableRow = ({ formData }) => {
  const [, setViewOrderDetail] = useAtom(viewOrderDetailAtom);
  const [showReviewForm, setShowReviewForm] = useState(false);
  // const [id,se]
  const navigate = useNavigate();
  const [reviewData, setReviewData] = useState({
    rating: 0,
    comment: "",
    productId: formData.productId,
  });
  const [errors, setErrors] = useState({
    rating: "",
    comment: "",
  });

  const handleAddReviewClick = () => {
    setShowReviewForm(true);
  };

  const handleRatingChange = (rating) => {
    setReviewData({
      ...reviewData,
      rating: rating,
    });

    if (errors.rating) {
      clearErrors();
    }
  };

  const handleCommentChange = (e) => {
    setReviewData({
      ...reviewData,
      comment: e.target.value,
    });

    if (errors.comment) {
      clearErrors();
    }
  };

  const validate = () => {
    let isValid = true;
    const newErrors = { ...errors };

    // Rating validation
    if (reviewData.rating === 0) {
      newErrors.rating = "Please select a rating.";
      isValid = false;
    } else {
      newErrors.rating = "";
    }

    // Comment validation
    if (reviewData.comment.trim() === "") {
      newErrors.comment = "Please enter a comment.";
      isValid = false;
    } else {
      newErrors.comment = "";
    }

    setErrors(newErrors);
    return isValid;
  };

  const getuserdetails = async () => {
    const token = localStorage.getItem("usertoken");
    const username = localStorage.getItem("user");

    if (!token) {
      // Handle the case where the token is not present (e.g., redirect to login page)
      toast.error("Authentication credentials not provided. Please log in.");
      return;
    }

    // Check if the authentication token has expired (example for JWT, may vary based on implementation)
    const isTokenExpired = () => {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000; // Convert to seconds
        console.log("decodedToken", decodedToken.exp);
        console.log("currentTime", currentTime);
        console.log("isTokenExpired", decodedToken.exp < currentTime);
        return decodedToken.exp < currentTime;
      } catch (error) {
        return true; // Error decoding token or invalid token, treat as expired
      }
    };

    if (isTokenExpired()) {
      // Handle token expiration (e.g., refresh the token or redirect to login page)
      toast.error("Authentication token has expired. Please log in again.");
      navigate("/login");
      return;
    }

    const url =
      process.env.REACT_APP_BASE_URL + `auth/getuserdetails/${username}/`;
    const response = await axios.get(`${url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    console.log("response", response);
    if (response.status === 200) {
    }
  };

  useEffect(() => {
    getuserdetails();
  }, []);

  const handleReviewSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      // Here you can handle submitting the review data to your backend
      console.log("Review Data:", reviewData);
      const url = process.env.REACT_APP_BASE_URL + `users/create-review/`;
      const token = localStorage.getItem("usertoken");
      
      console.log(formData, "formData");
      const response = await axios.post(`${url}`, reviewData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: formData,
      });

      console.log("Form submitted successfully:", formData);
    
      
      // Close the popup form
      setShowReviewForm(false);

      toast.success("Review submitted successfully!");
    }
  };

  const clearErrors = () => {
    setErrors({
      rating: "",
      comment: "",
    });
  };

  return (
    <>
      <tr className="w-full border-b-2 border-gray-300 text-gray-700 relative hover:bg-gray-100">
        <td>
          <div className="flex items-center gap-2 lg:gap-3 pr-6">
          <img
  src={formData.productImage || 'https://path-to-your-default-image.com/default.png'}
  alt=""
  className="w-[90px] h-[50px] sm:w-[110px] sm:h-[70px] object-fill rounded-[4px]"
/>

            <div className="flex flex-col gap-2">
              <p className="font-medium">{formData.productName}</p>
             
            </div>

          </div>
        </td>
        <td className="text-center">{formData.companyName}</td>
        <td className="text-center">Rs. {formData.productPrice}</td>



        <td>
          <div className="flex justify-center items-center gap-2">
            {/* <button
              className="px-1.5 border-2 border-gray-300 font-medium rounded-md h-[35px]"
              onClick={() => setViewOrderDetail(true)}
            >
              <AiOutlineEye className="w-[22px] h-[22px] text-gray-700" />
            </button> */}

            <button
              className="px-1.5 border-2 border-gray-300 font-medium rounded-md h-[35px]"
              onClick={handleAddReviewClick}
            >
              <MdOutlineRateReview className="text-xl" />
            </button>
          </div>
        </td>
      </tr>

      {/* Popup form */}
      {showReviewForm && (
        <div className="fixed p-6 top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-full sm:max-w-[450px]">
            <h2 className="text-lg text-blue font-medium mb-4 text-center">
              Add Rating & Review
            </h2>
            <form onSubmit={handleReviewSubmit} className="flex flex-col gap-2">
              <div className="mb-4 flex flex-col gap-2">
                <label
                  htmlFor="rating"
                  className="block text-sm font-medium text-gray-700"
                >
                  Rating:
                </label>
                <div className="flex items-center gap-1.5">
                  {[...Array(5)].map((_, index) => (
                    <button
                      key={index}
                      type="button"
                      className={`text-2xl ${
                        reviewData.rating >= index + 1
                          ? "text-yellow"
                          : "text-gray-300"
                      }`}
                      onClick={() => handleRatingChange(index + 1)}
                    >
                      <FaStar className="w-6 h-6" />
                    </button>
                  ))}
                </div>
                {errors.rating && (
                  <p className="text-red-900 text-sm mt-1">{errors.rating}</p>
                )}
              </div>

              <div className="mb-4 flex flex-col gap-2">
                <label
                  htmlFor="comment"
                  className="block text-sm font-medium text-gray-700"
                >
                  Comment:
                </label>
                <textarea
                  id="comment"
                  name="comment"
                  rows={3}
                  className={`p-2 block w-full border border-gray-400 outline-none  rounded-md shadow-sm transition-all duration-300 focus:ring-blue focus:border-blue sm:text-sm resize-none`}
                  value={reviewData.comment}
                  onChange={handleCommentChange}
                />
                {errors.comment && (
                  <p className="text-red-900 text-sm mt-1">{errors.comment}</p>
                )}
              </div>

              <div className="flex justify-end">
                <button
                  type="button"
                  className="mr-2 px-4 py-2 text-sm font-medium text-gray-700 bg-gray-200 border border-gray-300 rounded-md shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  onClick={() => setShowReviewForm(false)}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 text-sm font-medium text-white bg-blue border border-transparent rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default ToReviewTableRow;
