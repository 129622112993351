import React from "react";
import Footer from "../../components/User/common/Footer";
import Header from "../../components/User/common/Header";
import ScrollToTopButton from "../../components/User/common/ScrollToTopButton";
import PartDetailsTop from "../../components/User/PartDetail/PartDetailsTop";
import PartDescription from "../../components/User/PartDetail/PartDescription";

const PartDetails = () => {
  return (
    <div>
      <Header />

      <div className="relative">
        <div className="absolute top-0 left-0 bg-blue w-full min-h-[300px] hidden md:block"></div>

        <div className="relative z-10 px-6 md:px-10 lg:px-20 flex flex-col gap-6 md:gap-10">
          <PartDetailsTop />

          <PartDescription />


        </div>
      </div>

      <Footer showNewsletter={false} />

      <ScrollToTopButton />
    </div>
  );
};

export default PartDetails;
