import React from "react";

const FinalBill = () => {
  return (
    <div className="mx-auto m-6 flex flex-col gap-4">
      <div className="relative bg-white mx-6 rounded-lg shadow-lg py-10">
        <div className="px-8 flex flex-col gap-6 sm:flex-row sm:justify-between">
          <div className="flex flex-col gap-3">
            <img src="" alt="" />

            <div className="flex flex-col gap-1.5 text-gray-500 font-medium">
              <p>Office 149, 450 South Brand Brooklyn</p>
              <p>San Diego County, CA 91905, USA</p>
              <p>+1 (123) 456 7891, +44 (876) 543 2198</p>
            </div>
          </div>

          <div className="flex flex-col gap-3 sm:text-end">
            <h1 className="text-xl font-bold text-gray-500">Invoice #3492</h1>

            <div className="flex flex-col gap-1.5 text-gray-500 font-medium">
              <p>Date Issues: 25/08/2020</p>
              <p>Date Due: 29/08/2020</p>
            </div>
          </div>
        </div>

        <div className="bg-gray-400 h-[1px] w-full my-8"></div>

        <div className="px-8 flex flex-col gap-6 text-gray-500 sm:flex-row sm:justify-between">
          <div className="flex flex-col gap-3">
            <h2 className="text-lg font-semibold">Invoice To:</h2>

            <div className="flex flex-col gap-0.5 font-medium">
              <p>Nischal Bista</p>
              <p>Bista Company Limited</p>
              <p>Small Heath, B10 0HF, UK</p>
              <p>718-986-6062</p>
            </div>
          </div>

          <div className="flex flex-col gap-3">
            <h2 className="text-lg font-semibold">Invoice To:</h2>

            <div className="flex flex-col gap-0.5 font-medium">
              <p>Nischal Bista</p>
              <p>Bista Company Limited</p>
              <p>Small Heath, B10 0HF, UK</p>
              <p>718-986-6062</p>
            </div>
          </div>
        </div>

        <div class="relative overflow-x-auto mt-6">
          <table class="w-full text-sm text-left rtl:text-right text-gray-500">
            <thead class="text-xs text-gray-700 uppercase bg-gray-50 border-y border-gray-400">
              <tr>
                <th scope="col" class="px-6 py-2.5">
                  Product name
                </th>
                <th scope="col" class="px-6 py-2.5">
                  Color
                </th>
                <th scope="col" class="px-6 py-2.5">
                  Category
                </th>
                <th scope="col" class="px-6 py-2.5">
                  Price
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="bg-white border-b border-gray-400">
                <th
                  scope="row"
                  class="px-6 py-2.5 font-medium whitespace-nowrap"
                >
                  Apple MacBook Pro 17"
                </th>
                <td class="px-6 py-2.5">Silver</td>
                <td class="px-6 py-2.5">Laptop</td>
                <td class="px-6 py-2.5">$2999</td>
              </tr>

              <tr class="bg-white border-b border-gray-400 ">
                <th
                  scope="row"
                  class="px-6 py-2.5 font-medium whitespace-nowrap"
                >
                  Microsoft Surface Pro
                </th>
                <td class="px-6 py-2.5">White</td>
                <td class="px-6 py-2.5">Laptop PC</td>
                <td class="px-6 py-2.5">$1999</td>
              </tr>

              <tr class="bg-white">
                <th scope="row" class="px-6 py-2.5"></th>
                <td class="px-6 py-2.5"></td>
                <td class="px-6 py-2.5"></td>
                <td class="px-6 py-2.5"></td>
              </tr>

              <tr class="bg-white">
                <th scope="row" class="px-6 py-2.5"></th>
                <td class="px-6 py-2.5"></td>
                <td class="px-6 py-2.5 text-end">Subtotal:</td>
                <td class="px-6 py-2.5">$156.25</td>
              </tr>

              <tr class="bg-white">
                <th scope="row" class="px-6 py-2.5"></th>
                <td class="px-6 py-2.5"></td>
                <td class="px-6 py-2.5 text-end">Discount:</td>
                <td class="px-6 py-2.5">$156.25</td>
              </tr>

              <tr class="bg-white">
                <th scope="row" class="px-6 py-2.5"></th>
                <td class="px-6 py-2.5"></td>
                <td class="px-6 py-2.5 text-end">Tax:</td>
                <td class="px-6 py-2.5">$156.25</td>
              </tr>

              <tr class="bg-white">
                <th scope="row" class="px-6 py-2.5"></th>
                <td class="px-6 py-2.5"></td>
                <td class="px-6 py-2.5 text-end">Total:</td>
                <td class="px-6 py-2.5">$156.25</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div></div>
      </div>
    </div>
  );
};

export default FinalBill;
