import React from "react";

const TopSellingTable = () => {
  return (
    // <!-- component -->
    <div className="w-full flex items-center whitespace-nowrap">
      <div className="flex items-center justify-center">
        <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
          <div>
            <h2 className="text-gray-600 font-medium">Top Selling</h2>
          </div>
          <div className="w-[calc(100vw_-_60px)] lg:w-[calc(100vw_-_340px)] overflow-x-auto relative shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left text-black">
              <thead className="text-xs text-center text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="py-3 px-6">
                    No.
                  </th>
                  <th scope="col" className="py-3 px-6">
                    Product Name
                  </th>
                  <th scope="col" className="py-3 px-6">
                    Sold Quantity
                  </th>
                  <th scope="col" className="py-3 px-6">
                    Remaining Quantity
                  </th>
                  <th scope="col" className="py-3 px-6">
                    Price
                  </th>
                </tr>
              </thead>
              <tbody className="text-center">
                <tr className="bg-white border-b">
                  <td className="py-4 px-6">1</td>
                  <td className="py-4 px-6">82926417</td>
                  <td className="py-4 px-6">John Doe</td>
                  <td className="py-4 px-6">Ktm</td>
                  <td className="py-4 px-6">2023/12/26</td>
                 
                </tr>
                <tr className="bg-white border-b">
                  <td className="py-4 px-6">1</td>
                  <td className="py-4 px-6">55387621</td>

                  <td className="py-4 px-6">John Doe</td>
                  <td className="py-4 px-6">Ktm</td>
                  <td className="py-4 px-6">2023/12/26</td>
                 
                </tr>
                <tr className="bg-white border-b ">
                  <td className="py-4 px-6">2</td>
                  <td className="py-4 px-6">90817264</td>
                  <td className="py-4 px-6">John Doe</td>
                  <td className="py-4 px-6">Ktm</td>
                  <td className="py-4 px-6">2023/12/26</td>
                  
                </tr>
                <tr className="bg-white border-b ">
                  <td className="py-4 px-6">3</td>
                  <td className="py-4 px-6">26483910</td>
                  <td className="py-4 px-6">John Doe</td>
                  <td className="py-4 px-6">Ktm</td>
                  <td className="py-4 px-6">2023/12/26</td>
                  
                </tr>
                <tr className="bg-white ">
                  <td className="py-4 px-6">4</td>
                  <td className="py-4 px-6">64738290</td>
                  <td className="py-4 px-6">John Doe</td>
                  <td className="py-4 px-6">Ktm</td>
                  <td className="py-4 px-6">2023/12/26</td>
                 
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopSellingTable;
