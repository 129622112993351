import React, { useState } from "react";
import History from "./History/History";
import ToReview from "./ToReview/ToReview";

const dropdownOptions = [
  "17 Jan 2022 - 17 Feb 2022",
  "17 Feb 2022 - 17 Mar 2022",
  "17 Mar 2022 - 17 Apr 2022",
  "17 Apr 2022 - 17 May 2022",
  "17 May 2022 - 17 Jun 2022",
  "17 Jun 2022 - 17 Jul 2022",
];

const MyReviews = () => {
  const [reviewStatus, setReviewStatus] = useState("To Review");
  const [dropdownValue, setDropdownValue] = useState(dropdownOptions[0] || "");
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  return (
    <div className="bg-white pb-10">
      <div className="px-8 py-2 border-b-2 border-gray-200">
        <h1 className="font-bold text-lg text-gray-900">My Reviews</h1>
      </div>

      <div className="px-8 py-5 pb-5">
        <div className="flex flex-col gap-4 lg:flex-row items-stretch justify-between lg:items-center">
          <div className="w-full flex">
            <div className="w-full flex items-center whitespace-nowrap overflow-x-auto lg:overflow-x-hidden gap-6 font-medium text-[14px] text-gray-500">
              <button
                className={`px-1.5 pb-0.5 ${
                  reviewStatus === "To Review"
                    ? "text-blue-900 font-bold border-b-2 border-blue-900"
                    : ""
                }`}
                onClick={() => setReviewStatus("To Review")}
              >
                To Review
              </button>

              <button
                className={`px-1.5 pb-0.5 ${
                  reviewStatus === "History"
                    ? "text-blue-900 font-bold border-b-2 border-blue-900"
                    : ""
                }`}
                onClick={() => setReviewStatus("History")}
              >
                History
              </button>
            </div>
          </div>

          {/* Dropdown */}
          {/* <div className="relative group">
            <button
              onClick={() => setDropdownOpen(!isDropdownOpen)}
              className="w-full lg:w-fit min-w-[250px] border bg-gray-100 border-gray-300 px-4 py-2 rounded-md text-sm font-medium flex items-center justify-between gap-2 cursor-pointer text-gray-500"
            >
              <span>{dropdownValue}</span>

              <svg
                className={`transition-transform transform ${
                  isDropdownOpen ? "rotate-180" : ""
                } text-gray-500`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                width="16"
                height="16"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </button>

            {isDropdownOpen && (
              <div className="w-full z-20 absolute mt-2 bg-white border border-gray-200 rounded-md shadow-lg max-h-44 overflow-y-auto">
                {dropdownOptions.map((option) => (
                  <div
                    key={option}
                    onClick={() => {
                      setDropdownValue(option);
                      setDropdownOpen(false);
                    }}
                    className="cursor-pointer text-sm font-medium text-gray-500 px-4 py-2 hover:bg-gray-100"
                  >
                    {option}
                  </div>
                ))}
              </div>
            )}
          </div> */}
        </div>
      </div>

      <div className="px-8">
        {reviewStatus === "To Review" ? (
          <ToReview />
        ) : reviewStatus === "History" ? (
          <History />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default MyReviews;
