import React, { useState } from "react";
import { BiSearch } from "react-icons/bi";
import ChooseModelWithDropdown from "../../components/User/ChooseModel/ChooseModelWithDropdown";
import Footer from "../../components/User/common/Footer";
import Header from "../../components/User/common/Header";

const ChooseModel = () => {
  const modelDataList = [
    {
      imageUrl: "https://imgd.aeplcdn.com/664x374/n/cw/ec/26859/grand-i10-exterior-right-front-three-quarter.jpeg?q=80",
      name: "Hyundai Accent",
      yearStart: "0.1999",
      yearEnd: "12.2012",
      dropdownItems: ["Option1", "Option2", "Option3"],
    },
    {
      imageUrl: "https://imgd.aeplcdn.com/664x374/n/cw/ec/26859/grand-i10-exterior-right-front-three-quarter.jpeg?q=80",
      name: "Hyundai Sonata",
      yearStart: "0.2001",
      yearEnd: "12.2015",
      dropdownItems: ["ChoiceA", "ChoiceB", "ChoiceC"],
    },
    {
      imageUrl: "https://imgd.aeplcdn.com/664x374/n/cw/ec/26859/grand-i10-exterior-right-front-three-quarter.jpeg?q=80",
      name: "Hyundai Sonata",
      yearStart: "0.2001",
      yearEnd: "12.2015",
      dropdownItems: ["ChoiceA", "ChoiceB", "ChoiceC"],
    },
  ];

  const [searchInput, setSearchInput] = useState("");

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const filteredModelDataList = modelDataList.filter((model) =>
    model.name.toLowerCase().includes(searchInput.toLowerCase())
  );

  return (
    <div className="bg-white">
      <Header showTopPart={false} showCategory={false} />
      <div className="px-6 md:px-10 lg:px-20 flex flex-col gap-2 pb-8">
        <div className="pt-3">
          <p className="text-gray-800 text-[15px] font-medium">
            Home - <span className="font-bold">Hyundai</span>
          </p>
        </div>
        <div className="flex flex-col gap-4">
          <div className="relative w-full md:h-[200px] bg-[url(https://imgd.aeplcdn.com/664x374/n/cw/ec/26859/grand-i10-exterior-right-front-three-quarter.jpeg?q=80)] bg-cover bg-center bg-no-repeat px-4 md:px-16 py-4 flex flex-col vsm:flex-row items-center gap-4 vsm:gap-6">
            <div className="absolute w-full h-full top-0 left-0 bg-[#004ec46c]"></div>
            <div className="w-fit z-10">
              <div className="bg-white w-[110px] h-[100px] rounded-md flex items-center justify-center">
                <img
                  src="https://di-uploads-pod5.dealerinspire.com/triplejsaipan/uploads/2018/05/HyundaiLogoStacked_4cblk-1024x659.gif"
                  alt=""
                  className="object-fill rounded-md w-[60px] h-[60px] sm:w-[80px] sm:h-[80px] md:w-[110px] md:h-[100px]"
                />
              </div>
            </div>
            <div className="z-10 flex flex-col gap-2 text-white">
              <h1 className="font-bold text-xl text-center vsm:text-start md:text-2xl">Hyundai</h1>
              <p className="text-[15px]">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Labore, enim velit dolorum tenetur dolorem voluptatibus deserunt
                maxime consectetur impedit, aut nihil perspiciatis nulla sed.
                Velit quo corporis atque numquam, ab, enim consectetur possimus
                blanditiis doloribus natus incidunt.
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-5">
            <div className="flex items-center gap-4 justify-between">
              <h1 className="text-lg md:text-xl font-bold text-gray-800">
                Choose Your Model
              </h1>
              <button className="px-2  whitespace-nowrap sm:px-4 py-1.5 border border-gray-300 text-gray-500 text-sm sm:text-[15px] font-medium">
                View OEM Catalouge
              </button>
            </div>
            <div className="w-full bg-gray-200 px-5 py-2.5 rounded-[4px] flex items-center gap-4">
              <input
                type="text"
                className="outline-none w-full bg-[transparent] font-medium text-[15px] placeholder:font-normal"
                placeholder="Search for cars at Hyundai"
                value={searchInput}
                onChange={handleSearchInputChange}
              />
              <button>
                <BiSearch className="text-gray-500 text-[20px]" />
              </button>
            </div>
            <div className="flex flex-col gap-5">
              <div className="flex items-center gap-1 vsm:gap-2 sm:gap-4">
                <button className="font-bold px-2 text-blue text-[13px] vsm:text-[14px] sm:text-[15px] border-b-2 underline-offset-8 border-blue">
                  Cars
                </button>
                <div className="bg-gray-300 w-[2px] h-[15px]"></div>
                <button className="font-medium text-gray-500 text-[13px] vsm:text-[14px] sm:text-[15px]">
                  Parts & Accessories
                </button>
                <div className="bg-gray-300 w-[2px] h-[15px]"></div>
                <button className="font-medium text-gray-500 text-[13px] vsm:text-[14px] sm:text-[15px]">
                  Low Price Spare Parts
                </button>
              </div>
              <div className="grid vsm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-6">
                {filteredModelDataList.map((modelData, index) => (
                  <div key={index} className="border border-gray-200">
                    <ChooseModelWithDropdown modelData={modelData} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ChooseModel;
