import axios from "axios";
import React, { useState } from "react";
import { BiTrash } from "react-icons/bi";
import { FaUndo } from "react-icons/fa";
import { HiMinus, HiPlus } from "react-icons/hi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UnListedProductsTableRow = ({
  formData,
  fetchData,
  isSelected,
  onCheckboxChange,
}) => {
  const [isMobileTableRowOpen, setIsMobileTableRowOpen] = useState(false);


  const onAcceptButtonClick = async (e) => {
    e.preventDefault();
    formData["part_id"] = formData.id;
const url = process.env.REACT_APP_BASE_URL + "sellerdashboard/acceptreviewproduct/";
    try {
      const response = await axios.post(`${url}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        body: JSON.stringify(formData),
      });
      toast.success("Part Approved Successfully");
      fetchData();
    } catch (error) {
      toast.error(error.response.data);
    }
  };
  return (
    <>
      <div className="border-b-2 border-gray-400 px-5 py-3 sm:hidden">
        <ToastContainer />
        <div className="flex items-center gap-4">
          <div
            className={`w-6 h-6 flex justify-center items-center ${
              isMobileTableRowOpen
                ? "bg-blue text-white rounded-[4px]"
                : "border-2 border-gray-400 text-gray-400 rounded-sm"
            }`}
          >
            <button
              onClick={(event) => {
                event.preventDefault();
                setIsMobileTableRowOpen(!isMobileTableRowOpen);
              }}
            >
              {isMobileTableRowOpen ? (
                <HiMinus className="w-5 h-5" />
              ) : (
                <HiPlus className="w-5 h-5" />
              )}
            </button>
          </div>

          <div>
            <h1 className="text-gray-900 font-bold text-[15px] uppercase">
              Vehicle Company
            </h1>
            <p className="text-gray-800 font-medium text-[15px]">
              {formData.vehicleCompany}
            </p>
          </div>
        </div>

        {isMobileTableRowOpen && (
          <div className="flex flex-col gap-2 mt-4 ml-[2.4rem]">
            <div className="flex flex-row gap-2 items-center">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                Sub-Category:
              </h2>
              <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                {formData.subCategory}
              </p>
            </div>

            <div className="flex flex-row gap-2 items-center">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                Availability:
              </h2>
              <p
                className={`text-[15px] font-medium ${
                  formData.availability === "In Stock"
                    ? "text-green"
                    : "text-red"
                }`}
              >
                {" "}
                ● {formData.availability}
              </p>
            </div>

            <div className="flex flex-row gap-2 items-center">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                Part Number:
              </h2>
              <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                {formData.partNumber}
              </p>
            </div>

            <div className="flex flex-row gap-2 items-center">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                Year of Manufacturing:
              </h2>
              <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                {formData.manufactureYear}
              </p>
            </div>

            <div className="flex flex-row gap-2 items-center">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                Parts Categories:
              </h2>
              <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                {formData.partsCategories}
              </p>
            </div>

            <div className="flex flex-row gap-2 items-center">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                Sub-Parts Categories:
              </h2>
              <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                {formData.partSubCategories}
              </p>
            </div>

            <div className="flex flex-row gap-2 items-center">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                Parts Names:
              </h2>
              <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                {formData.partName}
              </p>
            </div>

            {/* <div className="flex flex-row gap-2 items-center">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                Stock Quantity:
              </h2>
              <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                {formData.stockQuantity}
              </p>
            </div>

            <div className="flex flex-row gap-2 items-center">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                Selling Price:
              </h2>
              <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                {formData.sellingPrice}
              </p>
            </div>

            <div className="flex flex-row gap-2 items-center">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                Units:
              </h2>
              <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                {formData.units}
              </p>
            </div> */}

     
          </div>
        )}
      </div>

      <tr className="border-b-2 border-gray-300 text-gray-700 relative hidden sm:table-row hover:bg-gray-100">


        <td>
          <div className="max-w-[180px] 2xl:max-w-[250px]  gap-2 lg:gap-3 pr-6">
            <span className="whitespace-break-spaces leading-5">
              {formData.id}
            </span>
          </div>
        </td>

        <td className="flex items-center gap-4">
          <button
            className="bg-[#e9fcf3] border border-[#06C270] text-[#047857] font-bold rounded-md px-5 h-[35px]"
            onClick={onAcceptButtonClick}
          >
            Accept
          </button>

        </td>

        

      </tr>
    </>
  );
};

export default UnListedProductsTableRow;
