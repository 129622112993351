import axios from "axios";
import React, { useState } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { BiEdit, BiTrash } from "react-icons/bi";
import { HiMinus, HiPlus } from "react-icons/hi";
import { MdOutlineLocalPrintshop } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const AcceptedTableRow = ({
  formData,
  onAcceptButtonClick,
  onDeclineButtonClick,
  onEditClick,
}) => {
  const [isMobileTableRowOpen, setIsMobileTableRowOpen] = useState(false);

  const navigate = useNavigate();

  const onDeleteClick = async (e) => {
    e.preventDefault();
    console.log(formData);
    formData["productinfo_id"] = formData.productId;
    formData["status"] = "Deleted";
    try {
      const url = process.env.REACT_APP_BASE_URL + "/sellerdashboard/updateproduct/";
      const response = await axios.post(
        `${url}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          body: JSON.stringify(formData),
        }
      );
      console.log("Data sent successfully:", response.data);

      // navigate("/product");
      // Handle success or navigate to a success page
    } catch (error) {
      console.error("Error sending data:", error);
      // Handle error
    }
  };
  return (
    <>
      <div className="border-b-2 border-gray-400 px-5 py-3 sm:hidden">
        <div className="flex items-center gap-4">
          <div
            className={`w-6 h-6 flex justify-center items-center ${
              isMobileTableRowOpen
                ? "bg-blue text-white rounded-[4px]"
                : "border-2 border-gray-400 text-gray-400 rounded-sm"
            }`}
          >
            <button
              onClick={(event) => {
                event.preventDefault();
                setIsMobileTableRowOpen(!isMobileTableRowOpen);
              }}
            >
              {isMobileTableRowOpen ? (
                <HiMinus className="w-5 h-5" />
              ) : (
                <HiPlus className="w-5 h-5" />
              )}
            </button>
          </div>

          <div>
            <h1 className="text-gray-900 font-bold text-[15px] uppercase">
              Order Number
            </h1>
            <p className="text-gray-800 font-medium text-[15px]">
              {formData.orderNo}
            </p>
          </div>
        </div>

        {isMobileTableRowOpen && (
          <div className="flex flex-col gap-2 mt-4 ml-[2.4rem]">
            <div className="flex flex-row gap-2 items-center">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                Order No:
              </h2>
              <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                {formData.orderNo}
              </p>
            </div>

            <div className="flex flex-row gap-2 items-center">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                Order Date:
              </h2>
              <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                {formData.orderDate}
              </p>
            </div>

            <div className="flex flex-row gap-2 items-center">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                Status:
              </h2>
              <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                {formData.status}
              </p>
            </div>

            <div className="flex items-center gap-2 my-4">
              <button className="px-1.5 border-2 border-gray-300 font-medium rounded-md h-[35px]">
                <MdOutlineLocalPrintshop className="w-6 h-6 text-gray-400" />
              </button>

              <button className="px-1.5 border-2 border-gray-300 font-medium rounded-md h-[35px]">
                <BiEdit className="w-5 h-5 text-blue" />
              </button>

              <button className="px-1.5 border-2 border-gray-300 font-medium rounded-md h-[35px]">
                <BiTrash className="w-5 h-5 text-red-900" />
              </button>

              <button className="px-1.5 border-2 border-gray-300 font-medium rounded-md h-[35px]">
                <AiOutlineEye className="w-[22px] h-[22px] text-gray-700" />
              </button>
            </div>
          </div>
        )}
      </div>

      <tr className="border-b-2 border-gray-300 text-gray-700 relative hidden sm:table-row hover:bg-gray-100">
      <td className="text-center">{formData.partNo}</td>

      <td className="text-center">{formData.partName}</td>

      <td className="text-center">{formData.subCategory}</td>

      <td className="text-center">{formData.orderDate}</td>

      <td className="text-center">{formData.quantity}</td>

      <td className="text-center">{formData.totalprice}</td>

        <td>
          <div className="flex items-center justify-center gap-2">
            <button
              className="px-1.5 border-2 border-gray-300 font-medium rounded-md h-[35px]"
              onClick={onDeleteClick}
            >
              <BiTrash className="w-5 h-5 text-red-900" />
            </button>
          </div>
        </td>
      </tr>
    </>
  );
};

export default AcceptedTableRow;
