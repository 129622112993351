import axios from "axios";
import { atom, useAtom } from "jotai";
import React, { useEffect, useState, useCallback } from "react";
import { FiSearch } from "react-icons/fi";
import { IoClose } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import CategoryCard from "../common/CategoryCard";
import { isSearchButtonClickedAtom } from "../common/Header";

export const selectedDetailsAtom = atom({
  search: null,
  category: null,
  brand: null,
  year: null,
  model: null,
});

const SearchModal = ({ searchInput, setSearchInput, setIsSearchModalOpen, fetchData }) => {
  const [apiData, setApiData] = useState(null);
  const [loading, setLoading] = useState(false);
  let [selectedCompany, setSelectedCompany] = useState(null);
  let [selectedCategory, setSelectedCategory] = useState(null);
  let [selectedBrand, setSelectedBrand] = useState(null);
  let [selectedModel, setSelectedModel] = useState(null);
  let [selectedYear, setSelectedYear] = useState(null);

  const [selectedDetails, setSelectedDetails] = useAtom(selectedDetailsAtom);
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const imageurl = process.env.REACT_APP_BASE;

  console.log("apiData", apiData);

  const [isSearchButtonClicked, setIsSearchButtonClicked] = useAtom(
    isSearchButtonClickedAtom
  );

  const handleKeyPress = (e) => {
    // Search when enter key is pressed
    if (e.key === "Enter") {
      console.log("Enter key pressed");
      handleSearchClick();

      // Prevent the default action to stop scrolling when space is pressed
      e.preventDefault();
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${apiUrl}users/search/${searchInput}`);
        setApiData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    if (isSearchButtonClicked) {
      fetchData();
      setIsSearchButtonClicked(false);
    }
  }, [apiUrl,searchInput,setIsSearchButtonClicked,isSearchButtonClicked]);


  const closeModal = () => {
    setIsSearchModalOpen(false);
    setSelectedCompany(null);
    setSelectedCategory(null);
    setSelectedBrand(null);
    setSelectedModel(null);
    setSelectedYear(null);
    setSearchInput("");
  };

  const handleSearchClick = () => {
    setSelectedCompany(null);
    setSelectedCategory(null);
    setSelectedBrand(null);
    setSelectedModel(null);
    setSelectedYear(null);
    fetchData();
  };

  const handleCompanyClick = (company) => {
    setSelectedCompany(company);
    setSelectedCategory(null);
    setSelectedBrand(null);
    setSelectedModel(null);
    setSelectedYear(null);
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    // setSelectedBrand(null);
    setSelectedModel(null);
    // setSelectedYear(null);
  };

  const handleBrandClick = (brand) => {
    setSelectedBrand(brand);
    setSelectedModel(null);
    // setSelectedYear(null);
  };

  const handleYearClick = (year) => {
    setSelectedYear(year);
    setSelectedModel(null);
  };

  const handleModelClick = (model) => {
    
    setSelectedModel(model);

    setTimeout(() => {
    setSelectedDetails({
      search: searchInput,
      category: selectedCategory,
      brand: selectedBrand,
      year: selectedYear,
      model: model,
    });
  }, 300);

    const filtered_result = apiData.filtered_result.filter(
      (item) =>
        item.subCategory === selectedCategory &&
        item.brand === selectedBrand &&
        item.manufactureYear === selectedYear &&
        item.selectedModel === model
    );

    console.log("filtered_result", filtered_result)
    console.log("selectedCompany", selectedCompany)
    console.log("selectedCategory", selectedCategory)
    console.log("selectedModel", model)
    console.log("selectedYear", selectedYear)

 
    setTimeout(() => {
    navigate(
      "/filter/" +
        selectedCompany +
        "-" +
        selectedCategory +
        "-" +
        model +
        "-" +
        selectedYear +
        "-" +
        selectedBrand,
      {
        state: { filtered_result },
      }
    );
  }, 1000);
  };




  useEffect(() => {
    if (apiData) {
      setSelectedCompany(apiData.filter_criteria.vehicleCompany__icontains || "");
      setSelectedBrand(apiData.filter_criteria.brand__icontains || "");
      setSelectedYear(apiData.filter_criteria.manufactureYear__icontains || "");
    }
  }, [apiData]);

  if (!apiData) {
    return (
      <div className="fixed top-0 left-0 w-full h-screen z-[60] overflow-hidden flex bg-black bg-opacity-50 md:items-center md:justify-center md:p-6">
        <div className="pt-10 w-full md:pt-0">
          <div className="relative bg-white h-full rounded-xl p-6 mx-auto flex flex-col gap-4 max-w-[970px] md:h-[90vh]">
            <div className="flex items-center justify-between gap-4">
              <h1 className="text-2xl text-blue-800 font-bold">Search</h1>
              <button className="text-2xl" onClick={closeModal}>
                <IoClose />
              </button>
            </div>
            <div className="border border-gray-300 bg-[#f9f9fb] py-0.5 pl-3 pr-0.5">
              <div className="flex items-center">
                <input
                  type="text"
                  placeholder="Search in osis"
                  className="outline-none bg-[#f9f9fb] w-[350px] text-[15px] flex-1"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  onKeyPress={handleKeyPress}
                />
                <button
                  className="bg-blue rounded-sm flex items-center justify-center text-white px-2 py-1.5"
                  onClick={handleSearchClick}
                >
                  <FiSearch />
                </button>
              </div>
            </div>

            {loading ? (
              <div className="flex items-center justify-center pt-10">
                <div className="flex flex-row gap-2">
                  <div className="w-4 h-4 rounded-full bg-blue-700 animate-bounce"></div>
                  <div className="w-4 h-4 rounded-full bg-blue-700 animate-bounce [animation-delay:-.3s]"></div>
                  <div className="w-4 h-4 rounded-full bg-blue-700 animate-bounce [animation-delay:-.5s]"></div>
                </div>{" "}
              </div>
            ) : (
              <h1 className="text-lg text-center text-gray-500 font-medium">
                No data found
              </h1>
            )}
          </div>
        </div>
      </div>
    );
  }

  const { filtered_result, filter_criteria } = apiData;

  const categories = {};

  if (filtered_result.length === 1) {
    const singleResult = filtered_result[0];
  
    let Company = singleResult.vehicleCompany;
    let Category = singleResult.subCategory;
    let Brand = singleResult.brand;
    let Year = singleResult.manufactureYear;
    let model = null;
  


    let filtered_resulting;
  
    // Delay navigation by 5 seconds after setting selected details
    setTimeout(() => {

      setSelectedDetails({
        search: searchInput,
        category: Category,
        brand: Brand,
        year: Year,
        model: singleResult.model,
      });


      filtered_resulting = apiData.filtered_result.filter(
        (item) =>
          item.subCategory === selectedCategory &&
          item.brand === selectedBrand &&
          item.manufactureYear === selectedYear &&
          item.selectedModel === model
      );

    }, 100);

    setTimeout(() => {
      navigate(
        "/filter/" +
          Company +
          "-" +
          Category +
          "-" +
          model +
          "-" +
          Year +
          "-" +
          Brand,
        {
          state: { filtered_resulting },
        }
      );
    }, 100);

  }
  




  const filtered_result_with_selected_company = filtered_result.filter(
    (item) => item.vehicleCompany === selectedCompany
  );

  const uniqueVehicleCompanies = filtered_result
    .map((item) => item.vehicleCompany)
    .filter((value, index, self) => self.indexOf(value) === index);

  if (selectedCompany) {
    filtered_result_with_selected_company.forEach((item) => {
      const category = item.subCategory || "Uncategorized";

      if (!categories[category]) {
        categories[category] = {
          productCount: 1,
          image: item.image
            ? imageurl + "/media/" + item.image
            : "https://www.svgrepo.com/show/508699/landscape-placeholder.svg",
        };
      } else {
        categories[category].productCount += 1;
      }
    });
  } else {
    filtered_result.forEach((item) => {
      const category = item.subCategory || "Uncategorized";

      if (!categories[category]) {
        categories[category] = {
          productCount: 1,
          image: item.image
            ? imageurl + "/media/" + item.image
            : "https://www.svgrepo.com/show/508699/landscape-placeholder.svg",
        };
      } else {
        categories[category].productCount += 1;
      }
    });
  }

  const categoryCards = Object.entries(categories).map(([category, data]) => (
    <div key={category} onClick={() => handleCategoryClick(category)}>
      <CategoryCard
        title={category}
        icon={data.image}
        productCount={data.productCount}
      />
    </div>
  ));

  const uniqueBrands = filtered_result
    .filter(
      (item) => selectedCategory && item.subCategory === selectedCategory
    )
    .map((item) => item.brand)
    .filter((value, index, self) => self.indexOf(value) === index);

  const brands = uniqueBrands.map((brand) => (
    <div
      key={brand}
      className={`bg-gray-100 p-2 rounded-md flex ${
        selectedBrand && selectedBrand !== brand
          ? "pointer-events-none opacity-50"
          : ""
      }`}
      onClick={() => handleBrandClick(brand)}
    >
      {brand}
    </div>
  ));

  const uniqueManufactureYears = filtered_result
    .filter((item) => selectedBrand && item.brand === selectedBrand)
    .map((item) => item.manufactureYear)
    .filter((value, index, self) => self.indexOf(value) === index);

  const manufactureYears = uniqueManufactureYears.map((year) => (
    <div
      key={year}
      className={`bg-gray-100 p-2 rounded-md flex ${
        selectedYear && selectedYear !== year
          ? "pointer-events-none opacity-50"
          : ""
      }`}
      onClick={() => handleYearClick(year)}
    >
      {year}
    </div>
  ));

  const uniqueModels = filtered_result
    .filter((item) => selectedBrand && item.brand === selectedBrand)
    .map((item) => item.model)
    .filter((value, index, self) => self.indexOf(value) === index);

  const models = uniqueModels.map((model) => (
    <div
      key={model}
      className={`bg-gray-100 p-2 rounded-md flex ${
        selectedModel && selectedModel !== model
          ? "pointer-events-none opacity-50"
          : ""
      }`}
      onClick={() => handleModelClick(model)}
    >
      {model}
    </div>
  ));


  console.log("******************************************")
  console.log("selectedCompany", selectedCompany)
  console.log("selectedCategory", selectedCategory)
  console.log("selectedBrand", selectedBrand)
  console.log("selectedModel", selectedModel)
  console.log("selectedYear", selectedYear)
  console.log("******************************************")

  return (
    <div className="fixed top-0 left-0 w-full h-screen z-[60] overflow-hidden flex bg-black bg-opacity-50 md:items-center md:justify-center md:p-6">
      <div className="pt-10 w-full md:pt-0">
        <div className="relative bg-white h-full rounded-xl p-6 mx-auto flex flex-col gap-4 max-w-[970px] md:h-[90vh]">
          <div className="flex items-center justify-between gap-4">
            <h1 className="text-2xl text-blue-800 font-bold">Search</h1>
            <button className="text-2xl" onClick={closeModal}>
              <IoClose />
            </button>
          </div>
          <div className="border border-gray-300 bg-[#f9f9fb] py-0.5 pl-3 pr-0.5">
            <div className="flex items-center">
              <input
                type="text"
                placeholder="Search in osis"
                className="outline-none bg-[#f9f9fb] w-[350px] text-[15px] flex-1"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                onKeyPress={handleKeyPress}
              />
              <button
                className="bg-blue rounded-sm flex items-center justify-center text-white px-2 py-1.5"
                onClick={handleSearchClick}
              >
                <FiSearch />
              </button>
            </div>
          </div>
          <div className="flex flex-col gap-4 overflow-y-auto select-category-div">
            {!selectedCompany && !selectedBrand && !selectedYear ? (
              <div className="flex flex-col gap-4">
                <h2 className="text-[15px] font-medium">
                  No company found for the search. Please select a company from
                  the list below:
                </h2>
                <div className="grid vsm:grid-cols-2 gap-4 md:grid-cols-3">
                  {uniqueVehicleCompanies.map((company) => (
                    <div
                      key={company}
                      className={`bg-gray-100 p-2 rounded-md flex  cursor-pointer ${
                        selectedCompany && selectedCompany !== company
                          ? "pointer-events-none opacity-50"
                          : ""
                      }`}
                      onClick={() => handleCompanyClick(company)}
                    >
                      {company}
                    </div>
                  ))}
                </div>
              </div>
            ) : selectedCompany && !selectedCategory ? (
              <div className="flex flex-col gap-4">
                <h2 className="text-[15px] font-medium">
                  Select the category of part:
                </h2>
                {loading ? (
                  <div className="flex items-center justify-center">
                    <div className="flex flex-row gap-2">
                      <div className="w-4 h-4 rounded-full bg-blue-700 animate-bounce"></div>
                      <div className="w-4 h-4 rounded-full bg-blue-700 animate-bounce [animation-delay:-.3s]"></div>
                      <div className="w-4 h-4 rounded-full bg-blue-700 animate-bounce [animation-delay:-.5s]"></div>
                    </div>{" "}
                  </div>
                ) : (
                  <div className="grid vsm:grid-cols-2 gap-4 md:grid-cols-3">
                    {categoryCards}
                  </div>
                )}
              </div>
            ) : selectedCategory && !selectedBrand ? (
              <div className="flex flex-col gap-4">
                <h2 className="text-[15px] font-medium">
                  Brands for {selectedCategory}:
                </h2>
                {brands.length > 0 ? (
                  <div className="grid vsm:grid-cols-2 gap-4 md:grid-cols-3 cursor-pointer">
                    {brands}
                  </div>
                ) : (
                  <p>No brands found for the selected category.</p>
                )}
              </div>
            ) : selectedBrand && !selectedYear ? (
              // Render manufacture year selection
              <div className="flex flex-col gap-4">
                <h2 className="text-[15px] font-medium">
                  Manufacture Years for {selectedBrand}:
                </h2>
                {manufactureYears.length > 0 ? (
                  <div className="grid vsm:grid-cols-2 gap-4 md:grid-cols-3 cursor-pointer">
                    {manufactureYears}
                  </div>
                ) : (
                  <p>No manufacture years found for the selected brand.</p>
                )}
              </div>
            ) : selectedYear && !selectedModel ? (
              // Render model selection
              <div className="flex flex-col gap-4">
                <h2 className="text-[15px] font-medium">
                  Models for {selectedBrand}:
                </h2>
                {models.length > 0 ? (
                  <div className="grid vsm:grid-cols-2 gap-4 md:grid-cols-3 cursor-pointer">
                    {models}
                  </div>
                ) : (
                  <p>No models found for the selected brand.</p>
                )}
              </div>
            ) : (
              // Render whatever is needed next based on the selected details
              <div>
                Render appropriate section based on the selected details...
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchModal;
