import { atom, useAtom } from "jotai";
import React, { useState } from "react";
import { HiChevronDown, HiOutlineSearch } from "react-icons/hi";
import { localSelectionAtom } from "../../OrderReviewManagement/OrderManagement/Pending/PendingTable";
import AllProducts from "./AllProducts/AllProducts";
import DeletedProducts from "./DeletedProducts/DeletedProducts";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

export const isManageActiveAtom = atom(true);
export const isSellerProductViewedAtom = atom(false);
export const viewedSellerProductAtom = atom(null);

const ManageProducts = () => {
  const [productStatus, setProductStatus] = useState("All Products");
  const [searchQuery, setSearchQuery] = useState("");
  const [showMobileDropdown, setShowMobileDropdown] = useState(false);
  const [, setIsManageActive] = useAtom(isManageActiveAtom);
  const [moveToOption, setMoveToOption] = useState(null);
  const [showMoveToDropdown, setShowMoveToDropdown] = useState(false);
  const [isAnyRowSelected, setIsAnyRowSelected] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [, setLocalSelection] = useAtom(localSelectionAtom);
  const [mobileSearchOpen, setMobileSearchOpen] = useState(false);
  

  // Function to handle selecting an option in the "Move To" dropdown
// Function to handle selecting an option in the "Move To" dropdown
const handleMoveToOptionSelect = async (option) => {
  if (option === "Delete") {
    console.log("Delete selected");
    console.log(selectedRows);
    console.log("Selected Rows", selectedRows);
    selectedRows.forEach(async (row) => {
      console.log("Row", row);
      console.log("Row Status", row.status);
      if (row.status === "Approved") {
        const productId = row.productId;
      let formData = new FormData(); // Declare formData here
      formData.append("productinfo_id", productId);
      formData.append("status", "Deleted");
      try {
        const url =
          process.env.REACT_APP_BASE_URL + "sellerdashboard/updateproduct/";
        const response = await axios.post(`${url}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log("Data sent successfully:", response.data);

        toast.success("Product deleted successfully!");

      } catch (error) {
        console.error("Error sending data:", error);
        // Handle error
        toast.error("Error deleting product.");
      }
      
      }else if (row.status === "Deleted") {
        const productId = row.productId;
        let formData = new FormData(); // Declare formData here
        formData.append("productId", productId);
        console.log("Form Data", formData);
    const url =
      process.env.REACT_APP_BASE_URL + "sellerdashboard/removeproduct/";
    try {
      const response = await axios.post(`${url}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        body: JSON.stringify(formData),
      });
      console.log("Data sent successfully:", response.data);

      toast.success("Deleted product removed successfully!");
    } catch (error) {
      console.error("Error sending data:", error);
      // Handle error
      toast.error("Error removing deleted product.");
    }
      }
      
    });
  }

  setSelectedRows([]);
  setIsAnyRowSelected(false);
  setMoveToOption(option);
  setShowMoveToDropdown(false);
  setLocalSelection([]);
};

  const handleSearchInputChange = (event) => {
    if (event && event.target && event.target.value !== undefined) {
      console.log(event.target.value);
      setSearchQuery(event.target.value);
    }
  };

  // Function to handle selecting an option in the mobile dropdown
  const handleMobileOptionSelect = (option) => {
    setProductStatus(option);
    setShowMobileDropdown(false);
  };

  return (
    <div className={`px-8 py-6 flex flex-col gap-4`}>
      <div className="flex items-stretch justify-between lg:items-center">
        {/* Mobile dropdown for product status */}
        <div className="xl:hidden relative">
          <button
            className="flex items-center gap-1.5 text-blue font-bold text-[16px] bg-white border-2 border-gray-300 rounded-md px-2 py-2"
            onClick={() => setShowMobileDropdown(!showMobileDropdown)}
          >
            <p>{productStatus}</p>
            <HiChevronDown className="text-[20px]" />
          </button>

          {showMobileDropdown && (
            <div className="absolute z-30 mt-2 left-2 w-48 bg-white border border-gray-300 rounded-md">
              {["All Products", "Deleted"].map((option) => (
                <button
                  key={option}
                  className={`w-full ${
                    productStatus === option ? "bg-gray-200" : ""
                  } cursor-pointer hover:bg-gray-200 p-1.5`}
                  onClick={(e) => {
                    handleMobileOptionSelect(option);
                    e.stopPropagation();
                  }}
                >
                  {option}
                </button>
              ))}
            </div>
          )}
        </div>

        {/* Desktop buttons for product status */}
        <div className="hidden xl:flex xl:gap-4">
          <div className="flex items-center gap-6 font-medium text-[14px] text-gray-500">
            <button
              className={`${
                productStatus === "All Products"
                  ? "text-blue-900 font-bold border-b-2 pb-0.5 border-blue-900"
                  : ""
              }`}
              onClick={() => setProductStatus("All Products")}
            >
              All Products
            </button>

            <button
              className={`${
                productStatus === "Deleted"
                  ? "text-blue-900 font-bold border-b-2 pb-0.5 border-blue-900"
                  : ""
              }`}
              onClick={() => setProductStatus("Deleted")}
            >
              Deleted
            </button>
          </div>

          {isAnyRowSelected && (
            <div className="relative inline-block text-left">
              <button
                className="px-2 py-2 flex items-center gap-1 text-gray-500 text-[14px] font-medium"
                onClick={() => setShowMoveToDropdown(!showMoveToDropdown)}
              >
                Move To
                <HiChevronDown className="text-[20px]" />
              </button>

              {showMoveToDropdown && (
                <div className="absolute z-30 mt-0 left-0 w-48 bg-white border border-gray-300 rounded-md">
                  {["Delete"].map((option) => (
                    <button
                      key={option}
                      className={`w-full text-[14px] text-gray-700 font-medium cursor-pointer hover:bg-gray-200 p-1.5`}
                      onClick={(e) => {
                        handleMoveToOptionSelect(option);
                        e.stopPropagation();
                      }}
                    >
                      {option}
                    </button>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>

        <div className="flex gap-4">
          <div className="hidden px-4 py-2 sm:flex items-center gap-3 bg-white border border-gray-300 rounded-md cursor-pointer sm:cursor-pointer">
            <HiOutlineSearch className="text-gray-500 text-[20px]" />

            <input
              type="text"
              placeholder="Search"
              className="bg-white outline-none font-medium text-[14px] placeholder:text-[13px] w-[120px] placeholder:text-gray-400"
              value={searchQuery}
              onChange={handleSearchInputChange}
            />
          </div>

          <div
            className="px-4 py-2 sm:hidden items-center gap-3 bg-white border border-gray-300 rounded-md cursor-pointer sm:cursor-pointer"
            onClick={() => {
              setMobileSearchOpen(!mobileSearchOpen);
            }}
          >
            <HiOutlineSearch className="text-gray-500 text-[20px]" />
          </div>
        </div>
      </div>

      {mobileSearchOpen && (
        <div className="px-4 py-2 flex items-center gap-3 bg-white border border-gray-300 rounded-md cursor-pointer sm:cursor-pointer">
          <HiOutlineSearch className="text-gray-500 text-[20px]" />

          <input
            type="text"
            placeholder="Search"
            className="bg-white outline-none font-medium text-[14px] placeholder:text-[13px] w-[120px] placeholder:text-gray-400 sm:block"
            value={searchQuery}
            onChange={handleSearchInputChange}
          />
        </div>
      )}

      {productStatus === "All Products" ? (
        <AllProducts
          searchQuery={searchQuery}
          isAnyRowSelected={isAnyRowSelected}
          setIsAnyRowSelected={setIsAnyRowSelected}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      ) : (
        <DeletedProducts
          searchQuery={searchQuery}
          isAnyRowSelected={isAnyRowSelected}
          setIsAnyRowSelected={setIsAnyRowSelected}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      )}
    </div>
  );
};

export default ManageProducts;
