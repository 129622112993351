import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast,ToastContainer } from "react-toastify"; // Import ToastContainer and toast from react-toastify
import "react-toastify/dist/ReactToastify.css"; // Import CSS for react-toastify

function PaymentCallback() {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const status = queryParams.get('status');
        const order_id = queryParams.get('purchase_order_id');

        const url = process.env.REACT_APP_BASE_URL + 'users/payment-callback/';

        // API call to verify payment
        axios.post(url, { status, order_id })
            .then(response => {
                if (response.data.success) {
                    toast.success("Payment Successful", "Success");
                      // Redirect on success
                } else {
                    toast.error("Payment Failed", "Error");
                }
                setTimeout(() => {
                    navigate("/");
                }, 1000);
            })
            .catch(error => {
                toast.error("Payment Failed", "Error");
                navigate('/'); 
                setTimeout(() => {
                    navigate("/");
                }, 1000);
            });
    }, [location, navigate]);

    return (
        <div>
            <ToastContainer position="top-center" autoClose={1000} />
            Verifying payment...
        </div>
    );
}

export default PaymentCallback;
