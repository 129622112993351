import { atom, useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { BiTrash } from "react-icons/bi";
import { IoClose } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "../../../styles/table.css";
import {
  isSellerProductViewedAtom,
  viewedSellerProductAtom,
} from "../ProductManagement/ManageProduct/ManageProduct";
import { selectedProductsFromSearchAtom } from "./SearchSection";

export const selectedProductsForRequestAtom = atom([]);

const RequestSection = () => {
  // const [formData, setFormData] = useState([]);
  const [, setIsProductViewed] = useAtom(isSellerProductViewedAtom);
  const [, setViewedProduct] = useAtom(viewedSellerProductAtom);
  const [, setSelectedProductsForRequest] = useAtom(
    selectedProductsForRequestAtom
  );
  const [selectedProductsFromSearch, setSelectedProductsFromSearch] = useAtom(
    selectedProductsFromSearchAtom
  );

  const [showPopupForm, setShowPopupForm] = useState(false);

  const [formData, setFormData] = useState({
    vendorName: "",
    address: "",
    phone: "",
  });

  const [errors, setErrors] = useState({});
  const isAgent = localStorage.getItem("agenttoken");

  const navigate = useNavigate();

  useEffect(() => {
    if (selectedProductsFromSearch && selectedProductsFromSearch.length > 0) {
      setSelectedProductsFromSearch((prevFormData) => {
        // Identify products that are not present in formData
        const newProducts = selectedProductsFromSearch.filter(
          (newProduct) =>
            !prevFormData.some(
              (existingProduct) => existingProduct.id === newProduct.id
            )
        );

        // Create an array with quantity set to 1 for each new product
        const newFormData = newProducts.map((newProduct) => ({
          ...newProduct,
          quantity: 1,
        }));

        // Combine existing formData with the new products
        const updatedFormData = [...prevFormData, ...newFormData];

        return updatedFormData;
      });
    }
  }, []);

  // Handle changes in quantity input
  const handleQuantityChange = (index, updateFunction) => {
    setSelectedProductsFromSearch((prevFormData) => {
      const updatedFormData = [...prevFormData];
      const existingProduct = updatedFormData[index];

      if (existingProduct) {
        // Update quantity for existing product
        const updatedQuantity = updateFunction(existingProduct.quantity || 1);

        // Ensure the updated quantity is a positive integer
        const newQuantity =
          updatedQuantity > 0 ? Math.floor(updatedQuantity) : 1;

        updatedFormData[index] = {
          ...existingProduct,
          quantity: newQuantity,
        };
      } else {
        // Add new product with quantity
        const newQuantity = updateFunction(1);
        const newProduct = {
          ...selectedProductsFromSearch[index],
          quantity: newQuantity > 0 ? Math.floor(newQuantity) : 1,
        };
        updatedFormData[index] = newProduct;
      }

      return updatedFormData;
    });
  };

  // Handle delete action
  const handleDelete = (index) => {
    const updatedFormData = [...selectedProductsFromSearch];
    updatedFormData.splice(index, 1);
    setSelectedProductsFromSearch(updatedFormData);
    console.log(selectedProductsFromSearch);
  };

  // Handle form submission
  const handleSubmit = () => {
    setSelectedProductsForRequest(selectedProductsFromSearch);

    navigate("/seller/request-seller/bill");
  };
  const handlePopupFormSubmit = () => {
    const { vendorName, address, phone } = formData;
    const newErrors = {};

    if (!vendorName || !vendorName.trim()) {
      newErrors.vendorName = "Please enter the vendor name.";
    }
    if (!address || !address.trim()) {
      newErrors.address = "Please enter the address.";
    }
    if (!phone || !phone.trim()) {
      newErrors.phone = "Please enter the phone number.";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    // Proceed with form submission
    // ...

    // Navigate to the next page with form data
    navigate("/seller/request-seller/bill", { state: formData });
  };

  console.log(selectedProductsFromSearch);

  return (
    <div className="w-full flex flex-col gap-8">
      <ToastContainer position="top-center" autoClose={3000} />
      <div className="relative w-full bg-white border-2 border-gray-400 overflow-x-auto whitespace-nowrap table-container">
        <table className="relative w-full  text-white text-center border-spacing-4">
          <thead className="text-gray-900 border-y-2 border-gray-400 uppercase table-header-group">
            <tr>
              <th className="text-center">Part Number</th>
              <th className="text-center">Part Name</th>
              <th className="text-center">Part Category</th>
              <th className="text-center">Vehicle Company</th>
              <th className="text-center">Brand</th>
              <th className="text-center">Model</th>
              <th className="text-center">Quantity</th>
              <th className="text-center">Action</th>
            </tr>
          </thead>

          {selectedProductsFromSearch &&
          selectedProductsFromSearch.length > 0 ? (
            <tbody className="text-gray-700">
              {selectedProductsFromSearch.map((foundProduct, index) => (
                <>
                  <tr className="border-b-2 border-gray-300 text-gray-700 relativetable-row">
                    <td className="text-center">
                      {foundProduct.partNumber
                        ? foundProduct.partNumber
                        : "N/A"}
                    </td>

                    <td className="text-center">
                      {foundProduct.partName ? foundProduct.partName : "N/A"}
                    </td>

                    <td className="text-center">
                      {" "}
                      {foundProduct.partCategories
                        ? foundProduct.partCategories
                        : "N/A"}
                    </td>

                    <td className="text-center">
                      {foundProduct.vehicleCompany
                        ? foundProduct.vehicleCompany
                        : "N/A"}
                    </td>

                    <td className="text-center">
                      {foundProduct.brand ? foundProduct.brand : "N/A"}
                    </td>

                    <td className="text-center">
                      {foundProduct.model ? foundProduct.model : "N/A"}
                    </td>

                    <td className="text-center w-full h-full flex justify-center items-center">
                      <div className="flex items-center gap-1">
                        <button
                          className="cursor-pointer font-bold text-lg"
                          onClick={() =>
                            handleQuantityChange(index, (prevQuantity) =>
                              prevQuantity > 1 ? prevQuantity - 1 : 1
                            )
                          }
                        >
                          -
                        </button>

                        <input
                          name=""
                          id=""
                          className="outline-none text-center font-medium max-w-[40px]"
                          value={
                            selectedProductsFromSearch[index] &&
                            selectedProductsFromSearch[index].quantity !==
                              undefined
                              ? selectedProductsFromSearch[index].quantity
                              : 1
                          }
                          onChange={(e) =>
                            handleQuantityChange(index, () =>
                              Number(e.target.value)
                            )
                          }
                        />

                        <button
                          className="cursor-pointer font-bold text-lg"
                          onClick={() =>
                            handleQuantityChange(
                              index,
                              (prevQuantity) => Number(prevQuantity) + 1
                            )
                          }
                        >
                          +
                        </button>
                      </div>
                    </td>

                    <td className="text-center">
                      <div className="flex justify-end items-center gap-2">
                        {foundProduct.otherPhotos && (
                          <button
                            className="px-1.5 border-2 border-gray-300 font-medium rounded-md h-[35px]"
                            onClick={(event) => {
                              event.preventDefault();
                              setIsProductViewed(true);
                              setViewedProduct(foundProduct);
                            }}
                          >
                            <AiOutlineEye className="w-[22px] h-[22px] text-gray-700" />
                          </button>
                        )}

                        <button
                          className="px-1.5 border-2 border-gray-300 font-medium rounded-md h-[35px]"
                          onClick={(event) => {
                            event.preventDefault();
                            handleDelete(index);
                          }}
                        >
                          <BiTrash className="w-5 h-5 text-red-900" />
                        </button>
                      </div>
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          ) : (
            <tbody>
              <tr className="border-b-2 border-gray-300 h-[65px] text-gray-700 relative hover:bg-gray-100">
                <td colSpan="8" className="text-center">
                  <p className="text-gray-700 font-semibold">
                    Selected Product will be displayed here
                  </p>
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>

      <button
        className="bg-blue text-white font-medium px-6 py-1.5 rounded-md self-end"
        onClick={
          isAgent
            ? () => {
                if (selectedProductsFromSearch.length === 0) {
                  toast.error("Please select a product to proceed.");
                  return;
                }
                setShowPopupForm(true);
              }
            : () => handleSubmit()
        }
      >
        Proceed
      </button>

      {showPopupForm && (
        <div className="bg-[#0000007c] z-50 fixed top-0 left-0 w-screen h-screen flex items-center justify-center p-4">
          <div className="relative bg-white max-w-[800px] max-h-[95vh] overflow-y-auto w-full mx-4 sm:min-w-[600px] rounded-lg px-6 sm:px-8 py-8">
            <button
              className="absolute right-2 top-2 text-red-900"
              onClick={() => {
                setShowPopupForm(false);
              }}
            >
              <IoClose className="w-7 h-7" />
            </button>
            {/* Form */}
            <div className="mt-4 flex flex-col">
              {/* Vendor Name */}
              <div className="mb-4">
                <label
                  htmlFor="vendorName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Vendor Name
                </label>
                <input
                  type="text"
                  id="vendorName"
                  name="vendorName"
                  value={formData.vendorName || ""}
                  onChange={(e) =>
                    setFormData({ ...formData, vendorName: e.target.value })
                  }
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full outline-none transition-all duration-300 hover:border-blue focus:border-blue"
                />
                {errors.vendorName && (
                  <p className="text-red-900 mt-1 text-sm">
                    {errors.vendorName}
                  </p>
                )}
              </div>
              {/* Address */}
              <div className="mb-4">
                <label
                  htmlFor="address"
                  className="block text-sm font-medium text-gray-700"
                >
                  Address
                </label>
                <input
                  type="text"
                  id="address"
                  name="address"
                  value={formData.address || ""}
                  onChange={(e) =>
                    setFormData({ ...formData, address: e.target.value })
                  }
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full outline-none transition-all duration-300 hover:border-blue focus:border-blue"
                />
                {errors.address && (
                  <p className="text-red-900 mt-1 text-sm">{errors.address}</p>
                )}
              </div>
              {/* Phone */}
              <div className="mb-4">
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium text-gray-700"
                >
                  Phone
                </label>
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  value={formData.phone || ""}
                  onChange={(e) =>
                    setFormData({ ...formData, phone: e.target.value })
                  }
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full outline-none transition-all duration-300 hover:border-blue focus:border-blue"
                />
                {errors.phone && (
                  <p className="text-red-900 mt-1 text-sm">{errors.phone}</p>
                )}
              </div>

              {/* Submit Button */}
              <button
                className="justify-end self-end bg-blue text-white font-medium py-2 px-4 rounded-md hover:bg-blue-600"
                onClick={handlePopupFormSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RequestSection;
