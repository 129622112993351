import { useAtom } from "jotai";
import React, { useState } from "react";
import InputWithLabel from "../../../common/InputWithLabel";
import { currentStepAtom } from "./StepsIndicator";

const PartsInformationForm = ({ formData, onInputChange }) => {
  const [, setCurrentStep] = useAtom(currentStepAtom);
  const [errors, setErrors] = useState({
    partCategories: "",
    partSubCategories: "",
    partName: "",
    partNumbers: "",
  });

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const newErrors = validateForm();

    if (Object.values(newErrors).some((error) => error !== "")) {
      setErrors(newErrors);
      return;
    }

    console.log(formData);

    // Move to the next step
    setCurrentStep((prevStep) => Math.min(prevStep + 1, 5));
  };

  const validateForm = () => {
    const { partCategories, partSubCategories, partName, partNumber } =
      formData;

    const newErrors = {
      partCategories: partCategories ? "" : "Part Categories is required",
      partSubCategories: partSubCategories
        ? ""
        : "Part Sub-Categories is required",
      partName: partName ? "" : "Part Name is required",
      partNumber: partNumber ? "" : "Part Number is required",
    };

    return newErrors;
  };

  const clearError = (fieldName) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: "",
    }));
  };

  return (
    <div className="flex-1 bg-white py-4 flex flex-col gap-6">
      <div className="flex items-center justify-between px-6 sm:px-8">
        <div className="text-blue font-bold">
          <p>Add Parts Info</p>
        </div>
      </div>

      <form
        className="flex-1 flex flex-col justify-between gap-16"
        encType="multipart/form-data"
      >
        <div className="flex flex-col gap-4 lg:gap-6 px-6 sm:px-8">
          <div className="flex flex-col gap-1">
            <InputWithLabel
              htmlFor="partCategories"
              label="Part Categories"
              type="text"
              placeholder="Engine, Body, etc"
              name="partCategories"
              value={formData.partCategories}
              onChange={(e) => {
                onInputChange("partCategories", e.target.value);
                clearError("partCategories");
              }}
            />

            {errors.partCategories && (
              <p className="text-red-900 text-[14px] font-medium">
                {errors.partCategories}
              </p>
            )}
          </div>

          <div className="flex flex-col gap-1">
            <InputWithLabel
              htmlFor="partSubCategories"
              label="Part Sub-Categories"
              type="text"
              placeholder="Engine, Body, etc"
              name="partSubCategories"
              value={formData.partSubCategories}
              onChange={(e) => {
                onInputChange("partSubCategories", e.target.value);
                clearError("partSubCategories");
              }}
            />

            {errors.partSubCategories && (
              <p className="text-red-900 text-[14px] font-medium">
                {errors.partSubCategories}
              </p>
            )}
          </div>

          
          <div className="flex flex-col gap-1">
            <InputWithLabel
              htmlFor="partName"
              label="Part Name"
              type="text"
              placeholder="Part Name"
              name="partName"
              value={formData.partName}
              onChange={(e) => {
                onInputChange("partName", e.target.value);
                clearError("partName");
              }}
            />

            {errors.partName && (
              <p className="text-red-900 text-[14px] font-medium">
                {errors.partName}
              </p>
            )}
          </div>
          
          <div className="flex flex-col gap-1">
            <InputWithLabel
              htmlFor="partNumber"
              label="Part Number"
              type="text"
              placeholder="Part Number"
              name="partNumber"
              value={formData.partNumber}
              onChange={(e) => {
                onInputChange("partNumber", e.target.value);
                clearError("partNumber");
              }}
            />

            {errors.partNumber && (
              <p className="text-red-900 text-[14px] font-medium">
                {errors.partNumber}
              </p>
            )}
          </div>
        </div>

        <div className="pt-6 px-6 sm:px-8 flex flex-row justify-between border-t border-gray-200">
          <button
            type="button"
            onClick={() =>
              setCurrentStep((prevStep) => Math.max(prevStep - 1, 1))
            }
            className="border border-gray-300 px-6 py-1 rounded-md text-[14px] text-gray-500 font-semibold"
          >
            Back
          </button>

          <button
            type="submit"
            className="bg-blue px-6 py-1 rounded-md text-[14px] text-white font-semibold"
            onClick={handleFormSubmit}
          >
            Next
          </button>
        </div>
      </form>
    </div>
  );
};

export default PartsInformationForm;
