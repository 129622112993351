import React from "react";

const OfferCard = ({ imageUrl, offerType, offerTitle, offerDescription, buttonText, productImage }) => {
  return (
    <div className="relative bg-cover bg-no-repeat bg-center flex items-center justify-between gap-4 px-6 py-8 vsm:px-8 vsm:py-10" style={{ backgroundImage: `url(${imageUrl})` }}>
      <div className="bg-[#201f37c5] w-full h-full absolute top-0 left-0"></div>

      <div className="z-10 flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <span className="text-yellow uppercase text-sm">{offerType}</span>
          <h1 className="text-white text-xl font-bold">{offerTitle}</h1>
          <p className="text-white text-sm">{offerDescription}</p>
        </div>
        
        <button className="w-fit bg-yellow px-4 py-1.5 rounded-md text-white font-bold text-[15px]">
          {buttonText}
        </button>
      </div>

      <div className="z-10 relative hidden sm:block">
        <div className="absolute top-10 left-14 w-[50px] h-[50px] rounded-full text-xs text-center bg-red-900 flex flex-col items-center justify-center text-white font-medium">
          20% <span className="block">OFF</span>
        </div>
        <img src={productImage} alt="" className="max-w-[240px]" />
      </div>
    </div>
  );
};

export default OfferCard;
