import React from "react";
import { AiOutlineClose } from "react-icons/ai"; // Import close icon
import "./Notification.css"

const Notification = ({ setShowNotificationPopup }) => {
  // Sample data (replace with your actual data)
  const notifications = [
    {
      id: 1,
      name: "John Doe",
      image: "https://picsum.photos/seed/1/40/40",
      message: "you got sale and received money.",
      time: "2 hours ago",
    },
    {
      id: 2,
      name: "Jane Smith",
      image: "https://picsum.photos/seed/2/40/40",
      message: "you got sale and received money.",
      time: "4 hours ago",
    },
    {
      id: 3,
      name: "Alice Johnson",
      image: "https://picsum.photos/seed/3/40/40",
      message: "you got sale and received money.",
      time: "8 hours ago",
    },
    {
      id: 3,
      name: "Alice Johnson",
      image: "https://picsum.photos/seed/3/40/40",
      message: "you got sale and received money.",
      time: "8 hours ago",
    },
    {
      id: 3,
      name: "Alice Johnson",
      image: "https://picsum.photos/seed/3/40/40",
      message: "you got sale and received money.",
      time: "8 hours ago",
    },
    {
      id: 3,
      name: "Alice Johnson",
      image: "https://picsum.photos/seed/3/40/40",
      message: "you got sale and received money.",
      time: "8 hours ago",
    },
    {
      id: 3,
      name: "Alice Johnson",
      image: "https://picsum.photos/seed/3/40/40",
      message: "you got sale and received money.",
      time: "8 hours ago",
    },
    {
      id: 3,
      name: "Alice Johnson",
      image: "https://picsum.photos/seed/3/40/40",
      message: "you got sale and received money.",
      time: "8 hours ago",
    },
    {
      id: 3,
      name: "Alice Johnson",
      image: "https://picsum.photos/seed/3/40/40",
      message: "you got sale and received money.",
      time: "8 hours ago",
    },
    {
      id: 3,
      name: "Alice Johnson",
      image: "https://picsum.photos/seed/3/40/40",
      message: "you got sale and received money.",
      time: "8 hours ago",
    },
    {
      id: 3,
      name: "Alice Johnson",
      image: "https://picsum.photos/seed/3/40/40",
      message: "you got sale and received money.",
      time: "8 hours ago",
    },
    {
      id: 3,
      name: "Alice Johnson",
      image: "https://picsum.photos/seed/3/40/40",
      message: "you got sale and received money.",
      time: "8 hours ago",
    },
    {
      id: 3,
      name: "Alice Johnson",
      image: "https://picsum.photos/seed/3/40/40",
      message: "you got sale and received money.",
      time: "8 hours ago",
    },
    {
      id: 3,
      name: "Alice Johnson",
      image: "https://picsum.photos/seed/3/40/40",
      message: "you got sale and received money.",
      time: "8 hours ago",
    },
    {
      id: 3,
      name: "Alice Johnson",
      image: "https://picsum.photos/seed/3/40/40",
      message: "you got sale and received money.",
      time: "8 hours ago",
    },
    {
      id: 3,
      name: "Alice Johnson",
      image: "https://picsum.photos/seed/3/40/40",
      message: "you got sale and received money.",
      time: "8 hours ago",
    },
    {
      id: 3,
      name: "Alice Johnson",
      image: "https://picsum.photos/seed/3/40/40",
      message: "you got sale and received money.",
      time: "8 hours ago",
    },
    {
      id: 3,
      name: "Alice Johnson",
      image: "https://picsum.photos/seed/3/40/40",
      message: "you got sale and received money.",
      time: "8 hours ago",
    },
    {
      id: 3,
      name: "Alice Johnson",
      image: "https://picsum.photos/seed/3/40/40",
      message: "you got sale and received money.",
      time: "8 hours ago",
    },
    {
      id: 3,
      name: "Alice Johnson",
      image: "https://picsum.photos/seed/3/40/40",
      message: "you got sale and received money.",
      time: "8 hours ago",
    },
    {
      id: 3,
      name: "Alice Johnson",
      image: "https://picsum.photos/seed/3/40/40",
      message: "you got sale and received money.",
      time: "8 hours ago",
    },
    {
      id: 3,
      name: "Alice Johnson",
      image: "https://picsum.photos/seed/3/40/40",
      message: "you got sale and received money.",
      time: "8 hours ago",
    },
    {
      id: 3,
      name: "Alice Johnson",
      image: "https://picsum.photos/seed/3/40/40",
      message: "you got sale and received money.",
      time: "8 hours ago",
    },
    {
      id: 3,
      name: "Alice Johnson",
      image: "https://picsum.photos/seed/3/40/40",
      message: "you got sale and received money.",
      time: "8 hours ago",
    },
    {
      id: 3,
      name: "Alice Johnson",
      image: "https://picsum.photos/seed/3/40/40",
      message: "you got sale and received money.",
      time: "8 hours ago",
    },
    {
      id: 3,
      name: "Alice Johnson",
      image: "https://picsum.photos/seed/3/40/40",
      message: "you got sale and received money.",
      time: "8 hours ago",
    },
    {
      id: 3,
      name: "Alice Johnson",
      image: "https://picsum.photos/seed/3/40/40",
      message: "you got sale and received money.",
      time: "8 hours ago",
    },
    {
      id: 3,
      name: "Alice Johnson",
      image: "https://picsum.photos/seed/3/40/40",
      message: "you got sale and received money.",
      time: "8 hours ago",
    },
    {
      id: 3,
      name: "Alice Johnson",
      image: "https://picsum.photos/seed/3/40/40",
      message: "you got sale and received money.",
      time: "8 hours ago",
    },
    {
      id: 3,
      name: "Alice Johnson",
      image: "https://picsum.photos/seed/3/40/40",
      message: "you got sale and received money.",
      time: "8 hours ago",
    },
    {
      id: 3,
      name: "Alice Johnson",
      image: "https://picsum.photos/seed/3/40/40",
      message: "you got sale and received money.",
      time: "8 hours ago",
    },
    {
      id: 3,
      name: "Alice Johnson",
      image: "https://picsum.photos/seed/3/40/40",
      message: "you got sale and received money.",
      time: "8 hours ago",
    },
    {
      id: 3,
      name: "Alice Johnson",
      image: "https://picsum.photos/seed/3/40/40",
      message: "you got sale and received money.",
      time: "8 hours ago",
    },
    {
      id: 3,
      name: "Alice Johnson",
      image: "https://picsum.photos/seed/3/40/40",
      message: "you got sale and received money.",
      time: "8 hours ago",
    },
    {
      id: 3,
      name: "Alice Johnson",
      image: "https://picsum.photos/seed/3/40/40",
      message: "you got sale and received money.",
      time: "8 hours ago",
    },
    {
      id: 3,
      name: "Alice Johnson",
      image: "https://picsum.photos/seed/3/40/40",
      message: "you got sale and received money.",
      time: "8 hours ago",
    },
    // Add more notifications as needed
  ];

  return (
    <div
      className="w-full min-h-screen fixed top-0 left-0 md:relative md:min-h-max md:max-w-md rounded mx-auto bg-white shadow-md text-black"
    >
      <div className="flex items-center font-bold justify-between px-4 py-3 md:py-2.5 border-b border-gray-300">
        <h2 className="text-lg md:text-base">Notifications</h2>
        <button
          className="text-xl md:text-lg text-red-900"
          onClick={() => setShowNotificationPopup(false)}
        >
          <AiOutlineClose />
        </button>
      </div>

      <div className="overflow-y-auto h-[calc(100vh_-_53px)] md:h-auto md:max-h-60" id="notifications">
        {notifications.map((notification) => (
          <div
            key={notification.id}
            className="px-4 py-2.5 border border-b border-gray-200 flex items-center gap-4 justify-between bg-white"
          >
            <div className="flex items-center space-x-2">
              <img
                src={notification.image}
                alt={notification.name}
                className="w-10 h-10 rounded-full object-cover"
              />
              <div className="leading-none flex flex-col gap-1">
                <p className="text-gray-800 font-semibold text-[15px]">
                  {notification.name}
                </p>
                <p className="text-black text-[14px]">{notification.message}</p>
              </div>
            </div>
            <p className="text-gray-400 text-xs">{notification.time}</p>
          </div>
        ))}
      </div>

      {/* <button className="w-full bg-gray-200 font-semibold text-[15px] py-2 px-4">
        View All
      </button> */}
    </div>
  );
};

export default Notification;
