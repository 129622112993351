import { atom, useAtom } from "jotai";
import { jwtDecode } from "jwt-decode";
import React, { useEffect, useRef, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { IoIosArrowDown, IoMdNotifications } from "react-icons/io";
import { IoLocationOutline } from "react-icons/io5";
import { MdOutlineShoppingCart, MdPhone } from "react-icons/md";
import { RiMenu2Fill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify"; // Import ToastContainer and toast from react-toastify
import "react-toastify/dist/ReactToastify.css"; // Import CSS for react-toastify
import BrandLogo from "../../../assets/images/osis.png";
import SearchModal from "../Search/SearchModal";
import { HiMiniUserCircle } from "react-icons/hi2";

export const isSearchButtonClickedAtom = atom(false);

const Header = ({ showTopPart = true, showCategory = false }) => {
  const [showProfileDropdown, setShowProfileDropdown] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState("");
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [, setIsSearchButtonClicked] = useAtom(isSearchButtonClickedAtom);

  const openSearchModal = () => {
    if (searchInput.trim() === "") {
      toast.info("Please enter a search term.");
      return;
    }
    setIsSearchModalOpen(true);
    setIsSearchButtonClicked(true);
  };

  const fetchData = async () => {
    const token = localStorage.getItem("usertoken");
    const username = localStorage.getItem("user");
    setUser(username);
    if (!token) {
      return;
    }

    // Check if the authentication token has expired (example for JWT, may vary based on implementation)
    const isTokenExpired = () => {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000; // Convert to seconds
        return decodedToken.exp < currentTime;
      } catch (error) {
        return true; // Error decoding token or invalid token, treat as expired
      }
    };

    if (isTokenExpired()) {
      // Handle token expiration (e.g., refresh the token or redirect to login page)
      toast.error("Please log in.");
      navigate("/login");
      return;
    } else {
      setIsLoggedIn(true);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const profileDropdownRef = useRef(null);

  const handleProfileClick = () => {
    setShowProfileDropdown(!showProfileDropdown);
  };

  const handleClickOutside = (event) => {
    if (
      profileDropdownRef.current &&
      !profileDropdownRef.current.contains(event.target)
    ) {
      setShowProfileDropdown(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      openSearchModal();
    }
  };

  const navigate = useNavigate();

  const handleBecomeSellerClick = () => {
    navigate('/signup', { state: { accountType: 'business' } });

  };

  return (
    <div>
      <ToastContainer position="top-center" autoClose={1000} />

      {showTopPart && (
        <div className="bg-[#D5E4FE] items-center gap-24 py-1 px-8 whitespace-nowrap lg:px-20 hidden lg:flex">
          

          <button className="absolute-left flex items-center space-x-40" onClick={handleBecomeSellerClick}>
          <p className="text-[13px] cursor-pointer">Become a Seller</p>
          </button>

          <div className="flex items-center gap-8 absolute right-4">
            <div className="flex items-center gap-1">
              <MdPhone />
              <p className="text-[13px]">+977-9861234567</p>
            </div>

            <div className="flex items-center gap-1">
              <IoLocationOutline />
              <p className="text-[13px]">Kathmandu, Nepal</p>
            </div>
          </div>
        </div>
//         <div className="bg-[#D5E4FE] flex items-center justify-between gap-24 py-1 px-8 whitespace-nowrap lg:px-20 hidden lg:flex">
//         <button className="flex items-center space-x-40" onClick={handleBecomeSellerClick}>
//           <p className="text-[13px] cursor-pointer">Become a Seller</p>
//           </button>
   
        
//         <div className=" absolute-right-4 flex items-center gap-8">
//           <div className="flex items-center gap-1">
//             <MdPhone />
//             <p className="text-[13px]">+977-9861234567</p>
//           </div>
//           <div className="flex items-center gap-1">
//             <IoLocationOutline />
//             <p className="text-[13px]">Kathmandu, Nepal</p>
//           </div>
//         </div>
//       </div>
      )}

      <header className="relative bg-white border-y border-gray-300 pb-[55px] lg:pb-0">
        <div className="px-6 py-3 flex items-center justify-between md:px-10 lg:px-20">
          <div className="flex items-center gap-4">
            <div
              className={`cursor-pointer flex items-center gap-2 md:gap-4`}
              onClick={() => navigate("/")}
            >
              <img src={BrandLogo} alt="" className="w-[35px] md:w-[40px]" />
              <p className="text-xl font-bold text-blue md:text-2xl">Osis</p>
            </div>
          </div>

          <div className="flex items-center gap-20">
            <div className="rounded-lg border border-gray-300 bg-[#f9f9fb] py-0.5 pl-3 pr-0.5 absolute top-16 left-[24px] w-[calc(100%_-_48px)] md:left-10 md:w-[calc(100%_-_80px)] lg:static lg:w-auto">
              <div className="flex items-center">
                <input
                  type="text"
                  placeholder="Search in osis"
                  className="outline-none bg-[#f9f9fb] w-[350px] text-[15px] flex-1"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  onKeyPress={handleKeyPress}
                />

                <button
                  className="bg-blue rounded-md flex items-center justify-center text-white px-2 py-1.5"
                  onClick={openSearchModal}
                >
                  <FiSearch />
                </button>
              </div>
            </div>

            <div className="flex items-center gap-6">
              <div className="items-center gap-4 text-gray-500 md:flex">
                <button onClick={() => navigate(`/cart`)}>
                  <MdOutlineShoppingCart className="text-xl" />
                </button>

                {/* <button>
                  <IoMdNotifications className="text-xl" />
                </button> */}
              </div>

              <div className="bg-gray-400 w-[1px] h-[20px] hidden md:block"></div>

              <div className="flex z-50 items-center gap-4">
                {isLoggedIn ? (
                  <div className="relative" ref={profileDropdownRef}>
                    <button
                      className="flex items-center gap-3 text-[15px] text-gray-500 font-medium cursor-pointer md:gap-6"
                      onClick={handleProfileClick}
                    >

                      <div
                        className={`${
                          showProfileDropdown 
                        } text-lg`}
                      >
                        <HiMiniUserCircle />
                      </div>
                    </button>
                    {showProfileDropdown && (
                      <div className="z-8 text-sm items-center  font-medium flex flex-col gap-3 absolute right-0 mt-2 bg-white border border-gray-300 rounded-md h-[160px] w-[130px]">
                        <p
                          className="cursor-pointer text-gray-500 hover:text-blue"
                          onClick={() => navigate("/account/profile")}
                        >
                          My Profile
                        </p>
                        <p
                          className="cursor-pointer text-gray-500 hover:text-blue"
                          onClick={() => navigate("/account/orders")}
                        >
                          My Order
                        </p>
                        <p
                          className="cursor-pointer text-gray-500 hover:text-blue"
                          onClick={() => navigate("/account/reviews")}
                        >
                          My Reviews
                        </p>
                        <p
                          className="cursor-pointer text-gray-500 hover:text-blue"
                          onClick={() => navigate("/account/address")}
                        >
                          My Addresses
                        </p>
                        <p
                          className="cursor-pointer text-gray-500 hover:text-blue"
                          onClick={() => {
                            localStorage.removeItem("usertoken");
                            localStorage.removeItem("admintoken");
                            localStorage.removeItem("agenttoken");
                            localStorage.removeItem("token");

                            navigate("/login");
                          }}
                        >
                          Logout
                        </p>
                      </div>
                    )}
                  </div>
                ) : (
                  <>
                    <button
                      className="border text-[15px] border-blue bg-blue w-[80px] py-1 text-white rounded-md font-medium"
                      onClick={() => navigate("/login")}
                    >
                      Login
                    </button>

                    <button
                      className="border text-[15px] border-blue w-[80px] py-1 text-blue rounded-md font-medium"
                      onClick={() => navigate("/signup")}
                    >
                      Sign up
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        {showCategory && (
          <div className="w-full px-20 flex items-center gap-6">
            <button className="w-fit bg-blue text-white px-4 py-1.5 flex items-center gap-2 text-[15px]">
              <RiMenu2Fill className="text-xl" />
              All Category
            </button>

            <div className="flex-1 flex items-center justify-center">
              <ul className="flex items-center gap-8 text-sm text-gray-500 font-medium">
                <li className="cursor-pointer transition-colors duration-300 hover:text-black">
                  Today's Deal
                </li>
                <li className="cursor-pointer transition-colors duration-300 hover:text-black">
                  Hot Offers
                </li>
                <li className="cursor-pointer transition-colors duration-300 hover:text-black">
                  Brands Trending
                </li>
                <li className="cursor-pointer transition-colors duration-300 hover:text-black">
                  Featured
                </li>
                <li className="cursor-pointer transition-colors duration-300 hover:text-black">
                  Help
                </li>
              </ul>
            </div>
          </div>
        )}
      </header>

      {isSearchModalOpen && (
        <SearchModal
          setIsSearchModalOpen={setIsSearchModalOpen}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          fetchData={fetchData}
        />
      )}
    </div>
  );
};

export default Header;
