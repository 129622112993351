import React from "react";
import LineChart from "../../SellerPage/Dashboard/LineChart";
import RevenueGoal from "./RevenueGoal";
import TopProductSold from "./TopProductSold";
import TotalSales from "./TotalSales";

export const AdminDashboard = () => {
  return (
    <div className="flex flex-col gap-6 pt-5 pb-10">
      <div className="flex item-center">
        <div className="px-6 w-full">
          <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4 xl:grid-cols-4">
            <div className="shadow rounded-lg py-3 px-5 bg-white">
              <div className="flex flex-row justify-between items-center">
                <div>
                  <h6 className="text-xl">Total Sales</h6>
                  <h4 className="text-black text-3xl font-bold text-left">
                    $10,000
                  </h4>
                </div>
              </div>

              <div className="text-left flex flex-row justify-start items-center">
                <span className="mr-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="#14B8A6"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"
                    />
                  </svg>
                </span>
                <p>
                  <span className="text-teal-500 font-bold">3%</span> in 7 days
                </p>
              </div>
            </div>
            <div className="shadow rounded-lg py-3 px-5 bg-white">
              <div className="flex flex-row justify-between items-center">
                <div>
                  <h6 className="text-xl">Orders</h6>
                  <h4 className="text-black text-3xl font-bold text-left">
                    567
                  </h4>
                </div>
              </div>
              <div className="text-left flex flex-row justify-start items-center">
                <span className="mr-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="#14B8A6"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"
                    />
                  </svg>
                </span>
                <p>
                  <span className="text-teal-500 font-bold">3%</span> in 7 days
                </p>
              </div>
            </div>
            <div className="shadow rounded-lg py-3 px-5 bg-white">
              <div className="flex flex-row justify-between items-center">
                <div>
                  <h6 className="text-xl">Product Solds</h6>
                  <h4 className="text-black text-3xl font-bold text-left">
                    678
                  </h4>
                </div>
              </div>
              <div className="text-left flex flex-row justify-start items-center">
                <span className="mr-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="#14B8A6"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"
                    />
                  </svg>
                </span>
                <p>
                  <span className="text-teal-500 font-bold">3%</span> in 7 days
                </p>
              </div>
            </div>
            <div className="shadow rounded-lg py-3 px-5 bg-white">
              <div className="flex flex-row justify-between items-center">
                <div>
                  <h6 className="text-xl">New Customers</h6>
                  <h4 className="text-black text-3xl font-bold text-left">
                    10
                  </h4>
                </div>
              </div>
              <div className="text-left flex flex-row justify-start items-center">
                <span className="mr-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="#EF4444"
                    stroke-width="{2}"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M13 17h8m0 0V9m0 8l-8-8-4 4-6-6"
                    />
                  </svg>
                </span>
                <p>
                  <span className="text-red-500 font-bold">2%</span> in 7 days
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="px-6 grid xl:grid-cols-5 gap-4">
        <div className="w-full xl:col-span-5">
          <LineChart />
        </div>

        <div className="w-full xl:col-span-5">
          <TopProductSold />
        </div>

        <div className="w-full grid gap-4 items-start md:grid-cols-2 xl:col-span-5">
          <RevenueGoal />

          <TotalSales />
        </div>
      </div>
    </div>
  );
};
