import React, { useRef, useState, useEffect } from "react";
import { MdOutlineAddCircle } from "react-icons/md";

const ImageInput = ({ text, height, iconSize, inputName, onChange, existingFileName }) => {
  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState(existingFileName || "");

  useEffect(() => {
    // Set the file name based on existingFileName when component mounts
    setFileName(existingFileName || "");
  }, [existingFileName]);

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    onChange && onChange(file);

    // Set the file name for display
    setFileName(file ? file.name : "");
  };

  return (
    <div
      style={{ minHeight: height }}
      className={`w-full bg-[#F9FBFF] border border-dashed border-blue rounded-lg flex items-center justify-center gap-1 text-blue cursor-pointer xl:h-full xl:flex-1`}
      onClick={handleClick}
    >
      {fileName ? (
        <p className="text-gray-700 text-center px-1 text-[15px] font-medium overflow-hidden overflow-ellipsis">
          {fileName}
        </p>
      ) : (
        <div className="flex flex-col items-center gap-4">
          <MdOutlineAddCircle
            style={iconSize ? { fontSize: iconSize } : { fontSize: "50px" }}
            className={`text-blue`}
          />
          {text && (
            <p className="text-gray-700 text-[15px] font-medium">
              <span className="text-blue underline">{text}</span> or drag & drop
            </p>
          )}
        </div>
      )}

      <input
        type="file"
        className="hidden"
        ref={fileInputRef}
        onChange={handleFileChange}
        name={inputName}
      />
    </div>
  );
};

export default ImageInput;
