import axios from "axios";
import { atom, useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { FaTruckPickup } from "react-icons/fa";
import { IoLocationSharp } from "react-icons/io5";
import { TbTruckDelivery } from "react-icons/tb";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import provinceDistrictData from "../../../assets/json/nepal_location.json";
import { currentStepAtom } from "../../../components/SellerPage/Signup/StepsIndicator";
import { vendorToEditAtom } from "../../Admin/VendorsManagement/AcceptedVendors/AcceptedVendorsTableRow";
import DropdownWithLabel from "../../common/DropdownWithLabel";
import InputWithLabel from "../../common/InputWithLabel";
import PhoneInputWithLabel from "../../common/PhoneInputWithLabel";
import { Navigate, useNavigate } from "react-router-dom";

export const addressIdAtom = atom(null);

const AddAddressForm = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [businessFormData, setBusinessFormData] = useState({
    address: "",
    province: "",
    area: "",
    district: "",
    additionalphone: "",
    descriptiveaddress: "",
  });

  const [pickupFormData, setPickupFormData] = useState({
    address: "",
    province: "",
    area: "",
    district: "",
    additionalphone: "",
    descriptiveaddress: "",
    sameAsBusiness: false,
  });

  const [deliveryFormData, setDeliveryFormData] = useState({
    address: "",
    province: "",
    area: "",
    district: "",
    additionalphone: "",
    descriptiveaddress: "",
    sameAsBusiness: false,
  });

  const [vendorToEdit, setVendorToEdit] = useAtom(vendorToEditAtom);

  useEffect(() => {
    console.log("Vendor to edit test:", vendorToEdit);
    // Check if vendorToEdit data is available
    if (vendorToEdit && vendorToEdit.length > 0) {
      const {
        selleraddress: { address, pickupaddress, deliveryaddress },
      } = vendorToEdit[0];

      // Set businessFormData
      setBusinessFormData({
        address: address.address,
        province: address.province,
        area: address.area,
        district: address.district,
        additionalphone: address.additionalphone,
        descriptiveaddress: address.descriptiveaddress,
      });

      // Set pickupFormData
      setPickupFormData({
        address: pickupaddress.address,
        province: pickupaddress.province,
        area: pickupaddress.area,
        district: pickupaddress.district,
        additionalphone: pickupaddress.additionalphone,
        descriptiveaddress: pickupaddress.descriptiveaddress,
        sameAsBusiness: false, // Assuming pickup address is not same as business address
      });

      // Set deliveryFormData
      setDeliveryFormData({
        address: deliveryaddress.address,
        province: deliveryaddress.province,
        area: deliveryaddress.area,
        district: deliveryaddress.district,
        additionalphone: deliveryaddress.additionalphone,
        descriptiveaddress: deliveryaddress.descriptiveaddress,
        sameAsBusiness: false, // Assuming delivery address is not same as business address
      });
    }
  }, [vendorToEdit]);

  const [, setAddressId] = useAtom(addressIdAtom);

  const [, setCurrentStep] = useAtom(currentStepAtom);

  const [isBusinessDistrictDisabled, setIsBusinessDistrictDisabled] =
    useState(true);
  const [, setIsBusinessAreaDisabled] = useState(true);

  const [isPickupDistrictDisabled, setIsPickupDistrictDisabled] =
    useState(true);

  const [isDeliveryDistrictDisabled, setIsDeliveryDistrictDisabled] =
    useState(true);

  const [businessErrors, setBusinessErrors] = useState({
    address: "",
    province: "",
    area: "",
    district: "",
    additionalphone: "",
    descriptiveaddress: "",
  });

  const [pickupErrors, setPickupErrors] = useState({
    address: "",
    province: "",
    area: "",
    district: "",
    additionalphone: "",
    descriptiveaddress: "",
  });

  const [deliveryErrors, setDeliveryErrors] = useState({
    address: "",
    province: "",
    area: "",
    district: "",
    additionalphone: "",
    descriptiveaddress: "",
  });

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Validate each form separately
    const businessErrors = validateForm(businessFormData);
    const pickupErrors = validateForm(pickupFormData);
    const deliveryErrors = validateForm(deliveryFormData);

    // Check if any errors exist
    if (
      Object.values(businessErrors).some((error) => error !== "") ||
      (!pickupFormData.sameAsBusiness &&
        Object.values(pickupErrors).some((error) => error !== "")) ||
      (!deliveryFormData.sameAsBusiness &&
        Object.values(deliveryErrors).some((error) => error !== ""))
    ) {
      // Update errors state
      setBusinessErrors(businessErrors);
      setPickupErrors(pickupErrors);
      setDeliveryErrors(deliveryErrors);

      return;
    }

    let newPickUpData;
    let newdeliveryform;
    // Process the form data here
    if (pickupFormData.sameAsBusiness) {
      newPickUpData = { ...businessFormData };
    } else {
      newPickUpData = { ...pickupFormData };
      delete newPickUpData["sameAsBusiness"];
    }

    if (deliveryFormData.sameAsBusiness) {
      newdeliveryform = { ...businessFormData };
    } else {
      newdeliveryform = { ...deliveryFormData };
      delete newdeliveryform["sameAsBusiness"];
    }

    const formData = {
      address: businessFormData,
      pickupaddress: newPickUpData,
      deliveryaddress: newdeliveryform,
    };

    const url = process.env.REACT_APP_BASE_URL + "newseller/selleraddress/";
    const updateseller =  process.env.REACT_APP_BASE_URL + "newseller/updateaddressseller/";

    setIsLoading(true);

    try {
      const response = await axios.post(`${url}`, formData, {
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 201) {
        const responseData = response.data;
        const sellerAddressId = responseData.id;
        setAddressId(sellerAddressId);
        if (vendorToEdit && vendorToEdit.length > 0) {
          const updateVendor = async () => {
            const newformDatass = new FormData();
            newformDatass.append("selleraddress", sellerAddressId);
            newformDatass.append("seller", vendorToEdit[0].id);
            console.log("newformDatass", newformDatass);
        
            const responseupdate = await axios.post(`${updateseller}`, newformDatass, {
              headers: {
                "Content-Type": "application-json",
              },
            });
            if (responseupdate.status === 201) {
              toast.success("Profile updated successfully!");
              navigate('/admin/vendors-management/')
            }
          }
        
          updateVendor();
        }


        toast.success("Address added successfully!");

        // Move to the next step
        setCurrentStep((prevStep) => Math.min(prevStep + 1, 4));
      } else {
        // Handle errors here
        console.error("Error creating Seller Address");
      }
    } catch (error) {
      console.error("Error sending data:", error);
      // Handle error
      toast.error("Error adding address. Please try again.")
    } finally {
      setIsLoading(false);
    }
  };

  const handleBusinessInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "province") {
      setBusinessFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
      setIsBusinessDistrictDisabled(value === "");
    } else {
      setBusinessFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
      setBusinessErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));

      if (name === "district") {
        setIsBusinessAreaDisabled(value === "");
      }
    }
  };

  const handlePickupInputChange = (e) => {
    const { name, value, checked } = e.target;

    if (name === "province") {
      setPickupFormData((prevData) => ({
        ...prevData,
        [name]: value,
        sameAsBusiness: checked,
      }));
      setIsPickupDistrictDisabled(value === "");
    } else {
      // Handle district selection separately
      if (name === "district") {
        setPickupFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }

      // Clear errors if the checkbox is checked
      if (name === "sameAsBusinessPickup") {
        setPickupFormData((prevData) => ({
          ...prevData,
          sameAsBusiness: checked,
        }));
        if (checked) {
          setPickupFormData((prevData) => ({
            ...prevData,
            address: businessFormData.address,
            province: businessFormData.province,
            area: businessFormData.area,
            district: businessFormData.district,
            additionalphone: businessFormData.additionalphone,
            descriptiveaddress: businessFormData.descriptiveaddress,
          }));
        }
        setPickupErrors((prevErrors) => ({
          ...prevErrors,
          address: "",
          province: "",
          area: "",
          district: "",
          additionalphone: "",
          descriptiveaddress: "",
        }));
      }
    }
  };

  const handleDeliveryInputChange = (e) => {
    const { name, value, checked } = e.target;

    if (name === "province") {
      setDeliveryFormData((prevData) => ({
        ...prevData,
        [name]: value,
        sameAsBusiness: checked,
      }));
      setIsDeliveryDistrictDisabled(value === "");
    } else {
      // Handle district selection separately
      if (name === "district") {
        setDeliveryFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }

      // Clear errors if the checkbox is checked
      if (name === "sameAsBusinessPickup") {
        setDeliveryFormData((prevData) => ({
          ...prevData,
          sameAsBusiness: checked,
        }));
        if (checked) {
          setDeliveryFormData((prevData) => ({
            ...prevData,
            address: businessFormData.address,
            province: businessFormData.province,
            area: businessFormData.area,
            district: businessFormData.district,
            additionalphone: businessFormData.additionalphone,
            descriptiveaddress: businessFormData.descriptiveaddress,
          }));
        }
        setDeliveryErrors((prevErrors) => ({
          ...prevErrors,
          address: "",
          province: "",
          area: "",
          district: "",
          additionalphone: "",
          descriptiveaddress: "",
        }));
      }
    }
  };

  const isValidPhone = (phone) => {
    const phoneRegex = /^[0-9]{10}$/;
    return phoneRegex.test(phone);
  };

  const validateForm = (formData) => {
    const {
      address,
      province,
      area,
      district,
      additionalphone,
      descriptiveaddress,
    } = formData;

    const errors = {
      address: address ? "" : "Please enter the store address.",
      province: province ? "" : "Please select a province/state.",
      area: area ? "" : "Please select an area.",
      district: district ? "" : "Please select a district.",
      additionalphone: additionalphone
        ? isValidPhone(additionalphone)
          ? ""
          : "Please enter a valid phone number."
        : "Please enter phone number.",
      descriptiveaddress: descriptiveaddress
        ? ""
        : "Please enter the company's address description.",
    };

    return errors;
  };

  return (
    <div className="flex-1 bg-white py-4 flex flex-col gap-6">
      <ToastContainer position="top-center" autoClose={3000} />
      <div className="flex items-center justify-between px-8">
        <div className="flex items-center gap-2 text-blue-800 font-bold">
          <IoLocationSharp className="min-w-[20px] min-h-[20px]" />
          <p>Add Address</p>
        </div>

        {/* <button className="border border-gray-200 text-gray-500 rounded-full px-5 py-2 text-[14px] leading-none">
          Skip & Go to Dashboard
        </button> */}
      </div>
      <h1 className="px-8 text-[15px] font-bold text-gray-800">
        Business Address
      </h1>
      <form className="flex flex-col gap-16">
        <div className="flex flex-col gap-8">
          <div className="flex flex-col gap-4 px-8 lg:gap-6">
            <div className="flex flex-col gap-6 lg:grid lg:grid-cols-2">
              <div className="flex flex-col gap-1">
                <InputWithLabel
                  htmlFor="address"
                  label="Store Address"
                  type="text"
                  placeholder="Nepal"
                  name="address"
                  value={businessFormData.address}
                  onChange={handleBusinessInputChange}
                />

                {businessErrors.address && (
                  <p className="text-red-900 text-[14px] font-medium">
                    {businessErrors.address}
                  </p>
                )}
              </div>

              <div className="flex flex-col gap-1">
                <DropdownWithLabel
                  label="Select Province/State"
                  name="province"
                  placeholder="Bagmati"
                  options={provinceDistrictData.provinces.map(
                    (province) => province.province_name
                  )}
                  value={businessFormData.province}
                  onChange={(value) =>
                    handleBusinessInputChange({
                      target: { name: "province", value },
                    })
                  }
                />

                {businessErrors.province && (
                  <p className="text-red-900 text-[14px] font-medium">
                    {businessErrors.province}
                  </p>
                )}
              </div>
            </div>

            <div className="flex flex-col gap-6 lg:grid lg:grid-cols-3">
              <div className="flex flex-col gap-1">
                <div className="flex flex-col gap-1">
                  <DropdownWithLabel
                    label="District"
                    name="district"
                    placeholder="Kathmandu"
                    options={
                      businessFormData.province
                        ? provinceDistrictData.provinces
                            .find(
                              (province) =>
                                province.province_name ===
                                businessFormData.province
                            )
                            ?.districts.map(
                              (district) => district.district_name
                            ) || []
                        : []
                    }
                    value={businessFormData.district}
                    onChange={(value) =>
                      handleBusinessInputChange({
                        target: { name: "district", value },
                      })
                    }
                    isDisabled={isBusinessDistrictDisabled}
                  />

                  {businessErrors.district && (
                    <p className="text-red-900 text-[14px] font-medium">
                      {businessErrors.district}
                    </p>
                  )}
                </div>
              </div>

              <div className="flex flex-col gap-1">
                <InputWithLabel
                  htmlFor="area"
                  label="Area"
                  type="text"
                  placeholder="Kathmandu"
                  name="area"
                  value={businessFormData.area}
                  onChange={handleBusinessInputChange}
                />

                {businessErrors.area && (
                  <p className="text-red-900 text-[14px] font-medium">
                    {businessErrors.area}
                  </p>
                )}
              </div>

              <div className="flex flex-col gap-1">
                <PhoneInputWithLabel
                  label="WhatsApp Phone No."
                  name="additionalphone"
                  placeholder="9852545652"
                  value={businessFormData.additionalphone}
                  onChange={handleBusinessInputChange}
                />

                {businessErrors.additionalphone && (
                  <p className="text-red-900 text-[14px] font-medium">
                    {businessErrors.additionalphone}
                  </p>
                )}
              </div>
            </div>

            <div className="col-span-2 flex flex-col gap-1">
              <InputWithLabel
                htmlFor="descriptiveaddress"
                label="Company's Address Description"
                type="text"
                placeholder="full address including house floor For eg. House no. 2b, Tarkeswor old guheswori area Kathmandu"
                name="descriptiveaddress"
                isTextArea={true}
                rows={4}
                value={businessFormData.descriptiveaddress}
                onChange={handleBusinessInputChange}
              />

              {businessErrors.descriptiveaddress && (
                <p className="text-red-900 text-[14px] font-medium">
                  {businessErrors.descriptiveaddress}
                </p>
              )}
            </div>
          </div>

          <div className="relative bg-light-900 py-6 mx-8 flex flex-col gap-5">
            <div className="flex flex-col gap-8 px-5">
              <div className="flex">
                <div className="w-[250px] flex items-center gap-1.5">
                  <FaTruckPickup className="text-gray-500 min-w-[22px] min-h-[22px]" />
                  <p className="text-gray-800 uppercase font-bold text-[15px]">
                    Pickup Address
                  </p>
                </div>

                <div className="flex items-center gap-4">
                  <p className="text-gray-800 font-semibold text-[15px]">
                    Same as business address
                  </p>

                  <label className="relative inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      className="sr-only"
                      name="sameAsBusinessPickup"
                      checked={pickupFormData.sameAsBusiness}
                      onChange={handlePickupInputChange}
                    />
                    <div
                      className={`w-11 h-6 rounded-full relative ${
                        pickupFormData.sameAsBusiness
                          ? "bg-blue"
                          : "bg-gray-400"
                      }`}
                    >
                      <div
                        className={`absolute top-[2px] transform  w-5 h-5 bg-white border-gray-300 border rounded-full transition-all ${
                          !pickupFormData.sameAsBusiness
                            ? "left-0 translate-x-0"
                            : "left-full translate-x-[-100%]"
                        }`}
                      ></div>
                    </div>
                  </label>
                </div>
              </div>

              {!pickupFormData.sameAsBusiness && (
                <div className="flex flex-col gap-4 lg:gap-6">
                  <div className="flex flex-col gap-6 lg:grid lg:grid-cols-2">
                    <div className="flex flex-col gap-1">
                      <InputWithLabel
                        htmlFor="address"
                        label="Store Address"
                        type="text"
                        placeholder="Nepal"
                        name="address"
                        value={pickupFormData.address}
                        onChange={handlePickupInputChange}
                      />

                      {pickupErrors.address && (
                        <p className="text-red-900 text-[14px] font-medium">
                          {pickupErrors.address}
                        </p>
                      )}
                    </div>

                    <div className="flex flex-col gap-1">
                      <DropdownWithLabel
                        label="Select Province/State"
                        name="province"
                        placeholder="Bagmati"
                        options={provinceDistrictData.provinces.map(
                          (province) => province.province_name
                        )}
                        value={pickupFormData.province}
                        onChange={(value) =>
                          handlePickupInputChange({
                            target: { name: "province", value },
                          })
                        }
                      />

                      {pickupErrors.province && (
                        <p className="text-red-900 text-[14px] font-medium">
                          {pickupErrors.province}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="flex flex-col gap-6 lg:grid lg:grid-cols-3">
                    <div className="flex flex-col gap-1">
                      <DropdownWithLabel
                        label="District"
                        name="district"
                        placeholder="Kathmandu"
                        options={
                          pickupFormData.province
                            ? provinceDistrictData.provinces
                                .find(
                                  (province) =>
                                    province.province_name ===
                                    pickupFormData.province
                                )
                                ?.districts.map(
                                  (district) => district.district_name
                                ) || []
                            : []
                        }
                        value={pickupFormData.district}
                        onChange={(value) =>
                          handlePickupInputChange({
                            target: { name: "district", value },
                          })
                        }
                        isDisabled={isPickupDistrictDisabled}
                      />

                      {pickupErrors.district && (
                        <p className="text-red-900 text-[14px] font-medium">
                          {pickupErrors.district}
                        </p>
                      )}
                    </div>

                    <div className="flex flex-col gap-1">
                      <InputWithLabel
                        htmlFor="area"
                        label="Area"
                        type="text"
                        placeholder="Kathmandu"
                        name="area"
                        value={pickupFormData.area}
                        onChange={handlePickupInputChange}
                      />

                      {pickupErrors.area && (
                        <p className="text-red-900 text-[14px] font-medium">
                          {pickupErrors.area}
                        </p>
                      )}
                    </div>

                    <div className="flex flex-col gap-1">
                      <PhoneInputWithLabel
                        label="Additional Phone No."
                        name="additionalphone"
                        placeholder="9852545652"
                        value={pickupFormData.additionalphone}
                        onChange={handlePickupInputChange}
                      />

                      {pickupErrors.additionalphone && (
                        <p className="text-red-900 text-[14px] font-medium">
                          {pickupErrors.additionalphone}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-span-2 flex flex-col gap-1">
                    <InputWithLabel
                      htmlFor="descriptiveaddress"
                      label="Company's Address Description"
                      type="text"
                      placeholder="full address including house floor For eg. House no. 2b, Tarkeswor old guheswori area Kathmandu"
                      name="descriptiveaddress"
                      isTextArea={true}
                      rows={4}
                      value={pickupFormData.descriptiveaddress}
                      onChange={handlePickupInputChange}
                    />

                    {pickupErrors.descriptiveaddress && (
                      <p className="text-red-900 text-[14px] font-medium">
                        {pickupErrors.descriptiveaddress}
                      </p>
                    )}
                  </div>
                </div>
              )}
            </div>

            {!pickupFormData.sameAsBusiness && (
              <div className="bg-gray-200 h-[1px] w-full my-5"></div>
            )}

            <div className="relative flex flex-col gap-8 px-5">
              <div className="flex ">
                <div className="w-[250px] flex items-center gap-1.5">
                  <TbTruckDelivery className="text-gray-500 min-w-[22px] min-h-[22px]" />
                  <p className="text-gray-800 uppercase font-bold text-[15px]">
                    Delivery Address
                  </p>
                </div>

                <div className="flex items-center gap-4">
                  <p className="text-gray-800 font-semibold text-[15px]">
                    Same as business address
                  </p>

                  <label className="relative inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      className="sr-only"
                      name="sameAsBusinessPickup"
                      checked={deliveryFormData.sameAsBusiness}
                      onChange={handleDeliveryInputChange}
                    />
                    <div
                      className={`w-11 h-6 rounded-full relative ${
                        deliveryFormData.sameAsBusiness
                          ? "bg-blue"
                          : "bg-gray-400"
                      }`}
                    >
                      <div
                        className={`absolute top-[2px] transform  w-5 h-5 bg-white border-gray-300 border rounded-full transition-all ${
                          !deliveryFormData.sameAsBusiness
                            ? "left-0 translate-x-0"
                            : "left-full translate-x-[-100%]"
                        }`}
                      ></div>
                    </div>
                  </label>
                </div>
              </div>

              {!deliveryFormData.sameAsBusiness && (
                <div className="flex flex-col gap-4 lg:gap-6">
                  <div className="flex flex-col gap-6 lg:grid lg:grid-cols-2">
                    <div className="flex flex-col gap-1">
                      <InputWithLabel
                        htmlFor="address"
                        label="Store Address"
                        type="text"
                        placeholder="Nepal"
                        name="address"
                        value={deliveryFormData.address}
                        onChange={handleDeliveryInputChange}
                      />

                      {deliveryErrors.address && (
                        <p className="text-red-900 text-[14px] font-medium">
                          {deliveryErrors.address}
                        </p>
                      )}
                    </div>

                    <div className="flex flex-col gap-1">
                      <DropdownWithLabel
                        label="Select Province/State"
                        name="province"
                        placeholder="Bagmati"
                        options={provinceDistrictData.provinces.map(
                          (province) => province.province_name
                        )}
                        value={deliveryFormData.province}
                        onChange={(value) =>
                          handleDeliveryInputChange({
                            target: { name: "province", value },
                          })
                        }
                      />

                      {deliveryErrors.province && (
                        <p className="text-red-900 text-[14px] font-medium">
                          {deliveryErrors.province}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="flex flex-col gap-6 lg:grid lg:grid-cols-3">
                    <div className="flex flex-col gap-1">
                      <DropdownWithLabel
                        label="District"
                        name="district"
                        placeholder="Kathmandu"
                        options={
                          deliveryFormData.province
                            ? provinceDistrictData.provinces
                                .find(
                                  (province) =>
                                    province.province_name ===
                                    deliveryFormData.province
                                )
                                ?.districts.map(
                                  (district) => district.district_name
                                ) || []
                            : []
                        }
                        value={deliveryFormData.district}
                        onChange={(value) =>
                          handleDeliveryInputChange({
                            target: { name: "district", value },
                          })
                        }
                        isDisabled={isDeliveryDistrictDisabled}
                      />

                      {deliveryErrors.district && (
                        <p className="text-red-900 text-[14px] font-medium">
                          {deliveryErrors.district}
                        </p>
                      )}
                    </div>

                    <div className="flex flex-col gap-1">
                      <InputWithLabel
                        htmlFor="area"
                        label="Area"
                        type="text"
                        placeholder="Kathmandu"
                        name="area"
                        value={deliveryFormData.area}
                        onChange={handleDeliveryInputChange}
                      />

                      {deliveryErrors.area && (
                        <p className="text-red-900 text-[14px] font-medium">
                          {deliveryErrors.area}
                        </p>
                      )}
                    </div>

                    <div className="flex flex-col gap-1">
                      <PhoneInputWithLabel
                        label="Additional Phone No."
                        name="additionalphone"
                        placeholder="9852545652"
                        value={deliveryFormData.additionalphone}
                        onChange={handleDeliveryInputChange}
                      />

                      {deliveryErrors.additionalphone && (
                        <p className="text-red-900 text-[14px] font-medium">
                          {deliveryErrors.additionalphone}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-span-2 flex flex-col gap-1">
                    <InputWithLabel
                      htmlFor="descriptiveaddress"
                      label="Company's Address Description"
                      type="text"
                      placeholder="full address including house floor For eg. House no. 2b, Tarkeswor old guheswori area Kathmandu"
                      name="descriptiveaddress"
                      isTextArea={true}
                      rows={4}
                      value={deliveryFormData.descriptiveaddress}
                      onChange={handleDeliveryInputChange}
                    />

                    {deliveryErrors.descriptiveaddress && (
                      <p className="text-red-900 text-[14px] font-medium">
                        {deliveryErrors.descriptiveaddress}
                      </p>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="pt-6 px-6 flex flex-row justify-between border-t border-gray-200">
          <button
            type="submit"
            onClick={() =>
              setCurrentStep((prevStep) => Math.max(prevStep - 1, 1))
            }
            className="border border-gray-300 px-6 py-1 rounded-md text-[14px] text-gray-500 font-semibold"
          >
            Back
          </button>

          <button
            type="submit"
            onClick={handleFormSubmit}
            className="bg-blue px-6 py-1 rounded-md text-[14px] text-white font-semibold"
          >
            {isLoading ? (
              <div role="status" className="flex justify-center items-center">
                <svg
                  aria-hidden="true"
                  class="w-5 h-5 text-gray-300 animate-spin dark:text-gray-600 fill-white"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              "Next"
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddAddressForm;
