import { useAtom } from "jotai";
import React, { useEffect } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import Notification from "../../components/Admin/common/Notification";
import PurchaseOrder from "../../components/SellerPage/BusinessOrders/PurchaseOrder/PurchaseOrder";
import ViewOrder from "../../components/SellerPage/BusinessOrders/PurchaseOrder/ViewOrder";
import Quotation from "../../components/SellerPage/BusinessOrders/Quotation/Quotation";
import Credit from "../../components/SellerPage/Credit/CreditManage/Credit";
import Chat from "../../components/SellerPage/CustomerSupport/Chat";
import { Dashboard } from "../../components/SellerPage/Dashboard/Dashboard";
import OrderManagement from "../../components/SellerPage/OrderReviewManagement/OrderManagement/OrderManagement";
import ViewOrderFromOM from "../../components/SellerPage/OrderReviewManagement/OrderManagement/ViewOrderFromOM";
import ReviewManagement from "../../components/SellerPage/OrderReviewManagement/ReviewManagement/ReviewManagement";
import AddProduct from "../../components/SellerPage/ProductManagement/AddProduct/AddProduct";
import AddProductMain, {
  isProductSelectedAtom,
  isProductViewedAtom,
  selectedProductDataAtom,
  showCustomProductFormAtom,
} from "../../components/SellerPage/ProductManagement/AddProduct/AddProductMain";
import ViewProduct from "../../components/SellerPage/ProductManagement/AddProduct/ViewProduct";
import ImportProduct from "../../components/SellerPage/ProductManagement/ImportProduct/ImportProduct";
import ManageProduct, {
  isSellerProductViewedAtom,
  viewedSellerProductAtom,
} from "../../components/SellerPage/ProductManagement/ManageProduct/ManageProduct";
import ReviewProducts from "../../components/SellerPage/ProductManagement/ReviewProduct/ReviewProducts";
import Profile from "../../components/SellerPage/Profile&Setting/Profile/Profile";
import Setting from "../../components/SellerPage/Profile&Setting/Profile/Setting";
import InvoiceBill from "../../components/SellerPage/RequestSeller/InvoiceBill";
import ManageRequests from "../../components/SellerPage/RequestSeller/RequestManagement/ManageRequests";
import RequestSellerMain from "../../components/SellerPage/RequestSeller/RequestSellerMain";
import SellerDecoration from "../../components/SellerPage/SellerTools/SellerDecoration";
import FinalBill from "../../components/SellerPage/common/FinalBill";
import MainPageHeader from "../../components/SellerPage/common/MainPageHeader";
import Sidebar, {
  activeNavItemAtom,
  activeNavNameAtom,
  activeSubNavItemAtom,
  activeSubNavNameAtom,
} from "../../components/SellerPage/common/Sidebar";
import ViewSellerProduct from "../../components/SellerPage/common/ViewProduct";
import MyOrders from "../../components/SellerPage/BusinessOrders/PersonalOrder/MyOrders/MyOrders";

const SellerMainPage = () => {
  // const [activeNavName] = useAtom(activeNavNameAtom);
  // const [activeSubNavName] = useAtom(activeSubNavNameAtom);
  const [showCustomProductForm] = useAtom(showCustomProductFormAtom);
  // const [isImportSuccess] = useAtom(isImportSuccessAtom);
  const [isProductSelected] = useAtom(isProductSelectedAtom);
  const [isProductViewed] = useAtom(isProductViewedAtom);
  const [selectedProductData] = useAtom(selectedProductDataAtom);
  const [isSellerProductViewed] = useAtom(isSellerProductViewedAtom);
  const [viewedSellerProduct] = useAtom(viewedSellerProductAtom);
  const [, setActiveNavName] = useAtom(activeNavNameAtom);
  const [, setActiveNavItem] = useAtom(activeNavItemAtom);
  const [, setActiveSubNavName] = useAtom(activeSubNavNameAtom);
  const [, setActiveSubNavItem] = useAtom(activeSubNavItemAtom);

  useEffect(() => {
    // check url and set the state
    const url = window.location.href;
    console.log(url.includes("product-management"));

    if (url.includes("product-management")) {
      setActiveNavItem(1);
      setActiveNavName("Product Management");
      setActiveSubNavItem(null);
      setActiveSubNavName(null);
    } else if (url.includes("order-review")) {
      setActiveNavItem(2);
      setActiveNavName("Order & Review");
      setActiveSubNavItem(null);
      setActiveSubNavName(null);
    } else if (
      url.includes("request-seller") | url.includes("business-orders")
    ) {
      setActiveNavItem(3);
      setActiveNavName("Request Seller");
      setActiveSubNavItem(null);
      setActiveSubNavName(null);
    } else if (url.includes("profile")) {
      setActiveNavItem(4);
      setActiveNavName("Profile & Settings");
      setActiveSubNavItem(null);
      setActiveSubNavName(null);
    } else {
      setActiveNavItem(0);
      setActiveNavName("Dashboard");
      setActiveSubNavItem(null);
      setActiveSubNavName(null);
    }
  }, []);

  return (
    <div className="overflow-x-hidden bg-gray-200 w-full min-h-screen flex">
      <Sidebar />

      <div className="relative w-full overflow-x-hidden">
        <MainPageHeader />

        {/* {subComponent || activeComponent} */}

        <Routes>
          <Route path="/" element={<Outlet />}>
            <Route index element={<Dashboard />} />
          </Route>

          <Route path="product-management" element={<Outlet />}>
            <Route
              path="add"
              element={
                showCustomProductForm || isProductSelected ? (
                  <AddProduct />
                ) : isProductViewed ? (
                  <ViewProduct formData={selectedProductData} />
                ) : (
                  <AddProductMain />
                )
              }
            />
            <Route path="edit" element={<AddProduct />} />
            <Route
              path="manage"
              element={
                isSellerProductViewed ? (
                  <ViewSellerProduct formData={viewedSellerProduct} />
                ) : (
                  <ManageProduct />
                )
              }
            />
            <Route path="import" element={<ImportProduct />} />
            <Route path="review" element={<ReviewProducts />} />
          </Route>

          <Route path="order-review" element={<Outlet />}>
            <Route path="order-management" element={<OrderManagement />} />
            <Route
              path="order-management/view/:Order_id"
              element={<ViewOrderFromOM />}
            />
            <Route path="review-management" element={<ReviewManagement />} />
          </Route>

          <Route path="seller-tools" element={<Outlet />}>
            <Route path="seller-decoration" element={<SellerDecoration />} />
          </Route>

          <Route path="business-orders" element={<Outlet />}>
            <Route path="purchase-order" element={<Outlet />}>
              <Route index element={<PurchaseOrder />} />

              <Route path="view" element={<ViewOrder />} />
            </Route>

            <Route path="quotation" element={<Outlet />}>
              <Route index element={<Quotation />} />

              <Route
                path="view"
                element={<ViewSellerProduct formData={viewedSellerProduct} />}
              />
            </Route>
            <Route path="seller-order" element={<Outlet />}>
              <Route index element={<MyOrders />} />
            </Route>

          </Route>

          <Route path="customer-support" element={<Chat />} />
          <Route path="notification" element={<Notification />} />

          <Route path="credit" element={<Credit />} />

          <Route path="profile" element={<Outlet />}>
            <Route path="" element={<Profile />} />
            <Route path="setting" element={<Setting />} />
          </Route>

          <Route path="request-seller" element={<Outlet />}>
            <Route
              index
              element={
                isSellerProductViewed ? (
                  <ViewSellerProduct formData={viewedSellerProduct} />
                ) : (
                  <RequestSellerMain />
                )
              }
            />

            <Route path="bill" element={<InvoiceBill />} />
          </Route>

          <Route path="manage-requests" element={<Outlet />}>
            <Route index element={<ManageRequests />} />
          </Route>

          <Route path="final-bill" element={<Outlet />}>
            <Route index element={<FinalBill />} />
          </Route>

          <Route path="credit" element={<Outlet />}>
            <Route index element={<Credit />} />
          </Route>
        </Routes>
      </div>
    </div>
  );
};

export default SellerMainPage;
