import axios from "axios";
import React, { useEffect, useState } from "react";
import DictionaryCard from "../../common/DictionaryCard";

const Category = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    // Fetch categories from the API
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const url = process.env.REACT_APP_BASE_URL + "admin/get-partcategories/";
      const response = await axios.get(`${url}`);
      const data = response.data;
      setCategories(data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const IMAGE_URL = process.env.REACT_APP_BASE_URL + "uploads/";

  return (
    <div>
      <div className="bg-white rounded-md p-4 flex flex-col gap-6">
        <div className="grid grid-cols-4 gap-6">
          {categories.map((category) => (
            <DictionaryCard
              key={category}
              imageSrc={
                category.image && category.image !== null
  ? `${IMAGE_URL}${category.image}.jpg`
  : "https://via.placeholder.com/250"


              }
              name={category}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Category;
