import axios from "axios";
import React, { useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify"; // Import ToastContainer and toast from react-toastify
import "react-toastify/dist/ReactToastify.css"; // Import CSS for react-toastify
import InputWithLabel from "../common/InputWithLabel";
import PrimaryButton from "../common/PrimaryButton";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa6";
import { FcGoogle } from "react-icons/fc";

const LoginForm = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  const [error, setError] = useState("");

  const validateEmailOrPhone = (input) => {
    const emailRegex =
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    const phoneRegex = /^[0-9]{10}$/;

    if (emailRegex.test(input)) {
      return "email";
    } else if (phoneRegex.test(input)) {
      return "phone";
    } else {
      return "invalid";
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isLoading) return;

    const { username, password } = formData;

    if (!username || !password) {
      setError("Please fill in all fields.");
    } else {
      const validationType = validateEmailOrPhone(username);

      if (validationType === "invalid") {
        setError("Please enter a valid email or phone number.");
      } else {
        setError("");
        setIsLoading(true); // Set loading state to true

        const url = process.env.REACT_APP_BASE_URL + "auth/login/";
        // Perform form submission logic here
        try {
          const response = await axios.post(`${url}`, formData, {
            headers: {
              "Content-Type": "application/json",
            },
          });
          console.log("Data sent successfully:", response);
          console.log("Response is : ", response);
          const error = response.data.msg;
          console.log("Error is : ", error);
          const username = response.data.username;
          if (error === "User Login Successful") {
            const responseData = response.data;
            const token = responseData.token.access;

            console.log("Responded Token is : ", token);

            // Set token in local storage
            localStorage.setItem("usertoken", token);
            localStorage.setItem("user", username);
            localStorage.setItem("userId", response.data.id);

            if (response.data.id === 1) {
              localStorage.setItem("admintoken", "1");
              navigate("/admin/");
            } else {
              toast.success("Login successful!");
              navigate("/");
            }
          } else if (error === "Seller Login Successful") {
            const responseData = response.data;
            const token = responseData.token.access;
            // Set token in local storage
            localStorage.setItem("token", token);
            localStorage.setItem("sellertoken", token);
            localStorage.setItem("userId", response.data.id);
            toast.success("Seller Login successful!");
            navigate("/seller/profile");
          } else if (error === "Buyer Login Successful") {
            const responseData = response.data;
            const token = responseData.token.access;
            // Set token in local storage
            localStorage.setItem("token", token);
            localStorage.setItem("buyertoken", token);
            localStorage.setItem("userId", response.data.id);
            toast.success("Buyer Login successful!");
            navigate("/seller/profile");
          } else if (error === "Login Successful") {
            const responseData = response.data;
            const token = responseData.token.access;
            console.log("Responded Token is : ", responseData);
            // Set token in local storage
            localStorage.setItem("token", token);
            localStorage.setItem("bothtoken", token);
            localStorage.setItem("userId", response.data.id);
            toast.success("Login successful!");
            navigate("/seller/profile");
          } else if (error === "Agent Login Successful") {
            const responseData = response.data;
            const token = responseData.token.access;

            console.log("Responded Token is : ", token);
            localStorage.setItem("userId", response.data.id);

            // Set token in local storage
            localStorage.removeItem("token");
            localStorage.setItem("agenttoken", token);

            toast.success("Agent Login successful!");
            navigate("/seller/request-seller");
          } else if (error === "Admin Login Successful") {
            const responseData = response.data;
            const token = responseData.token.access;

            console.log("Responded Token is : ", token);

            // Set admin token in local storage
            localStorage.setItem("token", token);
            localStorage.setItem("admintoken", token);

            toast.success("Admin Login successful!");
            navigate("/admin");
          } else if (error === "Your account is Banned") {
            toast.error("Your account is Banned");
          } else {
            toast.error("Login failed!");
          }
        } catch (error) {
          // Show error notification
          toast.error("Login failed!");
        } finally {
          setIsLoading(false); // Reset loading state
        }
      }
    }
  };

  const openGoogleLoginPage = useCallback(() => {
    const googleAuthUrl = "https://accounts.google.com/o/oauth2/v2/auth";
    
    const scope = [
      "https://www.googleapis.com/auth/userinfo.email",
      "https://www.googleapis.com/auth/userinfo.profile",
    ].join(" ");

    const params = new URLSearchParams({
      response_type: "code",
      // client_id: REACT_APP_GOOGLE_CLIENT_ID,
      // redirect_uri: `${REACT_APP_GOGGLE_REDIRECT_URL_ENDPOINT}/google`,
      prompt: "select_account",
      access_type: "offline",
      scope,
    });

    const url = `${googleAuthUrl}?${params}`;

    window.location.href = url;
  }, []);


  return (
    <div className="flex flex-col items-center">
      <form
        action=""
        className="self-start w-full flex flex-col items-start gap-5 pb-5 lg:px-[10%]"
      >
        <InputWithLabel
          htmlFor="username"
          label="Email or phone number"
          type="text"
          placeholder="Lexialexandra@gmail.com"
          name="username"
          onChange={handleInputChange}
          value={formData.username}
        />

        <div className="relative w-full">
          <InputWithLabel
            htmlFor="password"
            label="Password"
            type="password"
            placeholder="Helloecommerce"
            name="password"
            onChange={handleInputChange}
            value={formData.password}
          />

          {/* <button className="absolute right-0 top-0 text-[14px] text-blue-800 font-medium">
            Forgot Password?
          </button> */}
        </div>
        {error && (
          <p className="text-red-900 text-[14px] font-medium">{error}</p>
        )}

        <div className="flex items-center gap-2">
          <input type="checkbox" name="" id="" className="w-3.5 h-3.5" />
          <label htmlFor="" className="text-gray-500 text-[14px]">
            Remember me ?
          </label>
        </div>

        <PrimaryButton
          onClick={handleSubmit}
          label="Sign in"
          isLoading={isLoading}
        />

        <div className="flex text-[14px] text-gray-900 items-center gap-2 self-center">
          <p>Doesn't have an account?</p>
          <Link to="/signup">
            <button className="font-semibold">Signup</button>
          </Link>
        </div>
      </form>

      
      {/* <button class="flex items-center justify-center bg-blue-900 text-white rounded-lg p-2 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-opacity-50"
      onClick={openGoogleLoginPage}>
    <span class="text-md mr-2">Continue with</span>
    <FcGoogle class="text-xl" />
</button> */}
      
      <div className="flex flex-col items-center gap-6 mt-10">
        <div>
          <span>Follow us on </span>
        </div>
        <div className="flex justify-between gap-6">
          <button>
            <FaFacebook className="text-gray-300 text-[25px]" />
          </button>

          <button>
            <FaTwitter className="text-gray-300 text-[25px]" />
          </button>

          <button>
            <FaInstagram className="text-gray-300 text-[25px]" />
          </button>
        </div>
      </div>

      <ToastContainer position="top-center" autoClose={3000} />
    </div>
  );
};

export default LoginForm;
