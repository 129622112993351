import { atom, useAtom } from "jotai";
import React, { useEffect } from "react";
import "../../../../styles/table.css";
import UnidentifiedTableRow from "./UnidentifiedTableRow";

export const rowsPerPageAtom = atom(10);
export const isProductEditModeAtom = atom(false);
export const productIdAtom = atom(null);
export const dataAtom = atom([]);
export const localSelectionAtom = atom([]);

const UnidentifiedTable = ({
  startIndex,
  endIndex,
  searchQuery,
  isAnyRowSelected,
  setIsAnyRowSelected,
  setSelectedRows,
}) => {
  const [data, setData] = useAtom(dataAtom);
  const [, setIsEditButtonClicked] = useAtom(isProductEditModeAtom);
  const [, setProductId] = useAtom(productIdAtom);
  const [localSelection, setLocalSelection] = useAtom(localSelectionAtom);

  const handleCheckboxChange = (index) => {
    const newLocalSelection = [...localSelection];
    newLocalSelection[index] = !newLocalSelection[index];

    const updatedSelectedRows = data.filter((_, i) => newLocalSelection[i]);
    setSelectedRows(updatedSelectedRows);
    setIsAnyRowSelected(updatedSelectedRows.length > 0);

    setLocalSelection(newLocalSelection);
  };

  const handleHeaderCheckboxChange = () => {
    const allSelected = !isAnyRowSelected;
    const newLocalSelection = Array(data.length).fill(allSelected);

    setSelectedRows(allSelected ? [...data] : []);
    setIsAnyRowSelected(allSelected);
    setLocalSelection(newLocalSelection);
  };

  useEffect(() => {
    async function fetchData() {
      const manageproducturl =
        process.env.REACT_APP_BASE_URL + "sellerdashboard/api/manageproduct/";
      try {
        const response = await fetch(`${manageproducturl}`);
        const data = await response.json();
        console.log(data);
        setData(data);
        setLocalSelection(Array(data.length).fill(false));
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, [setData]);

  const filteredData = data.filter((item) =>
    Object.values(item).some((value) => {
      if (value !== null && value !== undefined) {
        return value
          .toString()
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
      }
      return false;
    })
  );

  return (
    <div className="relative bg-white border-2 border-gray-400 overflow-x-auto whitespace-nowrap table-container">
      <table className="w-full text-white text-center border-spacing-4">
        <div className="w-full  border-gray-400 bg-gray-300 text-gray-900 text-[14px] font-bold px-5 py-3 uppercase sm:hidden">
          <h1>All Product Detail</h1>
        </div>

        <thead className="text-gray-900 border-y-2 border-gray-400 uppercase hidden sm:table-header-group">
          <tr>
            <th className="flex justify-center text-center items-center">
              <input
                type="checkbox"
                name=""
                id=""
                onChange={handleHeaderCheckboxChange}
              />
            </th>
            <th>Order No</th>
            <th>Product Name</th>
            <th>Part Number</th>
            <th>Part Categories</th>
            <th>Vehicle Company</th>
            <th>Brand</th>
            <th>Model</th>
            <th>Quantity</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>

        {filteredData.length !== 0 && (
          <tbody className="text-gray-700">
            {filteredData.slice(startIndex, endIndex).map((item, index) => (
              <UnidentifiedTableRow
                key={index}
                formData={item}
                onEditClick={() => {
                  console.log("Edit button clicked");
                  setIsEditButtonClicked(true);
                  setProductId(index);
                }}
                isSelected={localSelection[index]}
                onCheckboxChange={() => handleCheckboxChange(index)}
              />
            ))}
          </tbody>
        )}
      </table>

      {data.length === 0 && (
        <div className="w-full">
          <div className="border-b-2 border-gray-300 text-center h-[65px] flex items-center justify-center text-gray-700 relative hover:bg-gray-100">
            <p className="text-gray-700 font-semibold">No data available</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default UnidentifiedTable;
