import React from "react";

const TestimonialCard = ({  content, imageUrl, name, position }) => {
  return (
    <div className="bg-white p-5 xl:p-10 flex flex-col gap-4 mx-3 rounded-md shadow-lg">


      <p className="text-[15px] text-gray-500 leading-8">{content}</p>

      <div className="flex items-center gap-3">


        <div className="flex flex-col">
          <h1 className="font-bold">{name}</h1>
        </div>
      </div>
    </div>
  );
};

export default TestimonialCard;
