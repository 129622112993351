import axios from "axios";
import React, { useState } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { BiTrash } from "react-icons/bi";
import { HiMinus, HiPlus } from "react-icons/hi";

const ProductReviewTableRow = ({ formData, isSelected, onCheckboxChange }) => {
  const [isMobileTableRowOpen, setIsMobileTableRowOpen] = useState(false);



  return (
    <>
      <div className="border-b-2 border-gray-400 px-5 py-3 sm:hidden">
        <div className="flex items-center gap-4">
          <div
            className={`w-6 h-6 flex justify-center items-center ${
              isMobileTableRowOpen
                ? "bg-blue text-white rounded-[4px]"
                : "border-2 border-gray-400 text-gray-400 rounded-sm"
            }`}
          >
            <button
              onClick={(event) => {
                event.preventDefault();
                setIsMobileTableRowOpen(!isMobileTableRowOpen);
              }}
            >
              {isMobileTableRowOpen ? (
                <HiMinus className="w-5 h-5" />
              ) : (
                <HiPlus className="w-5 h-5" />
              )}
            </button>
          </div>

          <div>
            <h1 className="text-gray-900 font-bold text-[15px] uppercase">
              Product:
            </h1>
            <p className="text-gray-800 font-medium text-[15px]">
              {formData.productName}
            </p>
          </div>
        </div>

        {isMobileTableRowOpen && (
          <div className="flex flex-col gap-2 mt-4 ml-[2.4rem]">
            <div className="flex items-center gap-2">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                Order No:
              </h2>
              <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                {formData.orderNo}
              </p>
            </div>

            <div className="flex items-center gap-2">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                Order Date:
              </h2>
              <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                {formData.orderDate}
              </p>
            </div>

            <div className="flex items-center gap-2">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                Price:
              </h2>
              <p className="text-[15px] text-gray-600 font-medium md:flex-1 text-blue-800">
                Rs {formData.price}
              </p>
            </div>

            <div className="flex items-center gap-2">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                Payment Method:
              </h2>
              <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                {formData.paymentMethod}
              </p>
            </div>

            <div className="flex items-center gap-2">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                Status:
              </h2>
              <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                {formData.status}
              </p>
            </div>

            <div className="flex items-center gap-2 my-4">
              {/* <button className="px-1.5 border-2 border-gray-300 font-medium rounded-md h-[35px]">
                <BiEdit className="w-5 h-5 text-blue" />
              </button> */}

              <button
                className="px-1.5 border-2 border-gray-300 font-medium rounded-md h-[35px]"
                
              >
                <BiTrash className="w-5 h-5 text-red-900" />
              </button>

              <button className="px-1.5 border-2 border-gray-300 font-medium rounded-md h-[35px]">
                <AiOutlineEye className="w-[22px] h-[22px] text-gray-700" />
              </button>
            </div>
          </div>
        )}
      </div>

      <tr className="border-b-2 border-gray-300 text-gray-700 relative hidden sm:table-row hover:bg-gray-100">
      <td>
          <div className="flex items-center gap-2 lg:gap-3 pr-6">
          <img
  src={formData.productImage || 'https://path-to-your-default-image.com/default.png'}
  alt=""
  className="w-[90px] h-[50px] sm:w-[110px] sm:h-[70px] object-fill rounded-[4px]"
/>
            <div className="flex flex-col gap-2">
              <p className="font-medium">{formData.productName}</p>
            </div>
          </div>
        </td>

        <td className="text-center">{formData.rating}</td>
        <td className="text-center">{formData.review}</td>

      
      </tr>
    </>
  );
};

export default ProductReviewTableRow;
