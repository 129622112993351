import React, { useState } from "react";
import { MdAddCircleOutline } from "react-icons/md";

const DictionaryCard = ({ defaultImageSrc, name , handleCardClick }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [displayImageSrc, setDisplayImageSrc] = useState(defaultImageSrc);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);

      const reader = new FileReader();
      reader.onloadend = () => {  

        setDisplayImageSrc(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="bg-gray-100 relative shadow-md">
      <div className="relative px-1 pt-1.5 w-full flex items-center justify-center">
        <img src={displayImageSrc} alt="" className="w-full h-[200px] object-cover" />
        <label htmlFor="imageUpload" className="absolute top-0 right-0 flex items-center justify-center w-8 h-8 bg-white rounded-full shadow-md cursor-pointer">
          <MdAddCircleOutline className="text-blue-900 w-6 h-6" />
          <input type="file" id="imageUpload" className="hidden" onChange={() => handleImageChange} />
        </label>
      </div>

      <div className="relative flex flex-col gap-2 px-2 items-center pb-3 mt-[10px]"  onClick={() =>  handleCardClick && handleCardClick(name)}>
        <div className="flex flex-col items-center">
          <h1 className="text-[17px] font-bold">{name}</h1>
        </div>
      </div>
    </div>
  );
};

export default DictionaryCard;
