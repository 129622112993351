import { useAtom } from "jotai";
import React, { useState } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { BiEdit, BiTrash } from "react-icons/bi";
import { HiMinus, HiPlus } from "react-icons/hi";
import { MdOutlineLocalPrintshop } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { orderDataForViewAtom } from "../../../SellerPage/OrderReviewManagement/OrderManagement/Pending/PendingTableRow";
// import { isSellerProductViewedAtom, viewedSellerProductAtom } from "../ManageProduct";

const UnidentifiedTableRow = ({ formData, isSelected, onCheckboxChange }) => {
  const [isMobileTableRowOpen, setIsMobileTableRowOpen] = useState(false);
  const [, setOrderDataForView] = useAtom(orderDataForViewAtom);

  const navigate = useNavigate();

  // {
  //   id: 1,
  //   orderNo: 82,
  //   productName: 'Hitachi 255-3910 Ignition Coil Honda City ZX / Type 3',
  //   partNumber: '1212121',
  //   partCategories: 'Maruti',
  //   vehicleCompany: 'Chitrakala',
  //   brand: 'Maruti',
  //   model: 'M-1',
  //   quantity: 12,
  //   status: 'Pending'
  // }

  console.log("formData", formData);

  return (
    <>
      <div className="border-b-2 border-gray-400 px-5 py-3 sm:hidden">
        <div className="flex items-center gap-4">
          <div
            className={`w-6 h-6 flex justify-center items-center ${
              isMobileTableRowOpen
                ? "bg-blue text-white rounded-[4px]"
                : "border-2 border-gray-400 text-gray-400 rounded-sm"
            }`}
          >
            <button
              onClick={(event) => {
                event.preventDefault();
                setIsMobileTableRowOpen(!isMobileTableRowOpen);
              }}
            >
              {isMobileTableRowOpen ? (
                <HiMinus className="w-5 h-5" />
              ) : (
                <HiPlus className="w-5 h-5" />
              )}
            </button>
          </div>

          <div>
            <h1 className="text-gray-900 font-bold text-[15px] uppercase">
              Product Name
            </h1>
            <p className="text-gray-800 font-medium text-[15px]">
              {formData.productName}
            </p>
          </div>
        </div>

        {isMobileTableRowOpen && (
          <div className="flex flex-col gap-2 mt-4 ml-[2.4rem]">
            <div className="flex flex-row gap-2 items-center">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                Order No:
              </h2>
              <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                {formData.orderNo}
              </p>
            </div>

            <div className="flex flex-row gap-2 items-center">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                Order Date:
              </h2>
              <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                {formData.orderDate}
              </p>
            </div>

            <div className="flex flex-row gap-2 items-center">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                Price:
              </h2>
              <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                {formData.price}
              </p>
            </div>

            <div className="flex flex-row gap-2 items-center">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                Payment Method:
              </h2>
              <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                {formData.paymentMethod}
              </p>
            </div>

            <div className="flex flex-row gap-2 items-center">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                Status:
              </h2>
              <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                {formData.status}
              </p>
            </div>

            <div className="flex items-center gap-2 my-4">
              <button className="px-1.5 border-2 border-gray-300 font-medium rounded-md h-[35px]">
                <MdOutlineLocalPrintshop className="w-6 h-6 text-gray-400" />
              </button>

              <button className="px-1.5 border-2 border-gray-300 font-medium rounded-md h-[35px]">
                <BiEdit className="w-5 h-5 text-blue" />
              </button>

              <button className="px-1.5 border-2 border-gray-300 font-medium rounded-md h-[35px]">
                <BiTrash className="w-5 h-5 text-red-900" />
              </button>

              <button className="px-1.5 border-2 border-gray-300 font-medium rounded-md h-[35px]">
                <AiOutlineEye className="w-[22px] h-[22px] text-gray-700" />
              </button>
            </div>
          </div>
        )}
      </div>

      <tr className="border-b-2 border-gray-300 text-gray-700 relative hidden sm:table-row hover:bg-gray-100">
        <td className="max-w-[1.5rem]">
          <div className="flex justify-between items-center ml-1">
            <input
              type="checkbox"
              name=""
              id=""
              checked={isSelected}
              onChange={onCheckboxChange}
            />
          </div>
        </td>

        <td>{formData.orderNo}</td>

        <td>{formData.productName}</td>

        <td>{formData.partNumber}</td>

        <td>{formData.partCategories}</td>

        <td>{formData.vehicleCompany}</td>

        <td>{formData.brand}</td>

        <td>{formData.model}</td>

        <td>{formData.quantity}</td>

        <td>{formData.status}</td>

        <td>
          <div className="flex items-center justify-center gap-2">
            <button className="bg-[#e9fcf3] border border-[#06C270] text-[#047857] font-bold rounded-md px-5 h-[35px]">
              Accept
            </button>

            <button className="bg-[#fcf3f6] border border-[#FF3B3B] text-[#F10056] font-bold rounded-md px-5 h-[35px]">
              Decline
            </button>

            <button className="px-1.5 border-2 border-gray-300 font-medium rounded-md h-[35px]">
              <AiOutlineEye className="w-[22px] h-[22px] text-gray-700" />
            </button>
          </div>
        </td>
      </tr>
    </>
  );
};

export default UnidentifiedTableRow;
