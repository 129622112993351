import { atom, useAtom } from "jotai";
import React, { useEffect } from "react";
import "../../../../styles/table.css";
import ProcessingTableRow from "./ProcessingTableRow";

export const rowsPerPageAtom = atom(10);
export const isProductEditModeAtom = atom(false);
export const productIdAtom = atom(null);
export const dataAtom = atom([]);

const ProcessingTable = ({ startIndex, endIndex, searchQuery }) => {
  const [data, setData] = useAtom(dataAtom);
  const [, setIsEditButtonClicked] = useAtom(isProductEditModeAtom);
  const [, setProductId] = useAtom(productIdAtom);



  return (
    <div className="relative bg-white border-2 border-gray-400 overflow-x-auto whitespace-nowrap table-container">
      <table className="w-full text-white text-start border-spacing-4">
        <div className="w-full  border-gray-400 bg-gray-300 text-gray-900 text-[14px] font-bold px-5 py-3 uppercase sm:hidden">
          <h1>All Product Detail</h1>
        </div>

        <thead className="text-gray-900 border-y-2 border-gray-400 uppercase hidden sm:table-header-group">
          <tr>
            <th className="text-center">Order Id</th>
            <th className="text-center">Order Date</th>
            <th className="text-center">Part Number</th>
            <th className="text-center">Quantity</th>
            <th className="text-center">Total Price</th>
            <th className="text-center">Status</th>
          </tr>
        </thead>

        <tbody className="text-gray-700">
          {data
            .filter((item) =>
              Object.values(item).some((value) =>
                value
                  .toString()
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase())
              )
            )
            .slice(startIndex, endIndex)
            .map((item, index) => (
              <ProcessingTableRow
                key={index}
                formData={item}
                onEditClick={() => {
                  setIsEditButtonClicked(true);
                  setProductId(index);
                }}
                onDeleteClick={() => {
                  // Delete product from the database with productId
                }}
              />
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProcessingTable;
