import React, { useState } from "react";
import { HiChevronDown, HiOutlineSearch } from "react-icons/hi";
import Unidentified from "./Unidentified Quotations/Unidentified";
import Processing from "./Processing Quotations/Processing";
import Cancelled from "./Cancelled Quotations/Cancelled";
import Completed from "./Completed Orders/Completed";
import Ongoing from "./Ongoing Orders/Ongoing";


const Quotation = () => {
  const [quotationStatus, setQuotationStatus] = useState("Unidentified Products");
  const [searchQuery, setSearchQuery] = useState("");
  const [showMobileDropdown, setShowMobileDropdown] = useState(false);
  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Function to handle selecting an option in the mobile dropdown
  const handleMobileOptionSelect = (option) => {
    setQuotationStatus(option);
    setShowMobileDropdown(false);
  };

  return (
    <div className={`px-8 py-6 flex flex-col gap-4`}>
      <div className="flex items-stretch justify-between lg:items-center">
        <div className="flex items-center gap-4 xl:gap-6">
          {/* Mobile dropdown for product status */}
          <div className="xl:hidden relative">
            <button
              className="flex items-center gap-1.5 text-blue font-bold text-[16px] bg-white border-2 border-gray-300 rounded-md px-2 py-2"
              onClick={() => setShowMobileDropdown(!showMobileDropdown)}
            >
              <p>{quotationStatus}</p>
              <HiChevronDown className="text-[20px]" />
            </button>

            {showMobileDropdown && (
              <div className="absolute z-30 mt-2 left-2 w-48 bg-white border border-gray-300 rounded-md">
                {["Unidentified Products", "Processing Quotations", "Cancelled Quotations", "Completed Orders"].map(
                  (option) => (
                    <button
                      key={option}
                      className={`w-full ${
                        quotationStatus === option ? "bg-gray-100" : ""
                      } cursor-pointer p-1.5`}
                      onClick={(e) => {
                        handleMobileOptionSelect(option);
                        e.stopPropagation();
                      }}
                    >
                      {option}
                    </button>
                  )
                )}
              </div>
            )}
          </div>

          {/* Desktop buttons for product status */}
          <div className="hidden xl:flex">
            <div className="flex items-center gap-6 font-medium text-[14px] text-gray-500">
              <button
                className={`${
                  quotationStatus === "Unidentified Products"
                    ? "text-blue-900 font-bold border-b-2 pb-0.5 border-blue-900"
                    : ""
                }`}
                onClick={() => setQuotationStatus("Unidentified Products")}
              >
                Unidentified Products
              </button>

              <button
                className={`${
                  quotationStatus === "Processing Quotations"
                    ? "text-blue-900 font-bold border-b-2 pb-0.5 border-blue-900"
                    : ""
                }`}
                onClick={() => setQuotationStatus("Processing Quotations")}
              >
                Processing Quotations
              </button>

              <button
                className={`${
                  quotationStatus === "Cancelled Quotations"
                    ? "text-blue-900 font-bold border-b-2 pb-0.5 border-blue-900"
                    : ""
                }`}
                onClick={() => setQuotationStatus("Cancelled Quotations")}
              >
                Cancelled Quotations
              </button>

              <button
                className={`${
                  quotationStatus === "Completed Orders"
                    ? "text-blue-900 font-bold border-b-2 pb-0.5 border-blue-900"
                    : ""
                }`}
                onClick={() => setQuotationStatus("Completed Orders")}
              >
                Completed Orders
              </button>

              <button
                className={`${
                  quotationStatus === "Ongoing Orders"
                    ? "text-blue-900 font-bold border-b-2 pb-0.5 border-blue-900"
                    : ""
                }`}
                onClick={() => setQuotationStatus("Ongoing Orders")}
              >
                Ongoing Orders
              </button>
            </div>
          </div>
        </div>

        <div className="flex gap-4">
          <div className="px-4 py-2 flex items-center gap-3 bg-white border border-gray-300 rounded-md cursor-pointer md:cursor-pointer">
            <HiOutlineSearch className="text-gray-500 text-[20px]" />

            <input
              type="text"
              placeholder="Search"
              className="hidden bg-white outline-none font-medium text-[14px] placeholder:text-[13px] w-[120px] placeholder:text-gray-400 md:block"
              value={searchQuery}
              onChange={handleSearchInputChange}
            />
          </div>
        </div>
      </div>

      {quotationStatus === "Unidentified Products" ? (
        <Unidentified searchQuery={searchQuery} />
      )  : quotationStatus === "Processing Quotations" ? (
          <Processing Quotations searchQuery={searchQuery} />
        ) : quotationStatus === "Cancelled Quotations" ? (
          <Cancelled Quotations searchQuery={searchQuery} />
        ) : quotationStatus === "Completed Orders" ? (
          <Completed Orders searchQuery={searchQuery} />
        )  : quotationStatus === "Ongoing Orders" ? (
          <Ongoing Orders searchQuery={searchQuery} />
        ) : null}
    </div>
  );
};

export default Quotation;
