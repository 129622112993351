import { useAtom } from "jotai";
import React, { useEffect } from "react";
import { orderDataForViewAtom } from "./Pending/PendingTableRow";
import { useState } from "react";

const ViewOrderFromOM = () => {
  const [orderDataForView] = useAtom(orderDataForViewAtom);

  console.log(orderDataForView);
  
  const [price,setPrice] = useState(0)
  useEffect(() => {
setPrice(orderDataForView.price)
  }, [orderDataForView.price]);
  return (
    <div className="mx-auto m-6 flex flex-col gap-4">
      <button
        className="bg-blue w-fit mx-6 px-4 py-1.5 rounded-md text-white font-medium"
        onClick={(event) => {
          event.preventDefault();
          window.history.back();
        }}
      >
        Back
      </button>

      <div className="bg-white mx-6 rounded-lg shadow-lg px-8 py-10">
        <div className="flex flex-col">
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
              <div className="overflow-hidden">
                <table className="min-w-full border text-center text-sm font-light">
                  <thead className="border-b font-bold text-lg">
                    <tr>
                      <th scope="col" className="border-r h-[34px] px-4 py-0">
                        SN
                      </th>
                      <th scope="col" className="border-r h-[34px] px-4 py-0">
                        Product Name
                      </th>
                      <th scope="col" className="border-r h-[34px] px-4 py-0">
                        Quantity
                      </th>
                      <th scope="col" className="border-r h-[34px] px-4 py-0">
                        Price
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {orderDataForView.products.map((product, index) => (
                      <tr className="border-b" key={index}>
                        <td className="whitespace-nowrap border-r h-[34px] px-4 py-0 font-medium">
                          {index + 1}
                        </td>
                        <td className="whitespace-nowrap border-r h-[34px] px-4 py-0">
                          {product.product_name}
                        </td>
                        <td className="whitespace-nowrap border-r h-[34px] px-4 py-0">
                          {product.quantity}
                        </td>

                        <td className="whitespace-nowrap border-r h-[34px] px-4 py-0">
                          {price}
                        </td>
                      </tr>
                    ))}

                    <tr className="border-b">
                      <td
                        className="whitespace-nowrap border-r h-[34px] px-4 py-0 text-start font-bold"
                        colSpan={3}
                      >
                        Total Price:{" "}
                        <span className="">{orderDataForView.total_price}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewOrderFromOM;
