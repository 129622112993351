import React, { useEffect, useRef, useState } from "react";

const DropdownWithLabel = ({ label, options, placeholder, value, onChange, isDisabled }) => {
  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef(null);

  const handleOptionClick = (option) => {
    onChange(option); // Notify parent component about the change
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    if (!isDisabled) {
      setIsOpen(!isOpen);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="flex flex-col items-start gap-1.5 relative">
      <label className="text-[14px] uppercase text-gray-800 font-bold">
        {label}
      </label>

      <div
        ref={dropdownRef}
        className={`relative bg-white w-full outline-none border border-gray-400 rounded-lg px-3 py-2 text-[14px] font-medium ${isDisabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}`}
        onClick={toggleDropdown}
      >
        {value === "" ? (
          <p className="text-gray-300">{placeholder}</p>
        ) : (
          value
        )}

        <div className="absolute right-2 top-2.5">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
          >
            <path
              d="M5.88281 10.6875L8.88281 13.6875L11.8828 10.6875"
              stroke="#9D9CAF"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.88281 5.6875L8.88281 2.6875L11.8828 5.6875"
              stroke="#9D9CAF"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>

        <div
          className={`absolute z-20 top-full left-0 ${
            isOpen ? "block" : "hidden"
          } w-full border border-gray-400 rounded-b-lg mt-1 bg-white`}
        >
          {options.map((option, index) => (
            <div
              key={index}
              className={`px-2 py-1 ${isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'} hover:bg-gray-200`}
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DropdownWithLabel;
