import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Slider from "react-slick";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import DigiLogo from "../../../assets/svg/digi.svg";
import { atom, useAtom } from "jotai";
import PopupYesNo from "../common/PopupYesNo";

export const buynowValue = atom(false);
export const indivisualprice = atom(0);
export const indproductDetails = atom(0);

const PartDetailsTop = () => {
  const [buynow, setBuynow] = useAtom(buynowValue);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [productDetails, setProductDetails] = useState({
    id: "", // Add ID to the state
    productImages: [],
    selectedImage: "",
    brand: "",
    title: "",
    guarantee: "",
    details: [],
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const images = [];

  const { "*": searchInput } = useParams();
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const imageurl = process.env.REACT_APP_BASE;

  if (!apiUrl) {
    console.error(
      "REACT_APP_API_URL is not defined in the environment variables."
    );
  }

  const productUrl = apiUrl
    ? `${apiUrl}users/searchedpart/${searchInput}`
    : null;
  const {
    id,
    productImages,
    selectedImage,
    brand,
    title,
    details,
  } = productDetails || {};

  useEffect(() => {
    const token = localStorage.getItem("usertoken");

    if (token) {
      setIsLoggedIn(true);
    }

    if (productUrl) {
      fetch(productUrl)
        .then((res) => res.json())
        .then((response) => {
          const data = response.data;
          if (Array.isArray(data)) {
            const flattenedData = data.reduce(
              (acc, productsArray) => acc.concat(productsArray),
              []
            );
            console.log("Flattened data:", flattenedData);
            const part = flattenedData[0];
            const productImagesArray = Array.isArray(part.image) ? part.image : [part.image];
            console.log("Part Data:", part);

            const transformedAttributes = Array.isArray(
                part.attributes
              )
                ? part.attributes.map((attribute) => ({
                    label: attribute.attributeName,
                    value: attribute.attributeValue,
                  }))
                : [];

            const partNumberString = Array.isArray(part.partnumber)
              ? part.partnumber
                  .map((part) => part.partNumber)
                  .join(", ")
              : "";
            const details = [
              { label: "Part Number", value: partNumberString },
              { label: "Brand", value: part.brand},
              { label: "Model", value: part.model },
              {
                label: "ManufactureYear",
                value: part.manufactureYear,
              },
              { label: "Category", value: part.partCategories },
              ...transformedAttributes,
            ];

            console.log("Part Details:", details);

            setProductDetails(() => ({
              id: part.id, // Capture the ID here
              productImages: part.image,
              selectedImage: part.image,
              brand: part.brand,
              title: part.partName,
              details: details,
            }));

            setProductDetails((prevState) => ({
                ...prevState,
                productImages: [...productImagesArray],
            }));

            console.log("Transformed data:", productDetails);
          } else {
            console.error("API response is not an array:", data);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [searchInput, productUrl]);

  useEffect(() => {
    console.log("productDetails", productDetails);
  }, [productDetails]);

  const handleSelectImage = (image) => {
    setProductDetails((prevState) => ({ ...prevState, selectedImage: image }));
  };

  console.log("productDetails", productDetails);

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 3,
    nextArrow: (
      <div>
        <button className="relative -top-6 bg-gray-200 rounded-full w-8 h-8 flex items-center justify-center text-gray-700 text-2xl focus:outline-none">
          <span className="sr-only">Next</span>
          <span className="font-bold">{" > "}</span>
        </button>
      </div>
    ),
    prevArrow: (
      <div>
        <button className="relative -top-6 bg-gray-200 rounded-full w-8 h-8 flex items-center justify-center text-gray-700 text-2xl focus:outline-none">
          <span className="sr-only">Previous</span>
          <span className="font-bold">{" < "}</span>
        </button>
      </div>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          arrows: false,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 3,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          arrows: false,
        },
      },
    ],
  };

  const navigate = useNavigate();

  useEffect(() => {
    const partNumberObject = details.find(detail => detail.label === "Part Number");
    const partNumber = partNumberObject ? partNumberObject.value : null;

    const productimageurl = process.env.REACT_APP_BASE_URL + `users/getpartimage/${partNumber}`;
    if (productimageurl){
      fetch(productimageurl)
      .then((res) => res.json())
      .then((response) => {
          const data = response;
          console.log(data);
          data.map((img) =>
              setProductDetails(prevState => ({
                  ...prevState,
                  productImages: [...prevState.productImages, img]
              }))
          );
          setProductDetails((prevState) => ({
              ...prevState,
              selectedImage: prevState.productImages[2]
          }));
      })
      .catch((error) => {
          console.error("Error fetching images:", error);
      });
    }
  }, [details,setProductDetails]);


  const handleAskForRateClick = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleModalConfirm = async () => {
    setIsModalOpen(false);
    // Make your API call here

    const token = localStorage.getItem("usertoken");
    const username = localStorage.getItem("user");

    if (!token) {
      console.log("Token not found")
      // Handle the case where the token is not present (e.g., redirect to login page)
      toast.error("Authentication credentials not provided. Please log in.");
      return;
    }

    // Check if the authentication token has expired (example for JWT, may vary based on implementation)
    const isTokenExpired = () => {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000; // Convert to seconds
        console.log("decodedToken", decodedToken.exp);
        console.log("currentTime", currentTime);
        console.log("isTokenExpired", decodedToken.exp < currentTime);
        return decodedToken.exp < currentTime;
      } catch (error) {
        console.log("Error decoding token:", error);
        return true; // Error decoding token or invalid token, treat as expired
      }
    };

    if (isTokenExpired()) {
      console.log("Token expired")
      // Handle token expiration (e.g., refresh the token or redirect to login page)
      toast.error("Authentication token has expired. Please log in again.");
      navigate("/login");
      return;
    }


    const url = `${apiUrl}users/askrate/`; // Replace with your actual endpoint
    try {
      let formData = new FormData();  
      formData.append('partId', id);

      const response = await axios.post(url, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status == 201) {
        // Handle the response as needed
        toast.success("Rate request successful!");
      } else {
        toast.error("Failed to request rate.");
      }
    } catch (error) {
      toast.error("Failed to request rate.");
    }
  };

  return (
    <div className="pt-5 md:pt-10 flex flex-col gap-2 md:shadow-lg">
      <div className="p-4 bg-white">
        <div className="flex gap-2 md:gap-4 flex-col md:flex-row">
          <div className="relative md:w-[60%] flex flex-col gap-6">
            <div className="border-2 border-gray-300">
              <img
                src={selectedImage ? `${imageurl}${selectedImage}` : "https://th.bing.com/th/id/R.f145fbfe280056043be63570724ac0c4?rik=E7e2uVHaKcWmdw&pid=ImgRaw&r=0"}
                alt="Product Image"
                className="w-full object-cover max-h-[370px]"
              />
            </div>

            {productImages.length > 1 && (
              <div className="mx-2 md:mx-6">
                <Slider {...sliderSettings}>
                  {productImages.map((image, index) => (
                    <div 
                      key={index}
                      onClick={() => handleSelectImage(image)}
                      className="px-1"
                    >
                      <img
                        src={`${imageurl}${image}`}
                        alt="https://th.bing.com/th/id/OIP.uEliYOB4Vy1H-AOZKbeGXwAAAA?rs=1&pid=ImgDetMain"
                        className="w-200px h-32 object-cover cursor-pointer border-2 border-gray-300 "
                      />
                    </div>
                  ))}
                </Slider>
              </div>
            )}
          </div>

          <div className="md:w-[40%] ">
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-2">
                <p className="text-yellow uppercase text-[15px] font-medium">
                  {brand}
                </p>

                <h1 className="font-bold text-2xl">{title}</h1>

              </div>

              <div className="w-full bg-gray-300 h-[2px]"></div>

              <div>
                <ul className="flex flex-col gap-3">
                  {details.map((detail, index) => (
                    <li
                      key={index}
                      className="font-medium text-gray-700 grid grid-cols-2 gap-2"
                    >
                      {detail.label}
                      {detail.label === "Part Number" && !isLoggedIn ? (
                        <span className="font-bold text-gray-800 text-[15px]">
                          ******
                        </span>
                      ) : (
                      <span className="font-bold text-gray-800 text-[15px]">
                        {detail.value}
                      </span>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="flex flex-col gap-4 mt-4">
              <button
                className="w-full bg-yellow py-2.5 rounded-[4px] text-white font-bold px-4"
                onClick={handleAskForRateClick} // Add onClick handler here
              >
                Ask for Rate
              </button>
            </div>
          </div>
        </div>

        <div className="bg-[#d5e4fe9c] w-full flex flex-col md:flex-row mt-6">

          <div className="md:flex-1 flex flex-col md:flex-row md:items-center justify-between md:gap-4 px-4 md:px-8 py-2.5 md:py-5 border-b-2 md:border-r-2 border-[#D5E4FE]">
            <div className="flex items-center gap-2 py-2.5 md:py-0">
              <img src={DigiLogo} alt="" className="max-w-[50px]" />
              <div>
                <h1 className="font-bold text-gray-800 text-[15px]">
                  100% Authentic from trusted brand
                </h1>
                <p className="font-medium text-gray-500 text-sm">
                  People's choice
                </p>
              </div>
            </div>

            <div className="flex items-center gap-2 py-2.5 md:py-0">
              <img src={DigiLogo} alt="" className="max-w-[50px]" />
              <div>
                <h1 className="font-bold text-gray-800 text-[15px]">
                   Support 24/7
                </h1>
                <p className="font-medium text-gray-500 text-sm">
                  call us anytime for our service 
                </p>
              </div>
            </div>

            <div className="flex items-center gap-2 py-2.5 md:py-0">
              <img src={DigiLogo} alt="" className="max-w-[50px]" />
              <div>
                <h1 className="font-bold text-gray-800 text-[15px]">
                  10 Days Assured Return
                </h1>
                <p className="font-medium text-gray-500 text-sm">
                  Return policy
                </p>
              </div>
            </div>

            <div className="flex items-center gap-2 py-2.5 md:py-0">
              <img src={DigiLogo} alt="" className="max-w-[50px]" />
              <div>
                <h1 className="font-bold text-gray-800 text-[15px]">
                  GST Invoice available
                </h1>
                <p className="font-medium text-gray-500 text-sm">
                  Osis Verified
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <PopupYesNo 
        isOpen={isModalOpen} 
        onClose={handleModalClose} 
        onConfirm={handleModalConfirm} 
      />
    </div>
  );
};

export default PartDetailsTop;
