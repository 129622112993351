import axios from "axios";
import { useAtom } from "jotai";
import { jwtDecode } from "jwt-decode";
import React, { useEffect, useRef, useState } from "react";
import { HiChevronDown, HiChevronLeft, HiChevronRight } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify"; // Import ToastContainer and toast from react-toastify
import "react-toastify/dist/ReactToastify.css"; // Import CSS for react-toastify
import DeletedProductsTable, {
  dataAtom,
  rowsPerPageAtom,
} from "./DeletedProductsTable";

const DeletedProducts = ({
  searchQuery,
  isAnyRowSelected,
  setIsAnyRowSelected,
  selectedRows,
  setSelectedRows,
}) => {
  const [rowsPerPage, setRowsPerPage] = useAtom(rowsPerPageAtom);
  const [data, setData] = useAtom(dataAtom);
  const dataLength = data.length;

  const optionsRef = useRef(null);

  const navigate = useNavigate();

  // State for managing the visibility of the rows per page options
  const [showRowsPerPageOptions, setShowRowsPerPageOptions] = useState(false);

  // Array of options for rows per page
  const rowsPerPageOptions = [10, 12, 14, 16, 18, 20];

  const fetchData = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        // Handle the case where the token is not present (e.g., redirect to login page)
        toast.error("Authentication credentials not provided. Please log in.");
        return;
      }

      // Check if the authentication token has expired (example for JWT, may vary based on implementation)
      const isTokenExpired = () => {
        try {
          const decodedToken = jwtDecode(token);
          const currentTime = Date.now() / 1000; // Convert to seconds
          console.log("decodedToken", decodedToken.exp);
          console.log("currentTime", currentTime);
          console.log("isTokenExpired", decodedToken.exp < currentTime);
          return decodedToken.exp < currentTime;
        } catch (error) {
          return true; // Error decoding token or invalid token, treat as expired
        }
      };

      if (isTokenExpired()) {
        // Handle token expiration (e.g., refresh the token or redirect to login page)
        toast.error("Authentication token has expired. Please log in again.");
        navigate("/login");
        return;
      }

      const url =
        process.env.REACT_APP_BASE_URL + "sellerdashboard/deletedproduct/";
      const response = await axios.get(`${url}`, {
        headers: {
          Authorization: `Bearer ${token}`, // Add the authorization token to the request header
        },
      });
      const apiData = response.data;

      console.log(apiData);

      // Transform the API data into the desired format
      const transformedData = apiData.map((item, index) => {
        return {
          productId: item.id,
          status: item.status,
          vehicleCompany: item.part_id.vehicleCompany,
          subCategory: item.part_id.subCategory,
          availability: item.part_id.availability,
          brand: item.part_id.brand,
          model: item.part_id.model,
          manufactureYear: parseInt(item.part_id.manufactureYear),
          partCategories: item.part_id.partCategories,
          partSubCategories: item.part_id.partSubCategories,
          partName: item.part_id.partName,
          partNumber: Array.isArray(item.partnumbers)
            ? item.partnumbers.map((part) => part.partNumber).join(", ")
            : item.partNumber,
          description: item.part_id.description,
          coverPhoto: item.part_id.image ? item.part_id.image : null,
          stockQuantity: item.stockQuantity ? item.stockQuantity : null,
          normalRate: item.normalRate ? item.normalRate : null,
          bulkRate: item.bulkRate ? item.bulkRate : null,
          
          units: item.units ? item.units : null,
          otherAttributes: item.attributes.map((attr) => ({
            attributeName: attr.attributeName,
            attributeValue: attr.attributeValue,
          })),
          otherPhotos: item.images.map((images) => images.image),
          tags: item.tags.map((tag) => tag.tagname),
        };
      });

      console.log(transformedData);

      setData(transformedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    // Event listener to close the popup when clicking outside of it
    const handleOutsideClick = (event) => {
      if (
        showRowsPerPageOptions &&
        optionsRef.current &&
        !optionsRef.current.contains(event.target)
      ) {
        setShowRowsPerPageOptions(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    // Cleanup the event listener when the component is unmounted
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showRowsPerPageOptions]);

  // State for managing the current page
  const [currentPage, setCurrentPage] = useState(1);

  // Number of items to display per page
  const itemsPerPage = rowsPerPage;

  // Calculate the total number of pages
  const totalPages = Math.ceil(dataLength / itemsPerPage);

  // Function to handle the click on the left arrow
  const handleLeftArrowClick = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  // Function to handle the click on the right arrow
  const handleRightArrowClick = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  // Calculate the range of items to display on the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  return (
    <>
      <DeletedProductsTable
        startIndex={startIndex}
        endIndex={endIndex}
        searchQuery={searchQuery}
        fetchData={fetchData}
        isAnyRowSelected={isAnyRowSelected}
        setIsAnyRowSelected={setIsAnyRowSelected}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
      />

      <div className="flex items-center justify-center text-gray-500 sm:justify-between">
        <div className="hidden sm:block">
          <p className="font-medium text-[15px]">
            1-
            <span>
              {rowsPerPage > dataLength ? dataLength : rowsPerPage}
            </span>{" "}
            of <span>{dataLength}</span>
          </p>
        </div>

        <div className="text-[15px] font-medium flex items-center gap-6">
          <div className="items-center gap-2 hidden sm:flex">
            <p>Rows per page:</p>

            <div
              className="relative flex items-center gap-1 cursor-pointer"
              onClick={() => setShowRowsPerPageOptions(!showRowsPerPageOptions)}
            >
              <p>{rowsPerPage}</p>
              <HiChevronDown className="text-[20px]" />

              {showRowsPerPageOptions && (
                <div
                  ref={optionsRef}
                  className="absolute right-6 bg-white border border-gray-300 mt-2 p-2 rounded-md"
                >
                  {rowsPerPageOptions.map((option) => (
                    <div
                      key={option}
                      className="cursor-pointer hover:bg-gray-200 p-1"
                      onClick={() => {
                        setRowsPerPage(option);
                        setShowRowsPerPageOptions(false);
                      }}
                    >
                      {option}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className="flex items-center gap-3">
            <button
              className="border-2 border-gray-300 text-gray-700 disabled:text-[#A1A9B8] rounded-xl p-1"
              onClick={handleLeftArrowClick}
              disabled={currentPage === 1}
            >
              <HiChevronLeft className="text-[24px]" />
            </button>

            <div>
              {currentPage}/{totalPages}
            </div>

            <button
              className="border-2 border-gray-300 text-gray-700 disabled:text-[#A1A9B8] rounded-xl p-1"
              onClick={handleRightArrowClick}
              disabled={currentPage === totalPages}
            >
              <HiChevronRight className="text-[24px]" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeletedProducts;
