import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify"; // Import ToastContainer and toast from react-toastify
import "react-toastify/dist/ReactToastify.css"; // Import CSS for react-toastify
import InputWithLabel from "../common/InputWithLabel";
import PrimaryButton from "../common/PrimaryButton";
import TermsAndConditionsModal from "../common/TermsAndConditionModal";

const PersonalSignupForm = () => {
  const [formData, setFormData] = useState({
    username: "",
    role: "User",
    name: "",
    password: "",
    password2: "",
  });

  const [isTermsModalOpen, setTermsModalOpen] = useState(false);

  const openTermsModal = () => {
    setTermsModalOpen(true);
  };

  const closeTermsModal = () => {
    setTermsModalOpen(false);
  };

  const [isCheckboxChecked, setCheckboxChecked] = useState(false);
  const [error, setError] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const validateEmailOrPhone = (input) => {
    const emailRegex =
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    const phoneRegex = /^[0-9]{10}$/;

    if (emailRegex.test(input)) {
      return "email";
    } else if (phoneRegex.test(input)) {
      return "phone";
    } else {
      return "invalid";
    }
  };

  const isStrongPassword = (password) => {
    // Define your criteria for a strong password
    const minLength = 8;
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    return (
      password.length >= minLength &&
      hasUppercase &&
      hasLowercase &&
      hasNumber &&
      hasSpecialChar
    );
  };

  const handleCheckboxChange = () => {
    setCheckboxChecked(!isCheckboxChecked);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isLoading) return; // Prevent multiple submissions

    const { username, name, password, password2 } = formData;

    if (!isCheckboxChecked || !username || !name || !password || !password2) {
      setError("Please fill in all fields.");
    } else if (password !== password2) {
      setError("Passwords do not match!");
    } else if (!isStrongPassword(password)) {
      let errorMessage = "Password must contain ";
      if (password.length < 8) {
        errorMessage += "at least 8 characters, ";
      }
      if (!/[A-Z]/.test(password)) {
        errorMessage += "an uppercase letter, ";
      }
      if (!/[a-z]/.test(password)) {
        errorMessage += "a lowercase letter, ";
      }
      if (!/\d/.test(password)) {
        errorMessage += "a number, ";
      }
      if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
        errorMessage += "a special character, ";
      }
      errorMessage = errorMessage.slice(0, -2); // Remove the trailing comma and space
      setError(errorMessage);
    } else {
      const validationType = validateEmailOrPhone(username);

      if (validationType === "invalid") {
        setError("Please enter a valid email or phone number.");
      } else {
        setError("");
        setIsLoading(true); // Set loading state to true

        const url = process.env.REACT_APP_BASE_URL + "auth/register/";

        // Perform form submission logic here
        try {
          const url = process.env.REACT_APP_BASE_URL + "auth/register/";

          const response = await axios.post(`${url}`, formData, {
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          });
          console.log(response);
          if (response.status === 201) {
            const responseData = response.data;
            const token = responseData.token.access;

            console.log("Responded Token is : ", token);

            // Set token in local storage
            localStorage.setItem("usertoken", token);
            // Show success notification
            toast.success("Signup successful!");

            navigate("/login");
          } else {
            // Handle errors here
            console.error("Error creating User");
            // Show error notification
            toast.error("Signup failed!");
          }
        } catch (error) {
          console.error("Error sending data:", error);
          // Check if the error is due to username already existing
          if (
            error.response &&
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors.username
          ) {
            toast.error(error.response.data.errors.username[0]);
          } else {
            // Show generic error notification
            toast.error("Signup failed. Please try again later.");
          }
        } finally {
          setIsLoading(false); // Reset loading state
        }
      }
    }
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit}
        className="self-start w-full flex flex-col items-start gap-5 lg:px-[10%]"
      >
        <InputWithLabel
          htmlFor="username"
          label="Email or phone number"
          type="text"
          placeholder="Lexialexandra@gmail.com"
          name="username"
          value={formData.username}
          onChange={handleInputChange}
        />

        <InputWithLabel
          htmlFor="fullName"
          label="Full Name"
          type="text"
          placeholder="Lexi Alexandra"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
        />

        <InputWithLabel
          htmlFor="password"
          label="Create Password"
          type="password"
          placeholder="Helloecommerce"
          name="password"
          value={formData.password}
          onChange={handleInputChange}
        />

        <InputWithLabel
          htmlFor="password2"
          label="Confirm Password"
          type="password"
          placeholder="Helloecommerce"
          name="password2"
          value={formData.password2}
          onChange={handleInputChange}
        />

        {error && (
          <p className="text-red-900 text-[14px] font-medium">{error}</p>
        )}

        <div className="flex items-center gap-2">
          <input
            type="checkbox"
            name="terms"
            id=""
            className="w-3.5 h-3.5"
            onChange={handleCheckboxChange}
            checked={isCheckboxChecked}
          />

          <label htmlFor="" className="text-gray-500 font-medium  text-[14px]">
            I agree to{" "}
            <span
              className="text-blue cursor-pointer hover:underline underline-offset-4"
              onClick={openTermsModal}
            >
              terms & conditions and privacy policy
            </span>
            .
          </label>
        </div>

        <PrimaryButton
          onClick={handleSubmit}
          label="Sign up"
          disabled={!isCheckboxChecked}
          isLoading={isLoading}
        />

        <div className="flex text-[14px] text-gray-900 items-center gap-2 self-center">
          <p>Already have an account?</p>
          <Link to="/login">
            <button className="font-semibold">Login</button>
          </Link>
        </div>
      </form>

      <TermsAndConditionsModal
        isOpen={isTermsModalOpen}
        onRequestClose={closeTermsModal}
      />

      <ToastContainer position="top-center" autoClose={3000} />
    </div>
  );
};

export default PersonalSignupForm;
