import axios from "axios";
import { atom, useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { BsFillPersonPlusFill } from "react-icons/bs";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { currentStepAtom } from "../../../components/SellerPage/Signup/StepsIndicator";
import { vendorToEditAtom } from "../../Admin/VendorsManagement/AcceptedVendors/AcceptedVendorsTableRow";
import DropdownWithFilter from "../../User/common/DropdownWithFilter";
import DropdownWithLabel from "../../common/DropdownWithLabel";
import FileInputWithLabel from "../../common/FileInputWithLabel";
import InputWithLabel from "../../common/InputWithLabel";
import PhoneInputWithLabel from "../../common/PhoneInputWithLabel";
import { sentOtpAtom, useridAtom } from "../../modals/VerificationModal";

export const profileIdAtom = atom(null);

const EditAddProfileForm = () => {
  const [vendorToEdit] = useAtom(vendorToEditAtom);
  const [isLoading, setIsLoading] = useState(false);
  const [tags, setTags] = useState([]);

  const [formData, setFormData] = useState({
    storename: "",
    storeemail: "",
    storephone: "",
    storetype: "",
    storewebsite: "",
    storelogo: null,
    storedescription: "",
    companies: [],
    usertype: "",
  });

  const fetchcompanies = async () => {
    const urlcompanies = process.env.REACT_APP_BASE_URL + "admin/get-company/";
    const responsecompanies = await axios.get(`${urlcompanies}`);
    const datacompanies = responsecompanies.data;
    console.log("Companies:", datacompanies);
    setTags(datacompanies);
  };

  useEffect(() => {
    fetchcompanies();
  }, []);


  useEffect(() => {
    if (vendorToEdit && vendorToEdit.length > 0) {
      console.log("Vendor to Edit:", vendorToEdit[0]); 
      const { sellerprofile } = vendorToEdit[0]; // Accessing the sellerprofile object
      console.log("Seller Profile:", sellerprofile);
      const {
        storename,
        storeemail,
        storephone,
        storetype,
        storewebsite,
        storedescription,
        storelogo,
        companies,
        usertype,
      } = sellerprofile;

      setFormData({
        storename: storename || "",
        storeemail: storeemail || "",
        storephone: storephone || "",
        storetype: storetype || "",
        storewebsite: storewebsite || "",
        storelogo: storelogo || null,
        storedescription: storedescription || "",
        companies: companies || [],
        usertype: usertype || "",
      });
    }
  }, [vendorToEdit]);

  const [profileId, setProfileId] = useAtom(profileIdAtom);
  const [currentStep, setCurrentStep] = useAtom(currentStepAtom);
  const [userid, setUserId] = useAtom(useridAtom);

  const [errors, setErrors] = useState({
    storename: "",
    storeemail: "",
    storephone: "",
    storetype: "",
    storedescription: "",
    companies: "",
    usertype: "",
  });




  const handleFormSubmit = async (e) => {
    e.preventDefault();

    console.log("Form Data:", formData);

    const newErrors = validateForm();

    if (Object.values(newErrors).some((error) => error !== "")) {
      setErrors(newErrors);
      return;
    }

    setIsLoading(true);
    console.log(userid);

    
    // const fullurl = process.env.REACT_APP_BASE + formData.storelogo;
    // const fulresponse = await fetch (fullurl);
    // const blob = await fulresponse.blob();

    const formDatas = new FormData();
    formDatas.append("userid", userid);
    formDatas.append("storename", formData.storename);
    // formDatas.append("storelogo", blob, formData.storelogo.name);

    if (Array.isArray(formData.companies)) {
      formData.companies = formData.companies.map((company) => company.name);
    }
    
    console.log("formData:", formData);
    
    const url = process.env.REACT_APP_BASE_URL + "newseller/sellerprofile/";

    const updateseller =  process.env.REACT_APP_BASE_URL + "newseller/updateprofileseller/";

    const accessToken = localStorage.getItem("token");
    console.log("Access Token:", accessToken);

    try {
      const response = await axios.post(`${url}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 201) {
        const responseData = response.data;
        const sellerProfileId = responseData.id;
        setProfileId(sellerProfileId);
        if (vendorToEdit) {
          const updatevendor = async () => {
            const newformDatass = new FormData();
            newformDatass.append("sellerprofile", sellerProfileId);
            newformDatass.append("seller", vendorToEdit[0].id);

            const responseupdate = await axios.post(`${updateseller}`, newformDatass, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              body: JSON.stringify(newformDatass),
            });
            if (responseupdate.status === 201) {
              toast.success("Profile updated successfully!");
            }

          }
          updatevendor();
        }


      } else {
        // Handle errors here
        console.error("Error creating Seller Profile");
      }
      toast.success("Profile added successfully!");

      setCurrentStep((prevStep) => Math.min(prevStep + 1, 4));
    } catch (error) {
      console.error("Error sending data:", error);
      // Handle error
      toast.error("Error adding profile. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "storetype") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
      // Clear the error for the changed field
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  const handleFileChange = (file) => {
    setFormData((prevData) => ({
      ...prevData,
      storelogo: file,
    }));
    // Clear the error for the storelogo field
    setErrors((prevErrors) => ({ ...prevErrors, storelogo: "" }));
  };

  const validateForm = () => {
    const {
      storename,
      storeemail,
      storephone,
      storetype,
    //   password,
      storedescription,
      companies,
      usertype,
    } = formData;

    const newErrors = {
      storename: storename ? "" : "Please enter the store name.",
      storeemail: storeemail
        ? isValidstoreemail(storeemail)
          ? ""
          : "Please enter a valid store email address."
        : "Please enter the store email address.",
      storephone: storephone
        ? isValidPhone(storephone)
          ? ""
          : "Please enter a valid phone number."
        : "Please enter the phone number.",
      storetype: storetype ? "" : "Please select an option.",
      usertype: usertype ? "" : "Please select an option.",
      // password: password ? "" : "Please enter the password.",
      storedescription: storedescription
        ? ""
        : "Please enter the company store description.",
      companies:
        companies.length > 0 ? "" : "Please select at least one company.",
    };

    // if (password && !isStrongPassword(password)) {
    //   let errorMessage = "Password must contain ";
    //   if (password.length < 8) {
    //     errorMessage += "at least 8 characters, ";
    //   }
    //   if (!/[A-Z]/.test(password)) {
    //     errorMessage += "an uppercase letter, ";
    //   }
    //   if (!/[a-z]/.test(password)) {
    //     errorMessage += "a lowercase letter, ";
    //   }
    //   if (!/\d/.test(password)) {
    //     errorMessage += "a number, ";
    //   }
    //   if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
    //     errorMessage += "a special character, ";
    //   }
    //   errorMessage = errorMessage.slice(0, -2);
    //   newErrors.password = errorMessage;
    // }

    return newErrors;
  };

  const isValidstoreemail = (storeemail) => {
    const storeemailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return storeemailRegex.test(storeemail);
  };

  const isValidPhone = (phone) => {
    const phoneRegex = /^[0-9]{10}$/;
    return phoneRegex.test(phone);
  };

//   const isStrongPassword = (password) => {
//     // Define your criteria for a strong password
//     const minLength = 8;
//     const hasUppercase = /[A-Z]/.test(password);
//     const hasLowercase = /[a-z]/.test(password);
//     const hasNumber = /\d/.test(password);
//     const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

//     return (
//       password.length >= minLength &&
//       hasUppercase &&
//       hasLowercase &&
//       hasNumber &&
//       hasSpecialChar
//     );
//   };

  const clearError = (fieldName) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: "",
    }));
  };

  const handleTagSelectionChange = (selectedTags) => {
    setFormData((prevData) => ({
      ...prevData,
      companies: selectedTags,
    }));
  };

  return (
    <div className="flex-1 bg-white py-4 mb-20 flex flex-col gap-6">
      <ToastContainer position="top-center" autoClose={3000} />

      <div className="flex items-center justify-between px-8">
        <div className="flex items-center gap-2 text-blue-800 font-bold">
          <BsFillPersonPlusFill className="min-w-[20px] min-h-[20px]" />
          <p>Add Profile</p>
        </div>
      </div>

      <div className="flex flex-col gap-16">
        <div className="flex flex-col gap-4 lg:grid lg:grid-cols-2 lg:gap-6 px-8">
          <div className="flex flex-col gap-1">
            <InputWithLabel
              htmlFor="storename"
              label="Store Name"
              type="text"
              placeholder="Bhatbhateni store"
              name="storename"
              value={formData.storename}
              onChange={handleInputChange}
            />

            {errors.storename && (
              <p className="text-red-900 text-[14px] font-medium">
                {errors.storename}
              </p>
            )}
          </div>

          <div className="flex flex-col gap-1">
            <InputWithLabel
              htmlFor="storeemail"
              label="store email Address"
              type="email"
              placeholder="debra.holt@example.com"
              name="storeemail"
              value={formData.storeemail}
              onChange={handleInputChange}
            />

            {errors.storeemail && (
              <p className="text-red-900 text-[14px] font-medium">
                {errors.storeemail}
              </p>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-4 lg:grid lg:grid-cols-2 lg:gap-6 px-8">
          <div className="flex flex-col gap-1">
            <PhoneInputWithLabel
              label="Phone "
              name="storephone"
              placeholder="9852545652"
              value={formData.storephone}
              onChange={handleInputChange}
            />

            {errors.storephone && (
              <p className="text-red-900 text-[14px] font-medium">
                {errors.storephone}
              </p>
            )}
          </div>

          <div className="flex flex-col gap-1">
            <DropdownWithLabel
              label="Store Type"
              name="storetype"
              placeholder="Select"
              options={["Individual", "Organizational", "Corporate"]}
              value={formData.storetype}
              onChange={(value) => {
                handleInputChange({
                  target: { name: "storetype", value },
                });

                // Clear the error for the storetype field
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  storetype: "",
                }));
              }}
            />

            {errors.storetype && (
              <p className="text-red-900 text-[14px] font-medium">
                {errors.storetype}
              </p>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-4 lg:grid lg:grid-cols-2 lg:gap-6 px-8">
          <div className="flex flex-col gap-1">
            <InputWithLabel
              htmlFor="storewebsite"
              label="Website storewebsite"
              type="text"
              placeholder="http://www.zotware.com"
              name="storewebsite"
              value={formData.storewebsite}
              onChange={handleInputChange}
            />

            {errors.storewebsite && (
              <p className="text-red-900 text-[14px] font-medium">
                {errors.storewebsite}
              </p>
            )}
          </div>
          <div className="flex flex-col gap-1">
            <DropdownWithLabel
              label="User Type"
              name="usertype"
              placeholder="Select"
              options={["Buyer", "Seller", "Both"]}
              value={formData.usertype}
              onChange={(value) => {
                handleInputChange({
                  target: { name: "usertype", value },
                });

                // Clear the error for the storetype field
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  usertype: "",
                }));
              }}
            />

            {errors.usertype && (
              <p className="text-red-900 text-[14px] font-medium">
                {errors.usertype}
              </p>
            )}
          </div>

          {/* <div className="flex flex-col gap-1">
            
            {!vendorToEdit && (
              <InputWithLabel
                htmlFor="password"
                label="Password"
                type="password"
                placeholder="Helloecommerce"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
              />
            )}

            {errors.password && (
              <p className="text-red-900 text-[14px] font-medium">
                {errors.password}
              </p>
            )}
          </div> */}
          <div className="col-span-2 flex flex-col gap-1">
            <FileInputWithLabel
              label="Upload storelogo"
              pathName={formData.storelogo}
              onChange={handleFileChange}
            />

            {errors.storelogo && (
              <p className="text-red-900 text-[14px] font-medium">
                {errors.storelogo}
              </p>
            )}
          </div>

          <div className="col-span-2 flex flex-col gap-1">
            <InputWithLabel
              htmlFor="storedescription"
              label="Company storedescription"
              type="text"
              placeholder="Say something about your company."
              name="storedescription"
              isTextArea={true}
              rows={5}
              value={formData.storedescription}
              onChange={handleInputChange}
            />

            {errors.storedescription && (
              <p className="text-red-900 text-[14px] font-medium">
                {errors.storedescription}
              </p>
            )}
          </div>

          <div
            className={`col-span-2 w-full flex flex-col gap-1.5 items-start`}
          >
            <label
              htmlFor="companies"
              className="text-[14px] text-gray-800 font-bold"
            >
              Companies
            </label>

            <div className="w-full flex flex-col gap-1.5">
              <DropdownWithFilter
                buttonLabel="Select Companies for your store"
                items={tags}
                selectedItems={formData.companies}
                onSelectionChange={handleTagSelectionChange}
                hasBgColor={true}
              />

              {errors.companies && (
                <p className="text-red-900 text-[14px] font-medium">
                  {errors.companies}
                </p>
              )}
            </div>
          </div>
        </div>

        <div className="flex justify-end border-t border-gray-200">
          <button
            type="submit"
            className="mx-6 mt-4 bg-blue px-8 py-1.5 rounded-md text-[14px] text-white font-semibold relative"
            onClick={handleFormSubmit}
          >
            {isLoading ? (
              <div role="status" className="flex justify-center items-center">
                <svg
                  aria-hidden="true"
                  class="w-5 h-5 text-gray-300 animate-spin dark:text-gray-600 fill-white"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              "Next"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditAddProfileForm;
