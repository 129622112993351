import axios from "axios";
import React, { useState } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { HiMinus, HiPlus } from "react-icons/hi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ProductTableRow = ({ formData }) => {
  const [isMobileTableRowOpen, setIsMobileTableRowOpen] = useState(false);

  const url = process.env.REACT_APP_BASE_URL + "admin/makefeaturedproduct/";
  const makeFeaturedProduct = async (id) => {
    try {
      const response = await axios.post(`${url}${id}/`);
      console.log(response);
      if (response.status === 201) {
        toast.success("Product made featured");
      }
    } catch (error) {
      console.error("Error making product featured:", error);
      toast.error("Error making product featured");
    }
  };

  return (
    <>
      <div className="border-b-2 border-gray-400 px-5 py-3 sm:hidden">
        <div className="flex items-center gap-4">
          <div
            className={`w-6 h-6 flex justify-center items-center ${
              isMobileTableRowOpen
                ? "bg-blue text-white rounded-[4px]"
                : "border-2 border-gray-400 text-gray-400 rounded-sm"
            }`}
          >
            <button
              onClick={(event) => {
                event.preventDefault();
                setIsMobileTableRowOpen(!isMobileTableRowOpen);
              }}
            >
              {isMobileTableRowOpen ? (
                <HiMinus className="w-5 h-5" />
              ) : (
                <HiPlus className="w-5 h-5" />
              )}
            </button>
          </div>

          <div className="text-start">
            <h1 className="text-gray-900 font-bold text-[15px] uppercase">
              Part Name
            </h1>
            <p className="text-gray-800 font-medium text-[15px]">
              {formData.part_id.partName}
            </p>
          </div>
        </div>

        {isMobileTableRowOpen && (
          <div className="flex flex-col gap-2 mt-4 ml-[2.4rem]">
            <div className="flex flex-row gap-2 items-center">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                Part Number
              </h2>
              <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                {Array.isArray(formData.part_id.partnumber) &&
                formData.part_id.partnumber.length > 0
                  ? formData.part_id.partnumber.map((part, index) => (
                      <span key={index}>
                        {part.partNumber}
                        {index < formData.part_id.partnumber.length - 1 && ", "}
                      </span>
                    ))
                  : "N/A"}
              </p>
            </div>

            <div className="flex flex-row gap-2 items-center">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                Normal Rate
              </h2>
              <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                {formData.normalRate || "N/A"}
              </p>
            </div>

            <div className="flex flex-row gap-2 items-center">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                Bulk Rate
              </h2>
              <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                {formData.bulkRate || "N/A"}
              </p>
            </div>

            <div className="flex flex-row gap-2 items-center">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                Status
              </h2>
              <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                {formData.part_id.status || "N/A"}
              </p>
            </div>

            <div className="flex items-center gap-2">
              <button
                className="px-1.5 border-2 text-blue flex items-center gap-2 text-[15px] border-gray-300 font-medium rounded-md h-[35px]"
                onClick={() => makeFeaturedProduct(formData.id)}
              >
                <AiOutlineCheck className="w-5 h-5" />
                Make Featured Product
              </button>
            </div>
          </div>
        )}
      </div>

      <tr className="border-b-2 border-gray-300 text-gray-700 relative hidden sm:table-row hover:bg-gray-100">
        <td>
          <div className="max-w-[180px] 2xl:max-w-[250px]  gap-2 lg:gap-3 pr-6">
            <span className="whitespace-break-spaces leading-5">
              {formData.seller_id}
            </span>
          </div>
        </td>

        <td className="text-center">
          {Array.isArray(formData.part_id.partnumber) &&
          formData.part_id.partnumber.length > 0
            ? formData.part_id.partnumber.map((part, index) => (
                <span key={index}>
                  {part.partNumber}
                  {index < formData.part_id.partnumber.length - 1 && ", "}
                </span>
              ))
            : "N/A"}
        </td>

        <td className="text-center">{formData.part_id.partName || "N/A"}</td>

        <td className="text-center">{formData.normalRate || "N/A"}</td>

        <td className="text-center">{formData.bulkRate || "N/A"}</td>

        <td className="text-center">{formData.part_id.status || "N/A"}</td>

        <td className="text-center">
          <button
            className="px-1.5 border-2 text-blue flex items-center gap-2 text-[15px] border-gray-300 font-medium rounded-md h-[35px]"
            onClick={() => makeFeaturedProduct(formData.id)}
          >
            <AiOutlineCheck className="w-5 h-5" />
            Make Featured Product
          </button>
        </td>
      </tr>
      <ToastContainer />
    </>
  );
};

export default ProductTableRow;
