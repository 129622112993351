import { atom, useAtom } from "jotai";
import React, { useState } from "react";
import { BsFolder } from "react-icons/bs";
import { FaChevronDown } from "react-icons/fa";
import { HiOutlineMenuAlt1 } from "react-icons/hi";
import { MdOutlineAddBusiness } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import BrandLogo from "../../../assets/images/osis.png";
import { ReactComponent as DashboardSVG } from "../../../assets/svg/dashboard.svg";
import { ReactComponent as LogoutSVG } from "../../../assets/svg/exit.svg";
import { ReactComponent as InventorySVG } from "../../../assets/svg/inventory.svg";
import { ReactComponent as ChatSVG } from "../../../assets/svg/message.svg";
import { ReactComponent as OrderSVG } from "../../../assets/svg/order.svg";
import { ReactComponent as UserSVG } from "../../../assets/svg/user.svg";
import { BiCreditCard } from "react-icons/bi";
import { IoClose } from "react-icons/io5";

import {
  isProductSelectedAtom,
  isProductViewedAtom,
  selectedProductDataAtom,
  showCustomProductFormAtom,
} from "../ProductManagement/AddProduct/AddProductMain";
import { currentStepAtom } from "../ProductManagement/AddProduct/StepsIndicator";
import { isImportSuccessAtom } from "../ProductManagement/ImportProduct/ImportProduct";
import { isSellerProductEditedAtom } from "../ProductManagement/ManageProduct/AllProducts/AllProductsTableRow";

export const activeNavItemAtom = atom(0);
export const activeSubNavItemAtom = atom(null);
export const activeNavNameAtom = atom("Dashboard");
export const activeSubNavNameAtom = atom(null);
export const sidebarOpenAtom = atom(false);

const Sidebar = () => {
  const [openSubNav, setOpenSubNav] = useState(null);
  const [activeNavItem, setActiveNavItem] = useAtom(activeNavItemAtom);
  const [activeSubNavItem, setActiveSubNavItem] = useAtom(activeSubNavItemAtom);
  const [, setActiveNavName] = useAtom(activeNavNameAtom);
  const [, setActiveSubNavName] = useAtom(activeSubNavNameAtom);
  const [sidebarOpen, setSidebarOpen] = useAtom(sidebarOpenAtom);
  const [, setShowCustomProductForm] = useAtom(showCustomProductFormAtom);
  const [, setIsImportSuccess] = useAtom(isImportSuccessAtom);
  const [, setCurrentStep] = useAtom(currentStepAtom);
  const [, setIsProductViewed] = useAtom(isProductViewedAtom);
  const [, setIsProductSelected] = useAtom(isProductSelectedAtom);
  const [, setIsSellerProductEdited] = useAtom(isSellerProductEditedAtom);
  const [, setSelectedProductData] = useAtom(selectedProductDataAtom);

  const navigate = useNavigate();

  // Check user roles
  const isAgent = localStorage.getItem("agenttoken") !== null;
  const isBuyer = localStorage.getItem("buyertoken") !== null;
  const isSeller = localStorage.getItem("sellertoken") !== null;
  const isBoth = localStorage.getItem("bothtoken") !== null;

  const handleSubNavToggle = (mainIndex, subIndex, navName, subNavName) => {
    setActiveNavItem(mainIndex);
    setActiveSubNavItem(subIndex);
    setActiveNavName(navName);
    setActiveSubNavName(subNavName);

    if (navName === "Product Management" && subNavName === "Add Product") {
      setShowCustomProductForm(false);
      setIsProductViewed(false);
      setIsProductSelected(false);
      setIsSellerProductEdited(false);
      setSelectedProductData(null);
      setCurrentStep(1);
    } else if (navName === "Product Management" && subNavName === "Import Product") {
      setIsImportSuccess(false);
    }

    if (mainIndex !== activeNavItem) {
      setActiveSubNavName(null);
    }

    setOpenSubNav(openSubNav === mainIndex ? null : mainIndex);
    setSidebarOpen(true);

    if (navName === "Dashboard") {
      navigate("/seller/");
    } else if (navName === "Product Management") {
      if (subNavName === "Manage Products") navigate("/seller/product-management/manage");
      else if (subNavName === "Import Product") navigate("/seller/product-management/import");
      else if (subNavName === "Review Products") navigate("/seller/product-management/review");
      else if (subNavName === "Add Product") navigate("/seller/product-management/add");
    } else if (navName === "Order & Review") {
      if (subNavName === "Order Management") navigate("/seller/order-review/order-management");
      else if (subNavName === "Review Management") navigate("/seller/order-review/review-management");
    } else if (navName === "Business Orders") {
      if (subNavName === "Create Order") navigate("/seller/request-seller");
      else if (subNavName === "Quotation") navigate("/seller/business-orders/quotation");
      else if (subNavName === "Purchase Order") navigate("/seller/business-orders/purchase-order");
      else if (subNavName === "Personal Order") navigate("/seller/business-orders/seller-order");
    } else if (navName === "Customer Support") {
      navigate("/seller/customer-support");
    } else if (navName === "Profile & Settings") {
      if (subNavName === "Profile") navigate("/seller/profile");
      else if (subNavName === "Setting") navigate("/seller/profile/setting");
    } else if (navName === "View Credit") {
      navigate("/seller/credit");
    } else if (navName === "Logout") {
      handleLogout();
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("agenttoken");
    localStorage.removeItem("buyertoken");
    localStorage.removeItem("sellertoken");
    localStorage.removeItem("bothtoken");
    navigate("/login");
  };

  const renderNavItem = (icon, text, hasSubNav, subNavItems = [], index) => (
    <li className="cursor-pointer" key={index}>
      <div
        onClick={() => handleSubNavToggle(index, 0, text, null)}
        className={`${
          activeNavItem === index ? "bg-blue-500 text-blue-800" : "text-gray-700"
        } px-5 py-2 relative flex items-center gap-2 font-semibold text-[15px]`}
      >
        {icon && React.cloneElement(icon, { className: "w-6 h-6 text-gray-500" })}
        <p className="block">{text}</p>
        {hasSubNav && (
          <FaChevronDown
            className={`absolute right-6 text-[13px] lg:right-6 transition-transform ${
              openSubNav === index ? "transform rotate-180" : ""
            }`}
          />
        )}
      </div>

      {hasSubNav && openSubNav === index && (
        <div className={`relative left-8 pl-6 pt-3 border-l-2 border-gray-300 flex flex-col gap-3 ${sidebarOpen ? "block" : "hidden"}`}>
          {subNavItems.map(({ text: subText }, subIndex) => (
            <div
              key={subIndex}
              onClick={() => handleSubNavToggle(index, subIndex, text, subText)}
              className={`w-[80%] relative flex items-center gap-2 px-4 py-1 text-gray-700 ${
                activeSubNavItem === subIndex ? "bg-light-900 text-blue-800" : ""
              }`}
            >
              <BsFolder className={`w-5 h-5 ${activeSubNavItem === subIndex ? "text-blue-800" : ""}`} />
              <p className={`text-[15px] font-medium ${activeSubNavItem === subIndex ? "text-blue-800" : ""}`}>{subText}</p>
              {subIndex === activeSubNavItem && <div className="bg-gray-300 w-[25px] h-[2px] absolute -left-[24px]"></div>}
            </div>
          ))}
        </div>
      )}
    </li>
  );

  const getMenuConfig = () => {
    if (isBoth || (!isAgent && !isBuyer && !isSeller)) {
      return [
        { icon: <DashboardSVG />, text: "Dashboard", hasSubNav: false },
        {
          icon: <InventorySVG />,
          text: "Product Management",
          hasSubNav: true,
          subNavItems: [
            { text: "Manage Products" },
            { text: "Add Product" },
            { text: "Import Product" },
            { text: "Review Products" },
          ],
        },
        {
          icon: <OrderSVG />,
          text: "Order & Review",
          hasSubNav: true,
          subNavItems: [{ text: "Order Management" }, { text: "Review Management" }],
        },
        {
          icon: <MdOutlineAddBusiness />,
          text: "Business Orders",
          hasSubNav: true,
          subNavItems: [
            { text: "Create Order" },
            { text: "Purchase Order" },
            { text: "Quotation" },
            { text: "Personal Order" },
          ],
        },
        { icon: <BiCreditCard />, text: "View Credit", hasSubNav: false },
        { icon: <ChatSVG />, text: "Customer Support", hasSubNav: false },
        {
          icon: <UserSVG />,
          text: "Profile & Settings",
          hasSubNav: true,
          subNavItems: [{ text: "Profile" }, { text: "Setting" }],
        },
        { icon: <LogoutSVG />, text: "Logout", hasSubNav: false },
      ];
    } else if (isAgent) {
      return [
        { icon: <DashboardSVG />, text: "Dashboard", hasSubNav: false },
        {
          icon: <InventorySVG />,
          text: "Product Management",
          hasSubNav: true,
          subNavItems: [
            { text: "Manage Products" },
            { text: "Add Product" },
            { text: "Import Product" },
            { text: "Review Products" },
          ],
        },
        { icon: <ChatSVG />, text: "Customer Support", hasSubNav: false },
        { icon: <LogoutSVG />, text: "Logout", hasSubNav: false },
      ];
    } else if (isBuyer) {
      return [
        { icon: <DashboardSVG />, text: "Dashboard", hasSubNav: false },
        { icon: <LogoutSVG />, text: "Logout", hasSubNav: false },
      ];
    } else if (isSeller) {
      return [
        { icon: <DashboardSVG />, text: "Dashboard", hasSubNav: false },
        {
          icon: <InventorySVG />,
          text: "Product Management",
          hasSubNav: true,
          subNavItems: [
            { text: "Manage Products" },
            { text: "Add Product" },
            { text: "Import Product" },
            { text: "Review Products" },
          ],
        },
        {
          icon: <OrderSVG />,
          text: "Order & Review",
          hasSubNav: true,
          subNavItems: [{ text: "Order Management" }, { text: "Review Management" }],
        },
        {
          icon: <MdOutlineAddBusiness />,
          text: "Business Orders",
          hasSubNav: true,
          subNavItems: [
            { text: "Create Order" },
            { text: "Purchase Order" },
            { text: "Quotation" },
            { text: "Personal Order" },
          ],
        },
        { icon: <BiCreditCard />, text: "View Credit", hasSubNav: false },
        { icon: <ChatSVG />, text: "Customer Support", hasSubNav: false },
        {
          icon: <UserSVG />,
          text: "Profile & Settings",
          hasSubNav: true,
          subNavItems: [{ text: "Profile" }, { text: "Setting" }],
        },
        { icon: <LogoutSVG />, text: "Logout", hasSubNav: false },
      ];
    } else {
      return [];
    }
  };

  return (
    <div className={`bg-white border-r-2 border-gray-200 flex flex-col py-8 transition-all ${sidebarOpen ? "w-80" : "w-80"}`}>
      <div className="flex flex-col justify-center items-center gap-2">
        <img className="w-[52px] h-[52px]" src={BrandLogo} alt="OSIS" />
        <div className="flex justify-between items-center w-full px-5">
          <p className={`text-2xl font-semibold text-gray-700 ${sidebarOpen ? "block" : "block"}`}></p>
          {/* <HiOutlineMenuAlt1
            onClick={() => setSidebarOpen(!sidebarOpen)}
            className={`h-6 w-6 text-gray-500 cursor-pointer ${sidebarOpen ? "block" : "block"}`}
          /> */}
          {/* <IoClose
            onClick={() => setSidebarOpen(!sidebarOpen)}
            className={`h-6 w-6 text-gray-500 cursor-pointer ${sidebarOpen ? "block" : "hidden"}`}
          /> */}
        </div>
      </div>
      <ul className="pt-8 flex flex-col gap-2.5">
        {getMenuConfig().map((navItem, index) =>
          renderNavItem(navItem.icon, navItem.text, navItem.hasSubNav, navItem.subNavItems, index)
        )}
      </ul>
    </div>
  );
};

export default Sidebar;
