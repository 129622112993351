import React from 'react';
import { Bar } from 'react-chartjs-2';

const TotalSales = () => {
  const data = {
    labels: ['Esewa', 'Khalti', 'Bank Transfer', 'ImePay'],
    datasets: [
      {
        label: 'Sales',
        data: [3000, 2500, 1800, 1200],
        backgroundColor: [
          'rgba(75, 192, 192, 0.8)',
          'rgba(255, 99, 132, 0.8)',
          'rgba(54, 162, 235, 0.8)',
          'rgba(255, 206, 86, 0.8)',
        ],
        borderColor: [
          'rgba(75, 192, 192, 1)',
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div className="p-4 w-[calc(100vw_-_60px)] md:w-auto bg-white rounded-lg shadow-md">
      <h2 className="text-xl font-bold mb-4">Total Sales</h2>
      <div className="relative">
        <Bar data={data} options={options} />
      </div>
    </div>
  );
};

export default TotalSales;