import { useState } from "react";
import { IoClose } from "react-icons/io5";

const TagsInputContainer = ({ tags = [], onTagAddDelete, setTagError }) => {
  const [tagInput, setTagInput] = useState("");

  console.log("tags", tags);

  const handleTagInput = (e) => {
    setTagInput(e.target.value);
    setTagError("");
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent form submission
      const trimmedTag = tagInput.trim();
      if (trimmedTag !== "") {
        if (!tags.includes(trimmedTag)) {
          onTagAddDelete(trimmedTag);
          setTagInput("");
          setTagError("");
        } else {
          setTagError("Already exists");
        }
      } else {
        setTagError("Cannot be empty");
      }
    }
  };

  const handleTagDelete = (tagToDelete) => {
    onTagAddDelete(tagToDelete);
  };

  return (
    <div className="w-full min-h-[60px] bg-gray-200 px-4 py-3 flex flex-wrap gap-2">
      {/* {tags.map((tag, index) => (
        <div
          key={index}
          className="flex items-center bg-white shadow-sm px-4 rounded-md py-0.5"
        >
          <span className="mr-2">{tag}</span>
          <button onClick={() => handleTagDelete(tag)}>
            <IoClose className="w-6 h-6 text-[#FB0000]" />
          </button>
        </div>
      ))} */}

      {tags
        ? tags.map((tag, index) => (
            <div
              key={index}
              className="flex items-center bg-white shadow-sm px-4 rounded-md py-0.5"
            >
              <span className="mr-2">{tag}</span>
              <button onClick={() => handleTagDelete(tag)}>
                <IoClose className="w-6 h-6 text-[#FB0000]" />
              </button>
            </div>
          ))
        : null}
      <input
        type="text"
        className={`outline-none bg-gray-200 flex-1`}
        value={tagInput}
        onChange={handleTagInput}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
};

export default TagsInputContainer;
