import React, { useState } from "react";
import Banner from "../../../assets/images/banner.jpg";
import { ReactComponent as EditSVG } from "../../../assets/svg/edit.svg";

const SellerDecoration = () => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  return (
    <div className="relative mx-8 my-6 px-5 py-5 bg-white min-h-[calc(100vh-65px-48px)] flex flex-col gap-4">
      <h1 className="font-bold text-gray-800">Banner</h1>

      <p className="text-[14px] text-[#606280] font-medium">Main Banner</p>

      <div className="max-w-[450px]">
        <div className="border border-[#D8D8E8] shadow-md px-4 pt-3 pb-6 flex flex-col gap-4">
          <label htmlFor="upload" className="cursor-pointer">
            <img src={selectedFile ? URL.createObjectURL(selectedFile) : Banner} alt="" className="object-cover w-full max-h-[200px]" />
            <input
              type="file"
              id="upload"
              accept=".png, .jpg"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
          </label>

          <div className="flex gap-4 items-center justify-between">
            <div className="font-medium">
              <label htmlFor="upload" className="text-blue font-semibold cursor-pointer">
                Click To Upload
              </label>
              <input
                type="file"
                id="upload"
                accept=".png, .jpg"
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
              <p className="text-[14px] text-[#686C7B]">Accepted file types are png and jpg</p>
            </div>

            <button className="text-blue flex items-center gap-2 border border-gray-200 rounded-sm shadow-sm px-2 py-1">
              <EditSVG className="w-5 h-5" />
              <p className="text-[14px]">Edit</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SellerDecoration;
