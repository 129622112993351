import React, { useState } from "react";
import { AiOutlineCheck, AiOutlineEye } from "react-icons/ai";
import { BiEdit, BiRightArrowCircle, BiTrash } from "react-icons/bi";
import { HiMinus, HiPlus } from "react-icons/hi";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";

const AskforRateTableRow = ({ formData, onEditClick, onDeleteClick }) => {
  const [isMobileTableRowOpen, setIsMobileTableRowOpen] = useState(false);

  const url = process.env.REACT_APP_BASE_URL + "admin/notifyvendor/";
  const askforRateCall = async (id) => {
    try {
      const response = await axios.post(`${url}${id}/`);
      console.log(response);
      if (response.status === 201) {
        toast.success("Product made featured");
      }
    } catch (error) {
      console.error("Error making product featured:", error);
      toast.error("Error making product featured");
    }
  };

  console.log("formData:", formData)

  return (
    <>
      <div className="border-b-2 border-gray-400 px-5 py-3 sm:hidden">
        <div className="flex items-center gap-4">
          <div
            className={`w-6 h-6 flex justify-center items-center ${
              isMobileTableRowOpen
                ? "bg-blue text-white rounded-[4px]"
                : "border-2 border-gray-400 text-gray-400 rounded-sm"
            }`}
          >
            <button
              onClick={(event) => {
                event.preventDefault();
                setIsMobileTableRowOpen(!isMobileTableRowOpen);
              }}
            >
              {isMobileTableRowOpen ? (
                <HiMinus className="w-5 h-5" />
              ) : (
                <HiPlus className="w-5 h-5" />
              )}
            </button>
          </div>

          <div>
            <h1 className="text-gray-900 font-bold text-[15px] uppercase">
              Vendor Store Name
            </h1>
            <p className="text-gray-800 font-medium text-[15px]">
              {formData.vendorStoreName}
            </p>
          </div>
        </div>

        {isMobileTableRowOpen && (
          <div className="flex flex-col gap-2 mt-4 ml-[2.4rem]">
            <div className="flex flex-row gap-2 items-center">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                Requsted User Name:
              </h2>
              <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                {formData.user.username}
              </p>
            </div>

            <div className="flex flex-row gap-2 items-center">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                Part No.:
              </h2>
              <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                {formData.partNumber}
              </p>
            </div>

            <div className="flex flex-row gap-2 items-center">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                Part Name:
              </h2>
              <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                {formData.partName}
              </p>
            </div>

            <div className="flex items-center gap-2">
              <button className="px-1.5 border-2 border-gray-300 font-medium rounded-md h-[35px]">
                <BiRightArrowCircle className="w-5 h-5 text-blue" />
              </button>

              
            </div>
          </div>
        )}
      </div>

      <tr className="border-b-2 border-gray-300 text-gray-700 relative hidden sm:table-row hover:bg-gray-100">
        <td className="text-center">{formData.user.username}</td>
        <td className="text-center">
          {Array.isArray(formData.part.partnumber) &&
          formData.part.partnumber.length > 0
            ? formData.part.partnumber.map((parts, index) => (
                <span key={index}>
                  {parts.partNumber}
                  {index < formData.part.partnumber.length - 1 && ", "}
                </span>
              ))
            : "N/A"}
        </td>

        <td className="text-center">{formData.part.partName}</td>

        
        <td className="text-center">
          <button
            className="px-1.5 border-2 text-blue flex items-center gap-2 text-[15px] border-gray-300 font-medium rounded-md h-[35px]"
            onClick={() => askforRateCall(formData.id)}
          >
            <AiOutlineCheck className="w-5 h-5" />
            Notify Vendor
          </button>
        </td>
      </tr>
      <ToastContainer />
    </>
  );
};

export default AskforRateTableRow;
