import React, { useState } from "react";
import SideImage from "../assets/images/e78402a0d645c32e2f21069bb9543406.jpg";
import BrandLogo from "../assets/images/osis.png";
import BusinessSignupForm from "../components/Forms/BusinessSignupForm";
import PersonalSignupForm from "../components/Forms/PersonalSignupForm";
import RadioButton from "../components/common/RadioButton";
import VerificationModal from "../components/modals/VerificationModal";
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

const SignupPage = () => {
  const location = useLocation();
  const defaultAccountType = location.state?.accountType || 'personal';
  const [selectedAccountType, setSelectedAccountType] = useState(defaultAccountType);
  const [selectedProcessType] = useState("signup");
  const [verificationDigits, setVerificationDigits] = useState(["", "", "", ""]);
  const [isVerificationModalOpen, setVerificationModalOpen] = useState(false);

  const navigate = useNavigate();

  return (
    <div className="w-full md:flex">
      <div className="p-6 py-10 md:w-[50%] lg:py-8 lg:px-[4%]">
        <div className="flex items-center gap-4 mb-6" onClick={() => navigate('/')}>
        <img src={BrandLogo} alt="" className="w-10"  />
          <p className="text-2xl font-bold text-blue">Osis</p>
        </div>

        <div className="flex flex-col gap-8">
          <h1 className="text-center text-gray-900 text-2xl font-bold">
            Create your account
          </h1>

          <div className="flex flex-col gap-8">
            <div className="flex items-center justify-center gap-6 sm:gap-10 md:gap-16">
              <RadioButton
                id="personal"
                name="accountType"
                label="Personal account"
                defaultChecked={selectedAccountType === "personal"}
                onChange={() => setSelectedAccountType("personal")}
              />

              <RadioButton
                id="business"
                name="accountType"
                label="Business account"
                defaultChecked={selectedAccountType === "business"}
                onChange={() => setSelectedAccountType("business")}
              />
            </div>

            {selectedAccountType === "personal" && <PersonalSignupForm />}
            {selectedAccountType === "business" && (
              <BusinessSignupForm
                setVerificationModalOpen={setVerificationModalOpen}
              />
            )}
          </div>
        </div>
      </div>

      <div className="hidden md:block md:fixed w-[50%] h-screen right-0 top-0">
        <img
          src={SideImage}
          alt=""
          className="min-w-full min-h-full object-cover"
        />
      </div>

      {isVerificationModalOpen && (
        <VerificationModal
          setVerificationModalOpen={setVerificationModalOpen}
          verificationDigits={verificationDigits}
          setVerificationDigits={setVerificationDigits}
          selectedProcessType={selectedProcessType}
        />
      )}
    </div>
  );
};

export default SignupPage;
