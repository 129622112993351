import axios from "axios";
import { jwtDecode } from "jwt-decode";
import React from "react";
import { FaRegHeart, FaStar } from "react-icons/fa";
import { MdOutlineShoppingCart } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const ProductCard = ({ imageUrl, brand, rating, title, price, unit, id }) => {
  console.log("imageUrl", imageUrl);
  const navigate = useNavigate();
  const imageUrlPath = process.env.REACT_APP_BASE;

  const handleCardClick = () => {
    navigate(`/productDetail/${id}`);
    window.scrollTo(0, 0); 
  };

  const handleAddToCart = async (e) => {
    e.stopPropagation();

    const token = localStorage.getItem("usertoken");
    const username = localStorage.getItem("user");

    if (!token) {
      // Handle the case where the token is not present (e.g., redirect to login page)
      toast.error("Authentication credentials not provided. Please log in.");
      return;
    }

    // Check if the authentication token has expired (example for JWT, may vary based on implementation)
    const isTokenExpired = () => {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000; // Convert to seconds
        console.log("decodedToken", decodedToken.exp);
        console.log("currentTime", currentTime);
        console.log("isTokenExpired", decodedToken.exp < currentTime);
        return decodedToken.exp < currentTime;
      } catch (error) {
        return true; // Error decoding token or invalid token, treat as expired
      }
    };

    if (isTokenExpired()) {
      // Handle token expiration (e.g., refresh the token or redirect to login page)
      toast.error("Authentication token has expired. Please log in again.");
      navigate("/login");
      return;
    }

    const url = process.env.REACT_APP_BASE_URL + `users/add_to_cart/${id}/`;
    const response = await axios.get(`${url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    console.log("response", response);
    if (response.status === 201) {
      toast.success("Product added to cart successfully");
    } else {
      toast.error("Error adding product to cart");
    }
  };

  return (
    <div
      className="relative cursor-pointer bg-white py-4 rounded-md shadow-md border border-gray-300"
      onClick={handleCardClick}
    >
      <ToastContainer position="top-center" />
      <div className="relative px-2 h-[250px] pb-4 border-b-2 border-gray-200 w-full flex items-center justify-center">
        <img
          src={imageUrl ? `${imageUrlPath}${imageUrl}` : "https://th.bing.com/th/id/R.f145fbfe280056043be63570724ac0c4?rik=E7e2uVHaKcWmdw&pid=ImgRaw&r=0"}
          alt="No Image"
          className="rounded-md w-full h-[250px] object-cover"
        />
      </div>


      <div className="py-2 px-4 flex flex-col gap-2">
        <div className="flex items-center justify-between font-medium">
          <span className="text-sm text-blue">{brand}</span>
        </div>

        <div className="flex flex-col gap-3">
          <h1 className="text-[17px] font-medium">{title}</h1>
          <p className="text-[17px] font-bold text-blue">
            Rs. {price} / {unit}
          </p>

          <button
            className="text-[15px] bg-blue text-white font-medium border rounded-[4px] w-full py-2 flex items-center justify-center gap-2"
            onClick={handleAddToCart}
          >
            <MdOutlineShoppingCart className="text-lg" />
            Add to Cart 
          </button>
        </div>
      </div>
      {/* <ToastContainer position="top-center" autoClose={3000} /> */}
    </div>
  );
};

export default ProductCard;
