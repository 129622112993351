import { useAtom } from "jotai";
import React, { useState } from "react";
import InputWithLabel from "../../../common/InputWithLabel";
import { isSellerProductEditedAtom } from "../ManageProduct/AllProducts/AllProductsTableRow";
import { isProductSelectedAtom } from "./AddProductMain";
import { currentStepAtom } from "./StepsIndicator";

const BasicInfoForm = ({ formData, onInputChange }) => {
  const [, setCurrentStep] = useAtom(currentStepAtom);
  const [errors, setErrors] = useState({
    vehicleCompany: "",
    subCategory: "",
    brand: "",
    model: "",
    manufactureYear: "",
  });
  const [isSellerProductEdited] = useAtom(isSellerProductEditedAtom);
  const [isProductSelected] = useAtom(isProductSelectedAtom);

  console.log(isSellerProductEdited);

  // State to store the initial state of formData
  const [initialFormData] = useState({ ...formData });

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const newErrors = validateForm();

    if (Object.values(newErrors).some((error) => error !== "")) {
      setErrors(newErrors);
      return;
    }

    console.log(formData);

    // Move to the next step
    setCurrentStep((prevStep) => Math.min(prevStep + 1, 5));
  };

  const validateForm = () => {
    const { vehicleCompany, subCategory, brand, model, manufactureYear } =
      formData;

    const newErrors = {
      vehicleCompany: vehicleCompany ? "" : "Vehicle Company is required",
      subCategory: subCategory ? "" : "Sub-Category is required",
      brand: brand ? "" : "Brand is required",
      model: model ? "" : "Model is required",
      manufactureYear: manufactureYear ? "" : "Manufacture Year is required",
    };

    return newErrors;
  };

  const clearError = (fieldName) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: "",
    }));
  };

  return (
    <div className="flex-1 bg-white py-4 flex flex-col gap-6">
      <div className="flex items-center justify-between px-6 sm:px-8">
        <div className="text-blue font-bold">
          <p>Add Basic Info</p>
        </div>
      </div>

      <form
        className="flex-1 flex flex-col justify-between gap-16"
        encType="multipart/form-data"
      >
        <div className="flex flex-col gap-4 lg:gap-6 px-6 sm:px-8">
          <div className="flex flex-col gap-1">
            <InputWithLabel
              htmlFor="vehicleCompany"
              label="Vehicle Company"
              type="text"
              placeholder="Engine"
              name="vehicleCompany"
              value={formData.vehicleCompany}
              onChange={(e) => {
                onInputChange("vehicleCompany", e.target.value);
                clearError("vehicleCompany");
              }}
              isDisabled={
                isProductSelected ? true : isSellerProductEdited ? true : false
              }
            />

            {errors.vehicleCompany && (
              <p className="text-red-900 text-[14px] font-medium">
                {errors.vehicleCompany}
              </p>
            )}
          </div>

          <div className="flex flex-col gap-1">
            <InputWithLabel
              htmlFor="subCategory"
              label="Sub-Category"
              type="text"
              placeholder="Engine"
              name="subCategory"
              value={formData.subCategory}
              onChange={(e) => {
                onInputChange("subCategory", e.target.value);
                clearError("subCategory");
              }}
              isDisabled={
                isProductSelected ? true : isSellerProductEdited ? true : false
              }
            />

            {errors.subCategory && (
              <p className="text-red-900 text-[14px] font-medium">
                {errors.subCategory}
              </p>
            )}
          </div>

          <div className="flex flex-col gap-1">
            <InputWithLabel
              htmlFor="brand"
              label="Brand"
              type="text"
              placeholder="Toyota, Honda, etc"
              name="brand"
              value={formData.brand}
              onChange={(e) => {
                onInputChange("brand", e.target.value);
                clearError("brand");
              }}
              isDisabled={
                isProductSelected ? true : isSellerProductEdited ? true : false
              }
            />

            {errors.brand && (
              <p className="text-red-900 text-[14px] font-medium">
                {errors.brand}
              </p>
            )}
          </div>

          <div className="flex flex-col gap-1">
            <InputWithLabel
              htmlFor="model"
              label="Model"
              type="text"
              placeholder="Corolla, Civic, etc"
              name="model"
              value={formData.model}
              onChange={(e) => {
                onInputChange("model", e.target.value);
                clearError("model");
              }}
              isDisabled={
                isProductSelected ? true : isSellerProductEdited ? true : false
              }
            />

            {errors.model && (
              <p className="text-red-900 text-[14px] font-medium">
                {errors.model}
              </p>
            )}
          </div>

          <div className="flex flex-col gap-1">
            <InputWithLabel
              htmlFor="manufactureYear"
              label="Year of Manufacturing"
              type="text"
              placeholder="2021"
              name="manufactureYear"
              value={formData.manufactureYear}
              onChange={(e) => {
                onInputChange("manufactureYear", e.target.value);
                clearError("manufactureYear");
              }}
              isDisabled={
                isProductSelected ? true : isSellerProductEdited ? true : false
              }
            />

            {errors.manufactureYear && (
              <p className="text-red-900 text-[14px] font-medium">
                {errors.manufactureYear}
              </p>
            )}
          </div>
        </div>

        <div className="flex justify-end border-t border-gray-200">
          <button
            type="button"
            className="mx-6 mt-4 bg-blue px-6 sm:px-8 py-1.5 rounded-md text-[14px] text-white font-semibold"
            onClick={handleFormSubmit}
          >
            Next
          </button>
        </div>
      </form>
    </div>
  );
};

export default BasicInfoForm;
