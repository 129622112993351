import { useAtom } from "jotai";
import React from "react";
import SuccessImage from "../../../../assets/images/success.png";
import {
  activeNavItemAtom,
  activeNavNameAtom,
  activeSubNavItemAtom,
  activeSubNavNameAtom,
} from "../../common/Sidebar";
import { formDataAtom, isAddPartSuccessAtom } from "./AddPart";
import { isAddPartFormSubmittedAtom } from "./AddPartSummary";
import { useNavigate } from "react-router-dom";

const SuccessPage = () => {
  const [, setIsAddPartFormSubmitted] = useAtom(isAddPartFormSubmittedAtom);
  const [, setIsAddPartSuccessAtom] = useAtom(isAddPartSuccessAtom);
  const [, setActiveNavName] = useAtom(activeNavNameAtom);
  const [, setActiveSubNavName] = useAtom(activeSubNavNameAtom);
  const [, setActiveNavItem] = useAtom(activeNavItemAtom);
  const [, setActiveSubNavItem] = useAtom(activeSubNavItemAtom);
  const [, setFormData] = useAtom(formDataAtom);

  const navigate = useNavigate();

  return (
    <div className="flex flex-col gap-4 items-center justify-center w-full min-h-[calc(100vh-65px-48px)]">
      <img src={SuccessImage} alt="" />

      <div className="flex flex-col items-center gap-2">
        <h1 className="text-lg font-semibold text-green-600 ">
          Congratulations!
        </h1>

        <p className="text-gray-500">You have successfully added the part.</p>
      </div>

      <button
        className="border border-gray-[#E5E5EB] text-gray-500 rounded-full px-10 py-1.5"
        onClick={() => {
          setIsAddPartFormSubmitted(false);
          setIsAddPartSuccessAtom(false);
          setActiveNavName("Dashboard");
          setActiveSubNavName(null);
          setActiveNavItem(0);
          setActiveSubNavItem(null);
          setFormData({
            vehicleCompany: "",
            subCategory: "",
            availability: "In Stock",
            brand: "",
            model: "",
            manufactureYear: "",
            partCategories: "",
            partSubCategories: "",
            partNumber: "",
            partName: "",
            description: "",
            image: null,
            otherAttributes: Array.from({ length: 5 }, () => ({
              attributeValue: "",
            })),
          });
          navigate("/admin/");
        }}
      >
        Go to Dashboard
      </button>
    </div>
  );
};

export default SuccessPage;
