import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import SliderNavButtons from "../common/SliderNavButtons";
import TestimonialCard from "../common/TestimonialCard";

const Testimonials = () => {
  const sliderRef = useRef(null);

  const settings = {
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: <></>,
    prevArrow: <></>,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 968,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const handlePrev = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  return (
    <div className="bg-[#f9f7f7] px-6 md:px-10 lg:px-20 pt-5 md:pt-10 pb-36">
      <div className="flex items-center justify-between">
        <div>
          <p className="uppercase text-blue font-medium text-[15px]">
            What People Say
          </p>
          <h1 className="text-lg vsm:text-xl font-bold">
            Testimonials
          </h1>
        </div>

        <SliderNavButtons onNext={handleNext} onPrev={handlePrev} />
      </div>

      <div className="relative w-full mt-2 mb-10 bg-gray-300 h-[2px] flex">
        <div className="absolute top-0 left-0 flex">
          <div className="w-[120px] bg-yellow h-[10px]"></div>
          <div className="w-[50px] relative -left-4 bg-yellow h-[10px] skew-x-[-50deg]"></div>
        </div>
      </div>

      <div className="md:pt-4">
        <Slider {...settings} ref={sliderRef}>
          <TestimonialCard
            content="I'm using Osis to order spare parts for my workshop. Delivery is always fast, prices are very competitive "
            name="Sandeep Rijal"
          />
          <TestimonialCard
            content="I can always find parts I need easilly here, the search is both simple and smart."
            name="Abhishek Mishra"
          />
          <TestimonialCard
            content="Catalogs and prices are always up to date. It is very helpfull."
            name="Ashish Sharma"
          />
          <TestimonialCard
            content="I like the delivery services very much, it helps me find the needed spare parts for my car very fast"
            name="Binod Gautam"
          />
          
        </Slider>
      </div>
    </div>
  );
};

export default Testimonials;
