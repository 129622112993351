import React, { useEffect, useRef, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { IoIosArrowUp } from "react-icons/io";

const DropdownWithFilter = ({
  buttonLabel,
  items,
  icon: IconComponent,
  hasBgColor,
  selectedItems,
  onSelectionChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const dropdownButtonRef = useRef(null);
  const dropdownMenuRef = useRef(null);
  const searchInputRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
    if (dropdownMenuRef.current) {
      dropdownMenuRef.current.classList.toggle("hidden");
    }
  };

  const handleButtonClick = () => {
    toggleDropdown();
  };

  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const handleItemClick = (item) => {
    const updatedSelection = selectedItems.includes(item)
      ? selectedItems.filter((value) => value !== item)
      : [...selectedItems, item];
    onSelectionChange(updatedSelection);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownButtonRef.current &&
        !dropdownButtonRef.current.contains(event.target) &&
        dropdownMenuRef.current &&
        !dropdownMenuRef.current.contains(event.target)
      ) {
        setIsOpen(false);
        if (dropdownMenuRef.current) {
          dropdownMenuRef.current.classList.add("hidden");
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const filteredItems = items.filter(
    (item) =>
      item.toLowerCase().includes(searchTerm) ||
      selectedItems.includes(item.toLowerCase())
  );

  return (
    <div className={`relative min-w-[200px] group`}>
      <button
        ref={dropdownButtonRef}
        className={`w-full flex justify-between items-center gap-2 px-4 py-1.5 text-sm font-medium text-gray-500 ${
          hasBgColor ? "bg-gray-200" : ""
        }`}
        onClick={handleButtonClick}
      >
        <div className="flex items-center gap-2">
          {IconComponent && <IconComponent className="w-[20px] h-[20px]" />}
          <span className="text-start">
            {selectedItems.length > 0
              ? selectedItems.map((item) => item.name || item).join(", ")
              : buttonLabel || "Select"}
          </span>
        </div>

        <div className="flex flex-col">
          <IoIosArrowUp className="w-[15px] h-[15px] rotate-180" />
        </div>
      </button>

      <div
        ref={dropdownMenuRef}
        className={`${
          isOpen ? "" : "hidden"
        } w-full absolute z-20 max-h-[150px] overflow-y-auto left-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-1.5 space-y-1`}
      >
        <div className="relative flex bg-white items-center gap-2 px-2 py-1.5 rounded-md border outline-none border-gray-300 focus:outline-none text-gray-500">
          <FiSearch className="" />
          <input
            ref={searchInputRef}
            className="w-full text-sm outline-none bg-transparent"
            type="text"
            placeholder="Search"
            autoComplete="off"
            onChange={handleSearchInputChange}
          />
        </div>

        {filteredItems.map((item, index) => (
          <label key={index} className="flex items-center">
            <input
              type="checkbox"
              value={item}
              checked={selectedItems.includes(item)}
              onChange={() => handleItemClick(item)}
              className="mr-2"
            />
            <span className="text-sm text-gray-700">{item.name || item}</span>
          </label>
        ))}
      </div>
    </div>
  );
};

export default DropdownWithFilter;
