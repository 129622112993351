import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import CarMakerCard from "../common/CarMakerCard";
import SliderNavButtons from "../common/SliderNavButtons";

const TopCarMakers = () => {
  const sliderRef = useRef(null);
  const [carmaker, setCarMaker] = useState([]);

  const handleNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const handlePrev = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const topcarmakerurl =
        process.env.REACT_APP_BASE_URL + "homepage/topcarmaker/";
      try {
        const response = await fetch(`${topcarmakerurl}`);
        const data = await response.json();
        console.log(data);
        const transformedData = data.map((product) => ({
          imageUrl:
            "https://thedriven.io/wp-content/uploads/2020/08/lucid-air-gallery-012.jpg",
          logoUrl: product.image
            ? product.image
            : "https://upload.wikimedia.org/wikipedia/commons/thumb/4/44/BMW.svg/2048px-BMW.svg.png",
          makerName: product.brand ? product.brand : "BMW",
          availability: product.product_count,
        }));
        console.log(transformedData);
        setCarMaker(transformedData);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchData();
  }, []);

  const settings = {
    infinite: true,

    slidesToShow: carmaker.length,
    maxslidesToShow: 4,
    slidesToShow: 5,
    slidesToScroll: 1,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: <></>,
    prevArrow: <></>,

    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 968,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="bg-gray-200 px-6 md:px-10 lg:px-20 pt-5 md:pt-10 pb-5">
      <div className="flex items-center justify-between">
        <h1 className="text-xl font-bold">Top Car Makers</h1>

        <SliderNavButtons onNext={handleNext} onPrev={handlePrev} />
      </div>

      <div className="relative w-full mt-2 mb-10 bg-gray-300 h-[2px] flex">
        <div className="absolute top-0 left-0 flex">
          <div className="w-[160px] bg-yellow h-[10px]"></div>
          <div className="w-[50px] relative -left-4 bg-yellow h-[10px] skew-x-[-50deg]"></div>
        </div>
      </div>

      <div className="md:pt-4">
        <Slider {...settings} ref={sliderRef}>
          {carmaker.map((product, index) => (
            <div key={index} className="px-5 first:ml-0 last:mr-0">
              <CarMakerCard
                imageUrl={product.imageUrl}
                logoUrl={product.logoUrl}
                makerName={product.makerName}
                availability={product.availability}
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default TopCarMakers;
