import { useAtom } from "jotai";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { isSellerProductEditedAtom } from "../ManageProduct/AllProducts/AllProductsTableRow";
import { selectedProductDataAtom } from "./AddProductMain";
import BasicInfoForm from "./BasicInfoForm";
import DescriptionForm from "./DescriptionForm";
import GalleryForm from "./GalleryForm";
import PartsInformationForm from "./PartsInformationForm";
import StepsIndicator, { currentStepAtom } from "./StepsIndicator";
import SuccessPage from "./SuccessPage";
import VariationForm from "./VariationForm";

const AddProduct = () => {
  const [currentStep, setCurrentStep] = useAtom(currentStepAtom);
  const [selectedProductData] = useAtom(selectedProductDataAtom);
  const [isSellerProductEdited, setIsSellerProductEdited] = useAtom(
    isSellerProductEditedAtom
  );

  console.log(isSellerProductEdited);

  const navigate = useNavigate();

  console.log("selectedProductData", selectedProductData);
  let otherPhotosData = [];
  if (selectedProductData && Array.isArray(selectedProductData.otherPhotos)) {
    otherPhotosData = selectedProductData.otherPhotos;
  }
  const initialFormData = {
    id: selectedProductData ? selectedProductData.productId : "",
    vehicleCompany: selectedProductData
      ? selectedProductData.vehicleCompany
      : "",
    subCategory: selectedProductData ? selectedProductData.subCategory : "",
    brand: selectedProductData ? selectedProductData.brand : "",
    model: selectedProductData ? selectedProductData.model : "",
    manufactureYear: selectedProductData
      ? selectedProductData.manufactureYear
      : "",
    partCategories: selectedProductData
      ? selectedProductData.partCategories
      : "",
    partSubCategories: selectedProductData
      ? selectedProductData.partSubCategories
      : "",
    partName: selectedProductData ? selectedProductData.partName : "",
    partNumber: selectedProductData ? selectedProductData.partNumber : [],
    coverPhoto: selectedProductData ? selectedProductData.coverPhoto : null,
    otherPhotos: [...otherPhotosData, ...Array(7 - otherPhotosData.length).fill(null)],
    availability: selectedProductData
      ? selectedProductData.availability
      : "In Stock",
    description: selectedProductData ? selectedProductData.description : "",
    // otherAttributes: Array.from({ length: 5 }, (_, index) => ({
    //   attributeName: `Attribute ${index + 1}`,
    //   attributeValue: "",
    // })),
    otherAttributes: selectedProductData
      ? selectedProductData.otherAttributes
      : Array.from({ length: 5 }, (_, index) => ({
          attributeName: `Attribute ${index + 1}`,
          attributeValue: "",
        })),
    tags:selectedProductData && selectedProductData.tags && selectedProductData.tags.length > 0
      ? selectedProductData.tags
      : [],
    stockQuantity: selectedProductData ? selectedProductData.stockQuantity : "",
    normalRate: selectedProductData ? selectedProductData.normalRate : "",
    units: selectedProductData ? selectedProductData.units : "",
    bulkRate: selectedProductData ? selectedProductData.bulkRate : "",
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleInputChange = (name, value) => {
    setFormData((prevData) => {
      // If the input is partNumber, we assume it's an array
      if (name === "partNumber") {
        // Ensure the value is an array, and update the formData
        return {
          ...prevData,
          [name]: Array.isArray(value) ? value : [value],
        };
      }

      return {
        ...prevData,
        [name]: value,
      };
    });
  };

  const handleTagAddDelete = (tag) => {
    console.log("tag", tag);
    setFormData((prevData) => {
      if (prevData.tags.includes(tag)) {
        return {
          ...prevData,
          tags: prevData.tags.filter((t) => t !== tag),
        };
      } else {
        return {
          ...prevData,
          tags: [...prevData.tags, tag],
        };
      }
    });
  };

  const handleOtherAttributesChange = (index, name, value) => {
    setFormData((prevData) => {
      const otherAttributes = [...prevData.otherAttributes];
      otherAttributes[index] = {
        ...otherAttributes[index],
        [name]: value,
      };
      return {
        ...prevData,
        otherAttributes,
      };
    });
  };

  const handleCoverPhotoChange = (file) => {
    setFormData((prevData) => ({
      ...prevData,
      coverPhoto: file,
    }));
  };

  const handleOtherPhotoChange = (file, index) => {
    setFormData((prevData) => ({
      ...prevData,
      otherPhotos: prevData.otherPhotos.map((photo, i) =>
        i === index ? file : photo
      ),
    }));
  };

  const renderForm = () => {
    switch (currentStep) {
      case 1:
        return (
          <BasicInfoForm
            formData={formData}
            onInputChange={handleInputChange}
          />
        );
      case 2:
        return (
          <PartsInformationForm
            formData={formData}
            onInputChange={handleInputChange}
          />
        );
      case 3:
        return (
          <GalleryForm
            formData={formData}
            onCoverPhotoChange={handleCoverPhotoChange}
            onOtherPhotoChange={handleOtherPhotoChange}
          />
        );
      case 4:
        return (
          <DescriptionForm
            formData={formData}
            setFormData={setFormData}
            onInputChange={handleInputChange}
            handleOtherAttributesChange={handleOtherAttributesChange}
            handleTagAddDelete={handleTagAddDelete}
          />
        );
      case 5:
        return (
          <VariationForm
            formData={formData}
            onInputChange={handleInputChange}
          />
        );
      case 6:
        return <SuccessPage />;
      default:
        return null;
    }
  };

  return (
    <>
      {isSellerProductEdited && (
        <button
          className="ml-8 mt-4 bg-blue px-4 py-1.5 rounded-md text-[14px] text-white font-semibold flex gap-2 items-center"
          onClick={() => {
            setIsSellerProductEdited(false);
            navigate(-1);
            setCurrentStep(1);
          }}
        >
          Back to Manage
        </button>
      )}

      <div className="px-8 py-6 flex gap-4 sm:gap-8">
        {renderForm()}

        <div className="hidden sm:block">
          {currentStep !== 6 && <StepsIndicator />}
        </div>
      </div>
    </>
  );
};

export default AddProduct;
