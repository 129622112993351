import axios from "axios";
import React, { useState } from "react";
import { FaUndo } from "react-icons/fa";
import { HiMinus, HiPlus } from "react-icons/hi";
import { toast } from "react-toastify"; // Import ToastContainer and toast from react-toastify
import "react-toastify/dist/ReactToastify.css"; // Import CSS for react-toastify

export const DeletedUsersTableRow = ({ formData, fetchData }) => {
  const [isMobileTableRowOpen, setIsMobileTableRowOpen] = useState(false);

  const unbanuser = async (e) => {
    e.preventDefault();

    const url =
      process.env.REACT_APP_BASE_URL +
      `auth/updaterole/${parseInt(formData.id)}/User/`;
    try {
      const response = await axios.put(`${url}`, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("Data sent successfully:", response.data);

      fetchData();

      toast.success("User unbanned successfully!");
    } catch (error) {
      console.error("Error sending data:", error);
      // Handle error
      toast.error("Failed to unban user!");
    }
  };

  return (
    <>
      <div className="border-b-2 border-gray-400 px-5 py-3 sm:hidden">
        <div className="flex items-center gap-4">
          <div
            className={`w-6 h-6 flex justify-center items-center ${
              isMobileTableRowOpen
                ? "bg-blue text-white rounded-[4px]"
                : "border-2 border-gray-400 text-gray-400 rounded-sm"
            }`}
          >
            <button
              onClick={(event) => {
                event.preventDefault();
                setIsMobileTableRowOpen(!isMobileTableRowOpen);
              }}
            >
              {isMobileTableRowOpen ? (
                <HiMinus className="w-5 h-5" />
              ) : (
                <HiPlus className="w-5 h-5" />
              )}
            </button>
          </div>

          <div className="text-start">
            <h1 className="text-gray-900 font-bold text-[15px] uppercase">
              User Name:
            </h1>
            <p className="text-gray-800 font-medium text-[15px]">
              {formData.name}
            </p>
          </div>
        </div>

        {isMobileTableRowOpen && (
          <div className="flex flex-col gap-2 mt-4 ml-[2.4rem]">
            <div className="flex flex-row gap-2 items-center">
              <h2 className="text-[15px] text-gray-600 font-bold uppercase md:flex-1">
                Email / Phone Num:
              </h2>
              <p className="text-[15px] text-gray-600 font-medium md:flex-1">
                {formData.username}
              </p>
            </div>

            <div className="flex items-center gap-2">
              <button className="px-1.5 border-2 text-green flex items-center gap-2 text-[15px] border-gray-300 font-medium rounded-md h-[35px]">
                <FaUndo className="w-5 h-5" />
                Unban User
              </button>
            </div>
          </div>
        )}
      </div>

      <tr className="border-b-2 border-gray-300 text-gray-700 relative hidden sm:table-row hover:bg-gray-100">
        <td className="text-center">{formData.name}</td>
        <td className="text-center">{formData.username}</td>

        <td>
          <div className="flex items-center justify-center gap-2">
            <button
              className="px-1.5 border-2 text-green flex items-center gap-2 text-[15px] border-gray-300 font-medium rounded-md h-[35px]"
              onClick={unbanuser}
            >
              <FaUndo className="w-5 h-5" />
              Unban User
            </button>
          </div>
        </td>
      </tr>
    </>
  );
};
